import { EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
/**
 * Servicio para la gestión de las alertas.
 *
 * @author lreverendo
 *
 * @version 01.02.0010
 * @since 01.02.0000
 */
var AlertService = /** @class */ (function () {
    function AlertService(translateService) {
        this.translateService = translateService;
        /**
         * Evento para mostrar una alerta.
         */
        this.alert = new EventEmitter();
        /**
         * Evento para mostrar un modal.
         */
        this.modal = new EventEmitter();
    }
    AlertService.prototype.lanzarExito = function (msj) {
        var data = {
            mensaje: msj,
            exito: true
        };
        this.alert.emit(data);
    };
    AlertService.prototype.showAlertToast = function (mensaje) {
        var data = {
            mensaje: this.translateService.instant(mensaje),
            exito: false
        };
        this.alert.emit(data);
    };
    AlertService.prototype.lanzarError = function (status) {
        if (status !== 401 && status !== 403 && status !== 404) {
            var data = {
                mensaje: this.translateService.instant('GENERAL.ERRORES.SERVIDOR'),
                exito: false
            };
            this.alert.emit(data);
        }
    };
    AlertService.prototype.lanzarErrorPermiso = function () {
        var data = {
            titulo: this.translateService.instant('MODALES.AVISOPERMISO.TITULO'),
            mensaje: this.translateService.instant('MODALES.AVISOPERMISO.MENSAJE'),
            buttons: [
                {
                    texto: this.translateService.instant('MODALES.AVISOPERMISO.OK'),
                    type: 'submit',
                    cerrar: true
                }
            ],
            cerrar: false
        };
        this.modal.emit(data);
    };
    AlertService.prototype.lanzarErrorSesion = function () {
        var data = {
            titulo: this.translateService.instant('MODALES.AVISOCADUCADA.TITULO'),
            mensaje: this.translateService.instant('MODALES.AVISOCADUCADA.MENSAJE'),
            buttons: [
                {
                    texto: this.translateService.instant('MODALES.AVISOCADUCADA.OK'),
                    type: 'submit',
                    cerrar: true
                }
            ],
            cerrar: false
        };
        this.modal.emit(data);
    };
    AlertService.ngInjectableDef = i0.defineInjectable({ factory: function AlertService_Factory() { return new AlertService(i0.inject(i1.TranslateService)); }, token: AlertService, providedIn: "root" });
    return AlertService;
}());
export { AlertService };
