import { BehaviorSubject } from 'rxjs';
import { BiomedidasService } from './biomedidas.service';
import { AlertService } from './alert.service';
import * as i0 from "@angular/core";
import * as i1 from "./biomedidas.service";
import * as i2 from "./alert.service";
/**
 * Servicio para la gestión de los dispositivos.
 *
 * @author lreverendo
 * @author aarodriguezo
 *
 * @version 01.02.0200
 * @since 01.02.0000
 */
var DispositivoService = /** @class */ (function () {
    function DispositivoService(biomedidasService, alertService) {
        this.biomedidasService = biomedidasService;
        this.alertService = alertService;
        this.dispositivos = [];
        this.dispositivosSubject = new BehaviorSubject(null);
        this.dispositivos$ = this.dispositivosSubject.asObservable();
    }
    DispositivoService.prototype.cargarDispositivos = function () {
        if (this.dispositivos.length === 0) {
            this.biomedidasService.getDispositivos().subscribe(this.filtrarDispositivos.bind(this), this.gestionarError.bind(this));
        }
    };
    DispositivoService.prototype.getDispositivos = function () {
        return this.dispositivos;
    };
    DispositivoService.prototype.setDispositivos = function (dispositivos) {
        this.filtrarDispositivos(dispositivos);
        return this.dispositivos;
    };
    DispositivoService.prototype.filtrarDispositivos = function (dispositivos) {
        // Filtro de los dispositivos para mostrar la descripción correcta.
        for (var _i = 0, dispositivos_1 = dispositivos; _i < dispositivos_1.length; _i++) {
            var disp = dispositivos_1[_i];
            switch (disp.subtipo.cod) {
                case 'TENS': {
                    disp.descripcion = 'BIOMEDIDAS.TENSIOMETRO.DESCRIPCION';
                    break;
                }
                case 'BASC': {
                    disp.descripcion = 'BIOMEDIDAS.BASCULA.DESCRIPCION';
                    break;
                }
                case 'IMC': {
                    disp.descripcion = 'BIOMEDIDAS.BASCULAIMC.DESCRIPCION';
                    break;
                }
                case 'COAG': {
                    disp.descripcion = 'BIOMEDIDAS.COAGULOMETRO.DESCRIPCION';
                    break;
                }
                case 'GLUC': {
                    disp.descripcion = 'BIOMEDIDAS.GLUCOMETRO.DESCRIPCION';
                    break;
                }
                case 'PULS': {
                    disp.descripcion = 'BIOMEDIDAS.PULSIOXIMETRO.DESCRIPCION';
                    break;
                }
                case 'TERM': {
                    disp.descripcion = 'BIOMEDIDAS.TERMOMETRO.DESCRIPCION';
                    break;
                }
                case 'INS': {
                    disp.descripcion = 'BIOMEDIDAS.INSULINA.DESCRIPCION';
                    break;
                }
                case 'RHC': {
                    disp.descripcion = 'BIOMEDIDAS.RHC.DESCRIPCION';
                    break;
                }
                case 'EF': {
                    disp.descripcion = 'BIOMEDIDAS.EF.DESCRIPCION';
                    break;
                }
            }
        }
        this.dispositivos = dispositivos;
        this.dispositivosSubject.next(this.dispositivos);
    };
    DispositivoService.prototype.buscarDispositivo = function (actividad, dispositivos) {
        // Función que se encarga de abrir el dispositivo correspondiente a la actividad de toma de biomedida.
        var split = actividad.agrupador.split('_2', 1);
        var agrupador = split[0];
        var agrupador2 = agrupador.split('_')[1];
        // Se encuentra el dispositivo correspondiente a la actividad.
        var dispositivo = dispositivos.filter(function (d) { return d.sensor.actividad.indexOf(agrupador) > -1 || d.sensor.variablesclinicas.indexOf(agrupador2) > -1; })[0];
        return dispositivo;
    };
    DispositivoService.prototype.gestionarError = function (error) {
        this.alertService.lanzarError(error.status);
    };
    DispositivoService.ngInjectableDef = i0.defineInjectable({ factory: function DispositivoService_Factory() { return new DispositivoService(i0.inject(i1.BiomedidasService), i0.inject(i2.AlertService)); }, token: DispositivoService, providedIn: "root" });
    return DispositivoService;
}());
export { DispositivoService };
