<div class="container general-container">
  <div class="row tel-encabezado-pagina">
    <div class="col-12">
      <h3 class="tel-titulo-pagina">
        <strong>{{ 'MEDICACION.TITULO' | translate }}</strong>
      </h3>
    </div>
  </div>
  <div class="row">
    <div class="separador"></div>
  </div>

  <loader></loader>

  <div class="row justify-content-center" *ngIf="listaMedicacion.length < 1 && !loader.active && !errorServicio">
    <div class="sinDatos col-12">
      <h4>{{ 'MEDICACION.SIN' | translate }}</h4>
    </div>
  </div>
  <div class="row justify-content-center" *ngIf="listaMedicacion.length < 1 && !loader.active && errorServicio">
    <div class="sinDatos col-12">
      <h4>{{ 'MEDICACION.ERROR' | translate }}</h4>
    </div>
  </div>
  <div class="tel-cuerpo-pagina" data-simplebar>
    <div class="medicacion" *ngIf="listaMedicacion.length > 0 && !loader.active">
      <div class="row">
        <div class="col-auto">
          <div class="fecha">
            <label class="diaMes">{{ seleccionada.fecha | date: 'dd' }}</label>
            <label class="mes">{{ seleccionada.fecha | date: 'MMM' }}</label>
            <label class="ano">{{ seleccionada.fecha | date: 'yyyy' }}</label>
          </div>
        </div>
        <div class="col row detalle-seleccionada">
          <div class="col-9 row">
            <div class="col-12 cabecera-detalle">
              {{ 'MEDICACION.TES' | translate }}
            </div>
            <div class="col-12">
              {{ seleccionada.test }}
            </div>
          </div>
          <div class="col-3 row">
            <div class="col-12 cabecera-detalle">
              {{ 'MEDICACION.RES' | translate }}
            </div>
            <div class="col-12">
              {{ seleccionada.resultado | number: '.1' }}
            </div>
          </div>
          <div class="col-9 row">
            <div class="col-12 cabecera-detalle">
              {{ 'MEDICACION.FARMACO' | translate }}
            </div>
            <div class="col-12">
              {{ seleccionada.farmaco }}
            </div>
          </div>
          <div class="col-3 row">
            <div class="col-12 cabecera-detalle">
              {{ 'MEDICACION.DOSIS' | translate }}
            </div>
            <div class="col-12">
              {{ seleccionada.dosis | number: '.1' }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center pdf-row" *ngIf="listaMedicacion.length > 0">
      <div class="col-12 col-sm-9 pdf">
        <iframe div #iframe class="pdf"> </iframe>
      </div>
    </div>
    <div class="row linkOtros justify-content-end" *ngIf="listaMedicacion.length > 0">
      <i class="icon-historial historial"></i>
      <div class="otros" (click)="verOtrosResultados()">
        {{ 'MEDICACION.OTROS' | translate }}
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="otros">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h3>
          <strong> {{ 'MEDICACION.OTROS' | translate }} </strong>
        </h3>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <div class="modal-body">
        <div class="row justify-content-center" *ngIf="listaMedicacion.length < 1 && !loader.active">
          <div class="sinDatos col-12">
            <h4>{{ 'MEDICACION.SIN' | translate }}</h4>
          </div>
        </div>
        <div class="row tabla-cabecera d-none d-sm-flex" *ngIf="listaMedicacion.length > 0">
          <div class="col-2 cabecera">
            {{ 'MEDICACION.DATA' | translate }}
          </div>
          <div class="col-10 detalle-resultado">
            <div class="row">
              <div class="col-3 cabecera">
                {{ 'MEDICACION.TES' | translate }}
              </div>
              <div class="col cabecera campo-resultado">
                {{ 'MEDICACION.RES' | translate }}
              </div>
              <div class="col-5 cabecera">
                {{ 'MEDICACION.FARMACO' | translate }}
              </div>
              <div class="col cabecera campo-dosis">
                {{ 'MEDICACION.DOSIS' | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="scroll" data-simplebar>
          <div
            class="row resultado"
            *ngFor="let medicacion of listaMedicacion"
            [ngClass]="{ seleccionada: medicacion.idControl == seleccionada.idControl }"
            (click)="abrirPdf(medicacion)"
          >
            <div class="col-2 d-none d-md-block">
              {{ medicacion.fecha | date: 'dd/MM/yyyy' }}
            </div>
            <div class="col-2 d-none d-sm-block d-md-none">
              {{ medicacion.fecha | date: 'dd/MM/yy' }}
            </div>
            <div class="col-2 d-block d-sm-none">
              <div class="fecha">
                <label class="diaMes">{{ medicacion.fecha | date: 'dd' }}</label>
                <label class="mes">{{ medicacion.fecha | date: 'MMM' }}</label>
                <label class="ano">{{ medicacion.fecha | date: 'yyyy' }}</label>
              </div>
            </div>
            <div class="col-10 detalle-resultado">
              <div class="row">
                <div class="col-8 d-block d-sm-none cabecera-detalle">
                  {{ 'MEDICACION.TES' | translate }}
                </div>
                <div class="col d-block d-sm-none campo-resultado cabecera-detalle">
                  {{ 'MEDICACION.RES' | translate }}
                </div>
                <div class="col-sm-3 col-8 campo-detalle">
                  {{ medicacion.test }}
                </div>
                <div class="col campo-resultado campo-detalle">
                  {{ medicacion.resultado | number: '.1' }}
                </div>
                <div class="col-8 d-block d-sm-none cabecera-detalle">
                  {{ 'MEDICACION.FARMACO' | translate }}
                </div>
                <div class="col d-block d-sm-none campo-dosis cabecera-detalle">
                  {{ 'MEDICACION.DOSIS' | translate }}
                </div>
                <div class="col-sm-5 col-8 campo-detalle">
                  {{ medicacion.farmaco }}
                </div>
                <div class="col campo-dosis campo-detalle">
                  {{ medicacion.dosis | number: '.1' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-lg" data-dismiss="modal">{{ 'GENERAL.CERRAR' | translate }}</button>
      </div>
    </div>
  </div>
</div>
