import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { AutenticacionService } from '../services/autenticacion.service';
import { Observable } from 'rxjs';

/**
 * Servicio que controla el redireccionamiento cuando el usuario está autenticado.
 *
 * @author priveiro
 *
 * @version 01.02.0002
 * @since 01.02.0000
 */
@Injectable({ providedIn: 'root' })
export class LoginGuardService implements CanActivate {
  constructor(private autenticacionService: AutenticacionService) {}

  public canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      if (this.autenticacionService.isUsuarioAutenticado()) {
        observer.next(false);
      } else {
        this.autenticacionService.borrarDatosAutenticacion();
        observer.next(true);
      }
    });
  }
}
