<div class="form-group" *ngIf="inr" [formGroup]="forma">
  <label for="inr" class="col-form-label" id="inrLabel" title="Cociente Internacional Normalizado">
    {{ 'BIOMEDIDAS.VARIABLES.' + inr.alias + '.descripcion' | translate }}
  </label>
  <div class="datoCol">
    <input
      type="text"
      [appFormatNumber]="inr.formato"
      appBlockCopyPaste
      class="form-control variable focus"
      id="inr"
      formControlName="inr"
    />
  </div>
</div>
<div
  class="filaError d-none"
  *ngIf="inr"
  [ngClass]="{ 'd-table-row': forma.controls['inr'].dirty && forma.controls['inr'].errors?.maxError }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted"> {{ 'BIOMEDIDAS.COAGULOMETRO.INRMAX' | translate }}{{ inr.max }} </small>
</div>
<div
  class="filaError d-none"
  *ngIf="inr"
  [ngClass]="{ 'd-table-row': forma.controls['inr'].dirty && forma.controls['inr'].errors?.minError }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted"> {{ 'BIOMEDIDAS.COAGULOMETRO.INRMIN' | translate }}{{ inr.min }} </small>
</div>
<div
  class="filaError d-none"
  *ngIf="inr"
  [ngClass]="{ 'd-table-row': forma.controls['inr'].dirty && forma.controls['inr'].errors?.required }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted">
    {{ 'BIOMEDIDAS.COAGULOMETRO.INRREQUERIDO' | translate }}
  </small>
</div>
