import { Component, ViewChild, DoCheck } from '@angular/core';
import { ContrasenaComponent } from './views/contrasena/contrasena.component';
import { CuidadoresComponent } from './views/cuidadores/cuidadores.component';
import { DatosComponent } from './views/datos/datos.component';
import { PerfilService } from '../../core/services';

/**
 * Componente para la gestión del perfil del paciente.
 *
 * @author lreverendo
 * @author dvila
 *
 * @version 01.02.0070
 * @since 01.02.0000
 */
@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements DoCheck {
  @ViewChild('datos') datos: DatosComponent;
  @ViewChild('cuidadores') cuidadores: CuidadoresComponent;
  @ViewChild('contrasena') contrasena: ContrasenaComponent;

  public vista = 'datos';
  public activo = 'datos';

  public tieneNasi = true;

  constructor(private perfilService: PerfilService) {}

  public ngDoCheck(): void {
    this.tieneNasi = this.perfilService.tieneNasi();
  }

  public loadDatos(): void {
    // Se carga o recarga la vista datos.
    if (this.activo === 'datos') {
      // Se lanza el evento reloadDia para recargar la vista datos.
      this.datos.reload();
    }
    this.activo = 'datos';
  }

  public loadCuidadores(): void {
    // Se carga o recarga la vista cuidadores.
    if (this.activo === 'cuidadores') {
      // Se lanza el evento reloadDia para recargar la vista cuidadores.
      this.cuidadores.reload();
    }
    this.activo = 'cuidadores';
  }

  public loadContrasena(): void {
    // Se carga o recarga la vista contrasena.
    if (this.activo === 'contrasena') {
      // Se lanza el evento reloadDia para recargar la vista contrasena.
      this.contrasena.reload();
    }
    this.activo = 'contrasena';
  }
}
