<table>
  <tr *ngFor="let item of items; let i = index" style="margin-bottom: 1em" [ngClass]="{ lineaPar: i % 2 == 0 }">
    <td
      class="pregunta"
      (click)="clickPregunta(item.pregunta.id)"
      [ngClass]="{ preguntaResumen: item.pregunta.id > -1 }"
      [innerHTML]="item.pregunta.nombre"
    ></td>
    <td class="respuesta">
      <strong *ngIf="item.respuesta.id != null">{{ item.respuesta.descripcion }}</strong>
      <strong *ngIf="item.respuesta.id == null">-</strong>
    </td>
  </tr>
</table>
