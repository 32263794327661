import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe que comprueba si un string está contenido en otro.
 *
 * @author lreverendo
 *
 * @version 01.02.0000
 * @since 01.02.0000
 */
@Pipe({
  name: 'contiene'
})
export class ContienePipe implements PipeTransform {
  public transform(value: string[], elem: string) {
    if (value.indexOf(elem) > -1) {
      return true;
    } else {
      return false;
    }
  }
}
