<div class="modal fade" id="mensaje">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h3 title="{{ mensaje.asunto | htmlTexto }}">
          <strong>{{ mensaje.asunto | htmlTexto }}</strong>
        </h3>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <div class="modal-separador"></div>

      <!-- Modal body -->
      <div class="modal-body" data-simplebar>
        <div class="fechaEnviado" *ngIf="!recibido">
          {{ 'MENSAJERIA.VISUALIZACION.ENVIO' | translate }}: {{ mensaje.fechaEnvio | date: 'dd/MM/yyyy' }} -
          {{ mensaje.fechaEnvio | date: 'HH:mm' }}h
        </div>
        <div class="fechaEnviado" *ngIf="recibido">
          {{ 'MENSAJERIA.VISUALIZACION.RECIBIDO' | translate }}: {{ mensaje.fechaEnvio | date: 'dd/MM/yyyy' }} -
          {{ mensaje.fechaEnvio | date: 'HH:mm' }}h
        </div>
        <div class="cuerpoMensaje">
          <pre>{{ mensaje.cuerpo | htmlTexto }}</pre>
        </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-lg" data-dismiss="modal">{{ 'GENERAL.CERRAR' | translate }}</button>
        <button
          type="button"
          class="btn btn-success btn-lg"
          data-dismiss="modal"
          *ngIf="recibido && permisoEnvioMensaje"
          (click)="nuevoMensaje()"
        >
          {{ 'MENSAJERIA.VISUALIZACION.RESPONDER' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
