import { HttpClient } from '@angular/common/http';
import { sprintf } from 'sprintf-js';
import { AppConfigService } from './app-config.service';
import { AutenticacionService } from './autenticacion.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./autenticacion.service";
/**
 * Servicio para la gestión de la actividad de extracción analítica
 *
 * @author dvila
 *
 * @version 01.02.0030
 * @since 01.02.0000
 */
var AnaliticaService = /** @class */ (function () {
    function AnaliticaService(http, autenticacionService) {
        this.http = http;
        this.autenticacionService = autenticacionService;
    }
    AnaliticaService.prototype.crearExtraccionAnalitica = function (analitica) {
        var dniPaciente = this.autenticacionService.getLoginPaciente();
        var url = sprintf(AppConfigService.urls.crearExtraccionAnalitica, dniPaciente);
        return this.http.post(url, analitica);
    };
    AnaliticaService.prototype.getExtraccionAnalitica = function (idAnalitica) {
        var dniPaciente = this.autenticacionService.getLoginPaciente();
        var url = sprintf(AppConfigService.urls.getExtraccionAnalitica, dniPaciente, idAnalitica);
        return this.http.get(url);
    };
    AnaliticaService.ngInjectableDef = i0.defineInjectable({ factory: function AnaliticaService_Factory() { return new AnaliticaService(i0.inject(i1.HttpClient), i0.inject(i2.AutenticacionService)); }, token: AnaliticaService, providedIn: "root" });
    return AnaliticaService;
}());
export { AnaliticaService };
