<div class="modal fade" id="biomedida">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h3>
          <strong> {{ actividad.titulo | localeTexto: getCurrentIdioma() }} </strong>
        </h3>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <div class="modal-separador"></div>

      <!-- Modal body -->
      <div class="modal-body" data-simplebar>
        <div>
          {{ 'BIOMEDIDAS.VISUALIZACION.ENVIO' | translate }}: {{ actividad.fechaActividad | date: 'dd/MM/yyyy' }} -
          {{ actividad.fechaActividad | date: 'HH:mm' }}h
        </div>
        <p class="identificadorEjercicio" *ngIf="actividad.antesEjercicio">
          {{ 'CALENDARIO.IDENTIFICADOR_EJERCICIO.ANTES' | translate }}
        </p>
        <p class="identificadorEjercicio" *ngIf="actividad.duranteEjercicio">
          {{ 'CALENDARIO.IDENTIFICADOR_EJERCICIO.DURANTE' | translate }}
        </p>
        <p class="identificadorEjercicio" *ngIf="actividad.despuesEjercicio">
          {{ 'CALENDARIO.IDENTIFICADOR_EJERCICIO.DESPUES' | translate }}
        </p>
        <div class="variables">
          <div class="form-group row" *ngIf="periodo != null">
            <label class="col-6 col-form-label"
              ><strong>{{ 'BIOMEDIDAS.PERIODODIA' | translate }}</strong
              >:</label
            >
            <div class="datoCol col-6">
              <input type="text" readonly class="form-control-plaintext" value="{{ 'BIOMEDIDAS.PERIODOS.' + periodo | translate }}" />
            </div>
          </div>
          <div class="form-group row" *ngFor="let data of datasets">
            <label class="col-6 col-form-label">
              <strong>{{ 'BIOMEDIDAS.VARIABLES.' + data.tipo + '.descripcion' | translate }}</strong
              >:
            </label>
            <div class="col-6 datoCol">
              <input
                type="text"
                readonly
                class="form-control-plaintext"
                value="{{ data.biomedida.valor }} {{ 'BIOMEDIDAS.VARIABLES.' + data.tipo + '.unidad' | translate }}"
              />
            </div>
          </div>
          <div class="form-group row" *ngIf="observaciones != null">
            <label class="col-6 col-form-label">
              <strong>{{ 'BIOMEDIDAS.OBSERVACIONES' | translate }}</strong
              >:
            </label>
            <div class="col-6 col-form-label observaciones" [innerHTML]="observaciones"></div>
          </div>
        </div>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-lg" data-dismiss="modal">{{ 'GENERAL.CERRAR' | translate }}</button>
      </div>
    </div>
  </div>
</div>
