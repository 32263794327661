/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./instrucciones.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../shared/components/loader/loader.component.ngfactory";
import * as i4 from "../../shared/components/loader/loader.component";
import * as i5 from "../../core/services/loader.service";
import * as i6 from "@angular/common";
import * as i7 from "./instrucciones.component";
import * as i8 from "../../core/services/alert.service";
import * as i9 from "../../core/services/perfil.service";
var styles_InstruccionesComponent = [i0.styles];
var RenderType_InstruccionesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InstruccionesComponent, data: {} });
export { RenderType_InstruccionesComponent as RenderType_InstruccionesComponent };
function View_InstruccionesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "sinProtocolo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("INSTRUCCIONES.AVISONOENCONTRADO.SUBTITULO")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("INSTRUCCIONES.AVISONOENCONTRADO.MENSAJE")); _ck(_v, 5, 0, currVal_1); }); }
function View_InstruccionesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[1, 0], ["iframe", 1]], null, 0, "iframe", [["class", "pdf"], ["div", ""]], null, null, null, null, null))], null, null); }
export function View_InstruccionesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { iframe: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 21, "div", [["class", "modal fade"], ["id", "instrucciones"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 20, "div", [["class", "modal-dialog modal-lg modal-dialog-centered"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 19, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "close"], ["data-dismiss", "modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.salir() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(11, 0, null, null, 7, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "loader", [], null, null, null, i3.View_LoaderComponent_0, i3.RenderType_LoaderComponent)), i1.ɵdid(13, 442368, null, 0, i4.LoaderComponent, [i1.ElementRef, i5.LoaderService], { for: [0, "for"] }, null), i1.ɵpad(14, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstruccionesComponent_1)), i1.ɵdid(16, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstruccionesComponent_2)), i1.ɵdid(18, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 3, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 2, "button", [["class", "btn btn-primary btn-lg"], ["data-dismiss", "modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.salir() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(21, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 14, 0, _co.operation.cargaInstruccionesPaciente); _ck(_v, 13, 0, currVal_1); var currVal_2 = _co.sinProtocolo; _ck(_v, 16, 0, currVal_2); var currVal_3 = !_co.sinProtocolo; _ck(_v, 18, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("INSTRUCCIONES.TITULO")); _ck(_v, 7, 0, currVal_0); var currVal_4 = i1.ɵunv(_v, 21, 0, i1.ɵnov(_v, 22).transform("GENERAL.CERRAR")); _ck(_v, 21, 0, currVal_4); }); }
export function View_InstruccionesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-instrucciones", [], null, null, null, View_InstruccionesComponent_0, RenderType_InstruccionesComponent)), i1.ɵdid(1, 180224, null, 0, i7.InstruccionesComponent, [i5.LoaderService, i8.AlertService, i9.PerfilService, i2.TranslateService], null, null)], null, null); }
var InstruccionesComponentNgFactory = i1.ɵccf("app-instrucciones", i7.InstruccionesComponent, View_InstruccionesComponent_Host_0, {}, {}, []);
export { InstruccionesComponentNgFactory as InstruccionesComponentNgFactory };
