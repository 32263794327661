import { Component, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { AlertService } from '../../core/services/alert.service';
import { PerfilService } from '../../core/services/perfil.service';
import { LoaderService } from '../../core/services/loader.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { Operation } from '../../core/services/app-config.service';
import { Pdf } from '../../core/models';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;

/**
 * Componente para la carga de instrucciones del protocolo de un paciente.
 *
 * @author lreverendo
 *
 * @version 01.02.0075
 * @since 01.02.0000
 */
@Component({
  selector: 'app-instrucciones',
  templateUrl: 'instrucciones.component.html',
  styleUrls: ['./instrucciones.component.css']
})
export class InstruccionesComponent implements OnDestroy {
  public pdf: Pdf;
  public operation = Operation;
  public sinProtocolo = false;

  private operacionFinalizada = new Subject();
  private BASE64_MARKER = ';base64,';
  private errorSubscription: Subscription;

  @ViewChild('iframe') iframe: ElementRef;

  constructor(
    public loader: LoaderService,
    private alertService: AlertService,
    private perfilService: PerfilService,
    private translateService: TranslateService
  ) {}

  public ngOnDestroy(): void {
    if (this.errorSubscription) {
      this.errorSubscription.unsubscribe();
    }
  }

  public abrirInstrucciones(): void {
    $('#instrucciones').modal('show');
    this.errorSubscription = this.perfilService.error$.subscribe(this.gestionarError.bind(this));
    this.perfilService.getInstrucciones();
    this.perfilService.instruccionesPaciente$
      .pipe(takeUntil(this.operacionFinalizada))
      .subscribe(this.cargarInstrucciones.bind(this), this.gestionarError.bind(this));
  }

  public salir(): void {
    if (!this.sinProtocolo) {
      this.iframe.nativeElement.contentWindow.PDFViewerApplication.close();
    } else {
      this.sinProtocolo = false;
    }
  }

  private cargarInstrucciones(instrucciones: Pdf): void {
    if (instrucciones !== null) {
      this.operacionFinalizada.next();
      this.pdf = instrucciones;

      const pdfAsDataUri = 'data:application/pdf;base64,' + this.pdf.valor;
      const pdfAsArray = this.convertDataURIToBinary(pdfAsDataUri);

      const url = 'assets/viewer/web/viewer.html?file=';

      const binaryData = [];
      binaryData.push(pdfAsArray);
      const dataPdf = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/pdf' }));

      this.iframe.nativeElement.style.width = '100%';
      this.iframe.nativeElement.setAttribute('src', url + encodeURIComponent(dataPdf) + '#locale=' + this.translateService.currentLang);
    }
  }

  private gestionarError(errorResponse: HttpErrorResponse): void {
    this.operacionFinalizada.next();
    if (errorResponse.status === 404) {
      this.sinProtocolo = true;
    } else {
      this.alertService.lanzarError(errorResponse.status);
    }
  }

  private convertDataURIToBinary(dataURI): Uint8Array {
    const base64Index = dataURI.indexOf(this.BASE64_MARKER) + this.BASE64_MARKER.length;
    const base64 = dataURI.substring(base64Index);
    const raw = window.atob(base64);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }
}
