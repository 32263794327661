import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ExternoService, LoginService, PerfilService, PermisosService, SplashScreenService } from '../../core/services';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { RespuestaCertificado, Perfil, Permisos } from '../../core/models';
import { takeUntil } from 'rxjs/operators';
import { AppConfigService } from '../../core/services/app-config.service';

/**
 * Componente principal para paginas que se mostraran al exterior.
 *
 * @author lreverendo
 *
 * @version 01.02.0102
 * @since 01.02.0000
 */
@Component({
  selector: 'app-principal-externo',
  templateUrl: 'principal-externo.component.html',
  styleUrls: ['../principal/principal.component.css', './principal-externo.component.css']
})
export class PrincipalExternoComponent implements OnInit, OnDestroy {
  public errorValiacionXml: boolean;
  public errorPermisos: boolean;
  public nombreUsuario: String;
  private errorSubscription: Subscription;
  private operacion: String;
  private data: String;
  private operacionFinalizada = new Subject();
  private permisos: Permisos;

  constructor(
    private externoService: ExternoService,
    private perfilService: PerfilService,
    private loginService: LoginService,
    private permisosService: PermisosService,
    private splashScreenService: SplashScreenService,
    private translateService: TranslateService,
    public router: Router
  ) {
    this.operacion = this.externoService.getOperacion();
    this.data = this.externoService.getData();
    this.externoService.limpiarDatos();
    this.errorValiacionXml = false;
    this.errorPermisos = false;
    this.nombreUsuario = '';
  }

  public ngOnInit(): void {
    this.abrirPaginaExterna();
  }

  public ngOnDestroy(): void {
    if (this.errorSubscription) {
      this.errorSubscription.unsubscribe();
    }
  }

  private abrirPaginaExterna(): void {
    this.permisos = this.getPermisos();
    if (this.permisos != null && this.permisos.lectura) {
      // Se llama al método encargado de comprobar usuario y pin.
      this.loginService.validarCertificado(this.data).subscribe(this.cargarPerfilUsuario.bind(this), (_error) => {
        this.errorValiacionXml = true;
        this.splashScreenService.cerrarSplashScreen();
      });
    } else {
      this.errorPermisos = true;
      this.splashScreenService.cerrarSplashScreen();
    }
  }

  private cargarPerfilUsuario(respuesta: RespuestaCertificado): void {
    this.perfilService.cargarPerfilUsuario(respuesta.dni);
    this.perfilService.cargarPerfilPaciente(respuesta.dni);
    this.errorSubscription = this.perfilService.error$.subscribe(this.gestionarError.bind(this));
    this.perfilService.perfilUsuario$.pipe(takeUntil(this.operacionFinalizada)).subscribe(this.abrirCalendarioExterno.bind(this));
  }

  private abrirCalendarioExterno(perfilUsuario: Perfil) {
    if (perfilUsuario != null) {
      this.operacionFinalizada.next();

      this.translateService.use(this.translateService.getDefaultLang());

      this.nombreUsuario = perfilUsuario.nombre;

      this.permisosService.setPermisos(this.permisos);

      this.splashScreenService.cerrarSplashScreen();

      if (this.permisos.escritura) {
        this.router.navigate(['/calendario']);
      } else {
        this.router.navigate(['/externo/calendario']);
      }
    }
  }

  private gestionarError(): void {
    this.operacionFinalizada.next();
    this.errorValiacionXml = true;
  }

  private getPermisos(): Permisos {
    const operaciones = Object.keys(AppConfigService.operacionesExterno);

    let permisos: Permisos = null;

    for (const operacion of operaciones) {
      if (operacion === this.operacion) {
        permisos = AppConfigService.operacionesExterno[operacion];
      }
    }

    return permisos;
  }
}
