<div class="form-group" [formGroup]="forma">
  <label for="glucosa" class="col-form-label"
    >{{ 'BIOMEDIDAS.GLUCOMETRO.GLUCOSA' | translate }} ({{ 'BIOMEDIDAS.VARIABLES.' + prandial.alias + '.unidad' | translate }})</label
  >
  <div class="datoCol">
    <input
      type="text"
      [appFormatNumber]="prandial.formato"
      appBlockCopyPaste
      class="form-control variable focus"
      id="glucosa"
      formControlName="glucosa"
    />
  </div>
</div>
<div class="filaError d-none" [ngClass]="{ 'd-table-row': forma.controls['glucosa'].dirty && forma.controls['glucosa'].errors?.maxError }">
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted"> {{ 'BIOMEDIDAS.GLUCOMETRO.GLUCOSAMAX' | translate }}{{ prandial.max }} </small>
</div>
<div class="filaError d-none" [ngClass]="{ 'd-table-row': forma.controls['glucosa'].dirty && forma.controls['glucosa'].errors?.minError }">
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted"> {{ 'BIOMEDIDAS.GLUCOMETRO.GLUCOSAMIN' | translate }}{{ prandial.min }} </small>
</div>
<div class="filaError d-none" [ngClass]="{ 'd-table-row': forma.controls['glucosa'].dirty && forma.controls['glucosa'].errors?.required }">
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted">
    {{ 'BIOMEDIDAS.GLUCOMETRO.GLUCOSAREQUERIDO' | translate }}
  </small>
</div>
