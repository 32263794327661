import { AlertService } from './alert.service';
import { PermisosService } from './permisos.service';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { LocaleTextoPipe } from '../../shared/pipes/locale-texto/locale-texto';
import * as i0 from "@angular/core";
import * as i1 from "./alert.service";
import * as i2 from "./permisos.service";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../shared/pipes/locale-texto/locale-texto";
/**
 * Servicio para la gestión del actividades de tipo videoconferencia.
 *
 * @author lreverendo
 *
 * @version 01.02.0050
 * @since 01.02.0050
 */
var GestionActVideoconferenciaService = /** @class */ (function () {
    function GestionActVideoconferenciaService(alertService, permisoService, translateService, localeTexto) {
        this.alertService = alertService;
        this.permisoService = permisoService;
        this.translateService = translateService;
        this.localeTexto = localeTexto;
    }
    GestionActVideoconferenciaService.prototype.abrirVideoconferencia = function (actividad) {
        switch (actividad.estado) {
            case 'VACIO':
                if (moment(actividad.fechaInicioConMargen).toDate() > new Date()) {
                    this.videoconferenciaFuturo(actividad);
                }
                else if (this.permisoService.getPermisos().escritura) {
                    this.realizarVideoconferencia(actividad);
                }
                break;
            case 'ADHERIDO':
                this.realizarVideoconferencia(actividad);
                break;
            case 'AUSENCIA':
                this.videoconferenciaCaducada(actividad);
                break;
        }
    };
    GestionActVideoconferenciaService.prototype.realizarVideoconferencia = function (actividad) {
        var url = actividad.url;
        var dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        var dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;
        var width = window.innerWidth
            ? window.innerWidth
            : document.documentElement.clientWidth
                ? document.documentElement.clientWidth
                : screen.width;
        var height = window.innerHeight
            ? window.innerHeight
            : document.documentElement.clientHeight
                ? document.documentElement.clientHeight
                : screen.height;
        var w = width - 50;
        var h = height - 50;
        var left = width / 2 - w / 2 + dualScreenLeft;
        var top = height / 2 - h / 2 + dualScreenTop;
        var newWindow = window.open(url, '_blank', 'directories=0,menubar=0,location=0,status=0,titlebar=0,toolbar=0,' + 'width=' + w + ',height=' + h + ',top=' + top + ',left=' + left);
        if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
            this.alertService.showAlertToast('GENERAL.ERRORES.POPUP_BLOQUEADOS');
        }
        else {
            newWindow.focus();
        }
    };
    GestionActVideoconferenciaService.prototype.videoconferenciaFuturo = function (actividad) {
        var data = {
            origen: this,
            titulo: this.localeTexto.transform(actividad.titulo, this.getCurrentIdioma()),
            mensaje: this.translateService.instant('VIDEOCONFERENCIA.NOTIFICACION.FUTURO'),
            buttons: [],
            cerrar: true
        };
        this.alertService.modal.emit(data);
    };
    GestionActVideoconferenciaService.prototype.videoconferenciaCaducada = function (actividad) {
        var data = {
            origen: this,
            titulo: this.localeTexto.transform(actividad.titulo, this.getCurrentIdioma()),
            mensaje: this.translateService.instant('VIDEOCONFERENCIA.NOTIFICACION.CADUCADO'),
            buttons: [],
            cerrar: true
        };
        this.alertService.modal.emit(data);
    };
    GestionActVideoconferenciaService.prototype.getCurrentIdioma = function () {
        // Se recupera el idioma actual.
        return this.translateService.currentLang;
    };
    GestionActVideoconferenciaService.ngInjectableDef = i0.defineInjectable({ factory: function GestionActVideoconferenciaService_Factory() { return new GestionActVideoconferenciaService(i0.inject(i1.AlertService), i0.inject(i2.PermisosService), i0.inject(i3.TranslateService), i0.inject(i4.LocaleTextoPipe)); }, token: GestionActVideoconferenciaService, providedIn: "root" });
    return GestionActVideoconferenciaService;
}());
export { GestionActVideoconferenciaService };
