import { Component, OnInit, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { AlertService, CalendarioService, GestionActBiomedidaService } from '../../core/services';
import { TiposActividad, FiltroActividad, Actividad, Dispositivo } from '../../core/models';
import { RegistroBiomedidaComponent } from '../biomedidas/views/registro-biomedida/registro-biomedida.component';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;

/**
 *  Componente del calendario.
 *
 * @author lreverendo
 *
 * @version 01.02.0050
 * @since 01.02.0000
 */
@Component({
  selector: 'app-calendario',
  templateUrl: 'calendario.component.html',
  styleUrls: ['./calendario.component.css']
})
export class CalendarioComponent implements OnInit, OnDestroy {
  @ViewChild(RegistroBiomedidaComponent) registro: RegistroBiomedidaComponent;
  @ViewChild('popoverInfo') public popoverInfo: NgbPopover;

  public fecha: Date;
  public vista = 'dia';
  public activo = 'dia';
  public dispositivo: Dispositivo;
  public actividadSeleccionada: Actividad;

  public filtro: FiltroActividad;
  public tiposActividadFiltrados: String[] = [];

  private subs: Subscription[] = [];
  private clickInfoPopover = false;

  constructor(
    private alertServiceSuper: AlertService,
    private calendarioService: CalendarioService,
    private gestionActBiomedidaService: GestionActBiomedidaService
  ) {}

  public ngOnInit(): void {
    this.filtro = new FiltroActividad(true, true, true, true, true);

    this.subs.push(this.calendarioService.tiposActividad$.subscribe(this.guardarTiposActividades.bind(this)));
    this.subs.push(this.calendarioService.error$.subscribe(this.gestionarError.bind(this)));

    this.fecha = new Date();
    this.activo = 'dia';

    // Subscripción en el evento vistaDia.
    // Se carga la vista día en la fecha pasada.
    this.subs.push(
      this.calendarioService.vistaDia.subscribe((data) => {
        this.fecha = new Date(data.dia);
        this.vista = 'dia';
        this.activo = 'dia';
      })
    );

    // Subscripción al evento registroBiomedida.
    this.subs.push(
      this.gestionActBiomedidaService.registroBiomedida.subscribe((data) => {
        this.dispositivo = data.dispositivo;
        this.actividadSeleccionada = data.actividad;

        const _this = this;

        // Se abre el modal.
        $('#registro').modal('show');
        $('#registro').on('shown.bs.modal', function () {
          setTimeout(function () {
            $('.focus2').focus();
            $('.focus').focus();
          }, 180);
        });
        $('#registro').on('hidden.bs.modal', function () {
          _this.dispositivo = null;
        });
      })
    );

    $('input:radio[name="options"]').on('click', function () {
      $(this).blur();
    });
  }

  public ngOnDestroy(): void {
    // Baja en todas las subscripciones.
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  public limpiarRegistro(): void {
    this.registro.limpiar();
  }

  public reloadDia(): void {
    // Se carga o recarga la vista diaria.
    if (this.activo === 'dia') {
      // Se lanza el evento reloadDia para recargar la vista diaria.
      this.calendarioService.reload.emit();
    }
    this.fecha = new Date();
    this.activo = 'dia';
  }

  public reloadSemana(): void {
    // Se carga o recarga la vista semanal
    if (this.activo === 'semana') {
      // Se lanza el evento reloadSemana para recargar la vista semanal.
      this.calendarioService.reload.emit();
    }
    this.activo = 'semana';
  }

  public reloadMes(): void {
    // Se carga o recarga la vista mensual.
    if (this.activo === 'mes') {
      // Se lanza el evento reloadMes para recargar la vista mensual.
      this.calendarioService.reload.emit();
    }
    this.activo = 'mes';
  }

  public elegirTipoActividad(filtro: string = ''): void {
    switch (filtro) {
      case 'bio':
        this.filtro.biomedida = !this.filtro.biomedida;
        break;
      case 'cuest':
        this.filtro.cuestionario = !this.filtro.cuestionario;
        break;
      case 'personal':
        this.filtro.personal = !this.filtro.personal;
        break;
      case 'video':
        this.filtro.videoconferencia = !this.filtro.videoconferencia;
        break;
      case 'analitica':
        this.filtro.analitica = !this.filtro.analitica;
        break;
      case '':
        if (this.filtro.todos()) {
          this.filtro = new FiltroActividad(false, false, false, false, false);
        } else {
          this.filtro = new FiltroActividad(true, true, true, true, true);
        }
        break;
    }
  }

  public agregarTipoActividad(filtro: string = ''): void {
    this.elegirTipoActividad(filtro);
    this.filtrarTiposActividad();
  }

  public abrirFiltros(): void {
    $('#filtros').modal('show');
  }

  public filtrarTiposActividad(): void {
    const tipos: String[] = [];
    if (this.filtro.biomedida) {
      tipos.push(this.calendarioService.tiposActividad.biomedida);
    }

    if (this.filtro.cuestionario) {
      tipos.push(this.calendarioService.tiposActividad.cuestionario);
    }

    if (this.filtro.personal) {
      tipos.push(this.calendarioService.tiposActividad.personal);
    }

    if (this.filtro.videoconferencia) {
      tipos.push(this.calendarioService.tiposActividad.videoconferencia);
    }

    if (this.filtro.analitica) {
      tipos.push(this.calendarioService.tiposActividad.analitica);
    }

    this.tiposActividadFiltrados = tipos;
  }

  public eventoClickInfoPopover(): void {
    if (this.clickInfoPopover) {
      this.popoverInfo.close();
      this.clickInfoPopover = !this.clickInfoPopover;
    } else {
      if (!this.popoverInfo.isOpen()) {
        this.clickInfoPopover = !this.clickInfoPopover;
      }
      this.popoverInfo.open();
    }
  }

  public abrirInfoPopover(): void {
    if (!this.clickInfoPopover) {
      this.popoverInfo.open();
    }
  }

  public cerrarInfoPopover(): void {
    if (!this.clickInfoPopover) {
      this.popoverInfo.close();
    }
  }

  @HostListener('document:click', ['$event'])
  public clickOutside(event): void {
    if (
      this.clickInfoPopover &&
      !event.target.classList.contains('icon-info_leyenda_2') &&
      !event.target.parentNode.classList.contains('migas-info')
    ) {
      this.popoverInfo.close();
      this.clickInfoPopover = !this.clickInfoPopover;
    }
  }

  private guardarTiposActividades(tiposActividad: TiposActividad): void {
    if (tiposActividad != null) {
      this.filtrarTiposActividad();
    }
  }

  private gestionarError(error: HttpErrorResponse): void {
    this.alertServiceSuper.lanzarError(error.status);
  }
}
