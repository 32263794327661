import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AutenticacionService } from './autenticacion.service';
import { Biomedida, Dispositivo } from '../models';
import { AppConfigService } from './app-config.service';
import { sprintf } from 'sprintf-js';

/**
 * Servicio para la gestión de las biomedidas.
 *
 * @author lreverendo
 *
 * @version 01.02.0002
 * @since 01.02.0000
 */
@Injectable({ providedIn: 'root' })
export class BiomedidasService {
  constructor(private http: HttpClient, private autenticacionService: AutenticacionService) {}

  public getBiomedidasHistorico(fechaIni: String, fechaFin: String, tipos: String[]): Observable<Biomedida[]> {
    fechaIni = fechaIni.split('T')[0];
    fechaFin = fechaFin.split('T')[0];
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.recuperarBiomedidasHistorico, dniPaciente, fechaIni, fechaFin, tipos);
    return this.http.get<Biomedida[]>(url);
  }

  public getBiomedidas(ids: String[]): Observable<Biomedida[]> {
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.recuperarBiomedidas, dniPaciente, ids);
    return this.http.get<Biomedida[]>(url);
  }

  public getTalla(): Observable<Biomedida[]> {
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.recuperarTalla, dniPaciente);
    return this.http.get<Biomedida[]>(url);
  }

  public getDispositivos(): Observable<Dispositivo[]> {
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.recuperarDispositivos, dniPaciente);
    return this.http.get<Dispositivo[]>(url);
  }

  public enviarBiomedida(body: any): Observable<Biomedida> {
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.enviarBiomedida, dniPaciente);
    return this.http.post<Biomedida>(url, body);
  }
}
