<div class="modal fade" id="modal">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h3 style="width: 100%; text-align: center">
          <strong> {{ titulo }} </strong>
        </h3>
        <button type="button" class="close" data-dismiss="modal" *ngIf="cerrar">&times;</button>
      </div>

      <div class="modal-separador"></div>

      <!-- Modal body -->
      <div class="modal-body" data-simplebar>
        <h4 style="margin: 0 0 1em 0" *ngIf="subtitulo && subtitulo !== null && subtitulo !== ''">{{ subtitulo }}</h4>
        <h5 *ngIf="mensaje !== null && mensaje !== ''">{{ mensaje }}</h5>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <div class="row justify-content-center">
          <div class="col-auto" *ngIf="cerrar">
            <button type="button" class="btn btn-primary btn-lg" data-dismiss="modal">
              {{ 'GENERAL.CERRAR' | translate }}
            </button>
          </div>
          <div class="col-auto buttons" *ngFor="let button of buttons">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              [ngClass]="{ success: button.success, submit: button.type == 'submit' }"
              (click)="onClickButton(button)"
            >
              {{ button.texto }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
