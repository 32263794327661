import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { VariableClinica } from '../../../../../core/models';
import { DispositivoGenerico } from '../dispositivo-generico.model';

import { AlertService } from '../../../../../core/services/alert.service';
import { BiomedidasService, LoaderService } from '../../../../../core/services';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';

declare var $: any;

/**
 * Componente que representa al dispositivo BásculaIMC.
 *
 * @author lreverendo
 *
 * @version 01.02.0070
 * @since 01.02.0000
 */
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tbody [basculaIMC]',
  templateUrl: 'bascula-imc.component.html',
  styleUrls: [
    '../../../biomedidas.component.css',
    '../../../views/registro-biomedida/registro-biomedida.component.css',
    './bascula-imc.component.css'
  ]
})
export class BasculaImcComponent extends DispositivoGenerico implements OnInit, OnDestroy {
  public peso: VariableClinica;
  public talla: VariableClinica;
  public imc: VariableClinica;

  constructor(private alertService: AlertService, private loader: LoaderService, private biomedidasService: BiomedidasService) {
    super();
  }

  public ngOnInit(): void {
    // Se buscan las variables peso, talla e imc.
    this.peso = this.variablesClinicas.find((v) => v.alias === 'PESO');
    this.talla = this.variablesClinicas.find((v) => v.alias === 'TALLA');
    this.imc = this.variablesClinicas.find((v) => v.alias === 'IMC');

    if (this.peso) {
      this.forma.addControl(
        'peso',
        new FormControl('', [
          Validators.required,
          Validators.pattern(this.getPatronFormato(this.peso.formato)),
          CustomValidators.porRango(this.peso.min, this.peso.max)
        ])
      );
    }

    if (this.talla) {
      this.forma.addControl(
        'talla',
        new FormControl('', [
          Validators.required,
          Validators.pattern(this.getPatronFormato(this.talla.formato)),
          CustomValidators.porRango(this.talla.min, this.talla.max)
        ])
      );
    }

    if (this.talla) {
      this.forma.disable();
      this.loader.showLoader();
      // Ser recoge la última talla enviada en los últimos 6 meses.
      this.biomedidasService.getTalla().subscribe(
        (data) => {
          if (data.length > 0) {
            this.talla.valor = Number(data[0].valor);
            this.forma.controls['talla'].setValue(this.talla.valor);
          }
          this.forma.enable();
          $('.focus').focus();
        },
        (error) => {
          this.alertService.lanzarError(error.status);
        }
      );
    }

    // Si hay cambios en el formulario (peso o talla) se recalcula el IMC.
    this.forma.valueChanges.subscribe(() => {
      // Sólo se recalcula el IMC si la talla y el peso no son nulos.
      if (this.forma.status === 'VALID' && this.imc && this.peso && this.talla) {
        const peso = this.forma.value.peso.replace(',', '.');
        const value = (peso / ((this.forma.value.talla / 100) * (this.forma.value.talla / 100))).toFixed(2);
        this.imc.valor = Number(value);
      }
    });

    this.forma.controls['talla'].valueChanges.subscribe((val) => {
      this.forma.controls['talla'].reset(String(val).replace(/[^0-9]/g, ''), { emitEvent: false });
      this.forma.controls['talla'].markAsDirty();
    });
  }

  public ngOnDestroy(): void {
    if (this.loader.active) {
      this.loader.redireccionar.emit();
    }
  }

  public getData(): VariableClinica[] {
    const result: VariableClinica[] = [];

    if (this.peso) {
      const peso = this.forma.value.peso.replace(',', '.');

      if (Number(peso) === NaN) {
        return [];
      }

      this.peso.valor = peso;
      result.push(this.peso);
    }

    if (this.talla) {
      const talla = this.forma.value.talla;

      if (Number(talla) === NaN) {
        return [];
      }
      this.talla.valor = talla;
      result.push(this.talla);
    }

    if (this.imc) {
      if (Number(this.imc) === NaN) {
        return [];
      }
      result.push(this.imc);
    }

    return result;
  }

  public limpiar(): void {
    const _this = this;
    Object.keys(this.forma.controls).map((k) => {
      if (k === 'talla') {
        this.forma.controls[k].reset(_this.talla.valor.toString());
      } else {
        this.forma.controls[k].reset('');
      }
    });
    if (this.imc) {
      this.imc.valor = null;
    }
  }
}
