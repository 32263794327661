import { Injectable } from '@angular/core';

/**
 * Servicio para la pantalla del splash screen.
 *
 * @author lreverendo
 *
 * @version 01.02.0002
 * @since 01.02.0000
 */
@Injectable({ providedIn: 'root' })
export class SplashScreenService {
  constructor() {}

  public cerrarSplashScreen(): void {
    // Oculta el splashScreen
    document.body.style.opacity = '0';

    // Elimina el splashScreen y muestra la pantalla inicial
    setTimeout(function () {
      document.body.style.opacity = '1';
      const splashScreen = document.getElementById('splashScreen');
      splashScreen.parentElement.removeChild(splashScreen);
    }, 800);

    // Elimina los estilos del splashScreen
    setTimeout(function () {
      const splashScreenStyles = document.getElementById('splashScreenStyles');
      splashScreenStyles.parentElement.removeChild(splashScreenStyles);
    }, 5000);
  }
}
