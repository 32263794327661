import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CalendarioService } from '../../../../core/services/calendario.service';
import { Actividad } from '../../../../core/models';
import { LocaleTextoPipe } from '../../../../shared/pipes/locale-texto/locale-texto';

declare var $: any;

/**
 * Componente para la visualización de una actividad personal.
 *
 * @author lreverendo
 *
 * @version 01.02.0000
 * @since 01.02.0000
 */
@Component({
  selector: 'app-visualizacion-actividad-personal',
  templateUrl: './visualizacion-actividad-personal.component.html',
  styleUrls: ['./visualizacion-actividad-personal.component.css']
})
export class VisualizacionActividadPersonalComponent implements OnInit, OnDestroy {
  public titulo = '';
  public descripcion = '';
  public actividad: Actividad = {
    id: '',
    descripcion: [],
    estado: '',
    fechaActividad: '',
    idPaciente: '',
    pautada: true,
    titulo: [],
    tipo: '',
    agrupador: '',
    idProtocolo: '',
    codigoActividad: '',
    fechaInicioConMargen: '',
    fechaFinalConMargen: '',
    fechaInicioSinMargen: '',
    fechaFinalSinMargen: '',
    todoElDia: false,
    url: '',
    idInstancia: '',
    actRelacionadas: [],
    antesEjercicio: false,
    duranteEjercicio: false,
    despuesEjercicio: false
  };
  private sub: Subscription;
  private locale = '';

  constructor(
    private calendarioService: CalendarioService,
    private translateService: TranslateService,
    private localeTexto: LocaleTextoPipe
  ) {}

  public ngOnInit(): void {
    // Subscripción al evento visualizarCuestionario.
    this.sub = this.calendarioService.visualizarActividadPersonal.subscribe((data) => {
      this.locale = this.translateService.currentLang;
      this.actividad = data.actividad;
      this.titulo = this.localeTexto.transform(this.actividad.titulo, this.locale);
      this.descripcion = this.localeTexto.transform(this.actividad.descripcion, this.locale);

      // Se abre el modal.
      $('#visualizacion-personal').modal('show');
    });
  }

  public ngOnDestroy(): void {
    // Baja a la subscripción.
    this.sub.unsubscribe();
  }
}
