import { Component, OnInit } from '@angular/core';
import { VariableClinica } from '../../../../../core/models';
import { DispositivoGenerico } from '../dispositivo-generico.model';
import { Validators, FormControl } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';

/**
 * Componente que representa la EjercicioFisico.
 *
 * @author lreverendo
 * @author dvila
 *
 * @version 01.02.0070
 * @since 01.02.0000
 */
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tbody [ejercicio]',
  templateUrl: 'ejercicio.component.html',
  styleUrls: [
    '../../../biomedidas.component.css',
    '../../../views/registro-biomedida/registro-biomedida.component.css',
    './ejercicio.component.css'
  ]
})
export class EjercicioComponent extends DispositivoGenerico implements OnInit {
  public ef: VariableClinica;

  constructor() {
    super();
  }

  public ngOnInit(): void {
    // Se busca la variable INR.
    this.ef = this.variablesClinicas.find((v) => v.alias === 'EF');

    if (this.ef) {
      this.forma.addControl(
        'pasos',
        new FormControl('', [
          Validators.required,
          Validators.pattern(this.getPatronFormato(this.ef.formato)),
          CustomValidators.porRango(this.ef.min, this.ef.max)
        ])
      );
    }
  }

  public getData(): VariableClinica[] {
    const result: VariableClinica[] = [];

    if (this.ef) {
      const pasos = this.forma.value.pasos;

      this.ef.valor = pasos;
      result.push(this.ef);
    }

    return result;
  }
}
