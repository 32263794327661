<div class="container general-container">
  <div class="row tel-encabezado-pagina">
    <div class="col-12">
      <h3 class="tel-titulo-pagina">
        <strong>{{ 'SOPORTE.TITULO' | translate }}</strong>
      </h3>
    </div>
  </div>
  <div class="row">
    <div class="separador"></div>
  </div>

  <loader></loader>

  <div class="soporte" *ngIf="!loader.active" data-simplebar>
    <div class="mensaje" [innerHTML]="mensajeServicioSoporte"></div>
  </div>
</div>
