import { EventEmitter } from '@angular/core';
import { AlertService } from './alert.service';
import { PermisosService } from './permisos.service';
import { CuestionariosService } from './cuestionarios.service';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { LocaleTextoPipe } from '../../shared/pipes/locale-texto/locale-texto';
import * as i0 from "@angular/core";
import * as i1 from "./alert.service";
import * as i2 from "./cuestionarios.service";
import * as i3 from "./permisos.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../shared/pipes/locale-texto/locale-texto";
/**
 * Servicio para la gestión del actividades de tipo cuestionario.
 *
 * @author lreverendo
 *
 * @version 01.02.0050
 * @since 01.02.0050
 */
var GestionActCuestionarioService = /** @class */ (function () {
    function GestionActCuestionarioService(alertService, cuestionariosService, permisoService, translateService, localeTexto) {
        this.alertService = alertService;
        this.cuestionariosService = cuestionariosService;
        this.permisoService = permisoService;
        this.translateService = translateService;
        this.localeTexto = localeTexto;
        /**
         * Evento para el registro de un cuestionario.
         */
        this.registroCuestionario = new EventEmitter();
        /**
         * Evento para abrir el modal de visualización de un cuestionario.
         */
        this.visualizarCuestionario = new EventEmitter();
    }
    GestionActCuestionarioService.prototype.abrirCuestionario = function (actividad) {
        switch (actividad.estado) {
            case 'VACIO':
                if (moment(actividad.fechaInicioConMargen).toDate() > new Date()) {
                    this.cuestionarioFuturo(actividad);
                }
                else if (this.permisoService.getPermisos().escritura) {
                    this.realizarCuestionario(actividad);
                }
                break;
            case 'ADHERIDO':
                this.abrirVisualizacionCuestionario(actividad);
                break;
            case 'AUSENCIA':
                this.cuestionarioCaducado(actividad);
                break;
        }
    };
    GestionActCuestionarioService.prototype.realizarCuestionario = function (actividad) {
        var _this = this;
        // Función para la realización de un cuestinario.
        this.cuestionariosService
            .getCuestionario(actividad.codigoActividad, this.getCurrentIdioma())
            .subscribe(function (cuestionario) {
            var params = {
                actividad: actividad,
                cuestionario: cuestionario
            };
            if (cuestionario.descripcion || cuestionario.leyenda) {
                var descripcion = null;
                if (cuestionario.descripcion && cuestionario.descripcion.length > 0) {
                    descripcion = [
                        {
                            locale: 'es',
                            texto: cuestionario.descripcion
                        },
                        {
                            locale: 'gl',
                            texto: cuestionario.descripcion_gl
                        },
                        {
                            locale: 'en',
                            texto: cuestionario.descripcion_en
                        }
                    ];
                }
                var leyenda = null;
                if (cuestionario.leyenda && cuestionario.leyenda.length > 0) {
                    leyenda = [
                        {
                            locale: 'es',
                            texto: cuestionario.leyenda
                        },
                        {
                            locale: 'gl',
                            texto: cuestionario.leyenda_gl
                        },
                        {
                            locale: 'en',
                            texto: cuestionario.leyenda_en
                        }
                    ];
                }
                var buttons = [
                    {
                        texto: _this.translateService.instant('CUESTIONARIOS.NOTIFICACION.EMPEZAR'),
                        type: 'button',
                        accion: 'empezarCuestionario',
                        params: params,
                        cerrar: true
                    }
                ];
                var data = {
                    origen: _this,
                    titulo: _this.localeTexto.transform(actividad.titulo, _this.getCurrentIdioma()),
                    subtitulo: _this.localeTexto.transform(descripcion, _this.getCurrentIdioma()),
                    mensaje: _this.localeTexto.transform(leyenda, _this.getCurrentIdioma()),
                    buttons: buttons,
                    cerrar: true
                };
                _this.alertService.modal.emit(data);
            }
            else {
                _this.empezarCuestionario(params);
            }
        }, this.gestionarError.bind(this));
    };
    GestionActCuestionarioService.prototype.empezarCuestionario = function (params) {
        // Se emite el evento de registroCuestionario.
        this.registroCuestionario.emit({
            titulo: params.actividad.titulo,
            plantilla: params.cuestionario.plantilla,
            tipoOntologia: params.actividad.codigoActividad,
            valoraciones: params.cuestionario.valoraciones
        });
    };
    GestionActCuestionarioService.prototype.abrirVisualizacionCuestionario = function (actividad) {
        var _this = this;
        // Carga del cuestionario.
        this.cuestionariosService.getCuestionarioRealizado(actividad.idInstancia, actividad.codigoActividad).subscribe(function (data) {
            // Visualización del cuestionario.
            _this.visualizarCuestionario.emit({
                actividad: actividad,
                cuestionario: data[0]
            });
        }, this.gestionarError.bind(this));
    };
    GestionActCuestionarioService.prototype.cuestionarioCaducado = function (actividad) {
        var ahora = new Date();
        ahora.setHours(0, 0, 0, 0);
        var buttons = [];
        var params = actividad;
        // Si la actividad tiene una fecha de inicio del día actual se permite volver a realizar como actividad no pautada.
        if (!(moment(actividad.fechaInicioConMargen).toDate() < ahora) && this.permisoService.getPermisos().escritura) {
            buttons = [
                {
                    texto: this.translateService.instant('CUESTIONARIOS.NOTIFICACION.REALIZAR'),
                    type: 'button',
                    accion: 'realizarCuestionario',
                    params: params,
                    cerrar: false
                }
            ];
        }
        var data = {
            origen: this,
            titulo: this.localeTexto.transform(actividad.titulo, this.getCurrentIdioma()),
            mensaje: this.translateService.instant('CUESTIONARIOS.NOTIFICACION.CADUCADO'),
            buttons: buttons,
            cerrar: true
        };
        this.alertService.modal.emit(data);
    };
    GestionActCuestionarioService.prototype.cuestionarioFuturo = function (actividad) {
        // Función para la gestión de una actividad cuestionario futura.
        var ahora = new Date();
        ahora.setDate(ahora.getDate() + 1);
        ahora.setHours(0, 0, 0, 0);
        var params = actividad;
        var buttons = [];
        // Si la actividad está programada para el día de hoy y se tienen permisos de escritura
        if (!(moment(actividad.fechaInicioConMargen).toDate() >= ahora) && this.permisoService.getPermisos().escritura) {
            buttons = [
                {
                    texto: this.translateService.instant('CUESTIONARIOS.NOTIFICACION.REALIZAR'),
                    type: 'button',
                    accion: 'realizarCuestionario',
                    params: params,
                    cerrar: false
                }
            ];
        }
        var data = {
            origen: this,
            titulo: this.localeTexto.transform(actividad.titulo, this.getCurrentIdioma()),
            mensaje: this.translateService.instant('CUESTIONARIOS.NOTIFICACION.FUTURO'),
            buttons: buttons,
            cerrar: true
        };
        this.alertService.modal.emit(data);
    };
    GestionActCuestionarioService.prototype.getCurrentIdioma = function () {
        // Se recupera el idioma actual.
        return this.translateService.currentLang;
    };
    GestionActCuestionarioService.prototype.gestionarError = function (error) {
        this.alertService.lanzarError(error.status);
    };
    GestionActCuestionarioService.ngInjectableDef = i0.defineInjectable({ factory: function GestionActCuestionarioService_Factory() { return new GestionActCuestionarioService(i0.inject(i1.AlertService), i0.inject(i2.CuestionariosService), i0.inject(i3.PermisosService), i0.inject(i4.TranslateService), i0.inject(i5.LocaleTextoPipe)); }, token: GestionActCuestionarioService, providedIn: "root" });
    return GestionActCuestionarioService;
}());
export { GestionActCuestionarioService };
