import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermisosService } from '../../../core/services';

@Directive({
  selector: '[appIfPermisoEscritura]'
})
export class IfPermisoEscrituraDirective {
  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private permisosService: PermisosService) {
    this.updateView();
  }

  private updateView() {
    if (this.permisosService.getPermisos().escritura) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
