<div class="mx-auto d-table">
  <div class="row leyenda">
    <div class="colorLeyenda d-table-cell">
      <div class="dot dotCaducada"></div>
    </div>
    <div class="col d-table-cell">
      <div class="actCaducada">
        {{ 'CALENDARIO.INFO.CADUCADA' | translate }}
      </div>
    </div>
  </div>
  <div class="row leyenda">
    <div class="colorLeyenda d-table-cell">
      <div class="dot dotRealizada"></div>
    </div>
    <div class="col d-table-cell">
      <div class="actRealizada">
        {{ 'CALENDARIO.INFO.REALIZADA' | translate }}
      </div>
    </div>
  </div>
  <div class="row leyenda">
    <div class="colorLeyenda d-table-cell">
      <div class="dot dotPautada"></div>
    </div>
    <div class="col d-table-cell">
      <div class="actPautada">
        {{ 'CALENDARIO.INFO.PENDIENTE' | translate }}
      </div>
    </div>
  </div>
  <div class="row leyenda">
    <div class="colorLeyenda d-table-cell">
      <div class="dot dotTodoDia"></div>
    </div>
    <div class="col d-table-cell">
      <div class="actTodoDia">
        {{ 'CALENDARIO.INFO.TODOELDIA' | translate }}
      </div>
    </div>
  </div>
</div>
