import { Component, OnInit, OnChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { VariableClinica } from '../../../../../core/models';
import { DispositivoGenerico } from '../dispositivo-generico.model';
import { CustomValidators } from '../../../../../shared/validators/custom-validators';

/**
 * Componente que representa la Insulina.
 *
 * @author lreverendo
 *
 * @version 01.02.0070
 * @since 01.02.0000
 */
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tbody [insulina]',
  templateUrl: 'insulina.component.html',
  styleUrls: [
    '../../../biomedidas.component.css',
    '../../../views/registro-biomedida/registro-biomedida.component.css',
    './insulina.component.css'
  ]
})
export class InsulinaComponent extends DispositivoGenerico implements OnInit, OnChanges {
  constructor() {
    super();
  }

  public ngOnInit(): void {
    if (this.prandial) {
      this.forma.addControl(
        'insulina',
        new FormControl('', [
          Validators.required,
          Validators.pattern(this.getPatronFormato(this.prandial.formato)),
          CustomValidators.porRango(this.prandial.min, this.prandial.max)
        ])
      );
    }
  }

  public getData(): VariableClinica[] {
    const result: VariableClinica[] = [];

    if (this.prandial) {
      const insulina = this.forma.value.insulina.replace(',', '.');

      if (Number(insulina) === NaN) {
        return [];
      }

      this.prandial.valor = insulina;
      result.push(this.prandial);
    }

    return result;
  }

  public changeMaxMin(): void {
    if (this.forma && this.forma.controls['insulina']) {
      this.forma.controls['insulina'].setValidators([
        Validators.required,
        Validators.pattern(this.getPatronFormato(this.prandial.formato)),
        CustomValidators.porRango(this.prandial.min, this.prandial.max)
      ]);
      this.forma.controls['insulina'].updateValueAndValidity();
    }
  }
}
