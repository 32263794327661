<div class="container">
  <div class="row selec-fecha">
    <div class="selPag col-1 offset-sm-1 offset-lg-2" (click)="restar()">
      <i class="icon-flechaIzq"></i>
    </div>
    <div class="col-10 col-sm-8 col-lg-6 fecha">
      <h3>{{ 'CALENDARIO.SEMANA' | translate }} {{ fechas[0] | semana }} &bull; {{ fechas[0] | date: 'yyyy' }}</h3>
    </div>
    <div class="selPag col-1" (click)="sumar()">
      <i class="icon-flechaDcha"></i>
    </div>
  </div>

  <div class="row contenido">
    <div class="col-12 eventos-cabecera">
      <table class="table table-bordered">
        <tr>
          <th scope="col" *ngFor="let fecha of fechas" class="diasSemana" (click)="irDia(fecha)">
            {{ fecha | dia: getCurrentIdioma() }}
            <br />
            <p style="margin: 0" [ngClass]="{ hoy: fecha == hoy }">
              {{ fecha | date: 'dd' }}
            </p>
          </th>
        </tr>
      </table>
    </div>
    <ngx-simplebar class="col-12 eventos-contenido" #scroll>
      <table class="table table-bordered">
        <tr *ngIf="loader.active && loader.status.operation === 6">
          <td colspan="7">
            <loader [for]="[operation.recuperarActividades]"></loader>
          </td>
        </tr>
        <tr *ngIf="(!loader.active || loader.status.operation !== 6) && actividadesTratadas.length == 0">
          <td colspan="7">
            <h3 class="texto-centrado">
              {{ 'CALENDARIO.SINACT' | translate }}
            </h3>
          </td>
        </tr>
        <tr *ngIf="(!loader.active || loader.status.operation !== 6) && actividadesTratadas.length != 0">
          <td *ngFor="let data of dataset">
            <div *ngFor="let actividad of data.actividades" class="list-group-item btn actividad">
              <div
                class="icono element-click"
                [appClaseTipoActividad]="actividad"
                [conDispositivo]="true"
                (click)="seleccionarActividad(popoverRef, actividad)"
                #popoverRef="ngbPopover"
                [ngbPopover]="popContent"
                [placement]="['top', 'left', 'right', 'auto']"
              ></div>
            </div>
          </td>
        </tr>
      </table>
    </ngx-simplebar>
  </div>

  <div class="row linkActPersonal" *appIfPermisoEscritura>
    <div class="col-12">
      <i class="icon-mas masActPersonal"></i>
      <div class="actPersonal" (click)="crearActividad()">
        {{ 'CALENDARIO.PERSONAL.CREAR.TITULOPAG' | translate }}
      </div>
    </div>
  </div>
</div>

<ng-template #popContent>
  <div
    class="pop"
    id="pop"
    [appBotonActividadPermiso]="actividadSeleccionada"
    (clickBotonActividadPermiso)="abrirActividad(actividadSeleccionada)"
  >
    <div *ngIf="actividadSeleccionada.estado | contiene: 'ADHERIDO'">
      <div class="row">
        <div class="col actAdherida actividadInfo">
          <span class="popover-titulo" id="tituloPopover">{{ actividadSeleccionada.titulo | localeTexto: getCurrentIdioma() }}</span>
        </div>
        <div class="col icon-ok realizado" align-self-center id="tickRealizado"></div>
      </div>
      <div class="row">
        <div class="col actAdherida actividadInfoHora">
          <div *ngIf="!actividadSeleccionada.todoElDia && !(actividadSeleccionada.estado | contiene: 'ADHERIDO')">
            {{ actividadSeleccionada.fechaInicioSinMargen | date: 'HH:mm' }} -
            {{ actividadSeleccionada.fechaFinalSinMargen | date: 'HH:mm' }}
          </div>
          <p class="identificadorEjercicio" *ngIf="actividadSeleccionada.antesEjercicio">
            {{ 'CALENDARIO.IDENTIFICADOR_EJERCICIO.ANTES' | translate }}
          </p>
          <p class="identificadorEjercicio" *ngIf="actividadSeleccionada.duranteEjercicio">
            {{ 'CALENDARIO.IDENTIFICADOR_EJERCICIO.DURANTE' | translate }}
          </p>
          <p class="identificadorEjercicio" *ngIf="actividadSeleccionada.despuesEjercicio">
            {{ 'CALENDARIO.IDENTIFICADOR_EJERCICIO.DESPUES' | translate }}
          </p>
        </div>
        <div class="col realizado">
          <div>{{ actividadSeleccionada.fechaActividad | date: 'HH:mm' }}</div>
        </div>
      </div>
    </div>

    <div *ngIf="!(actividadSeleccionada.estado | contiene: 'ADHERIDO')">
      <div class="row">
        <div class="col-12 actividadInfo">
          <span class="popover-titulo" id="tituloPopover">{{ actividadSeleccionada.titulo | localeTexto: getCurrentIdioma() }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 actividadInfoHora">
          <div *ngIf="!actividadSeleccionada.todoElDia">
            {{ actividadSeleccionada.fechaInicioSinMargen | date: 'HH:mm' }} -
            {{ actividadSeleccionada.fechaFinalSinMargen | date: 'HH:mm' }}
          </div>
          <p class="identificadorEjercicio" *ngIf="actividadSeleccionada.antesEjercicio">Antes del ejercicio físico</p>
          <p class="identificadorEjercicio" *ngIf="actividadSeleccionada.duranteEjercicio">Durante el ejercicio físico</p>
          <p class="identificadorEjercicio" *ngIf="actividadSeleccionada.despuesEjercicio">Después del ejercicio físico</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
