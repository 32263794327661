import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { MensajeriaService } from '../../../../core/services';
import { Mensaje } from '../../../../core/models';

declare var $: any;

/**
 *  Componente para la visualización de un mensaje.
 *
 * @author lreverendo
 *
 * @version 01.02.0075
 * @since 01.02.0000
 */
@Component({
  selector: 'app-visualizacion-mensaje',
  templateUrl: './visualizacion-mensaje.component.html',
  styleUrls: ['../../mensajeria.component.css', './visualizacion-mensaje.component.css']
})
export class VisualizacionMensajeComponent implements OnInit, OnDestroy {
  @Input() permisoEnvioMensaje: Boolean;

  public mensaje: Mensaje = {
    id: '',
    pacienteNombre: '',
    pacienteApellido1: '',
    pacienteApellido2: '',
    cuerpo: '',
    asunto: '',
    leido: null,
    fechaEnvio: '',
    fechaLeido: ''
  };

  public recibido: Boolean = false;

  private sub: Subscription;

  constructor(private mensajeriaService: MensajeriaService) {}

  public ngOnInit(): void {
    // Subscripción al evento visualizarMensaje.
    this.sub = this.mensajeriaService.visualizarMensaje.subscribe(this.mostrarMensaje.bind(this));
  }

  public ngOnDestroy(): void {
    // Baja a la subscripción.
    this.sub.unsubscribe();
  }

  public nuevoMensaje(): void {
    this.mensajeriaService.crearNuevoMensaje.emit({ mensaje: this.mensaje });
  }

  private mostrarMensaje(data: { mensaje: Mensaje; recibido: boolean }): void {
    this.mensaje = data.mensaje;
    $('#mensaje').modal('show');

    this.recibido = data.recibido;
  }
}
