import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AutenticacionService } from './autenticacion.service';
import { AppConfigService } from './app-config.service';
import { sprintf } from 'sprintf-js';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./autenticacion.service";
/**
 * Servicio para la gestión de mensajería.
 *
 * @author lreverendo
 *
 * @version 01.02.0002
 * @since 01.02.0000
 */
var MensajeriaService = /** @class */ (function () {
    function MensajeriaService(http, autenticacionService) {
        this.http = http;
        this.autenticacionService = autenticacionService;
        /**
         * Evento para abrir el modal de visualización de un mensaje.
         */
        this.visualizarMensaje = new EventEmitter();
        /**
         * Evento que recarga los mensajes recibidos.
         */
        this.reloadRecibidos = new EventEmitter();
        /**
         * Evento que recarga los mensajes enviados.
         */
        this.reloadEnviados = new EventEmitter();
        /**
         * Evento que abre el modal para enviar un nuevo mensaje.
         */
        this.crearNuevoMensaje = new EventEmitter();
        /**
         * Evento actualizar enviados.
         */
        this.actualizarEnviados = new EventEmitter();
    }
    MensajeriaService.prototype.getMensajesRecibidos = function () {
        var dniPaciente = this.autenticacionService.getLoginPaciente();
        var url = sprintf(AppConfigService.urls.recuperarMensajesRecibidos, dniPaciente);
        return this.http.get(url);
    };
    MensajeriaService.prototype.getMensajesEnviados = function () {
        var dniPaciente = this.autenticacionService.getLoginPaciente();
        var url = sprintf(AppConfigService.urls.recuperarMensajesEnviados, dniPaciente);
        return this.http.get(url);
    };
    MensajeriaService.prototype.enviarMensaje = function (mensaje) {
        var dniPaciente = this.autenticacionService.getLoginPaciente();
        var url = sprintf(AppConfigService.urls.enviarMensaje, dniPaciente);
        return this.http.post(url, mensaje);
    };
    MensajeriaService.prototype.marcarMensajeLeido = function (idMensaje) {
        var dniPaciente = this.autenticacionService.getLoginPaciente();
        var url = sprintf(AppConfigService.urls.marcarMensajeLeido, dniPaciente, idMensaje);
        return this.http.put(url, null);
    };
    MensajeriaService.ngInjectableDef = i0.defineInjectable({ factory: function MensajeriaService_Factory() { return new MensajeriaService(i0.inject(i1.HttpClient), i0.inject(i2.AutenticacionService)); }, token: MensajeriaService, providedIn: "root" });
    return MensajeriaService;
}());
export { MensajeriaService };
