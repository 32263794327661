import { HttpClient } from '@angular/common/http';
import { AutenticacionService } from './autenticacion.service';
import { AppConfigService } from './app-config.service';
import { sprintf } from 'sprintf-js';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./autenticacion.service";
/**
 * Servicio para la gestión de las biomedidas.
 *
 * @author lreverendo
 *
 * @version 01.02.0002
 * @since 01.02.0000
 */
var BiomedidasService = /** @class */ (function () {
    function BiomedidasService(http, autenticacionService) {
        this.http = http;
        this.autenticacionService = autenticacionService;
    }
    BiomedidasService.prototype.getBiomedidasHistorico = function (fechaIni, fechaFin, tipos) {
        fechaIni = fechaIni.split('T')[0];
        fechaFin = fechaFin.split('T')[0];
        var dniPaciente = this.autenticacionService.getLoginPaciente();
        var url = sprintf(AppConfigService.urls.recuperarBiomedidasHistorico, dniPaciente, fechaIni, fechaFin, tipos);
        return this.http.get(url);
    };
    BiomedidasService.prototype.getBiomedidas = function (ids) {
        var dniPaciente = this.autenticacionService.getLoginPaciente();
        var url = sprintf(AppConfigService.urls.recuperarBiomedidas, dniPaciente, ids);
        return this.http.get(url);
    };
    BiomedidasService.prototype.getTalla = function () {
        var dniPaciente = this.autenticacionService.getLoginPaciente();
        var url = sprintf(AppConfigService.urls.recuperarTalla, dniPaciente);
        return this.http.get(url);
    };
    BiomedidasService.prototype.getDispositivos = function () {
        var dniPaciente = this.autenticacionService.getLoginPaciente();
        var url = sprintf(AppConfigService.urls.recuperarDispositivos, dniPaciente);
        return this.http.get(url);
    };
    BiomedidasService.prototype.enviarBiomedida = function (body) {
        var dniPaciente = this.autenticacionService.getLoginPaciente();
        var url = sprintf(AppConfigService.urls.enviarBiomedida, dniPaciente);
        return this.http.post(url, body);
    };
    BiomedidasService.ngInjectableDef = i0.defineInjectable({ factory: function BiomedidasService_Factory() { return new BiomedidasService(i0.inject(i1.HttpClient), i0.inject(i2.AutenticacionService)); }, token: BiomedidasService, providedIn: "root" });
    return BiomedidasService;
}());
export { BiomedidasService };
