import { Component, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { MensajeriaService, AlertService } from '../../../../core/services';
import { Mensaje } from '../../../../core/models';
import * as moment from 'moment';
import { HTMLTextoPipe } from 'src/app/shared/pipes/html-texto/html-texto';

declare var $: any;

/**
 * Componente para la creación de actividades personales.
 *
 * @author lreverendo
 *
 * @version 01.02.0075
 * @since 01.02.0000
 */
@Component({
  selector: 'app-nuevo-mensaje',
  templateUrl: './nuevo-mensaje.component.html',
  styleUrls: ['../../../perfil/perfil.component.css', './nuevo-mensaje.component.css']
})
export class NuevoMensajeComponent implements OnDestroy {
  public forma: FormGroup;
  public disabled = false;
  public respuesta = false;

  private subs: Subscription[] = [];

  constructor(
    private alertService: AlertService,
    private translateService: TranslateService,
    private mensajeriaService: MensajeriaService,
    private htmlTexto: HTMLTextoPipe
  ) {
    // Subscripción al evento crearNuevoMensaje.
    this.subs.push(this.mensajeriaService.crearNuevoMensaje.subscribe(this.abrirModal.bind(this)));

    // Inicialización del formulario.
    this.forma = new FormGroup({
      asunto: new FormControl('', Validators.required),
      cuerpo: new FormControl('', [Validators.required, Validators.maxLength(500)])
    });
  }

  public ngOnDestroy(): void {
    // Baja a la subscripción.
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  public abrirModal(data: { mensaje: Mensaje }): void {
    this.respuesta = false;
    if (data != null) {
      this.respuesta = true;
      const asunto = this.htmlTexto.transform(data.mensaje.asunto);
      let asuntoRespuesta = '';
      if (!asunto.startsWith('RE:')) {
        asuntoRespuesta = 'RE: ';
      }
      asuntoRespuesta += asunto;
      const cuerpo = this.htmlTexto.transform(data.mensaje.cuerpo);
      const fechaEnvio = data.mensaje.fechaEnvio;
      const cuerpoRespuesta = '\n\n\n' + '_'.repeat(20) + '\n' + moment(fechaEnvio).format('MM/DD/YYYY - HH:mm') + '\n' + cuerpo;

      this.forma.reset({
        asunto: asuntoRespuesta,
        cuerpo: cuerpoRespuesta
      });
    } else {
      this.limpiar();
    }

    // Se abre el modal.
    $('#nuevoMensaje').modal('show');
    $('#nuevoMensaje').on('shown.bs.modal', function () {
      setTimeout(function () {
        $('#asunto').trigger('focus');
      }, 180);
    });
  }

  public guardarCambios(): void {
    this.disabled = true;

    const mensaje = new Mensaje();

    mensaje.asunto = this.htmlTexto.escapeHTML(this.forma.value.asunto);
    mensaje.cuerpo = this.htmlTexto.escapeHTML(this.forma.value.cuerpo);

    this.mensajeriaService.enviarMensaje(mensaje).subscribe(this.gestionarExito.bind(this), this.gestionarError.bind(this));
  }

  public limpiar(): void {
    this.forma.reset({
      asunto: '',
      cuerpo: ''
    });
  }

  private gestionarError(error: HttpErrorResponse): void {
    // Se cierra el modal.
    $('#nuevoMensaje').modal('hide');

    this.disabled = false;
    this.alertService.lanzarError(error.status);
  }

  private gestionarExito(): void {
    // Se cierra el modal.
    $('#nuevoMensaje').modal('hide');

    // Limpiamos el formulario.
    this.limpiar();

    // Se cargan los mensajes enviados.
    this.mensajeriaService.actualizarEnviados.emit();

    this.disabled = false;

    // Se muestra mensaje de éxito.
    this.alertService.lanzarExito(this.translateService.instant('MENSAJERIA.CREAR.EXITO'));
  }
}
