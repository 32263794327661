import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { LoaderStatus } from '../models/loaderStatus.model';
import { Operation } from '../../core/services/app-config.service';
import { OperationInfo } from '../models/operationInfo.model';

/**
 * Servicio para la gestión del loader.
 *
 * @author priveiro
 *
 * @version 01.02.0002
 * @since 01.02.0000
 */
@Injectable({ providedIn: 'root' })
export class LoaderService {
  /**
   * Devuelve el estado del loader.
   *
   * Este flujo no se cierra automáticamente después de la primera respuesta,
   * sino que continuará enviando datos cuando haya una actualización de los mismos.
   * Este flujo nunca devolverá un error. Hay que suscribirse al flujo error$ para ello.
   */
  public loaderStatus$: Observable<LoaderStatus>;
  private loaderStatusSubject: BehaviorSubject<LoaderStatus>;

  public status: LoaderStatus;
  public active: boolean;

  /**
   * Evento que indica si se trata de una redireccion.
   */
  public redireccionar: EventEmitter<any> = new EventEmitter();

  constructor() {
    this.active = false;
    this.status = new LoaderStatus();
    this.resetStatus();

    this.loaderStatusSubject = new BehaviorSubject(this.status);
    this.loaderStatus$ = this.loaderStatusSubject.asObservable();
  }

  private resetStatus(): void {
    this.status.enabled = false;
    this.status.lockScreen = false;
    this.status.operation = Operation.unknown;
    this.status.requireSubscription = false;
  }

  public showLoader(operationInfo: OperationInfo = null): void {
    this.status.enabled = true;
    if (operationInfo !== null) {
      this.status.lockScreen = operationInfo.lockScreen;
      this.status.operation = operationInfo.operation;
      this.status.requireSubscription = operationInfo.requireSubscription;
    }
    this.loaderStatusSubject.next(this.status);
    this.active = true;
  }

  public hideLoader(): void {
    this.resetStatus();
    this.loaderStatusSubject.next(this.status);
    this.active = false;
  }
}
