var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DispositivoGenerico } from '../dispositivo-generico.model';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';
import * as i0 from "@angular/core";
/**
 *  Componente que representa al dispositivo Pulxioxímetro.
 *
 * @author lreverendo
 *
 * @version 01.02.0070
 * @since 01.02.0000
 */
var PulsioximetroComponent = /** @class */ (function (_super) {
    __extends(PulsioximetroComponent, _super);
    function PulsioximetroComponent() {
        return _super.call(this) || this;
    }
    PulsioximetroComponent.prototype.ngOnInit = function () {
        // Se buscan las variables de saturación y pulso.
        this.sato2 = this.variablesClinicas.find(function (v) { return v.alias === 'SAT_O2'; });
        this.pulso = this.variablesClinicas.find(function (v) { return v.alias === 'PPM'; });
        if (this.sato2) {
            this.forma.addControl('sat', new FormControl('', [
                Validators.required,
                Validators.pattern(this.getPatronFormato(this.sato2.formato)),
                CustomValidators.porRango(this.sato2.min, this.sato2.max)
            ]));
        }
        if (this.pulso) {
            this.forma.addControl('pulso', new FormControl('', [
                Validators.required,
                Validators.pattern(this.getPatronFormato(this.pulso.formato)),
                CustomValidators.porRango(this.pulso.min, this.pulso.max)
            ]));
        }
    };
    PulsioximetroComponent.prototype.getData = function () {
        var result = [];
        if (this.sato2) {
            var sat = this.forma.value.sat.replace(',', '.');
            if (Number(sat) === NaN) {
                return [];
            }
            this.sato2.valor = sat;
            result.push(this.sato2);
        }
        if (this.pulso) {
            var pulso = this.forma.value.pulso.replace(',', '.');
            if (Number(pulso) === NaN) {
                return [];
            }
            this.pulso.valor = pulso;
            result.push(this.pulso);
        }
        return result;
    };
    PulsioximetroComponent.ngInjectableDef = i0.defineInjectable({ factory: function PulsioximetroComponent_Factory() { return new PulsioximetroComponent(); }, token: PulsioximetroComponent, providedIn: "root" });
    return PulsioximetroComponent;
}(DispositivoGenerico));
export { PulsioximetroComponent };
