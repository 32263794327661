import { Injectable } from '@angular/core';
import { ExtraccionAnalitica } from '../models/extraccionAnalitica.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { sprintf } from 'sprintf-js';
import { AppConfigService } from './app-config.service';
import { AutenticacionService } from './autenticacion.service';

/**
 * Servicio para la gestión de la actividad de extracción analítica
 *
 * @author dvila
 *
 * @version 01.02.0030
 * @since 01.02.0000
 */

@Injectable({
  providedIn: 'root'
})
export class AnaliticaService {
  constructor(private http: HttpClient, private autenticacionService: AutenticacionService) {}

  public crearExtraccionAnalitica(analitica: ExtraccionAnalitica): Observable<Object> {
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.crearExtraccionAnalitica, dniPaciente);
    return this.http.post(url, analitica);
  }

  public getExtraccionAnalitica(idAnalitica: string): Observable<Object> {
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.getExtraccionAnalitica, dniPaciente, idAnalitica);
    return this.http.get(url);
  }
}
