import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { RespuestaCertificado, RespuestaLoginEsaude } from '../models';
import { sprintf } from 'sprintf-js';

/**
 * Servicio para la gestión de la autenticación en el API.
 *
 * @author lreverendo
 *
 * @version 01.02.0002
 * @since 01.02.0000
 */
@Injectable({ providedIn: 'root' })
export class LoginService {
  private httpheadersPost = new HttpHeaders().set('Content-Type', 'application/json;charset=UTF-8');

  constructor(private http: HttpClient) {}

  public prepararLogin(body: any): Observable<Boolean> {
    const url = sprintf(AppConfigService.urls.prepararLogin);
    return this.http.post<Boolean>(url, body, { headers: this.httpheadersPost });
  }

  public enviarPin(body: any): Observable<Boolean> {
    const url = sprintf(AppConfigService.urls.enviarPin);
    const acceso = this.http.post<Boolean>(url, body, { headers: this.httpheadersPost });
    return acceso;
  }

  public logout(): Observable<Boolean> {
    const url = sprintf(AppConfigService.urls.logout);
    return this.http.post<Boolean>(url, { headers: this.httpheadersPost });
  }

  public prepararRecuperacion(body: any): Observable<Boolean> {
    const url = sprintf(AppConfigService.urls.prepararRecuperacion);
    return this.http.post<Boolean>(url, body, { headers: this.httpheadersPost });
  }

  public recuperar(body: any): Observable<Boolean> {
    const url = sprintf(AppConfigService.urls.recuperar);
    return this.http.post<Boolean>(url, body, { headers: this.httpheadersPost });
  }

  public validarCertificado(body: any): Observable<RespuestaCertificado> {
    const url = sprintf(AppConfigService.urls.validarCertificado);
    return this.http.post<RespuestaCertificado>(url, body, { headers: this.httpheadersPost });
  }

  public recuperarUrlEsaude(): Observable<Object> {
    const url = sprintf(AppConfigService.urls.urlEsaude, AppConfigService.urlEsaudeRedireccion);
    return this.http.get<Object>(url);
  }

  public loginEsaude(body: any): Observable<RespuestaLoginEsaude> {
    const url = sprintf(AppConfigService.urls.loginEsaude, AppConfigService.urlEsaudeRedireccion);
    return this.http.post<RespuestaLoginEsaude>(url, body, { headers: this.httpheadersPost });
  }

  public recuperarUrlLogoutEsaude(params: string): Observable<Object> {
    const body = {
      urlRedireccion: AppConfigService.urlLogoutEsaudeRedireccion + params
    };
    const url = sprintf(AppConfigService.urls.urlLogoutEsaude);
    return this.http.post<Object>(url, body, { headers: this.httpheadersPost });
  }
}
