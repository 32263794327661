import { AutenticacionService } from './autenticacion.service';
import { PerfilService } from './perfil.service';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from './alert.service';
import * as i0 from "@angular/core";
import * as i1 from "./autenticacion.service";
import * as i2 from "./alert.service";
import * as i3 from "./perfil.service";
import * as i4 from "@angular/router";
import * as i5 from "@ngx-translate/core";
/**
 * Servicio para la gestión de la navegación.
 *
 * @author lreverendo
 *
 * @version 01.02.0042
 * @since 01.02.0000
 */
var NavegacionService = /** @class */ (function () {
    function NavegacionService(autenticacionService, alertService, perfilService, router, translateService) {
        this.autenticacionService = autenticacionService;
        this.alertService = alertService;
        this.perfilService = perfilService;
        this.router = router;
        this.translateService = translateService;
        this.operacionFinalizada = new Subject();
    }
    NavegacionService.prototype.cargarPerfilUsuario = function (usuario) {
        var _this = this;
        this.perfilService.perfilUsuario$.pipe(takeUntil(this.operacionFinalizada)).subscribe(function (perfil) {
            if (perfil == null) {
                _this.perfilService.cargarPerfilUsuario(usuario);
            }
            else {
                _this.establecerPaginaInicio(perfil);
            }
        });
    };
    NavegacionService.prototype.cargarPerfilPaciente = function (paciente) {
        var _this = this;
        this.perfilService.cargarPerfilPaciente(paciente);
        this.perfilService.perfilPaciente$.pipe(takeUntil(this.operacionFinalizada)).subscribe(function (perfil) {
            _this.acceder(perfil);
        });
    };
    NavegacionService.prototype.establecerPaginaInicio = function (perfilUsuario) {
        var _this = this;
        if (perfilUsuario != null) {
            this.operacionFinalizada.next();
            var idioma = this.translateService.currentLang;
            if (perfilUsuario.accesoPrimeraVez) {
                perfilUsuario.localePaciente = idioma;
                perfilUsuario.paginaInicio = '/calendario';
                this.perfilService.editarPerfilUsuario(perfilUsuario).subscribe(function (perfil) { return _this.comprobarEsCuidador(perfil); }, function (error) { return _this.gestionarError(error); });
            }
            else {
                this.comprobarEsCuidador(perfilUsuario);
            }
        }
    };
    NavegacionService.prototype.comprobarEsCuidador = function (perfilUsuario) {
        if (perfilUsuario != null) {
            this.operacionFinalizada.next();
            var loginPaciente = this.autenticacionService.getLoginPaciente();
            if (loginPaciente != null) {
                this.cargarPerfilPaciente(loginPaciente);
            }
            else {
                if (perfilUsuario.pacientes.length > 0) {
                    // Si el usuario es ecuidador.
                    this.router.navigate(['elegir']);
                }
                else {
                    this.cargarPerfilPaciente(perfilUsuario.dni);
                }
            }
        }
    };
    NavegacionService.prototype.acceder = function (perfilPaciente) {
        if (perfilPaciente != null) {
            this.operacionFinalizada.next();
            // Redirección según situación del paciente.
            if (perfilPaciente.accesoPrimeraVez) {
                // Si es el primer acceso del usuario.
                this.router.navigate(['primerAcceso']);
            }
            else {
                var paginaInicio = perfilPaciente.paginaInicio != null ? perfilPaciente.paginaInicio : '/calendario';
                this.router.navigate([paginaInicio]);
            }
        }
    };
    NavegacionService.prototype.gestionarError = function (error) {
        this.alertService.lanzarError(error.status);
    };
    NavegacionService.ngInjectableDef = i0.defineInjectable({ factory: function NavegacionService_Factory() { return new NavegacionService(i0.inject(i1.AutenticacionService), i0.inject(i2.AlertService), i0.inject(i3.PerfilService), i0.inject(i4.Router), i0.inject(i5.TranslateService)); }, token: NavegacionService, providedIn: "root" });
    return NavegacionService;
}());
export { NavegacionService };
