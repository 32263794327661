<div class="form-group" *ngIf="sato2" [formGroup]="forma">
  <label for="sat" class="col-form-label"
    >{{ 'BIOMEDIDAS.PULSIOXIMETRO.SATURACION' | translate }} ({{ 'BIOMEDIDAS.VARIABLES.' + sato2.alias + '.unidad' | translate }})</label
  >
  <div class="datoCol">
    <input
      type="text"
      [appFormatNumber]="sato2.formato"
      appBlockCopyPaste
      class="form-control variable focus"
      id="sat"
      formControlName="sat"
    />
  </div>
</div>
<div
  class="filaError d-none"
  *ngIf="sato2"
  [ngClass]="{ 'd-table-row': forma.controls['sat'].dirty && forma.controls['sat'].errors?.maxError }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted"> {{ 'BIOMEDIDAS.PULSIOXIMETRO.SATMAX' | translate }}{{ sato2.max }} </small>
</div>
<div
  class="filaError d-none"
  *ngIf="sato2"
  [ngClass]="{ 'd-table-row': forma.controls['sat'].dirty && forma.controls['sat'].errors?.minError }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted"> {{ 'BIOMEDIDAS.PULSIOXIMETRO.SATMIN' | translate }}{{ sato2.min }} </small>
</div>
<div
  class="filaError d-none"
  *ngIf="sato2"
  [ngClass]="{ 'd-table-row': forma.controls['sat'].dirty && forma.controls['sat'].errors?.required }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted">
    {{ 'BIOMEDIDAS.PULSIOXIMETRO.SATREQUERIDO' | translate }}
  </small>
</div>
<div class="form-group" *ngIf="pulso" [formGroup]="forma">
  <label for="pulso" class="col-form-label"
    >{{ 'BIOMEDIDAS.PULSIOXIMETRO.PULSO' | translate }} ({{ 'BIOMEDIDAS.VARIABLES.' + pulso.alias + '.unidad' | translate }})</label
  >
  <div class="datoCol">
    <input
      type="text"
      [appFormatNumber]="pulso.formato"
      appBlockCopyPaste
      class="form-control variable"
      id="pulso"
      formControlName="pulso"
    />
  </div>
</div>
<div
  class="filaError d-none"
  *ngIf="pulso"
  [ngClass]="{ 'd-table-row': forma.controls['pulso'].dirty && forma.controls['pulso'].errors?.maxError }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted"> {{ 'BIOMEDIDAS.PULSIOXIMETRO.PULSOMAX' | translate }}{{ pulso.max }} </small>
</div>
<div
  class="filaError d-none"
  *ngIf="pulso"
  [ngClass]="{ 'd-table-row': forma.controls['pulso'].dirty && forma.controls['pulso'].errors?.minError }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted"> {{ 'BIOMEDIDAS.PULSIOXIMETRO.PULSOMIN' | translate }}{{ pulso.min }} </small>
</div>
<div
  class="filaError d-none"
  *ngIf="pulso"
  [ngClass]="{ 'd-table-row': forma.controls['pulso'].dirty && forma.controls['pulso'].errors?.required }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted">
    {{ 'BIOMEDIDAS.PULSIOXIMETRO.PULSOREQUERIDO' | translate }}
  </small>
</div>
