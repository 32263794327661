import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AutenticacionService } from './autenticacion.service';
import { AppConfigService } from './app-config.service';
import { sprintf } from 'sprintf-js';
import { Medicacion, Pdf } from '../models';

/**
 * Servicio para la gestión de la medicacion del paciente.
 *
 * @author lreverendo
 *
 * @version 01.02.0002
 * @since 01.02.0000
 */
@Injectable({ providedIn: 'root' })
export class MedicacionService {
  constructor(private http: HttpClient, private autenticacionService: AutenticacionService) {}

  public getMedicacion(): Observable<Medicacion[]> {
    const usuario = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.obtenerMedicacion, usuario);
    return this.http.get<Medicacion[]>(url);
  }

  public getHojaMedicacion(codigo: string): Observable<Pdf> {
    const usuario = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.obtenerlHojaMedicacion, usuario, codigo);
    return this.http.get<Pdf>(url);
  }
}
