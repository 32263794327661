import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AutenticacionService } from './autenticacion.service';
import { Cuestionario, CuestionarioPlantilla, CuestionarioRespuesta } from '../models';
import { AppConfigService } from './app-config.service';
import { sprintf } from 'sprintf-js';

/**
 * Servicio para la gestión de los cuestionarios.
 *
 * @author lreverendo
 * @author dvila
 *
 * @version 01.02.0040
 * @since 01.02.0000
 */
@Injectable({ providedIn: 'root' })
export class CuestionariosService {
  constructor(private http: HttpClient, private autenticacionService: AutenticacionService) {}

  public getHistorico(): Observable<Cuestionario[]> {
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.recuperarHistorico, dniPaciente);
    return this.http.get<Cuestionario[]>(url);
  }

  public getCuestionarioRealizado(id: string, cod: string): Observable<Cuestionario> {
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.recuperarCuestionarioRealizado, dniPaciente, id, cod);
    return this.http.get<Cuestionario>(url);
  }

  public getCuestionario(cod: string, locale: string = 'gl'): Observable<CuestionarioPlantilla> {
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.recuperarCuestionario, cod, dniPaciente, locale);
    return this.http.get<CuestionarioPlantilla>(url);
  }

  public enviarCuestionario(body: CuestionarioRespuesta): Observable<Cuestionario> {
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.enviarCuestionario, dniPaciente);
    return this.http.post<Cuestionario>(url, body);
  }
}
