import { OnChanges } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AlertService, BiomedidasService, LoaderService } from '../../../../core/services';
import { Dispositivo } from '../../../../core/models';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
/**
 *  Componente para el historial de biomedidas.
 *
 * @author lreverendo
 *
 * @version 01.02.0070
 * @since 01.02.0000
 */
var HistorialBiomedidaComponent = /** @class */ (function () {
    function HistorialBiomedidaComponent(loader, alertService, biomedidasService, datePipe, translateService) {
        this.loader = loader;
        this.alertService = alertService;
        this.biomedidasService = biomedidasService;
        this.datePipe = datePipe;
        this.translateService = translateService;
        this.errorFechas = false;
        this.labels = [];
        this.datasets = [];
        this.fechas = [];
        this.tipos = [];
        this.coloresLineas = ['#b94d8d', '#ec8c1b', '#4f75d5'];
        this.biomedidas = [];
    }
    HistorialBiomedidaComponent.prototype.ngOnChanges = function () {
        this.calcularFechaActual();
        // Se recogen las variables clinicas.
        this.variablesclinicas = this.dispositivo.sensor.variablesclinicasobj;
        // Cargamos el tipo de variable clínica
        this.gestionarTipos();
        // Cargamos los datos.
        this.fechaChange();
    };
    HistorialBiomedidaComponent.prototype.isNaN = function (valor) {
        return Number.isNaN(valor);
    };
    HistorialBiomedidaComponent.prototype.gestionarTipos = function () {
        var _this_1 = this;
        this.tiposVariableClinica = [];
        this.variablesclinicas.forEach(function (variableClinica) {
            _this_1.tiposVariableClinica.push(variableClinica.alias);
        });
    };
    HistorialBiomedidaComponent.prototype.fechaChange = function () {
        // Se cargan las biomedidas con fecha actividad entre las fechas seleccionadas.
        var fechaIni = new Date(this.fechaIni.year, this.fechaIni.month - 1, this.fechaIni.day);
        var fechaFin = new Date(this.fechaFin.year, this.fechaFin.month - 1, this.fechaFin.day);
        if (new Date(fechaIni) <= new Date(fechaFin)) {
            // Si la fecha inicial es menor que la final se cargan las biomedidas entre esas fechas.
            this.limpiar();
            this.errorFechas = false;
            this.biomedidasService
                .getBiomedidasHistorico(this.datePipe.transform(fechaIni, 'yyyy-MM-dd'), this.datePipe.transform(fechaFin, 'yyyy-MM-dd'), this.tiposVariableClinica)
                .subscribe({
                next: this.gestionarDatos.bind(this),
                error: this.gestionarError.bind(this)
            });
        }
        else {
            // Si la fecha inicial es mayor que la final se muestra un mensaje de error.
            this.errorFechas = true;
        }
    };
    HistorialBiomedidaComponent.prototype.gestionarDatos = function (biomedidas) {
        this.datos = biomedidas;
        this.encontrarBiomedidasTipo();
        this.crearLabels();
        this.crearDataSet();
        if (this.labels.length > 0) {
            this.crearGrafica();
        }
    };
    HistorialBiomedidaComponent.prototype.gestionarError = function (error) {
        this.alertService.lanzarError(error.status);
    };
    HistorialBiomedidaComponent.prototype.calcularFechaActual = function () {
        // Se calcula la fecha actual.
        var fecha = new Date();
        this.fechaFin = { year: fecha.getFullYear(), month: fecha.getMonth() + 1, day: fecha.getDate() };
        this.fechaIni = { year: fecha.getFullYear(), month: fecha.getMonth() + 1, day: 1 };
    };
    HistorialBiomedidaComponent.prototype.encontrarBiomedidasTipo = function () {
        // Se buscan las biomedidas correspondientes al dispositivo.
        var biomedidas = [];
        var tipos = [];
        var _loop_1 = function (variableClinica) {
            var listaBiomedidas = [];
            var formato = variableClinica.formato;
            var tipo = variableClinica.alias;
            listaBiomedidas = this_1.datos.filter(function (b) { return b.tipo === tipo; });
            biomedidas.push({
                tipo: tipo,
                formato: formato,
                listaBiomedidas: listaBiomedidas
            });
            tipos.push(tipo);
        };
        var this_1 = this;
        for (var _i = 0, _a = this.variablesclinicas; _i < _a.length; _i++) {
            var variableClinica = _a[_i];
            _loop_1(variableClinica);
        }
        this.tipos = tipos;
        this.biomedidas = biomedidas;
    };
    HistorialBiomedidaComponent.prototype.crearLabels = function () {
        // Se crean las etiquetas de la tabla y el gráfico.
        var fechas = [];
        for (var i = 0; i < this.biomedidas.length; i++) {
            for (var o = 0; o < this.biomedidas[i].listaBiomedidas.length; o++) {
                var fecha = this.biomedidas[i].listaBiomedidas[o].fechaActividad;
                if (fechas.indexOf(fecha) === -1) {
                    fechas.push(this.biomedidas[i].listaBiomedidas[o].fechaActividad);
                }
            }
        }
        fechas = fechas.sort(function (n1, n2) {
            if (n1 > n2) {
                return 1;
            }
            if (n1 < n2) {
                return -1;
            }
            return 0;
        });
        this.labels = fechas;
    };
    HistorialBiomedidaComponent.prototype.crearDataSet = function () {
        var datos = [];
        for (var i = 0; i < this.biomedidas.length; i++) {
            var valores = [];
            for (var u = 0; u < this.labels.length; u++) {
                var valor = NaN;
                var fechaValor = void 0;
                for (var o = 0; o < this.biomedidas[i].listaBiomedidas.length; o++) {
                    var biomedida = this.biomedidas[i].listaBiomedidas[o];
                    var fecha = biomedida.fechaActividad;
                    if (biomedida.fechaActividad === this.labels[u]) {
                        valor = Number(biomedida.valor);
                        fechaValor = fecha;
                    }
                }
                valores.push([fechaValor, valor]);
            }
            datos.push({
                name: this.translateService.instant('BIOMEDIDAS.VARIABLES.' + this.biomedidas[i].tipo + '.alias'),
                type: 'line',
                data: valores,
                lineStyle: {
                    color: this.coloresLineas[i]
                },
                itemStyle: {
                    color: this.coloresLineas[i]
                },
                symbolSize: 10,
                smooth: true,
                formato: this.biomedidas[i].formato
            });
        }
        this.datasets = datos;
    };
    HistorialBiomedidaComponent.prototype.crearGrafica = function () {
        var _this = this;
        this.opciones = {
            tooltip: {
                trigger: 'item'
            },
            legend: {
                data: this.tipos
            },
            grid: {
                left: '5%',
                bottom: '55px',
                top: '48px',
                containLabel: true
            },
            xAxis: {
                type: 'time',
                axisLabel: {
                    formatter: function (params) {
                        var lastYear = new Date(_this.labels[_this.labels.length - 1].valueOf()).getFullYear();
                        var firstYear = new Date(_this.labels[0].valueOf()).getFullYear();
                        if (lastYear - firstYear > 0) {
                            if (_this.translateService.currentLang === 'en') {
                                return _this.datePipe.transform(params, 'MM-dd\nyyyy');
                            }
                            else {
                                return _this.datePipe.transform(params, 'dd-MM\nyyyy');
                            }
                        }
                        if (moment(_this.labels[_this.labels.length - 1].valueOf()).diff(_this.labels[0].valueOf(), 'months') > 0) {
                            if (_this.translateService.currentLang === 'en') {
                                return _this.datePipe.transform(params, 'MM-dd\n');
                            }
                            else {
                                return _this.datePipe.transform(params, 'dd-MM\n');
                            }
                        }
                        if (_this.translateService.currentLang === 'en') {
                            return _this.datePipe.transform(params, 'MM-dd\nHH:mm');
                        }
                        else {
                            return _this.datePipe.transform(params, 'dd-MM\nHH:mm');
                        }
                    }
                },
                axisPointer: {
                    snap: true,
                    lineStyle: {
                        color: '#55b0e5',
                        opacity: 0.5,
                        width: 5
                    },
                    label: {
                        show: true,
                        formatter: function (params) {
                            var lastYear = new Date(_this.labels[_this.labels.length - 1].valueOf()).getFullYear();
                            var firstYear = new Date(_this.labels[0].valueOf()).getFullYear();
                            if (lastYear - firstYear > 0) {
                                if (_this.translateService.currentLang === 'en') {
                                    return _this.datePipe.transform(params.value, 'MM-dd-yyyy\nHH:mm');
                                }
                                else {
                                    return _this.datePipe.transform(params.value, 'dd-MM-yyyy\nHH:mm');
                                }
                            }
                            if (_this.translateService.currentLang === 'en') {
                                return _this.datePipe.transform(params.value, 'MM-dd\nHH:mm');
                            }
                            else {
                                return _this.datePipe.transform(params.value, 'dd-MM\nHH:mm');
                            }
                        },
                        backgroundColor: '#55b0e5'
                    },
                    handle: {
                        show: true,
                        shadowColor: '#fff',
                        color: '#55b0e5',
                        margin: 60
                        /*             icon: 'path://M32 16c0-8.836-7.164-16-16-16s-16 7.164-16 16 7.164 16 16 16 16-7.164 16-16zM10 18v2l-4-4 4-4v2h12v-2l4 4-4 4v-2h-12z' */
                    }
                }
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    formatter: '{value}\n'
                },
                scale: true,
                zlevel: 1000
            },
            series: this.datasets,
            useUTC: true
        };
    };
    HistorialBiomedidaComponent.prototype.limpiar = function () {
        // Borrado de los datos.
        this.opciones = {};
    };
    return HistorialBiomedidaComponent;
}());
export { HistorialBiomedidaComponent };
