import { Component, OnDestroy, Input, ElementRef, DoCheck } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from '../../../core/services/loader.service';
import { LoaderStatus } from '../../../core/models/loaderStatus.model';
import { Operation } from '../../../core/services/app-config.service';

/**
 * Componente para la gestión del loader.
 *
 * @author priveiro
 *
 * @version 01.02.0000
 * @since 01.02.0000
 */
@Component({
  selector: 'loader',
  templateUrl: 'loader.component.html',
  styleUrls: ['loader.component.css']
})
@Input()
export class LoaderComponent implements DoCheck, OnDestroy {
  private loaderServiceSubscription: Subscription;

  public enabled = false;

  @Input() public lockScreen: boolean;
  @Input() public for: Operation[];

  constructor(private loader: ElementRef, private loaderService: LoaderService) {}

  public ngDoCheck(): void {
    const isVisible = this.isVisible();
    if (isVisible) {
      if (!this.loaderServiceSubscription || this.loaderServiceSubscription.closed) {
        this.loaderServiceSubscription = this.loaderService.loaderStatus$.subscribe(this.updateLoaderStatus.bind(this));
      }
    } else {
      if (this.loaderServiceSubscription && !this.loaderServiceSubscription.closed) {
        this.loaderServiceSubscription.unsubscribe();
      }
    }
  }

  public ngOnDestroy(): void {
    if (this.loaderServiceSubscription) {
      this.loaderServiceSubscription.unsubscribe();
    }
  }

  public getSubscribedOperations(): Operation[] {
    let subscribedOperations = [];

    if (this.for) {
      subscribedOperations = this.for;
    }

    return subscribedOperations;
  }

  public showLoader(loaderStatus: LoaderStatus): void {
    const subscribedOperations = this.getSubscribedOperations();
    if (
      (subscribedOperations.length <= 0 && !loaderStatus.requireSubscription) ||
      subscribedOperations.indexOf(loaderStatus.operation) > -1
    ) {
      if (this.lockScreen || loaderStatus.lockScreen) {
        this.showScreenOverlay();
      }
      this.enabled = true;
    }
  }

  public hideLoader(): void {
    this.hideScreenOverlay();
    this.enabled = false;
  }

  private updateLoaderStatus(loaderStatus: LoaderStatus): void {
    if (loaderStatus.enabled) {
      if (this.isVisible()) {
        this.showLoader(loaderStatus);
      }
    } else {
      if (this.enabled === true) {
        this.hideLoader();
      }
    }
  }

  private getScreenOverlay(): HTMLElement {
    return document.getElementById('loaderOverlay');
  }

  private showScreenOverlay(): void {
    let loaderOverlay = this.getScreenOverlay();
    if (!loaderOverlay) {
      loaderOverlay = document.createElement('div');
      loaderOverlay.id = 'loaderOverlay';
      loaderOverlay.className = 'loader-overlay';
      document.body.appendChild(loaderOverlay);
    }
  }

  private hideScreenOverlay(): void {
    const loaderOverlay = this.getScreenOverlay();
    if (loaderOverlay) {
      loaderOverlay.parentElement.removeChild(loaderOverlay);
    }
  }

  private isVisible(): boolean {
    return (
      this.loader.nativeElement.parentElement.offsetHeight > 0 ||
      this.loader.nativeElement.parentElement.offsetLeft > 0 ||
      this.loader.nativeElement.parentElement.offsetTop > 0 ||
      this.loader.nativeElement.parentElement.offsetWidth > 0
    );
  }
}
