<div class="container container-login" [ngClass]="{ heightAuto: heightAuto }">
  <loader [lockScreen]="true"></loader>

  <div class="loader-container" *ngIf="redireccionExterna">
    <div class="loader"></div>
  </div>

  <div class="login-content">
    <div class="row justify-content-center">
      <div class="col-9 col-md-6" style="padding: 0">
        <div class="container container-titulo">
          <div class="row">
            <div class="titulo col-lg-9">
              <h1>
                <strong class="titulo-telea">{{ 'LOGIN.TITULO' | translate }}</strong>
              </h1>
              <h1 class="subtitulo">
                <span>{{ 'LOGIN.SUBTITULO.PLATAFORMA' | translate }}</span>
                <span>{{ 'LOGIN.SUBTITULO.TECNOLOGICA' | translate }}</span>
                <span>{{ 'LOGIN.SUBTITULO.ASISTENCIA' | translate }}</span>
                <span>{{ 'LOGIN.SUBTITULO.DOMICILIARIA' | translate }}</span>
              </h1>
              <h6 class="version">v.{{ version }}</h6>
            </div>
          </div>
          <div class="manual row element-click d-none d-md-flex justify-content-end" (click)="abrirManual()">
            <i class="icono icon-manual"> </i>
            <div class="col-auto manual-titulo">
              {{ 'LOGIN.MANUAL' | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-9 col-md-6" style="padding: 0">
        <div class="container container-formulario">
          <div class="row">
            <div class="col-md-9">
              <div class="formulario">
                <form [formGroup]="forma" (ngSubmit)="enviar()">
                  <ul class="list-group">
                    <li class="list-group-item" text-wrap>
                      <h5 id="bienvenido">
                        <strong>{{ 'LOGIN.BIENVENIDO' | translate }}</strong>
                        <br />
                        {{ 'LOGIN.SUBBIENVENIDO' | translate }}
                      </h5>
                      <div class="mensaje" *ngIf="pin" text-wrap>
                        {{ 'LOGIN.MSJPIN' | translate }}
                        <br />
                        {{ 'LOGIN.SUBMSJPIN' | translate }}
                      </div>
                    </li>
                    <li class="list-group-item form-group usuario" *ngIf="!pin">
                      <div>
                        <input class="input-placeholder" type="text" maxlength="9" formControlName="usuario" tabindex="1" />
                        <label class="placeholder" *ngIf="forma.controls['usuario'].value == ''">{{ 'LOGIN.USUARIO' | translate }}</label>
                      </div>
                      <label
                        text-wrap
                        class="error form-control-feedback"
                        *ngIf="forma.controls['usuario'].touched && forma.controls['usuario'].errors?.required"
                      >
                        {{ 'LOGIN.ERRORES.USUARIO.REQUERIDO' | translate }}
                      </label>
                    </li>
                    <li class="list-group-item form-group usuario pin">
                      <div *ngIf="!pin">
                        <input type="password" formControlName="contrasena" tabindex="2" />
                        <label class="placeholder" *ngIf="forma.controls['contrasena'].value == ''">{{
                          'LOGIN.CONTRASENA' | translate
                        }}</label>
                      </div>
                      <div *ngIf="pin">
                        <input type="password" formControlName="contrasena" tabindex="2" />
                        <label class="placeholder" *ngIf="forma.controls['contrasena'].value == ''">{{ 'LOGIN.PIN' | translate }}</label>
                      </div>
                      <label
                        text-wrap
                        class="error form-control-feedback"
                        *ngIf="!pin && forma.controls['contrasena'].touched && forma.controls['contrasena'].errors?.required"
                      >
                        {{ 'LOGIN.ERRORES.CONTRASENA.REQUERIDO' | translate }}
                      </label>
                      <label
                        text-wrap
                        class="error form-control-feedback"
                        *ngIf="pin && forma.controls['contrasena'].touched && forma.controls['contrasena'].errors?.required"
                      >
                        {{ 'LOGIN.ERRORES.PIN.REQUERIDO' | translate }}
                      </label>
                      <label
                        text-wrap
                        class="error form-control-feedback"
                        *ngIf="
                          forma.controls['contrasena'].errors?.credenciales &&
                          !forma.controls['contrasena'].errors?.required &&
                          !errorServidor
                        "
                      >
                        {{ 'LOGIN.ERRORES.CONTRASENA.INCORRECTA' | translate }}
                      </label>
                      <label
                        text-wrap
                        class="error form-control-feedback"
                        *ngIf="
                          forma.controls['contrasena'].errors?.pinincorrecto &&
                          !forma.controls['contrasena'].errors?.required &&
                          !errorServidor
                        "
                      >
                        {{ 'LOGIN.ERRORES.PIN.INCORRECTO' | translate }}
                      </label>
                      <label text-wrap class="error form-control-feedback" *ngIf="errorServidor">
                        {{ 'GENERAL.ERRORES.SERVIDOR' | translate }}
                      </label>
                      <label text-wrap class="error form-control-feedback" *ngIf="errorEsaudeSeguridad">
                        {{ 'LOGIN.ERRORES.ESAUDE.SEGURIDAD' | translate }}
                      </label>
                      <label text-wrap class="error form-control-feedback" *ngIf="errorEsaudeAcceso">
                        {{ 'LOGIN.ERRORES.ESAUDE.ACCESO' | translate }}
                      </label>
                    </li>
                    <li class="list-group-item recuperar" (click)="recuperarContrasena()" tabindex="3" *ngIf="!pin">
                      {{ 'LOGIN.RECUPERAR' | translate }}
                    </li>
                    <li class="list-group-item" [ngClass]="{ acceder: !pin }">
                      <button
                        class="btn btn-primary acceder"
                        type="submit"
                        [disabled]="forma.controls['contrasena'].errors?.required || forma.controls['usuario'].errors?.required"
                        tabindex="4"
                      >
                        {{ 'LOGIN.ACCEDER' | translate }}
                      </button>
                    </li>
                    <li class="list-group-item acceder-esaude" *ngIf="!pin">
                      <button class="btn btn-primary d-none d-lg-inline-block acceder" type="button" tabindex="5" (click)="irEsaude()">
                        {{ 'LOGIN.ESAUDE' | translate }}
                      </button>
                      <button class="btn btn-primary d-lg-none d-inline-block acceder" type="button" tabindex="5" (click)="irEsaude()">
                        {{ 'LOGIN.ESAUDE-REDUCIDO' | translate }}
                      </button>
                    </li>
                  </ul>
                </form>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-9">
              <div class="idiomas d-flex justify-content-end">
                <div [class.idioma-select]="idioma == 'es'" class="element-click" (click)="cambiarIdioma('es')">Español</div>
                <div>&nbsp;|&nbsp;</div>
                <div [class.idioma-select]="idioma == 'gl'" class="element-click" (click)="cambiarIdioma('gl')">Galego</div>
                <div>&nbsp;|&nbsp;</div>
                <div [class.idioma-select]="idioma == 'en'" class="element-click" (click)="cambiarIdioma('en')">English</div>
              </div>
            </div>
          </div>
          <div class="manual row element-click d-flex d-md-none justify-content-center" (click)="abrirManual()">
            <i class="icono icon-manual"> </i>
            <div class="col-auto manual-titulo">
              {{ 'LOGIN.MANUAL' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="login-footer">
    <div class="row justify-content-center">
      <div class="imagen-footer">
        <img src="assets/images/logoSergas.svg" />
      </div>
      <div class="imagen-footer">
        <img src="assets/images/ministerioeconomia.jpg" />
      </div>
      <div class="imagen-footer">
        <img src="assets/images/feder.jpg" />
      </div>
    </div>
  </div>
</div>

<app-recuperacion #recuperacion></app-recuperacion>
