/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../principal/principal.component.css.shim.ngstyle";
import * as i1 from "./principal-externo.component.css.shim.ngstyle";
import * as i2 from "@angular/core";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/common";
import * as i5 from "@ng-bootstrap/ng-bootstrap";
import * as i6 from "@angular/router";
import * as i7 from "./principal-externo.component";
import * as i8 from "../../core/services/externo.service";
import * as i9 from "../../core/services/perfil.service";
import * as i10 from "../../core/services/login.service";
import * as i11 from "../../core/services/permisos.service";
import * as i12 from "../../core/services/splash-screen.service";
var styles_PrincipalExternoComponent = [i0.styles, i1.styles];
var RenderType_PrincipalExternoComponent = i2.ɵcrt({ encapsulation: 0, styles: styles_PrincipalExternoComponent, data: {} });
export { RenderType_PrincipalExternoComponent as RenderType_PrincipalExternoComponent };
function View_PrincipalExternoComponent_1(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 2, "h3", [["class", "error"]], null, null, null, null, null)), (_l()(), i2.ɵted(1, null, ["", ""])), i2.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i2.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i2.ɵunv(_v, 1, 0, i2.ɵnov(_v, 2).transform("EXTERNO.ERROR.VALIDACIONXML")); _ck(_v, 1, 0, currVal_0); }); }
function View_PrincipalExternoComponent_2(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 2, "h3", [["class", "error"]], null, null, null, null, null)), (_l()(), i2.ɵted(1, null, ["", ""])), i2.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i2.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i2.ɵunv(_v, 1, 0, i2.ɵnov(_v, 2).transform("EXTERNO.ERROR.PERMISOS")); _ck(_v, 1, 0, currVal_0); }); }
export function View_PrincipalExternoComponent_0(_l) { return i2.ɵvid(0, [i2.ɵpid(0, i4.UpperCasePipe, []), (_l()(), i2.ɵeld(1, 0, null, null, 7, "nav", [["class", "navbar navbar-expand-lg navbar-light"]], null, null, null, null, null)), i2.ɵdid(2, 16384, null, 0, i5.ɵm, [], null, null), (_l()(), i2.ɵeld(3, 0, null, null, 5, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i2.ɵeld(4, 0, null, null, 4, "div", [["class", "titulo align-items-center col"]], null, null, null, null, null)), (_l()(), i2.ɵeld(5, 0, null, null, 3, "h4", [["style", "width: 100%; text-align: center"]], null, null, null, null, null)), (_l()(), i2.ɵeld(6, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i2.ɵted(7, null, ["", ""])), i2.ɵppd(8, 1), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_PrincipalExternoComponent_1)), i2.ɵdid(10, 16384, null, 0, i4.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵand(16777216, null, null, 1, null, View_PrincipalExternoComponent_2)), i2.ɵdid(12, 16384, null, 0, i4.NgIf, [i2.ViewContainerRef, i2.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i2.ɵeld(13, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i2.ɵdid(14, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i2.ViewContainerRef, i2.ComponentFactoryResolver, [8, null], i2.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.errorValiacionXml; _ck(_v, 10, 0, currVal_1); var currVal_2 = _co.errorPermisos; _ck(_v, 12, 0, currVal_2); _ck(_v, 14, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i2.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i2.ɵnov(_v, 0), _co.nombreUsuario)); _ck(_v, 7, 0, currVal_0); }); }
export function View_PrincipalExternoComponent_Host_0(_l) { return i2.ɵvid(0, [(_l()(), i2.ɵeld(0, 0, null, null, 1, "app-principal-externo", [], null, null, null, View_PrincipalExternoComponent_0, RenderType_PrincipalExternoComponent)), i2.ɵdid(1, 245760, null, 0, i7.PrincipalExternoComponent, [i8.ExternoService, i9.PerfilService, i10.LoginService, i11.PermisosService, i12.SplashScreenService, i3.TranslateService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PrincipalExternoComponentNgFactory = i2.ɵccf("app-principal-externo", i7.PrincipalExternoComponent, View_PrincipalExternoComponent_Host_0, {}, {}, []);
export { PrincipalExternoComponentNgFactory as PrincipalExternoComponentNgFactory };
