<loader></loader>
<form [formGroup]="formulario" (ngSubmit)="guardarCambios()" *ngIf="perfil != null" class="h-100">
  <div class="container" data-simplebar>
    <div class="subtitulo">
      <h4>{{ 'DATOS.SUBTITULO.GENERAL' | translate }}</h4>
    </div>
    <div class="row">
      <div class="row">
        <div class="avatar">
          <i class="icon-avatar_v2 img-avatar"></i>
        </div>
        <div class="datosAvatar">
          <div class="row">
            <div class="form-group col-12 col-md-6">
              <label for="staticNombre" class="col-form-label">{{ 'DATOS.NOMBRE' | translate }}</label>
              <div class="datoCol">
                <input type="text" readonly class="form-control-plaintext" id="staticNombre" value="{{ perfil.nombre | capitalizado }}" />
              </div>
            </div>
            <div class="form-group col-12 col-md-6">
              <label for="staticDNI" class="col-form-label">{{ 'DATOS.DNI' | translate }}</label>
              <div class="datoCol">
                <input type="text" readonly class="form-control-plaintext" id="staticDNI" value="{{ perfil.dni }}" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="form-group col-12 col-md-6">
              <label for="staticFECHA" class="col-form-label">{{ 'DATOS.FECHA_NACIMIENTO' | translate }}</label>
              <div class="datoCol">
                <input
                  type="text"
                  readonly
                  class="form-control-plaintext"
                  id="staticFECHA"
                  value="{{ perfil.fechaNacimiento | fecha: getCurrentIdioma() }}"
                />
              </div>
            </div>
            <div class="form-group col-12 col-md-6">
              <label for="staticCIP" class="col-form-label">{{ 'DATOS.CIP' | translate }}</label>
              <div class="datoCol">
                <input type="text" readonly class="form-control-plaintext" id="staticCIP" value="{{ perfil.cip || '-' }}" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-12 col-md-6">
          <label for="email" class="col-form-label">{{ 'DATOS.EMAIL' | translate }}</label>
          <div class="datoCol">
            <input type="email" class="form-control" id="email" formControlName="email" />
            <small
              class="error form-text text-muted"
              *ngIf="formulario.controls['email'].touched && formulario.controls['email'].errors?.email"
              >{{ 'DATOS.ERRORES.EMAIL' | translate }}</small
            >
          </div>
        </div>
        <div class="form-group col-12 col-md-6">
          <label for="movil" class="col-form-label">{{ 'DATOS.MOVIL' | translate }}</label>
          <div class="datoCol">
            <input type="tel" appTelefono class="form-control" id="movil" formControlName="movil" />
            <small
              class="error form-text text-muted"
              *ngIf="formulario.controls['movil'].touched && formulario.controls['movil'].errors?.pattern"
              >{{ 'DATOS.ERRORES.MOVIL' | translate }}</small
            >
            <small
              class="error form-text text-muted"
              *ngIf="formulario.controls['movil'].touched && formulario.controls['movil'].errors?.required"
            >
              {{ 'DATOS.ERRORES.MOVILREQUERIDO' | translate }}
            </small>
          </div>
        </div>
      </div>

      <div class="row" *ngFor="let direccion of perfil.direccion; index as i">
        <div class="form-group col-12 col-md-6">
          <label for="{{ 'domicilio-' + i }}" class="col-form-label">{{ 'DATOS.DIRECCION.DOMICILIO' | translate }} {{ i + 1 }}</label>
          <div class="datoCol">
            <input
              type="text"
              readonly
              class="form-control-plaintext"
              id="{{ 'domicilio-' + i }}"
              *ngIf="direccion.tipoVia != null || direccion.direccion1 != null"
              value="{{ direccion.tipoVia }} {{ direccion.direccion1 || '-' }} {{ direccion.numero1 }}, {{ direccion.piso }} {{
                direccion.puerta
              }}"
            />
          </div>
        </div>
        <div class="form-group col-12 col-md-6">
          <label for="{{ 'localidad-' + i }}" class="col-form-label">{{ 'DATOS.DIRECCION.LOCALIDAD' | translate }}</label>
          <div class="datoCol">
            <input
              type="text"
              readonly
              class="form-control-plaintext"
              id="{{ 'localidad-' + i }}"
              value="{{ direccion.localidad || '-' }}"
            />
          </div>
        </div>
        <div class="form-group col-12 col-md-6">
          <label for="{{ 'municipio-' + i }}" class="col-form-label">{{ 'DATOS.DIRECCION.MUNICIPIO' | translate }}</label>
          <div class="datoCol">
            <input
              type="text"
              readonly
              class="form-control-plaintext"
              id="{{ 'minicipio-' + i }}"
              value="{{ direccion.municipio || '-' }}"
            />
          </div>
        </div>
        <div class="form-group col-12 col-md-6">
          <label for="{{ 'provincia-' + i }}" class="col-form-label" style="padding: 0">{{
            'DATOS.DIRECCION.PROVINCIA' | translate
          }}</label>
          <div class="datoCol">
            <input
              type="text"
              readonly
              class="form-control-plaintext"
              id="{{ 'provincia-' + i }}"
              value="{{ direccion.provincia || '-' }}"
            />
          </div>
        </div>
        <div class="form-group col-12 col-md-6">
          <label for="{{ 'codigoPostal-' + i }}" class="col-form-label" style="padding: 0">{{
            'DATOS.DIRECCION.CODPOS' | translate
          }}</label>
          <div class="datoCol">
            <input
              type="text"
              readonly
              class="form-control-plaintext"
              id="{{ 'codigoPostal-' + i }}"
              value="{{ direccion.codigoPostal || '-' }}"
            />
          </div>
        </div>
        <div class="form-group col-12 col-md-6">
          <label for="{{ 'telFijo-' + i }}" class="col-form-label" style="padding: 0">{{ 'DATOS.DIRECCION.TELEFONO' | translate }}</label>
          <div class="datoCol">
            <input type="text" readonly class="form-control-plaintext" id="{{ 'telFijo-' + i }}" value="{{ direccion.telFijo1 || '-' }}" />
          </div>
        </div>
        <div class="separador" *ngIf="i + 1 < perfil.direccion.length" style="margin-bottom: 1rem"></div>
      </div>
    </div>

    <div class="subtitulo seccion">
      <h4>{{ 'DATOS.SUBTITULO.CONFIGURACION' | translate }}</h4>
    </div>
    <div class="row">
      <div class="form-group col-12 col-md-6">
        <label class="col-form-label" for="idioma">{{ 'DATOS.IDIOMA.TITULO' | translate }}</label>
        <div class="datoCol">
          <select class="form-control" id="idioma" formControlName="localePaciente">
            <option value="es">{{ 'DATOS.IDIOMA.CASTELLANO' | translate }}</option>
            <option value="gl">{{ 'DATOS.IDIOMA.GALLEGO' | translate }}</option>
            <option value="en">{{ 'DATOS.IDIOMA.INGLES' | translate }}</option>
          </select>
        </div>
      </div>
      <div class="form-group col-12 col-md-6">
        <label class="col-form-label" for="pagina">{{ 'DATOS.PAGINA_INICIO.TITULO' | translate }}</label>
        <div class="datoCol">
          <select class="form-control" id="pagina" formControlName="paginaInicio">
            <option value="/calendario">{{ 'DATOS.PAGINA_INICIO.CALENDARIO' | translate }}</option>
            <option value="/notificaciones">{{ 'DATOS.PAGINA_INICIO.NOTIFICACIONES' | translate }}</option>
          </select>
        </div>
      </div>
    </div>

    <div class="form-group col-12">
      <label class="col-form-label">{{ 'DATOS.NOTIFICACIONES.ETIQUETA' | translate }}</label>
      <div class="row">
        <label
          formArrayName="tipoNotificacionesSuscritas"
          *ngFor="let tipoNotificacion of getNotificacionesSuscritas(); let i = index"
          class="telea-checkbox col-md-4"
        >
          <input type="checkbox" [formControlName]="i" />
          <i class="telea-icon-checkbox"></i>
          {{ getTituloNotificacion(perfil.tipoNotificacionesSuscritas[i].codigo) }}
        </label>
      </div>
    </div>

    <div class="row seccion footer">
      <div class="form-group col-6" style="text-align: right">
        <button class="btn btn-primary btn-lg" type="button" (click)="limpiar()">
          {{ 'GENERAL.LIMPIAR' | translate }}
        </button>
      </div>
      <div class="form-group col-6">
        <button class="btn btn-success btn-lg" type="submit" [disabled]="!formulario.valid || !formulario.dirty">
          {{ 'GENERAL.GUARDAR' | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
