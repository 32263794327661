import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AutenticacionService } from './autenticacion.service';
import { AppConfigService } from './app-config.service';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { sprintf } from 'sprintf-js';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./autenticacion.service";
import * as i3 from "@ngx-translate/core";
/**
 * Servicio para la gestión del perfil del paciente.
 *
 * @author lreverendo
 * @author fjsalgado
 * @author dvila
 *·@author aarodriguezo
 *
 * @version 01.02.0250
 * @since 01.02.0000
 */
var PerfilService = /** @class */ (function () {
    function PerfilService(http, autenticacionService, translateService) {
        this.http = http;
        this.autenticacionService = autenticacionService;
        this.translateService = translateService;
        this.perfilUsuarioSubject = new BehaviorSubject(null);
        this.perfilUsuario$ = this.perfilUsuarioSubject.asObservable();
        this.perfilPacienteSubject = new BehaviorSubject(null);
        this.perfilPaciente$ = this.perfilPacienteSubject.asObservable();
        this.cuidadoresPacienteSubject = new BehaviorSubject(null);
        this.cuidadoresPaciente$ = this.cuidadoresPacienteSubject.asObservable();
        this.tipoNotificacionesSuscritasSubject = new BehaviorSubject(null);
        this.tipoNotificacionesSuscritas$ = this.tipoNotificacionesSuscritasSubject.asObservable();
        this.instruccionesPacienteSubject = new BehaviorSubject(null);
        this.instruccionesPaciente$ = this.instruccionesPacienteSubject.asObservable();
        this.errorSubject = new Subject();
        this.error$ = this.errorSubject.asObservable();
    }
    PerfilService.prototype.iniciar = function () {
        var isUsuarioAutenticado = this.autenticacionService.isUsuarioAutenticado();
        if (isUsuarioAutenticado) {
            this.recuperarPerfilUsuario().subscribe({
                next: this.actualizarPerfilUsuario.bind(this),
                error: this.gestionarError.bind(this)
            });
        }
    };
    PerfilService.prototype.cargarPerfilUsuario = function (loginUsuario) {
        this.perfilUsuario = null;
        this.autenticacionService.setLoginUsuario(loginUsuario);
        this.recuperarPerfilUsuario().subscribe({
            next: this.actualizarPerfilUsuario.bind(this),
            error: this.gestionarError.bind(this)
        });
    };
    PerfilService.prototype.recuperarPerfilUsuario = function () {
        var idioma = this.translateService.currentLang;
        var usuarioLogueado = this.autenticacionService.getLoginUsuario();
        var url = sprintf(AppConfigService.urls.recuperarPerfil, usuarioLogueado, idioma);
        return this.http.get(url);
    };
    PerfilService.prototype.actualizarPerfilUsuario = function (perfil) {
        perfil.nombre = this.quitarNombresNull(perfil.nombre);
        this.perfilUsuario = perfil;
        var currentLang = this.translateService.currentLang;
        var idioma = perfil.accesoPrimeraVez ? currentLang : perfil.localePaciente;
        this.translateService.use(idioma);
        localStorage.setItem('locale', idioma);
        this.perfilUsuarioSubject.next(perfil);
    };
    PerfilService.prototype.cargarPerfilPaciente = function (loginPaciente) {
        this.perfilPacienteSubject.next(null);
        this.autenticacionService.setLoginPaciente(loginPaciente);
        this.recuperarPerfilPaciente().subscribe({
            next: this.actualizarPerfilPaciente.bind(this),
            error: this.gestionarError.bind(this)
        });
    };
    PerfilService.prototype.recuperarPerfilPaciente = function () {
        var idioma = this.translateService.currentLang;
        var paciente = this.autenticacionService.getLoginPaciente();
        var url = sprintf(AppConfigService.urls.recuperarPerfil, paciente, idioma);
        return this.http.get(url);
    };
    PerfilService.prototype.actualizarPerfilPaciente = function (perfil) {
        perfil.nombre = this.quitarNombresNull(perfil.nombre);
        this.perfilPaciente = perfil;
        if (this.perfilPaciente.codCentro != null) {
            this.autenticacionService.setCentroPaciente(this.perfilPaciente.codCentro.toString());
        }
        var tipoNotificacionesSuscritas = perfil.tipoNotificacionesSuscritas.filter(function (notificacion) { return notificacion.suscrita; });
        this.tipoNotificacionesSuscritasSubject.next(tipoNotificacionesSuscritas);
        this.perfilPacienteSubject.next(perfil);
    };
    PerfilService.prototype.getInstrucciones = function () {
        this.instruccionesPacienteSubject.next(null);
        this.recuperarInstrucciones().subscribe(this.actualizarInstrucciones.bind(this), this.gestionarError.bind(this));
    };
    PerfilService.prototype.recuperarInstrucciones = function () {
        var idioma = this.translateService.currentLang;
        var usuario = this.autenticacionService.getLoginPaciente();
        var url = sprintf(AppConfigService.urls.recuperarInstruccionesPaciente, usuario, idioma);
        return this.http.get(url);
    };
    PerfilService.prototype.actualizarInstrucciones = function (instrucciones) {
        this.instruccionesPacienteSubject.next(instrucciones);
    };
    PerfilService.prototype.editarPerfil = function (perfil) {
        var _this = this;
        var loginPaciente = this.autenticacionService.getLoginPaciente();
        var loginUsuario = this.autenticacionService.getLoginUsuario();
        var locale = this.translateService.currentLang;
        if (loginUsuario === loginPaciente) {
            locale = perfil.localePaciente;
        }
        var url = sprintf(AppConfigService.urls.editarPerfil, loginPaciente, locale);
        var respuesta = this.http.post(url, perfil);
        var perfil$ = new Observable(function (observer) {
            respuesta.subscribe(function (perfilEditado) {
                if (loginUsuario === loginPaciente) {
                    _this.translateService.use(perfil.localePaciente);
                    _this.actualizarPerfilUsuario(perfilEditado);
                    observer.next(perfilEditado);
                }
                _this.actualizarPerfilPaciente(perfilEditado);
            }, function (e) { return observer.error(e); });
        });
        return perfil$;
    };
    PerfilService.prototype.editarPerfilUsuario = function (perfil) {
        var _this = this;
        var loginUsuario = this.autenticacionService.getLoginUsuario();
        var locale = this.translateService.currentLang;
        var url = sprintf(AppConfigService.urls.editarPerfil, loginUsuario, locale);
        var respuesta = this.http.post(url, perfil);
        var perfil$ = new Observable(function (observer) {
            respuesta.subscribe(function (perfilEditado) {
                _this.translateService.use(perfil.localePaciente);
                _this.actualizarPerfilUsuario(perfilEditado);
                observer.next(perfilEditado);
            }, function (e) { return observer.error(e); });
        });
        return perfil$;
    };
    PerfilService.prototype.cambiarContrasena = function (body) {
        var usuario = this.autenticacionService.getLoginPaciente();
        var url = sprintf(AppConfigService.urls.cambiarContrasena, usuario);
        return this.http.post(url, body);
    };
    PerfilService.prototype.getCuidadores = function () {
        this.cuidadoresPacienteSubject.next(null);
        this.buscarCuidadoresPaciente().subscribe({
            next: this.actualizarCuidadores.bind(this),
            error: this.gestionarError.bind(this)
        });
    };
    PerfilService.prototype.buscarCuidadoresPaciente = function () {
        var idioma = this.translateService.currentLang;
        var usuario = this.autenticacionService.getLoginPaciente();
        var url = sprintf(AppConfigService.urls.recuperarCuidadores, usuario, idioma);
        return this.http.get(url);
    };
    PerfilService.prototype.actualizarCuidadores = function (cuidadoresPaciente) {
        this.cuidadoresPacienteSubject.next(cuidadoresPaciente);
    };
    PerfilService.prototype.actualizarAccesoPrimeraVez = function (accesoPrimeraVez) {
        var usuario = this.autenticacionService.getLoginPaciente();
        var url = sprintf(AppConfigService.urls.actualizarAccesoPrimeraVez, usuario);
        return this.http.post(url, accesoPrimeraVez);
    };
    PerfilService.prototype.lanzarBotonPanico = function () {
        var usuario = this.autenticacionService.getLoginPaciente();
        var url = sprintf(AppConfigService.urls.lanzarBotonPanico, usuario);
        return this.http.post(url, []);
    };
    PerfilService.prototype.esUsuarioCuidador = function () {
        return ((this.perfilUsuario != null && this.perfilUsuario.pacientes.length > 0) ||
            this.autenticacionService.getLoginPaciente() !== this.autenticacionService.getLoginUsuario());
    };
    PerfilService.prototype.tieneNasi = function () {
        return this.perfilPaciente != null && this.perfilPaciente.nasi != null;
    };
    PerfilService.prototype.tieneVinculoAbbott = function () {
        return this.perfilPaciente.tieneVinculoAbbott;
    };
    PerfilService.prototype.gestionarError = function (errorResponse) {
        this.errorSubject.next(errorResponse);
    };
    PerfilService.prototype.detener = function () {
        this.perfilUsuario = null;
        this.perfilUsuarioSubject.next(null);
        this.perfilPacienteSubject.next(null);
        this.tipoNotificacionesSuscritasSubject.next(null);
    };
    PerfilService.prototype.obtenerActividadesPrescritas = function () {
        if (this.perfilPaciente.protocolosActivos.length === 0) {
            return null;
        }
        else {
            if (this.perfilPaciente.protocolosActivos[0].idProtocoloMaestro === 4) {
                return null;
            }
            else {
                return this.perfilPaciente.protocolosActivos[0].actividadesPrescritas;
            }
        }
    };
    PerfilService.prototype.quitarNombresNull = function (nombre) {
        var nombreNuevo = nombre;
        while (nombreNuevo.includes("null")) {
            nombreNuevo = nombreNuevo.replace("null", "");
        }
        return nombreNuevo;
    };
    PerfilService.ngInjectableDef = i0.defineInjectable({ factory: function PerfilService_Factory() { return new PerfilService(i0.inject(i1.HttpClient), i0.inject(i2.AutenticacionService), i0.inject(i3.TranslateService)); }, token: PerfilService, providedIn: "root" });
    return PerfilService;
}());
export { PerfilService };
