import { OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AutenticacionService } from './autenticacion.service';
import { AppConfigService, AppConfig } from './app-config.service';
import { sprintf } from 'sprintf-js';
import { PerfilService } from './perfil.service';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { timer } from 'rxjs';
import * as moment from 'moment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./autenticacion.service";
import * as i3 from "./perfil.service";
/**
 * Servicio para la gestión del notificaciones.
 *
 * @author priveiro
 * @author fjsalgado
 *
 * @version 01.02.0002
 * @since 01.02.0000
 */
var NotificacionesService = /** @class */ (function () {
    function NotificacionesService(http, autenticacionService, perfilService) {
        this.http = http;
        this.autenticacionService = autenticacionService;
        this.perfilService = perfilService;
        this.notificaciones = null;
        this.tipoNotificacionesSuscritas = null;
        this.notificacionesSubject = new BehaviorSubject(null);
        this.notificaciones$ = this.notificacionesSubject.asObservable();
        this.nuevasNotificacionesSubject = new Subject();
        this.nuevasNotificaciones$ = this.nuevasNotificacionesSubject.asObservable();
        this.numeroNotificacionesNoRevisadasSubject = new BehaviorSubject(0);
        this.numeroNotificacionesNoRevisadas$ = this.numeroNotificacionesNoRevisadasSubject.asObservable();
        this.errorSubject = new Subject();
        this.error$ = this.errorSubject.asObservable();
    }
    NotificacionesService.prototype.iniciar = function () {
        this.tipoNotificacionesSuscritas = null;
        this.fechaUltimaBusqueda = moment().utc().format(AppConfig.constant.utcFormat);
        // Se suscribe al flujo que devuelve el tipo de notificaciones al que el paciente está suscrito.
        // Cada vez que se cambien se recargarán las notificaciones.
        this.tipoNotificacionesSubscription = this.perfilService.tipoNotificacionesSuscritas$.subscribe(this.cargarNotificaciones.bind(this));
    };
    NotificacionesService.prototype.cargarNotificaciones = function (arrayTipoNotificacionesSuscritas) {
        var _this = this;
        if (arrayTipoNotificacionesSuscritas != null) {
            var tipoNotificacionesSuscritasAnteriores = this.tipoNotificacionesSuscritas;
            var tipoNotificacionesSuscritas_1 = arrayTipoNotificacionesSuscritas.map(function (tipoNotificacion) { return tipoNotificacion.codigo; });
            this.tipoNotificacionesSuscritas = tipoNotificacionesSuscritas_1;
            // Si no se ha lanzado una búsqueda de notificaciones se lanza
            if (tipoNotificacionesSuscritasAnteriores == null) {
                // Recupera todas las notificaciones del paciente
                this.buscarNotificaciones().subscribe(this.actualizarNotificaciones.bind(this), this.enviarErrorRecuperandoNotificaciones.bind(this));
                // Cada x segundos se lanza una petición para buscar nuevas notificaciones
                var tiempoRefresco = AppConfigService.segundosRefrescoNotificaciones * 1000;
                this.nuevasNotificacionesSubcription = timer(tiempoRefresco, tiempoRefresco)
                    .pipe(switchMap(function () { return _this.buscarNuevasNotificaciones(); }))
                    .subscribe(this.actualizarNuevasNotificaciones.bind(this), this.enviarErrorRecuperandoNuevasNotificaciones.bind(this));
            }
            else if (!this.sonIguales(tipoNotificacionesSuscritasAnteriores, tipoNotificacionesSuscritas_1)) {
                // Si ya se había lanzado una búsqueda y han variado los tipos de notificaciones suscritos
                // comprueba si el usuario se ha suscrito a nuevos tipos
                // Si el usuario se ha suscrito a nuevos tipos de notificaciones hay que lanzar una nueva búsqueda
                if (this.suscritosNuevosTipoNotificaciones(tipoNotificacionesSuscritas_1, tipoNotificacionesSuscritasAnteriores)) {
                    this.buscarNotificaciones().subscribe(this.actualizarNotificaciones.bind(this), this.enviarErrorRecuperandoNotificaciones.bind(this));
                }
                else {
                    // Si solo se han eliminado suscripciones se filtra la lista de notificaciones dejando solo las de los tipos suscritos
                    this.notificaciones = this.notificaciones.filter(function (notificacion) {
                        return tipoNotificacionesSuscritas_1.includes(notificacion.tipo);
                    });
                    this.notificacionesSubject.next(this.notificaciones);
                    this.actualizarNumeroNotificacionesNoRevisadas();
                }
            }
        }
    };
    NotificacionesService.prototype.suscritosNuevosTipoNotificaciones = function (tipoNotificacionesSuscritas, tipoNotificacionesSuscritasAnteriores) {
        for (var _i = 0, tipoNotificacionesSuscritas_2 = tipoNotificacionesSuscritas; _i < tipoNotificacionesSuscritas_2.length; _i++) {
            var notificacionSuscrita = tipoNotificacionesSuscritas_2[_i];
            if (!tipoNotificacionesSuscritasAnteriores.includes(notificacionSuscrita)) {
                return true;
            }
        }
        return false;
    };
    NotificacionesService.prototype.sonIguales = function (arrayA, arrayB) {
        if (arrayA.length !== arrayB.length) {
            return false;
        }
        for (var _i = 0, arrayA_1 = arrayA; _i < arrayA_1.length; _i++) {
            var elementArrayA = arrayA_1[_i];
            if (!arrayB.includes(elementArrayA)) {
                return false;
            }
        }
        return true;
    };
    /* private arrayAContieneElementosNoExistentesEnB(arrayA: string[], arrayB: string[]) {
      if (arrayA.length !== arrayB.length) {
        return false;
      }
      for (const elementArrayA of arrayA) {
        if (!arrayB.includes(elementArrayA)) {
          return false;
        }
      }
      return true;
    } */
    NotificacionesService.prototype.actualizarNotificaciones = function (notificaciones) {
        this.ordenarNotificacionesPorFecha(notificaciones);
        this.notificaciones = notificaciones;
        this.notificacionesSubject.next(notificaciones);
        this.actualizarNumeroNotificacionesNoRevisadas();
    };
    NotificacionesService.prototype.enviarErrorRecuperandoNotificaciones = function (error) {
        this.notificacionesSubject.error(error);
    };
    NotificacionesService.prototype.actualizarNuevasNotificaciones = function (notificaciones) {
        var _this = this;
        if (notificaciones != null && notificaciones.length > 0) {
            // Elimina las notificaciones que ya estubiesen cargadas
            var notificacionesNuevas = notificaciones.filter(function (notificacionNueva) { return !_this.notificaciones.some(function (notificacion) { return notificacion.id === notificacionNueva.id; }); });
            if (notificacionesNuevas.length > 0) {
                // Actualiza la lista de notificaciones
                this.notificaciones = this.notificaciones.concat(notificacionesNuevas);
                this.ordenarNotificacionesPorFecha(this.notificaciones);
                this.notificacionesSubject.next(this.notificaciones);
                // Envía los nuevos datos
                this.nuevasNotificacionesSubject.next(notificacionesNuevas);
                this.actualizarNumeroNotificacionesNoRevisadas();
            }
        }
    };
    NotificacionesService.prototype.enviarErrorRecuperandoNuevasNotificaciones = function (error) {
        this.nuevasNotificacionesSubject.error(error);
    };
    NotificacionesService.prototype.ordenarNotificacionesPorFecha = function (listaNotificaciones) {
        listaNotificaciones.sort(function (notificacionA, notificacionB) {
            if (notificacionA.fecha < notificacionB.fecha) {
                return 1;
            }
            else if (notificacionA.fecha > notificacionB.fecha) {
                return -1;
            }
            else {
                return 0;
            }
        });
    };
    NotificacionesService.prototype.buscarNotificaciones = function () {
        var dniPaciente = this.autenticacionService.getLoginPaciente();
        var url = sprintf(AppConfigService.urls.busquedaNotificaciones, dniPaciente, this.tipoNotificacionesSuscritas);
        return this.http.get(url);
    };
    NotificacionesService.prototype.buscarNuevasNotificaciones = function () {
        var dniPaciente = this.autenticacionService.getLoginPaciente();
        var url = sprintf(AppConfigService.urls.busquedaUltimasNotificaciones, dniPaciente, this.tipoNotificacionesSuscritas, this.fechaUltimaBusqueda);
        this.fechaUltimaBusqueda = moment().utc().format(AppConfig.constant.utcFormat);
        return this.http.get(url);
    };
    NotificacionesService.prototype.actualizarNumeroNotificacionesNoRevisadas = function () {
        // Actualiza el número de notificaciones sin leer
        var numeroNotificacionesNoRevisadas = this.notificaciones.filter(function (notificacion) { return notificacion.revisada === false; }).length;
        this.numeroNotificacionesNoRevisadasSubject.next(numeroNotificacionesNoRevisadas);
    };
    NotificacionesService.prototype.marcarNotificacionRevisada = function (notificacion) {
        var _this = this;
        var loginPaciente = this.autenticacionService.getLoginPaciente();
        var idNotificacion = notificacion.id;
        var url = sprintf(AppConfigService.urls.marcarNotificacionRevisada, loginPaciente, idNotificacion);
        var peticion = this.http.put(url, null);
        var peticion$ = new BehaviorSubject(null);
        // Se suscribe para actualizar la lista de notificaciones
        peticion.subscribe(function (marcadaComoRevisada) {
            if (marcadaComoRevisada) {
                _this.marcarComoRevisadaOEliminar(notificacion);
                _this.actualizarNumeroNotificacionesNoRevisadas();
            }
            peticion$.next(marcadaComoRevisada);
        }, function (err) { return peticion$.error(err); });
        return peticion$;
    };
    NotificacionesService.prototype.marcarComoRevisadaOEliminar = function (notificacion) {
        var diasDiferenciaFechaActual = this.getDiasDiferenciaFechaActual(notificacion);
        if (diasDiferenciaFechaActual <= AppConfigService.diasNotificacionesRecientes) {
            // Si es una notificación reciente la marca como revisada
            notificacion.revisada = true;
        }
        else {
            // Si es una notificación anterior la elimina
            var posicionEnArray = this.notificaciones.indexOf(notificacion);
            if (posicionEnArray !== -1) {
                this.notificaciones.splice(posicionEnArray, 1);
            }
        }
    };
    NotificacionesService.prototype.getDiasDiferenciaFechaActual = function (notificacion) {
        var fechaActual = moment();
        var fechaNotificacion = moment.utc(notificacion.fecha).local();
        return fechaActual.diff(fechaNotificacion, 'days');
    };
    NotificacionesService.prototype.detener = function () {
        if (this.tipoNotificacionesSubscription) {
            this.tipoNotificacionesSubscription.unsubscribe();
        }
        if (this.nuevasNotificacionesSubcription) {
            this.nuevasNotificacionesSubcription.unsubscribe();
        }
        this.notificaciones = null;
        this.notificacionesSubject.next(null);
        this.numeroNotificacionesNoRevisadasSubject.next(0);
    };
    NotificacionesService.prototype.ngOnDestroy = function () {
        this.detener();
    };
    NotificacionesService.ngInjectableDef = i0.defineInjectable({ factory: function NotificacionesService_Factory() { return new NotificacionesService(i0.inject(i1.HttpClient), i0.inject(i2.AutenticacionService), i0.inject(i3.PerfilService)); }, token: NotificacionesService, providedIn: "root" });
    return NotificacionesService;
}());
export { NotificacionesService };
