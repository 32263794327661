import { Pipe, PipeTransform } from '@angular/core';

/**
 * Componente para dividir un string pasado.
 *
 * @author lreverendo
 *
 * @version 01.02.0000
 * @since 01.02.0000
 */
@Pipe({
  name: 'split'
})
export class SplitPipe implements PipeTransform {
  public transform(value: string, elem: string, pos: number): string {
    if (value.indexOf(elem) > -1) {
      return value.split(elem)[pos];
    } else {
      return value;
    }
  }
}
