<div class="modal fade" id="instrucciones">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h3>
          <strong> {{ 'INSTRUCCIONES.TITULO' | translate }} </strong>
        </h3>
        <button type="button" class="close" data-dismiss="modal" (click)="salir()">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <loader [for]="[operation.cargaInstruccionesPaciente]"></loader>
        <div *ngIf="sinProtocolo" class="sinProtocolo">
          <h3>
            {{ 'INSTRUCCIONES.AVISONOENCONTRADO.SUBTITULO' | translate }}
            <br />
            {{ 'INSTRUCCIONES.AVISONOENCONTRADO.MENSAJE' | translate }}
          </h3>
        </div>
        <iframe div #iframe class="pdf" *ngIf="!sinProtocolo"></iframe>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-lg" data-dismiss="modal" (click)="salir()">
          {{ 'GENERAL.CERRAR' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
