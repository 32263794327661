<nav class="navbar navbar-expand-lg navbar-light">
  <div class="row">
    <div class="titulo align-items-center col">
      <h3 style="width: 100%; text-align: center">
        <strong>{{ 'MENU.TITULO' | translate }}</strong>
      </h3>
    </div>

    <div class="opcion align-items-center col salir d-table" (click)="salir()">
      <div>
        <i class="icon-salir icono" title="{{ 'MENU.SALIR' | translate }}"></i>
        <span class="d-none d-lg-inline-block">
          {{ 'MENU.SALIR' | translate }}
        </span>
      </div>
    </div>
  </div>
</nav>

<div class="pagina">
  <div class="container general-container" data-simplebar>
    <div class="row">
      <div class="col-12">
        <h3 class="tituloContenido">
          <strong>{{ 'PRIMERACCESO.TITULO' | translate }}</strong>
        </h3>
      </div>
      <div class="separador"></div>
      <div class="col-12">
        <h4 class="mensaje">{{ 'PRIMERACCESO.MENSAJE' | translate }}</h4>
      </div>
    </div>

    <loader></loader>

    <div class="row" *ngIf="!loader.active && perfil">
      <div class="form-group col-12">
        <label for="staticNombre" class="col-form-label">{{ 'DATOS.NOMBRE' | translate }}</label>
        <div class="datoCol">
          <input type="text" readonly class="form-control-plaintext" id="staticNombre" value="{{ perfil.nombre || '-' }}" />
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!loader.active && perfil">
      <div class="form-group col-12 col-md-4">
        <label for="staticDNI" class="col-form-label">{{ 'DATOS.DNI' | translate }}</label>
        <div class="datoCol">
          <input type="text" readonly class="form-control-plaintext" id="staticDNI" value="{{ perfil.dni || '-' }}" />
        </div>
      </div>
      <div class="form-group col-12 col-md-4">
        <label for="staticFECHA" class="col-form-label">{{ 'DATOS.FECHA_NACIMIENTO' | translate }}</label>
        <div class="datoCol">
          <input
            type="text"
            readonly
            class="form-control-plaintext"
            id="staticFECHA"
            value="{{ perfil.fechaNacimiento | fecha: getCurrentIdioma() }}"
          />
        </div>
      </div>
      <div class="form-group col-12 col-md-4">
        <label for="staticCIP" class="col-form-label">{{ 'DATOS.CIP' | translate }}</label>
        <div class="datoCol">
          <input type="text" readonly class="form-control-plaintext" id="staticCIP" value="{{ perfil.cip || '-' }}" />
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!loader.active && perfil">
      <div class="form-group col-12 col-md-4">
        <label for="email" class="col-form-label">{{ 'DATOS.EMAIL' | translate }}</label>
        <div class="datoCol">
          <input type="email" readonly class="form-control-plaintext" id="email" value="{{ perfil.email || '-' }}" />
        </div>
      </div>
      <div class="form-group col-12 col-md-4">
        <label for="movil" class="col-form-label">{{ 'DATOS.MOVIL' | translate }}</label>
        <div class="datoCol">
          <input type="text" readonly class="form-control-plaintext" id="movil" value="{{ perfil.movil || '-' }}" />
        </div>
      </div>
    </div>
    <div class="row" *ngFor="let direccion of direcciones; index as i">
      <div class="form-group col-6">
        <label for="domicilio" class="col-form-label">{{ 'DATOS.DIRECCION.DOMICILIO' | translate }} {{ i + 1 }}</label>
        <div class="datoCol" style="margin: 0">
          <input
            type="text"
            readonly
            class="form-control-plaintext"
            id="domicilio"
            *ngIf="direccion.tipoVia != null || direccion.direccion1 != null"
            value="{{ direccion.tipoVia }} {{ direccion.direccion1 }} {{ direccion.numero1 }}, {{ direccion.piso }} {{ direccion.puerta }}"
          />
        </div>
      </div>
      <div class="form-group col-3">
        <label for="localidad" class="col-form-label">{{ 'DATOS.DIRECCION.LOCALIDAD' | translate }}</label>
        <div class="datoCol" style="margin: 0">
          <input type="text" readonly class="form-control-plaintext" id="localidad" value="{{ direccion.localidad || '-' }}" />
        </div>
      </div>
      <div class="form-group col-3">
        <label for="municipio" class="col-form-label">{{ 'DATOS.DIRECCION.MUNICIPIO' | translate }}</label>
        <div class="datoCol" style="margin: 0">
          <input type="text" readonly class="form-control-plaintext" id="municipio" value="{{ direccion.municipio || '-' }}" />
        </div>
      </div>
      <div class="form-group col-3">
        <label for="provincia" class="col-form-label" style="padding: 0">{{ 'DATOS.DIRECCION.PROVINCIA' | translate }}</label>
        <div class="datoCol">
          <input type="text" readonly class="form-control-plaintext" id="provincia" value="{{ direccion.provincia || '-' }}" />
        </div>
      </div>
      <div class="form-group col-3">
        <label for="codigoPostal" class="col-form-label" style="padding: 0">{{ 'DATOS.DIRECCION.CODPOS' | translate }}</label>
        <div class="datoCol">
          <input type="text" readonly class="form-control-plaintext" id="codigoPostal" value="{{ direccion.codigoPostal || '-' }}" />
        </div>
      </div>
      <div class="form-group col-3">
        <label for="telFijo" class="col-form-label" style="padding: 0">{{ 'DATOS.DIRECCION.TELEFONO' | translate }}</label>
        <div class="datoCol">
          <input type="text" readonly class="form-control-plaintext" id="telFijo" value="{{ direccion.telFijo1 || '-' }}" />
        </div>
      </div>
      <div class="separadorDir" *ngIf="i + 1 < perfil.direccion.length" style="margin-bottom: 1rem"></div>
    </div>
    <div class="row" style="margin: 15px 0 30px 0">
      <div class="form-group col-6" style="text-align: right; padding-left: 0">
        <button class="btn btn-primary btn-lg" type="button" (click)="modificar()">
          {{ 'PRIMERACCESO.MODIFICAR' | translate }}
        </button>
      </div>
      <div class="form-group col-6">
        <button class="btn btn-success btn-lg" type="submit" (click)="continuar()" [disabled]="loader.active">
          {{ 'PRIMERACCESO.CONTINUAR' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
