/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./soporte-tecnico.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../shared/components/loader/loader.component.ngfactory";
import * as i4 from "../../shared/components/loader/loader.component";
import * as i5 from "../../core/services/loader.service";
import * as i6 from "@angular/common";
import * as i7 from "./soporte-tecnico.component";
import * as i8 from "../../core/services/alert.service";
import * as i9 from "../../core/services/perfil.service";
var styles_SoporteTecnicoComponent = [i0.styles];
var RenderType_SoporteTecnicoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SoporteTecnicoComponent, data: {} });
export { RenderType_SoporteTecnicoComponent as RenderType_SoporteTecnicoComponent };
function View_SoporteTecnicoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "soporte"], ["data-simplebar", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "mensaje"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mensajeServicioSoporte; _ck(_v, 1, 0, currVal_0); }); }
export function View_SoporteTecnicoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "container general-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "row tel-encabezado-pagina"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "h3", [["class", "tel-titulo-pagina"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "separador"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "loader", [], null, null, null, i3.View_LoaderComponent_0, i3.RenderType_LoaderComponent)), i1.ɵdid(10, 442368, null, 0, i4.LoaderComponent, [i1.ElementRef, i5.LoaderService], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SoporteTecnicoComponent_1)), i1.ɵdid(12, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 10, 0); var currVal_1 = !_co.loader.active; _ck(_v, 12, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("SOPORTE.TITULO")); _ck(_v, 5, 0, currVal_0); }); }
export function View_SoporteTecnicoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-soporte-tecnico", [], null, null, null, View_SoporteTecnicoComponent_0, RenderType_SoporteTecnicoComponent)), i1.ɵdid(1, 114688, null, 0, i7.SoporteTecnicoComponent, [i5.LoaderService, i8.AlertService, i9.PerfilService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SoporteTecnicoComponentNgFactory = i1.ɵccf("app-soporte-tecnico", i7.SoporteTecnicoComponent, View_SoporteTecnicoComponent_Host_0, {}, {}, []);
export { SoporteTecnicoComponentNgFactory as SoporteTecnicoComponentNgFactory };
