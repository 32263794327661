<div class="modal fade" id="analitica">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h3>
          <strong> {{ 'CALENDARIO.ANALITICA.VISUALIZACION.TITULO' | translate }} </strong>
        </h3>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body" data-simplebar>
        <loader></loader>
        <form [formGroup]="forma" (ngSubmit)="guardarCambios()">
          <div class="row">
            <div class="separador"></div>
          </div>
          <div class="detalleActividad">
            <p *ngIf="actividad && !actividad.todoElDia">
              {{ 'CALENDARIO.REALIZACION.REALIZAR_ENTRE' | translate }}: {{ actividad.fechaInicioSinMargen | date: 'HH:mm' }} -
              {{ actividad.fechaFinalSinMargen | date: 'HH:mm' }} &nbsp;
            </p>
            <p *ngIf="actividad && actividad.todoElDia">{{ 'CALENDARIO.REALIZACION.TODO_DIA' | translate }}&nbsp;</p>
          </div>

          <table class="m-auto">
            <thead>
              <!-- FECHA -->
              <div class="form-group">
                <label for="fecha" class="col-form-label">{{ 'CALENDARIO.ANALITICA.FECHA' | translate }}</label>
                <div class="input-group datoCol fechaMedida">
                  <input
                    class="form-control"
                    placeholder="yyyy-mm-dd"
                    formControlName="fecha"
                    ngbDatepicker
                    #d="ngbDatepicker"
                    id="fecha"
                  />
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
                      <i class="icon-calendario" style="font-size: 1.5rem; cursor: pointer"></i>
                    </button>
                  </div>
                </div>
              </div>
              <!-- ERROR FECHA -->
              <div class="filaError d-none d-table-row" [ngClass]="{ 'd-table-row': forma.controls['fecha'].errors?.required }">
                <div class="d-none d-sm-table-cell"></div>
                <small class="error form-text text-muted">
                  {{ 'CALENDARIO.ANALITICA.ERRORES.REQUERIDO.FECHAREQUERIDO' | translate }}
                </small>
              </div>
              <div
                class="filaError d-none"
                [ngClass]="{ 'd-table-row': forma.errors && forma.errors['fechaInferior'] && !forma.controls['fecha'].errors?.required }"
              >
                <div class="d-none d-sm-table-cell"></div>
                <small class="error form-text text-muted">
                  {{ 'CALENDARIO.ANALITICA.ERRORES.FECHAINFERIOR' | translate }}
                </small>
              </div>
              <div
                class="filaError d-none"
                [ngClass]="{ 'd-table-row': forma.errors && forma.errors['fechaSuperior'] && !forma.controls['fecha'].errors?.required }"
              >
                <div class="d-none d-sm-table-cell"></div>
                <small class="error form-text text-muted">
                  {{ 'CALENDARIO.ANALITICA.ERRORES.FECHASUPERIOR' | translate }}
                </small>
              </div>

              <!-- HORA -->
              <div class="form-group">
                <label for="hora" class="col-form-label">{{ 'CALENDARIO.ANALITICA.HORA' | translate }}</label>
                <div class="datoCol horaMedida">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="hh:mm"
                    id="hora"
                    [textMask]="{ mask: mask }"
                    formControlName="hora"
                  />
                </div>
              </div>
            </thead>
            <!-- ERROR HORA -->
            <div
              class="filaError d-none"
              [ngClass]="{
                'd-table-row': forma.controls['hora'].dirty && forma.controls['hora'].errors && !forma.controls['hora'].errors?.pattern
              }"
            >
              <div class="d-none d-sm-table-cell"></div>
              <small class="error form-text text-muted d-none" [ngClass]="{ 'd-block': forma.controls['hora'].errors?.required }">
                {{ 'CALENDARIO.ANALITICA.ERRORES.REQUERIDO.HORAREQUERIDO' | translate }}
              </small>
            </div>

            <!-- OBSERVACIONES -->
            <tfoot>
              <div class="form-group">
                <label for="observaciones" class="col-form-label labelObservaciones">{{
                  'CALENDARIO.ANALITICA.OBSERVACIONES' | translate
                }}</label>
                <div class="datoCol" id="observaciones">
                  <textarea class="form-control focus2" formControlName="observaciones" maxlength="200" rows="5"></textarea>
                </div>
                <div class="observaciones d-table-cell">{{ forma.value.observaciones.length }}/200</div>
              </div>
              <!-- ERROR OBSERVACIONES -->
              <div
                class="filaError d-none"
                [ngClass]="{ 'd-table-row': forma.controls['observaciones'].dirty && forma.controls['observaciones'].errors }"
              >
                <div class="d-none d-sm-table-cell"></div>
                <small class="error form-text text-muted">
                  {{ 'CALENDARIO.ANALITICA.ERRORES.OBSERVACIONES_FORMATO' | translate }}
                </small>
              </div>
            </tfoot>
          </table>
        </form>
      </div>
      <!-- Modal footer -->
      <div class="modal-footer">
        <button class="btn btn-primary btn-lg" type="button" (click)="limpiar()">
          {{ 'GENERAL.LIMPIAR' | translate }}
        </button>
        <button class="btn btn-success btn-lg" type="submit" (click)="guardarCambios()" [disabled]="forma.invalid">
          {{ 'GENERAL.GUARDAR' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
