<div class="modal fade" id="visualizacion-personal">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h3>
          <strong> {{ titulo }} </strong>
        </h3>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <div class="modal-separador"></div>

      <!-- Modal body -->
      <div class="modal-body" data-simplebar>
        <div class="text-left" [innerHTML]="descripcion"></div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-lg" data-dismiss="modal">{{ 'GENERAL.CERRAR' | translate }}</button>
      </div>
    </div>
  </div>
</div>
