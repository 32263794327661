<div class="modal fade" id="visualizacion-analitica">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h3>
          <strong>{{ 'CALENDARIO.ANALITICA.VISUALIZACION.TITULO' | translate }}</strong>
        </h3>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <div class="modal-separador"></div>

      <!-- Modal body -->
      <div class="modal-body" data-simplebar>
        <div>
          {{ 'CALENDARIO.ANALITICA.VISUALIZACION.ENVIO' | translate }}: {{ analitica.fechaActividad | date: 'dd/MM/yyyy' }} -
          {{ analitica.fechaActividad | date: 'HH:mm' }}h
        </div>
        <div class="form-group row texto-analitica" *ngIf="analitica.observaciones">
          <label class="col-6 col-form-label">
            <strong>{{ 'CALENDARIO.ANALITICA.VISUALIZACION.OBSERVACIONES' | translate }}</strong
            >:
          </label>
          <div class="col-6 col-form-label observaciones" [innerHTML]="analitica.observaciones"></div>
        </div>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-lg" data-dismiss="modal">{{ 'GENERAL.CERRAR' | translate }}</button>
      </div>
    </div>
  </div>
</div>
