import { Injectable, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalButton } from '../models/modalButton.model';

/**
 * Servicio para la gestión de las alertas.
 *
 * @author lreverendo
 *
 * @version 01.02.0010
 * @since 01.02.0000
 */
@Injectable({ providedIn: 'root' })
export class AlertService {
  /**
   * Evento para mostrar una alerta.
   */
  public alert: EventEmitter<{ mensaje: string; exito: boolean }> = new EventEmitter();

  /**
   * Evento para mostrar un modal.
   */
  public modal: EventEmitter<{
    origen?: any;
    titulo: string;
    subtitulo?: string;
    mensaje: string;
    buttons: ModalButton[];
  }> = new EventEmitter();

  constructor(private translateService: TranslateService) {}

  public lanzarExito(msj: string): void {
    const data = {
      mensaje: msj,
      exito: true
    };

    this.alert.emit(data);
  }

  public showAlertToast(mensaje: string): void {
    const data = {
      mensaje: this.translateService.instant(mensaje),
      exito: false
    };
    this.alert.emit(data);
  }

  public lanzarError(status: number): void {
    if (status !== 401 && status !== 403 && status !== 404) {
      const data = {
        mensaje: this.translateService.instant('GENERAL.ERRORES.SERVIDOR'),
        exito: false
      };

      this.alert.emit(data);
    }
  }

  public lanzarErrorPermiso(): void {
    const data = {
      titulo: this.translateService.instant('MODALES.AVISOPERMISO.TITULO'),
      mensaje: this.translateService.instant('MODALES.AVISOPERMISO.MENSAJE'),
      buttons: [
        {
          texto: this.translateService.instant('MODALES.AVISOPERMISO.OK'),
          type: 'submit',
          cerrar: true
        }
      ],
      cerrar: false
    };

    this.modal.emit(data);
  }

  public lanzarErrorSesion(): void {
    const data = {
      titulo: this.translateService.instant('MODALES.AVISOCADUCADA.TITULO'),
      mensaje: this.translateService.instant('MODALES.AVISOCADUCADA.MENSAJE'),
      buttons: [
        {
          texto: this.translateService.instant('MODALES.AVISOCADUCADA.OK'),
          type: 'submit',
          cerrar: true
        }
      ],
      cerrar: false
    };

    this.modal.emit(data);
  }
}
