/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./errorAlmacenamiento.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./errorAlmacenamiento.component";
var styles_ErrorAlmacenamientoComponent = [i0.styles];
var RenderType_ErrorAlmacenamientoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorAlmacenamientoComponent, data: {} });
export { RenderType_ErrorAlmacenamientoComponent as RenderType_ErrorAlmacenamientoComponent };
export function View_ErrorAlmacenamientoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "contenido"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [["class", "tituloError"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Erro de acceso \u00E1 aplicaci\u00F3n:"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Non se pode acceder ao almacenamento local."])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Pode enviar as s\u00FAas consultas t\u00E9cnicas acerca da plataforma TELEA \u00E1 conta de correo "])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "a", [["href", "mailto:soporte.cidadania@sergas.es?Subject=TELEA"], ["target", "_top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "u", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["soporte.cidadania@sergas.es"])), (_l()(), i1.ɵted(-1, null, [" (Asunto: TELEA) e recibir\u00E1 resposta \u00E1 maior brevidade posible. "]))], null, null); }
export function View_ErrorAlmacenamientoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-error-almacenamiento", [], null, null, null, View_ErrorAlmacenamientoComponent_0, RenderType_ErrorAlmacenamientoComponent)), i1.ɵdid(1, 49152, null, 0, i2.ErrorAlmacenamientoComponent, [], null, null)], null, null); }
var ErrorAlmacenamientoComponentNgFactory = i1.ɵccf("app-error-almacenamiento", i2.ErrorAlmacenamientoComponent, View_ErrorAlmacenamientoComponent_Host_0, {}, {}, []);
export { ErrorAlmacenamientoComponentNgFactory as ErrorAlmacenamientoComponentNgFactory };
