<div class="container general-container" data-simplebar>
  <div class="row tel-encabezado-pagina">
    <div class="col-12">
      <h3 class="tel-titulo-pagina">
        <strong>{{ 'NOTIFICACIONES.TITULO' | translate }}</strong>
      </h3>
    </div>
  </div>
  <div class="row">
    <div class="separador"></div>
  </div>
  <div class="tel-cuerpo-pagina col-12" data-simplebar>
    <loader [for]="[operation.actualizacionNotificaciones]"></loader>
    <loader [for]="[operation.busquedaNotificaciones]"></loader>

    <div
      class="row justify-content-center"
      *ngIf="listaNotificacionesRecientes.length <= 0 && listaNotificacionesAnteriores.length <= 0 && !loader.active"
    >
      <div class="sinDatos col-12">
        <h3>{{ 'NOTIFICACIONES.SIN_DATOS' | translate }}</h3>
      </div>
    </div>

    <div class="tel-grupo-notificaciones row justify-content-center mb-4" *ngIf="listaNotificacionesRecientes.length > 0 && !loader.active">
      <div class="tel-cabecera-grupo">
        <div class="d-flex w-100">
          <h4 class="mb-1">{{ 'NOTIFICACIONES.RECIENTES' | translate }}</h4>
        </div>
      </div>
      <ul class="list-group w-100">
        <li
          *ngFor="let notificacion of listaNotificacionesRecientes"
          class="tel-notificacion list-group-item list-group-item-action flex-column align-items-start"
          [ngClass]="{ revisada: notificacion.revisada }"
          (click)="revisarNotificacion(notificacion)"
        >
          <i class="icon-{{ notificacion.icono }} icono"></i>
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">{{ notificacion.titulo }}</h5>
            <small class="tel-tiempo-notificacion">{{ notificacion.fechaFormateada }}</small>
          </div>
          <p class="mb-1">{{ notificacion.descripcion }}</p>
        </li>
      </ul>
    </div>

    <div class="tel-grupo-notificaciones row justify-content-center" *ngIf="listaNotificacionesAnteriores.length > 0 && !loader.active">
      <div class="tel-cabecera-grupo">
        <div class="d-flex w-100">
          <h4 class="mb-1">{{ 'NOTIFICACIONES.ANTERIORES' | translate }}</h4>
        </div>
      </div>
      <ul class="list-group w-100">
        <li
          *ngFor="let notificacion of listaNotificacionesAnteriores"
          class="tel-notificacion list-group-item list-group-item-action flex-column align-items-start"
          [ngClass]="{ revisada: notificacion.revisada }"
          (click)="revisarNotificacion(notificacion)"
        >
          <i class="icon-{{ notificacion.icono }} icono"></i>
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">{{ notificacion.titulo }}</h5>
            <small class="tel-tiempo-notificacion">{{ notificacion.fechaFormateada }}</small>
          </div>
          <p class="mb-1">{{ notificacion.descripcion }}</p>
        </li>
      </ul>
    </div>
  </div>
</div>
