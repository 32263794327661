import { CommonModule } from '@angular/common';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

// SERVICES
import { AppConfigService } from './services/app-config.service';

// INTERCEPTORS
import { RequestInterceptor } from '../core/interceptors/request.interceptor';
import { AutenticacionInterceptor } from '../core/interceptors/autenticacion.interceptor';

// Función que se ejecutará durante el proceso de inicialización de la aplicación
const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AutenticacionInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService]
    }
  ],
  declarations: []
})
export class CoreModule {}
