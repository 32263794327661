import { OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { VariableClinica } from '../../../../core/models';
import * as i0 from "@angular/core";
/**
 *  Clase padre de los dispositivos.
 *
 * @author lreverendo
 *
 * @version 01.02.0070
 * @since 01.02.0000
 */
var DispositivoGenerico = /** @class */ (function () {
    function DispositivoGenerico() {
    }
    DispositivoGenerico.prototype.ngOnChanges = function () {
        this.changeMaxMin();
        if (this.prandial == null) {
            this.prandial = this.variablesClinicas[0];
        }
    };
    DispositivoGenerico.prototype.limpiar = function () {
        var _this = this;
        // Método para resetear el formulario.
        Object.keys(this.forma.controls).map(function (k) { return _this.forma.controls[k].reset(''); });
    };
    DispositivoGenerico.prototype.changeMaxMin = function () {
        // Método opcional
    };
    /*
     * Método que devuelve el patrón a utilizar según el formato (entero o decimal) según el formato que tenga configurado la variable clínica
     */
    DispositivoGenerico.prototype.getPatronFormato = function (formato) {
        if (formato === void 0) { formato = ''; }
        switch (formato.trim().toUpperCase()) {
            case 'DECIMAL':
                return DispositivoGenerico.PATRON_DECIMAL;
            case 'ENTERO':
                return DispositivoGenerico.PATRON_ENTERO;
            default:
                return DispositivoGenerico.PATRON_ENTERO;
        }
    };
    DispositivoGenerico.PATRON_DECIMAL = '^[0-9]{0,4}((.|,)([0-9]{1,2}))?$';
    DispositivoGenerico.PATRON_ENTERO = '^[0-9]{0,5}$';
    DispositivoGenerico.ngInjectableDef = i0.defineInjectable({ factory: function DispositivoGenerico_Factory() { return new DispositivoGenerico(); }, token: DispositivoGenerico, providedIn: "root" });
    return DispositivoGenerico;
}());
export { DispositivoGenerico };
