import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { OperationInfo } from '../../core/models/operationInfo.model';

/**
 * Carga de la configuración en tiempo de ejecución.
 *
 * @author lreverendo
 * @author fjsalgado
 * @author dvila
 * @author aarodriguezo
 *
 * @version 01.02.0250
 * @since 01.02.0000
 */
@Injectable()
export class AppConfigService {
  public static version;
  public static apiEndpoint;
  public static urlEsaudeRedireccion;
  public static listaVinculacionDispositivos;
  public static urlLogoutEsaudeRedireccion;
  public static urlManual;
  public static urlManualEn;
  public static identificadorEjercicio;
  public static operacionesExterno;

  public static urls;

  public static segundosRefrescoNotificaciones = 30;
  public static diasCaducidadActividad = 30;
  public static diasNotificacionesRecientes = 7;
  public static diasMensajesRecientes = 7;
  public static origenDatos = 'TPACWEB';

  // Tiempo de sesión: 20 minutos por defecto
  public static tiempoSesion = 20 * 60;

  constructor(private injector: Injector) {}

  loadAppConfig() {
    const http = this.injector.get(HttpClient);

    return http
      .get('./assets/app-config.json')
      .toPromise()
      .then((data) => {
        AppConfigService.version = data['VERSION'];
        AppConfigService.apiEndpoint = data['API_ENDPOINT'];
        this.cargarURLs();
        AppConfigService.segundosRefrescoNotificaciones = data['SEGUNDOS_REFRESCO_NOTIFICACIONES'];
        AppConfigService.diasCaducidadActividad = data['DIAS_CADUCIDAD_ACTIVIDAD'];
        AppConfigService.diasNotificacionesRecientes = data['DIAS_NOTIFICACIONES_RECIENTES'];
        AppConfigService.diasMensajesRecientes = data['DIAS_MENSAJES_RECIENTES'];
        AppConfigService.urlEsaudeRedireccion = data['URL_ESAUDE_REDIRECCION'];
        AppConfigService.listaVinculacionDispositivos = data['VINCULACION_DISPOSITIVOS'];
        AppConfigService.urlLogoutEsaudeRedireccion = data['URL_LOGOUT_ESAUDE_REDIRECCION'];
        AppConfigService.urlManual = data['URL_MANUAL'];
        AppConfigService.urlManualEn = data['URL_MANUAL_EN'];
        AppConfigService.tiempoSesion = data['TIEMPO_SESION'] * 60;
        AppConfigService.identificadorEjercicio = data['IDENTIFICADOR_EJERCICIO'];
        AppConfigService.operacionesExterno = data['OPERACIONES_EXTERNO'];
      })
      .catch(() => {
        console.warn('Error cargando [app-config.json], usando fichero environment en su lugar...');
        AppConfigService.version = environment.VERSION;
        AppConfigService.apiEndpoint = environment.API_ENDPOINT;
        this.cargarURLs();
      });
  }

  cargarURLs() {
    AppConfigService.urls = {
      // Perfil
      recuperarPerfil: AppConfigService.apiEndpoint + '/perfil/%s?locale=%s',
      recuperarInstruccionesPaciente: AppConfigService.apiEndpoint + '/instrucciones/%s?locale=%s',
      editarPerfil: AppConfigService.apiEndpoint + '/edit/perfil/%s?locale=%s',
      cambiarContrasena: AppConfigService.apiEndpoint + '/edit/contrasena/%s',
      recuperarCuidadores: AppConfigService.apiEndpoint + '/cuidadores/%s?locale=%s',
      lanzarBotonPanico: AppConfigService.apiEndpoint + '/emergencia/%s',
      actualizarAccesoPrimeraVez: AppConfigService.apiEndpoint + '/edit/accesoPrimeraVez/%s',
      // Actividades
      recuperarActividadesCuestionarios:
        AppConfigService.apiEndpoint + '/actividades/cuestionarios/%s?fechaInicioBusqueda=%s&fechaFinBusqueda=%s',
      recuperarActividadesBiomedidas:
        AppConfigService.apiEndpoint + '/actividades/biomedidas/%s?fechaInicioBusqueda=%s&fechaFinBusqueda=%s',
      recuperarActividadesPersonales:
        AppConfigService.apiEndpoint + '/actividades/personales/%s?fechaInicioBusqueda=%s&fechaFinBusqueda=%s',
      recuperarActividades: AppConfigService.apiEndpoint + '/actividades/%s?fechaInicioBusqueda=%s&fechaFinBusqueda=%s&tiposActividades=%s',
      recuperarTiposActividades: AppConfigService.apiEndpoint + '/actividades/tipos',
      crearActividadPersonal: AppConfigService.apiEndpoint + '/add/actividad/%s',
      editarActividadPersonal: AppConfigService.apiEndpoint + '/edit/actividad/%s',
      // Extracción analítica
      crearExtraccionAnalitica: AppConfigService.apiEndpoint + '/add/analitica/%s',
      getExtraccionAnalitica: AppConfigService.apiEndpoint + '/analitica/%s/%s',
      // Medicación
      obtenerMedicacion: AppConfigService.apiEndpoint + '/medicacion/%s',
      obtenerlHojaMedicacion: AppConfigService.apiEndpoint + '/medicacion/hoja/%s?codigo=%s',
      // Mensajería
      recuperarMensajesRecibidos: AppConfigService.apiEndpoint + '/paciente/%s/mensajes/recibidos',
      recuperarMensajesEnviados: AppConfigService.apiEndpoint + '/paciente/%s/mensajes/enviados',
      enviarMensaje: AppConfigService.apiEndpoint + '/paciente/%s/mensajes/envio',
      marcarMensajeLeido: AppConfigService.apiEndpoint + '/paciente/%s/mensajes/leidos/%s',
      // Notificaciones
      busquedaNotificaciones: AppConfigService.apiEndpoint + '/paciente/%s/notificaciones?tiposNotificacion=%s',
      busquedaUltimasNotificaciones:
        AppConfigService.apiEndpoint + '/paciente/%s/notificaciones/ultimas?tiposNotificacion=%s&fechaInicioBusqueda=%s',
      marcarNotificacionRevisada: AppConfigService.apiEndpoint + '/paciente/%s/notificaciones/revisadas/%s',
      // Biomedidas
      recuperarDispositivos: AppConfigService.apiEndpoint + '/dispositivos/%s',
      enviarBiomedida: AppConfigService.apiEndpoint + '/add/biomedida/%s',
      recuperarBiomedidasHistorico: AppConfigService.apiEndpoint + '/biomedidas/historico/%s?inicio=%s&fin=%s&tipos=%s',
      recuperarBiomedidas: AppConfigService.apiEndpoint + '/biomedidas/%s?ids=%s',
      recuperarTalla: AppConfigService.apiEndpoint + '/talla/%s',
      // Cuestionarios
      recuperarCuestionarioRealizado: AppConfigService.apiEndpoint + '/cuestionarios/realizado/%s/%s/%s',
      recuperarHistorico: AppConfigService.apiEndpoint + '/cuestionarios/historico/%s',
      recuperarCuestionario: AppConfigService.apiEndpoint + '/cuestionarios/%s/%s?locale=%s',
      enviarCuestionario: AppConfigService.apiEndpoint + '/add/cuestionario/%s',
      // Login
      prepararLogin: AppConfigService.apiEndpoint + '/login',
      enviarPin: AppConfigService.apiEndpoint + '/pin',
      logout: AppConfigService.apiEndpoint + '/logout',
      prepararRecuperacion: AppConfigService.apiEndpoint + '/prepararRecuperacion',
      recuperar: AppConfigService.apiEndpoint + '/recuperar',
      validarCertificado: AppConfigService.apiEndpoint + '/certificado/validar',
      urlEsaude: AppConfigService.apiEndpoint + '/urlEsaude?urlRedireccion=%s',
      loginEsaude: AppConfigService.apiEndpoint + '/loginEsaude?urlRedireccion=%s',
      urlLogoutEsaude: AppConfigService.apiEndpoint + '/logoutEsaude',
      // Vinculacion de dispositivos
      vinculacionDispositivos: AppConfigService.apiEndpoint + '/vinculacion/paciente/%s/codCentro/%s/idSistema/%s/dispositivos/url?urlRedireccion=%s',
      vinculacionDispositivosSuccess: AppConfigService.apiEndpoint + '/vinculacion/paciente/%s/codCentro/%s/idSistema/%s/vinculacion',
    };
  }
}

export enum Operation {
  cargaAsset,
  cargaInstruccionesPaciente,
  actualizacionNotificaciones,
  busquedaNotificaciones,
  marcarNotificacionRevisada,
  recuperarDispositivos,
  recuperarActividades,
  logout,
  unknown
}

export const AppConfig = {
  route: {
    CALENDARIO: ['calendario'],
    MENSAJES: ['mensajeria']
  },
  loader: {
    operations: [
      new OperationInfo(Operation.cargaAsset, /\w*\/assets\/\w*/, false, false),
      new OperationInfo(Operation.cargaInstruccionesPaciente, /\w*\/instrucciones\w*/, true, false),
      new OperationInfo(Operation.actualizacionNotificaciones, /\w*\/paciente\/\w*\/notificaciones\/ultimas\w*/, false, false),
      new OperationInfo(Operation.busquedaNotificaciones, /\w*\/paciente\/\w*\/notificaciones\w*\/tiposNotificacion\w*/, false, false),
      new OperationInfo(Operation.marcarNotificacionRevisada, /\w*\/notificaciones\/revisadas\w*/, false, false),
      new OperationInfo(Operation.recuperarDispositivos, /w*\/dispositivos\/\w*/, false, false),
      new OperationInfo(Operation.recuperarActividades, /w*\/actividades\/\w*/, true, false),
      new OperationInfo(Operation.logout, /w*\/logout\w*/, false, true),
      new OperationInfo(Operation.unknown, /\w*/, false, false)
    ],
    ignoredOperations: [
      Operation.cargaAsset,
      Operation.actualizacionNotificaciones,
      Operation.marcarNotificacionRevisada,
      Operation.recuperarDispositivos
    ],
    lockScreenMethods: ['POST', 'PUT', 'DELETE']
  },
  constant: {
    utcFormat: 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'
  },
  error: {
    TICKET_NO_VALIDO: 1010,
    TICKET_ACCESO_EXPIRADO: 1018,
    TOKEN_ACCESO_NO_RECIBIDO: 1005,
    XML_INCORRECTO: 1021
  }
};
