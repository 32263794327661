import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AlertService, PerfilService } from '../../../../core/services';
import { RespuestaPeticion } from '../../../../core/models';

/**
 * Componente para la vista de cambio de contraseña.
 *
 * @author lreverendo
 *
 * @version 01.02.0000
 * @since 01.02.0000
 */
@Component({
  selector: 'app-contrasena',
  templateUrl: './contrasena.component.html',
  styleUrls: ['../../perfil.component.css', './contrasena.component.css']
})
export class ContrasenaComponent {
  public forma: FormGroup;
  public disabled = false;

  constructor(private alertService: AlertService, private perfilService: PerfilService, private translateService: TranslateService) {
    this.reload();
  }

  public reload(): void {
    // Se inicializa el formulario.
    this.forma = new FormGroup(
      {
        contrasenaActual: new FormControl('', Validators.required),
        contrasenaNueva: new FormControl('', [Validators.required, Validators.minLength(4)]),
        contrasenaRepetir: new FormControl('', Validators.required)
      },
      this.noIgual
    );
  }

  public cambiarContrasena(): void {
    this.disabled = true;
    // Se llama al método encargado de cambiar la contraseña.
    this.perfilService.cambiarContrasena(this.forma.value).subscribe(
      (resp: RespuestaPeticion) => {
        if (resp.executionResult) {
          this.limpiar();
          this.alertService.lanzarExito(this.translateService.instant('CONTRASENA.NOTIFICACION.SUCCESS'));
        } else {
          // Se comprueba el código de error devuelto.
          switch (resp.errorCode) {
            case 'ER_CHPWD_01':
              this.forma.controls['contrasenaNueva'].setErrors({ minlength: true });
              break;
            case 'ER_CHPWD_03':
              this.forma.controls['contrasenaNueva'].setErrors({ noiguales: true });
              break;
            case 'ER_CHPWD_05':
              this.forma.controls['contrasenaActual'].setErrors({ incorrecta: true });
              break;
          }
        }
        this.disabled = false;
      },
      (error) => {
        this.alertService.lanzarError(error.status);
      }
    );
  }

  public limpiar(): void {
    // Reseteo del formulario.
    this.forma.reset({
      contrasenaNueva: '',
      contrasenaActual: '',
      contrasenaRepetir: ''
    });
  }

  // Función para mostrar error en el formulario.
  private noIgual(forma: FormGroup): { [s: string]: boolean } {
    // Se comprueba si la contraseña nueva y la repetición son iguales.
    if (forma.controls['contrasenaRepetir'].value !== forma.controls['contrasenaNueva'].value) {
      return {
        noiguales: true
      };
    }
    return null;
  }
}
