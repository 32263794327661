<div class="form-group" [formGroup]="forma">
  <label for="insulina" class="col-form-label"
    >{{ 'BIOMEDIDAS.INSULINA.INSULINA' | translate }} ({{ 'BIOMEDIDAS.VARIABLES.INS_PRE.unidad' | translate }})</label
  >
  <div class="datoCol">
    <input
      type="text"
      [appFormatNumber]="prandial.formato"
      appBlockCopyPaste
      class="form-control variable focus"
      id="insulina"
      formControlName="insulina"
    />
  </div>
</div>
<div
  class="filaError d-none"
  [ngClass]="{ 'd-table-row': forma.controls['insulina'].dirty && forma.controls['insulina'].errors?.maxError }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted"> {{ 'BIOMEDIDAS.INSULINA.INSULINAMAX' | translate }}{{ prandial.max }} </small>
</div>
<div
  class="filaError d-none"
  [ngClass]="{ 'd-table-row': forma.controls['insulina'].dirty && forma.controls['insulina'].errors?.minError }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted"> {{ 'BIOMEDIDAS.INSULINA.INSULINAMIN' | translate }}{{ prandial.min }} </small>
</div>
<div
  class="filaError d-none"
  [ngClass]="{ 'd-table-row': forma.controls['insulina'].dirty && forma.controls['insulina'].errors?.required }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted">
    {{ 'BIOMEDIDAS.INSULINA.INSULINAREQUERIDO' | translate }}
  </small>
</div>
