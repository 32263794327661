import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe para mostrar correctamente las fechas.
 *
 * @author lreverendo
 *
 * @version 01.02.0030
 * @since 01.02.0000
 */
@Pipe({
  name: 'fecha'
})
export class FechaPipe implements PipeTransform {
  public transform(value: string, idioma: string = 'gl', tipo: string = 'large'): string {
    const mesesGL: string[] = [
      'Xaneiro',
      'Febreiro',
      'Marzo',
      'Abril',
      'Maio',
      'Xuño',
      'Xullo',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Decembro'
    ];
    const mesesES: string[] = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre'
    ];
    const mesesEN: string[] = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];

    if (value === '' || value === null) {
      return '';
    }

    const date = new Date(parseInt(value, 10));
    let month = mesesES[date.getMonth()];
    if (idioma === 'gl') {
      month = mesesGL[date.getMonth()];
    }

    if (idioma === 'en') {
      month = mesesEN[date.getMonth()];
      if (tipo === 'large') {
        return month + ' ' + date.getDate() + ', ' + date.getFullYear();
      } else {
        return month + ', ' + date.getFullYear();
      }
    }

    if (tipo === 'large') {
      return date.getDate() + ' ' + month + ', ' + date.getFullYear();
    } else {
      return month + ', ' + date.getFullYear();
    }
  }
}
