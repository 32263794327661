import { Pipe, PipeTransform } from '@angular/core';
import { LocaleTexto } from '../../../core/models/localeTexto.model';
import { isNull } from 'util';

/**
 * Pipe para gestionar el json localeTexto.
 *
 * @author lreverendo
 *
 * @version 01.02.0040
 * @since 01.02.0000
 */
@Pipe({
  name: 'localeTexto'
})
export class LocaleTextoPipe implements PipeTransform {
  public transform(value: LocaleTexto[], idioma: string = 'gl'): string {
    if (isNull(idioma)) {
      idioma = 'gl';
    }
    let texto = '';
    let texto_gl = '';
    if (value != null) {
      for (let i = 0; i < value.length; i++) {
        if (value[i].locale === idioma) {
          texto = value[i].texto;
        }
        if (value[i].locale === 'gl') {
          texto_gl = value[i].texto;
        }
      }
      if (texto === '') {
        texto = texto_gl;
      }
    }
    return texto;
  }
}
