import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';

// DIRECTIVES
import { FormatNumberDirective } from './directives/format-number/format-number.directive';
import { IfPermisoEscrituraDirective } from './directives/if-permiso-escritura/if-permiso-escritura.directive';
import { ClaseTipoActividadDirective } from './directives/clase-tipo-actividad/clase-tipo-actividad.directive';
import { BotonActividadPermisoDirective } from './directives/boton-actividad-permiso/boton-actividad-permiso.directive';
import { BlockCopyPasteDirective } from './directives/block-copy-paste/block-copy-paste.directive';
import { TelefonoDirective } from './directives/telefono/telefono.directive';
import { TipoDispositivoBiomedidaDirective } from './directives/tipo-dispositivo-biomedida/tipo-dispositivo-biomedida.directive';

// COMPONENTS
import { AlertComponent } from './components/alert/alert.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ModalComponent } from './components/modal/modal.component';
import { ErrorAlmacenamientoComponent } from './components/errorAlmacenamiento/errorAlmacenamiento.component';

// PIPES
import { FechaPipe } from './pipes/fecha/fecha';
import { MesPipe } from './pipes/mes/mes';
import { SemanaPipe } from './pipes/semana/semana';
import { DiaPipe } from './pipes/dia/dia';
import { HTMLTextoPipe } from './pipes/html-texto/html-texto';
import { LocaleTextoPipe } from './pipes/locale-texto/locale-texto';
import { CapitalizadoPipe } from './pipes/capitalizado/capitalizado';
import { ContienePipe } from './pipes/contiene/contiene';
import { SplitPipe } from './pipes/split/split';
import { DatePipe, UpperCasePipe } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  declarations: [
    FormatNumberDirective,
    IfPermisoEscrituraDirective,
    ClaseTipoActividadDirective,
    BotonActividadPermisoDirective,
    BlockCopyPasteDirective,
    TelefonoDirective,
    AlertComponent,
    LoaderComponent,
    ModalComponent,
    ErrorAlmacenamientoComponent,
    FechaPipe,
    MesPipe,
    SemanaPipe,
    DiaPipe,
    SplitPipe,
    CapitalizadoPipe,
    HTMLTextoPipe,
    LocaleTextoPipe,
    ContienePipe,
    TipoDispositivoBiomedidaDirective
  ],
  exports: [
    FormatNumberDirective,
    IfPermisoEscrituraDirective,
    ClaseTipoActividadDirective,
    BotonActividadPermisoDirective,
    BlockCopyPasteDirective,
    TelefonoDirective,
    AlertComponent,
    LoaderComponent,
    ModalComponent,
    ErrorAlmacenamientoComponent,
    FechaPipe,
    MesPipe,
    SemanaPipe,
    DiaPipe,
    SplitPipe,
    CapitalizadoPipe,
    HTMLTextoPipe,
    LocaleTextoPipe,
    ContienePipe,
    TipoDispositivoBiomedidaDirective
  ],
  providers: [ContienePipe, DatePipe, HTMLTextoPipe, LocaleTextoPipe, UpperCasePipe]
})
export class SharedModule {}
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
