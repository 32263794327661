<div class="container">
  <loader></loader>
  <div class="row tel-encabezado-pagina">
    <div class="col-12">
      <h3 class="tel-titulo-pagina">
        <strong>{{ 'DISPOSITIVOS.TITULO' | translate }}</strong>
      </h3>
    </div>
  </div>
  <div class="row">
    <div class="separador"></div>
  </div>

  <div class="eventos-contenido" data-simplebar>
    
    <div *ngFor="let dispositivo of listaDispositivosTodos" class="list-group-item btn col-12 dispositivo">
      <div class="row">
        <img [src]="'assets/images/' + dispositivo['icono']" class="icono" />
        <div class="col">
          <div class="row">
            <div class="col dispositivoInfo">{{ 'DISPOSITIVOS.' + dispositivo['nombre'] | translate }}</div>
            <div class="dispositivoVinculado" *ngIf="tieneVinculoAbbott">{{ 'DISPOSITIVOS.VINCULACION.VINCULADO' | translate }}</div>
            <button class="btn btn-primary" type="button" (click)="irURLVinculacionDispositivo(dispositivo['nombre'])" *ngIf="!tieneVinculoAbbott">
              {{ 'DISPOSITIVOS.VINCULACION.VINCULAR' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>
