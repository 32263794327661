var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { TranslateService } from '@ngx-translate/core';
import { AlertService, CalendarioService, PerfilService, LoaderService, DispositivoService } from '../../../../core/services';
import { VistaCalendario } from '../vista-calendario.model';
import * as moment from 'moment';
import { SimplebarAngularComponent } from 'simplebar-angular';
/**
 * Componente para la vista semanal del calendario.
 *
 * @author lreverendo
 *
 * @version 01.02.0090
 * @since 01.02.0000
 */
var SemanaComponent = /** @class */ (function (_super) {
    __extends(SemanaComponent, _super);
    function SemanaComponent(loader, alertService, calendarioService, perfilService, dispositivoService, translateService) {
        var _this = _super.call(this, alertService, calendarioService, perfilService, dispositivoService, translateService) || this;
        _this.loader = loader;
        _this.alertService = alertService;
        _this.calendarioService = calendarioService;
        _this.perfilService = perfilService;
        _this.dispositivoService = dispositivoService;
        _this.actividadSeleccionada = null;
        _this.dataset = [];
        // Inicialización de los datos.
        var hoy = new Date();
        hoy.setHours(0, 0, 0, 0);
        _this.hoy = new Date(+hoy).getTime();
        _this.fecha = new Date();
        // Subscripción al evento reload.
        // Se recarga la vista semanal.
        _this.subs.push(_this.calendarioService.reload.subscribe(function () {
            _this.hoy = new Date(+hoy).getTime();
            _this.fecha = new Date();
            _this.cargarActividades();
        }));
        return _this;
    }
    SemanaComponent.prototype.actualizarActividades = function (actividades) {
        this.actividadSeleccionada = null;
        this.actividadesTratadas = actividades;
        this.guardarActividadesDataset();
    };
    SemanaComponent.prototype.calcularFechaInicial = function () {
        // Cálculo de la fecha inicial.
        var numeroDia = this.fecha.getDay() || 7;
        var diasHastaLunes;
        if (numeroDia === 1) {
            diasHastaLunes = 0;
        }
        else {
            diasHastaLunes = numeroDia - 1;
        }
        var primerDiaAMostrar = new Date(+this.fecha);
        primerDiaAMostrar.setDate(primerDiaAMostrar.getDate() - diasHastaLunes);
        this.fechaIni = new Date(primerDiaAMostrar.getFullYear(), primerDiaAMostrar.getMonth(), primerDiaAMostrar.getDate(), 0, 0, 0, 0);
        // Se prepara la semana.
        this.prepararSemana();
    };
    SemanaComponent.prototype.calcularFechaFinal = function () {
        // Cálculo de la fecha final.
        var numeroDia = this.fecha.getDay();
        var diasHastaDomingo;
        if (numeroDia === 0) {
            diasHastaDomingo = 1;
        }
        else {
            // Se calculan 8 días ya que la fecha final tendrá una hora de 00:00.
            diasHastaDomingo = 8 - numeroDia;
        }
        var ultimoDiaAMostrar = new Date(+this.fecha);
        ultimoDiaAMostrar.setDate(ultimoDiaAMostrar.getDate() + diasHastaDomingo);
        this.fechaFin = new Date(ultimoDiaAMostrar.getFullYear(), ultimoDiaAMostrar.getMonth(), ultimoDiaAMostrar.getDate(), 0, 0, 0, 0);
    };
    SemanaComponent.prototype.restar = function () {
        // Función que cambia la fecha a mostrar a la de la semana anterior y carga los datos de esa semana.
        this.fecha.setDate(this.fecha.getDate() - 7);
        this.cargarActividades();
    };
    SemanaComponent.prototype.sumar = function () {
        // Función que cambia la fecha a mostrar a la de la semana posterior y carga los datos de esa semana.
        this.fecha.setDate(this.fecha.getDate() + 7);
        this.cargarActividades();
    };
    SemanaComponent.prototype.guardarActividadesDataset = function () {
        this.dataset = [];
        var _loop_1 = function (fecha) {
            var fechaFin = new Date(fecha);
            fechaFin.setDate(fechaFin.getDate() + 1);
            this_1.dataset.push({
                fecha: fecha,
                actividades: this_1.actividadesTratadas.filter(function (a) {
                    return moment(a.fechaInicioSinMargen).toDate().getTime() >= fecha &&
                        ((moment(a.fechaFinalSinMargen).toDate().getTime() < fechaFin.getTime() &&
                            moment(a.fechaInicioSinMargen).toDate().getTime() === moment(a.fechaFinalSinMargen).toDate().getTime()) ||
                            (moment(a.fechaFinalSinMargen).toDate().getTime() <= fechaFin.getTime() &&
                                moment(a.fechaInicioSinMargen).toDate().getTime() !== moment(a.fechaFinalSinMargen).toDate().getTime()));
                })
            });
        };
        var this_1 = this;
        // Se guardan las actividades en su fecha correspondiente.
        for (var _i = 0, _a = this.fechas; _i < _a.length; _i++) {
            var fecha = _a[_i];
            _loop_1(fecha);
        }
        var instancia = this;
        this.eventosScroll.SimpleBar.getScrollElement().addEventListener('scroll', function (_event) {
            if (instancia.lastPopoverRef) {
                instancia.lastPopoverRef.close();
                instancia.lastPopoverRef = null;
            }
        });
    };
    SemanaComponent.prototype.prepararSemana = function () {
        // Método para preparar la semana.
        this.fechas = [];
        this.fechas.push(this.fechaIni.getTime());
        var fechaAux = new Date(this.fechaIni.getTime());
        fechaAux.setHours(0, 0, 0, 0);
        for (var i = 1; i < 7; i++) {
            fechaAux.setDate(fechaAux.getDate() + 1);
            this.fechas.push(fechaAux.getTime());
        }
    };
    //////////////////////////////////////////////////////////////////
    // Gestión de los popovers.
    //////////////////////////////////////////////////////////////////
    SemanaComponent.prototype.clickOutside = function (event) {
        // Método que escucha el evento click en el documento.
        // Si existe lastPopoverRef y el evento click se realizó fuera de este.
        if (this.lastPopoverRef && !this.lastPopoverRef._elementRef.nativeElement.contains(event.target)) {
            this.lastPopoverRef.close();
            this.lastPopoverRef = null;
        }
    };
    SemanaComponent.prototype.seleccionarActividad = function (popReference, actividad) {
        popReference.container = 'body';
        // Si ya existe una última referencia y la nueva referencia es distinta.
        if (this.lastPopoverRef && this.lastPopoverRef !== popReference) {
            this.lastPopoverRef.close();
        }
        // Registro de la nueva referencia.
        this.lastPopoverRef = popReference;
        this.actividadSeleccionada = actividad;
    };
    return SemanaComponent;
}(VistaCalendario));
export { SemanaComponent };
