import { EventEmitter } from '@angular/core';
import { AlertService } from './alert.service';
import { BiomedidasService } from './biomedidas.service';
import { DispositivoService } from './dispositivo.service';
import { PermisosService } from './permisos.service';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LocaleTextoPipe } from '../../shared/pipes/locale-texto/locale-texto';
import * as i0 from "@angular/core";
import * as i1 from "./alert.service";
import * as i2 from "./biomedidas.service";
import * as i3 from "./dispositivo.service";
import * as i4 from "./permisos.service";
import * as i5 from "@ngx-translate/core";
import * as i6 from "../../shared/pipes/locale-texto/locale-texto";
/**
 * Servicio para la gestión del actividades de tipo biomedida.
 *
 * @author lreverendo
 *
 * @version 01.02.0050
 * @since 01.02.0050
 */
var GestionActBiomedidaService = /** @class */ (function () {
    function GestionActBiomedidaService(alertService, biomedidasService, dispositivoService, permisoService, translateService, localeTexto) {
        this.alertService = alertService;
        this.biomedidasService = biomedidasService;
        this.dispositivoService = dispositivoService;
        this.permisoService = permisoService;
        this.translateService = translateService;
        this.localeTexto = localeTexto;
        /**
         * Evento para abrir el modal de visualización de una biomedida.
         */
        this.visualizarBiomedida = new EventEmitter();
        /**
         * Evento para el registro de una biomedida.
         */
        this.registroBiomedida = new EventEmitter();
        this.operacionFinalizada = new Subject();
    }
    GestionActBiomedidaService.prototype.abrirBiomedida = function (actividad, fechaCaducidad) {
        switch (actividad.estado) {
            case 'VACIO':
                if (moment(actividad.fechaInicioConMargen).toDate() > new Date()) {
                    this.biomedidaFutura(actividad);
                }
                else if (this.permisoService.getPermisos().escritura) {
                    this.realizarBiomedida(actividad);
                }
                break;
            case 'ADHERIDO':
                this.abrirVisualizacionBiomedida(actividad);
                break;
            case 'AUSENCIA':
                this.biomedidaCaducada(actividad, fechaCaducidad);
                break;
        }
    };
    GestionActBiomedidaService.prototype.realizarBiomedida = function (actividad) {
        var _this = this;
        var dispositivo;
        this.dispositivoService.dispositivos$.pipe(takeUntil(this.operacionFinalizada)).subscribe(function (dispositivos) {
            if (dispositivos.length === 0) {
                _this.dispositivoService.cargarDispositivos();
            }
            else {
                _this.operacionFinalizada.next();
                dispositivo = _this.dispositivoService.buscarDispositivo(actividad, dispositivos);
            }
        });
        // Se redirige al dispositivo correspondiente.
        this.registroBiomedida.emit({
            dispositivo: dispositivo,
            actividad: actividad
        });
    };
    GestionActBiomedidaService.prototype.abrirVisualizacionBiomedida = function (actividad) {
        var _this = this;
        var instancias = actividad.instancias;
        // Se cargan las biomedidas del paciente.
        this.biomedidasService.getBiomedidas(instancias).subscribe(function (biomedidas) {
            var biomedidasTratas = [];
            // Se clasifican las biomedidas por tipo
            for (var _i = 0, biomedidas_1 = biomedidas; _i < biomedidas_1.length; _i++) {
                var biomedida = biomedidas_1[_i];
                biomedidasTratas.push({
                    tipo: biomedida.tipo,
                    biomedida: biomedida
                });
            }
            if (biomedidasTratas.length > 0) {
                // Se muestra la actividad.
                _this.visualizarBiomedida.emit({
                    actividad: actividad,
                    biomedidas: biomedidasTratas
                });
            }
            else {
                _this.alertService.lanzarError(404);
            }
        }, this.gestionarError.bind(this));
    };
    GestionActBiomedidaService.prototype.biomedidaCaducada = function (actividad, fechaCaducidad) {
        // Función para la gestión de una actividad biomedida caducada.
        var params = actividad;
        var buttons = [];
        // Si la actividad ha caducado hace menos de 30 días.
        if (moment(actividad.fechaInicioSinMargen).toDate() >= fechaCaducidad && this.permisoService.getPermisos().escritura) {
            buttons = [
                {
                    texto: this.translateService.instant('BIOMEDIDAS.NOTIFICACION.REALIZAR'),
                    type: 'button',
                    accion: 'realizarBiomedida',
                    params: params,
                    cerrar: true
                }
            ];
        }
        var data = {
            origen: this,
            titulo: this.localeTexto.transform(actividad.titulo, this.getCurrentIdioma()),
            mensaje: this.translateService.instant('BIOMEDIDAS.NOTIFICACION.CADUCADO'),
            buttons: buttons,
            cerrar: true
        };
        this.alertService.modal.emit(data);
    };
    GestionActBiomedidaService.prototype.biomedidaFutura = function (actividad) {
        // Función para la gestión de una actividad biomedida futura.
        var ahora = new Date();
        ahora.setDate(ahora.getDate() + 1);
        ahora.setHours(0, 0, 0, 0);
        var params = actividad;
        var buttons = [];
        // Si la actividad está programada para el día de hoy y se tienen permisos de escritura
        if (!(moment(actividad.fechaInicioConMargen).toDate() >= ahora) && this.permisoService.getPermisos().escritura) {
            buttons = [
                {
                    texto: this.translateService.instant('BIOMEDIDAS.NOTIFICACION.REALIZAR'),
                    type: 'button',
                    accion: 'realizarBiomedida',
                    params: params,
                    cerrar: true
                }
            ];
        }
        var data = {
            origen: this,
            titulo: this.localeTexto.transform(actividad.titulo, this.getCurrentIdioma()),
            mensaje: this.translateService.instant('BIOMEDIDAS.NOTIFICACION.FUTURO'),
            buttons: buttons,
            cerrar: true
        };
        this.alertService.modal.emit(data);
    };
    GestionActBiomedidaService.prototype.getCurrentIdioma = function () {
        // Se recupera el idioma actual.
        return this.translateService.currentLang;
    };
    GestionActBiomedidaService.prototype.gestionarError = function (error) {
        this.alertService.lanzarError(error.status);
    };
    GestionActBiomedidaService.ngInjectableDef = i0.defineInjectable({ factory: function GestionActBiomedidaService_Factory() { return new GestionActBiomedidaService(i0.inject(i1.AlertService), i0.inject(i2.BiomedidasService), i0.inject(i3.DispositivoService), i0.inject(i4.PermisosService), i0.inject(i5.TranslateService), i0.inject(i6.LocaleTextoPipe)); }, token: GestionActBiomedidaService, providedIn: "root" });
    return GestionActBiomedidaService;
}());
export { GestionActBiomedidaService };
