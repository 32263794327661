import { Injectable } from '@angular/core';
import { Perfil } from '../models';
import { AutenticacionService } from './autenticacion.service';
import { PerfilService } from './perfil.service';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertService } from './alert.service';

/**
 * Servicio para la gestión de la navegación.
 *
 * @author lreverendo
 *
 * @version 01.02.0042
 * @since 01.02.0000
 */
@Injectable({ providedIn: 'root' })
export class NavegacionService {
  public operacionFinalizada = new Subject();

  constructor(
    private autenticacionService: AutenticacionService,
    private alertService: AlertService,
    private perfilService: PerfilService,
    private router: Router,
    private translateService: TranslateService
  ) {}

  public cargarPerfilUsuario(usuario: string): void {
    this.perfilService.perfilUsuario$.pipe(takeUntil(this.operacionFinalizada)).subscribe((perfil: Perfil) => {
      if (perfil == null) {
        this.perfilService.cargarPerfilUsuario(usuario);
      } else {
        this.establecerPaginaInicio(perfil);
      }
    });
  }

  public cargarPerfilPaciente(paciente: string): void {
    this.perfilService.cargarPerfilPaciente(paciente);
    this.perfilService.perfilPaciente$.pipe(takeUntil(this.operacionFinalizada)).subscribe((perfil: Perfil) => {
      this.acceder(perfil);
    });
  }

  public establecerPaginaInicio(perfilUsuario: Perfil): void {
    if (perfilUsuario != null) {
      this.operacionFinalizada.next();
      const idioma = this.translateService.currentLang;
      if (perfilUsuario.accesoPrimeraVez) {
        perfilUsuario.localePaciente = idioma;
        perfilUsuario.paginaInicio = '/calendario';
        this.perfilService.editarPerfilUsuario(perfilUsuario).subscribe(
          (perfil: Perfil) => this.comprobarEsCuidador(perfil),
          (error) => this.gestionarError(error)
        );
      } else {
        this.comprobarEsCuidador(perfilUsuario);
      }
    }
  }

  public comprobarEsCuidador(perfilUsuario: Perfil): void {
    if (perfilUsuario != null) {
      this.operacionFinalizada.next();
      const loginPaciente = this.autenticacionService.getLoginPaciente();

      if (loginPaciente != null) {
        this.cargarPerfilPaciente(loginPaciente);
      } else {
        if (perfilUsuario.pacientes.length > 0) {
          // Si el usuario es ecuidador.
          this.router.navigate(['elegir']);
        } else {
          this.cargarPerfilPaciente(perfilUsuario.dni);
        }
      }
    }
  }

  public acceder(perfilPaciente: Perfil): void {
    if (perfilPaciente != null) {
      this.operacionFinalizada.next();
      // Redirección según situación del paciente.
      if (perfilPaciente.accesoPrimeraVez) {
        // Si es el primer acceso del usuario.
        this.router.navigate(['primerAcceso']);
      } else {
        const paginaInicio = perfilPaciente.paginaInicio != null ? perfilPaciente.paginaInicio : '/calendario';
        this.router.navigate([paginaInicio]);
      }
    }
  }

  private gestionarError(error: HttpErrorResponse): void {
    this.alertService.lanzarError(error.status);
  }
}
