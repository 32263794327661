import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AutenticacionService } from './autenticacion.service';
import { AppConfigService } from './app-config.service';
import { sprintf } from 'sprintf-js';
import { SSO } from '../models';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./autenticacion.service";
/**
 * Servicio para la gestión de la vinculacion con dispositivos.
 *
 * @author lreverendo
 * @author aarodriguezo
 *
 * @version 01.02.0250
 * @since 01.02.0100
 */
var VinculacionDispositivosService = /** @class */ (function () {
    function VinculacionDispositivosService(http, autenticacionService) {
        this.http = http;
        this.autenticacionService = autenticacionService;
        this.ssoVinculacion = null;
        this.httpheadersPost = new HttpHeaders().set('Content-Type', 'application/json;charset=UTF-8');
    }
    VinculacionDispositivosService.prototype.setSSOVinculacion = function (bind_result, error, nombreSistema) {
        this.ssoVinculacion = new SSO(bind_result, error, nombreSistema);
    };
    VinculacionDispositivosService.prototype.getSSOVinculacion = function () {
        return this.ssoVinculacion;
    };
    VinculacionDispositivosService.prototype.recuperarUrlVinculacionDispositivo = function (nombreSistema) {
        var dniPaciente = this.autenticacionService.getLoginPaciente();
        var codCentro = this.autenticacionService.getCentroPaciente();
        var url = sprintf(AppConfigService.urls.vinculacionDispositivos, dniPaciente, codCentro, nombreSistema, AppConfigService.listaVinculacionDispositivos[nombreSistema].urlRedireccion);
        return this.http.get(url);
    };
    /* TODO cambiar el idSistema fijo de aquí y desde dispositivos.component.ts mandar el seleccionado con el botón*/
    VinculacionDispositivosService.prototype.vincularDispositivoSuccess = function (body) {
        var nombreSistema = this.ssoVinculacion.nombreSistema;
        this.ssoVinculacion = null;
        var dniPaciente = this.autenticacionService.getLoginPaciente();
        var codCentro = this.autenticacionService.getCentroPaciente();
        var url = sprintf(AppConfigService.urls.vinculacionDispositivosSuccess, dniPaciente, codCentro, AppConfigService.listaVinculacionDispositivos[nombreSistema].idSistema);
        return this.http.post(url, body, { headers: this.httpheadersPost });
    };
    VinculacionDispositivosService.ngInjectableDef = i0.defineInjectable({ factory: function VinculacionDispositivosService_Factory() { return new VinculacionDispositivosService(i0.inject(i1.HttpClient), i0.inject(i2.AutenticacionService)); }, token: VinculacionDispositivosService, providedIn: "root" });
    return VinculacionDispositivosService;
}());
export { VinculacionDispositivosService };
