<div class="modal fade" id="nuevoMensaje">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h3>
          <strong> {{ 'MENSAJERIA.CREAR.TITULO' | translate }} </strong>
        </h3>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body" data-simplebar>
        <loader></loader>

        <form [formGroup]="forma">
          <div class="container formulario">
            <div class="row">
              <div class="form-group col-12">
                <label for="asunto" class="col-form-label">{{ 'MENSAJERIA.CREAR.ASUNTO' | translate }}</label>
                <div class="datoCol">
                  <input type="text" class="form-control" id="asunto" formControlName="asunto" [readOnly]="respuesta" />
                  <small
                    class="error form-text text-muted"
                    *ngIf="forma.controls['asunto'].touched && forma.controls['asunto'].errors?.required"
                  >
                    {{ 'MENSAJERIA.CREAR.ERRORES.ASUNTOREQUERIDO' | translate }}
                  </small>
                </div>
              </div>
              <div class="form-group col-12">
                <label for="cuerpo" class="col-form-label">{{ 'MENSAJERIA.CREAR.CUERPO' | translate }}</label>
                <div class="datoCol">
                  <textarea class="form-control" maxlength="500" id="cuerpo" formControlName="cuerpo" rows="9"></textarea>
                  <small
                    class="error form-text text-muted"
                    *ngIf="forma.controls['cuerpo'].touched && forma.controls['cuerpo'].errors?.required"
                  >
                    {{ 'MENSAJERIA.CREAR.ERRORES.CUERPOREQUERIDO' | translate }}
                  </small>
                  <small class="d-table-cell">{{ forma.value.cuerpo.length }}/500</small>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button class="btn btn-primary btn-lg" type="button" (click)="limpiar()" *ngIf="!respuesta">
          {{ 'GENERAL.LIMPIAR' | translate }}
        </button>
        <button class="btn btn-success btn-lg" type="submit" (click)="guardarCambios()" [disabled]="disabled || !forma.valid">
          {{ 'MENSAJERIA.CREAR.ENVIAR' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
