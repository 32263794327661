import { Router } from '@angular/router';
import { AutenticacionService } from '../services/autenticacion.service';
import { PerfilService } from '../services/perfil.service';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { LoaderService, NavegacionService } from '../services';
import * as i0 from "@angular/core";
import * as i1 from "../services/autenticacion.service";
import * as i2 from "../services/perfil.service";
import * as i3 from "../services/navegacion.service";
import * as i4 from "@angular/router";
import * as i5 from "../services/loader.service";
/**
 * Servicio que controla el redireccionamiento cuando el usuario está autenticado.
 *
 * @author priveiro
 *
 * @version 01.02.0002
 * @since 01.02.0000
 */
var AuthGuardService = /** @class */ (function () {
    function AuthGuardService(autenticacionService, perfilService, navegacionService, router, loader) {
        this.autenticacionService = autenticacionService;
        this.perfilService = perfilService;
        this.navegacionService = navegacionService;
        this.router = router;
        this.loader = loader;
    }
    AuthGuardService.prototype.canActivate = function (_next, state) {
        var _this = this;
        return new Observable(function (observer) {
            if (_this.autenticacionService.isUsuarioAutenticado()) {
                var perfilCargado_1 = new Subject();
                var errorGestionado_1 = new Subject();
                _this.perfilService.error$.pipe(takeUntil(errorGestionado_1)).subscribe(function (_error) {
                    errorGestionado_1.next();
                    perfilCargado_1.next();
                    observer.next(true);
                });
                _this.perfilService.perfilUsuario$.pipe(takeUntil(perfilCargado_1)).subscribe(function (perfilUsuario) {
                    if (perfilUsuario != null) {
                        perfilCargado_1.next();
                        _this.comprobarAcceso(state, perfilUsuario).subscribe(function (navegar) {
                            _this.loader.redireccionar.emit();
                            if (!navegar) {
                                _this.router.navigate(['/perfil']);
                            }
                            else if (['/elegir'].indexOf(state.url) > -1) {
                                if (perfilUsuario.pacientes.length < 1) {
                                    navegar = false;
                                }
                            }
                            else if (!_this.autenticacionService.isPacienteSeleccionado()) {
                                _this.navegacionService.comprobarEsCuidador(perfilUsuario);
                                navegar = false;
                            }
                            errorGestionado_1.next();
                            observer.next(navegar);
                        });
                    }
                });
            }
            else {
                _this.loader.redireccionar.emit();
                _this.router.navigate(['/login']);
                observer.next(false);
            }
        });
    };
    AuthGuardService.prototype.comprobarAcceso = function (state, perfil) {
        var _this = this;
        return new Observable(function (observer) {
            var acceso = true;
            var perfilCargado = new Subject();
            if (['', '/primerAcceso', '/perfil', '/soporte', '/elegir'].indexOf(state.url) < 0) {
                if (_this.autenticacionService.isPacienteSeleccionado()) {
                    _this.perfilService.perfilPaciente$.pipe(takeUntil(perfilCargado)).subscribe(function (perfilPaciente) {
                        if (perfilPaciente != null) {
                            perfilCargado.next();
                            perfil = perfilPaciente;
                            acceso = _this.comprobarNasi(perfil);
                            observer.next(acceso);
                        }
                    });
                }
                else {
                    acceso = _this.comprobarNasi(perfil);
                    observer.next(acceso);
                }
            }
            else {
                observer.next(acceso);
            }
        });
    };
    AuthGuardService.prototype.comprobarNasi = function (perfil) {
        if (perfil.nasi == null) {
            this.loader.redireccionar.emit();
            return false;
        }
        else {
            return true;
        }
    };
    AuthGuardService.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuardService_Factory() { return new AuthGuardService(i0.inject(i1.AutenticacionService), i0.inject(i2.PerfilService), i0.inject(i3.NavegacionService), i0.inject(i4.Router), i0.inject(i5.LoaderService)); }, token: AuthGuardService, providedIn: "root" });
    return AuthGuardService;
}());
export { AuthGuardService };
