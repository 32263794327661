import { Injectable } from '@angular/core';
import { Permisos } from '../models';

/**
 * Servicio para la gestión de permisos.
 *
 * @author lreverendo
 *
 * @version 01.02.0002
 * @since 01.02.0000
 */
@Injectable({ providedIn: 'root' })
export class PermisosService {
  private permisos: Permisos;

  public setPermisos(permisos: Permisos) {
    this.permisos = permisos;
  }

  public getPermisos(): Permisos {
    return this.permisos;
  }
}
