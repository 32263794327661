import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { VariableClinica } from '../../../../../core/models';
import { DispositivoGenerico } from '../dispositivo-generico.model';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';

/**
 *  Componente que representa al dispositivo Tensiómetro.
 *
 * @author lreverendo
 *
 * @version 01.02.0070
 * @since 01.02.0000
 */
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tbody [tensiometro]',
  templateUrl: 'tensiometro.component.html',
  styleUrls: [
    '../../../biomedidas.component.css',
    '../../../views/registro-biomedida/registro-biomedida.component.css',
    './tensiometro.component.css'
  ]
})
export class TensiometroComponent extends DispositivoGenerico implements OnInit {
  public tad: VariableClinica;
  public tas: VariableClinica;
  public pulso: VariableClinica;

  constructor() {
    super();
  }

  public ngOnInit(): void {
    // Se buscan las variables tad, tas y pulso.
    this.tad = this.variablesClinicas.find((v) => v.alias === 'TAD');
    this.tas = this.variablesClinicas.find((v) => v.alias === 'TAS');
    this.pulso = this.variablesClinicas.find((v) => v.alias === 'PPM');

    if (this.tad) {
      this.forma.addControl(
        'tad',
        new FormControl('', [
          Validators.required,
          Validators.pattern(this.getPatronFormato(this.tad.formato)),
          CustomValidators.porRango(this.tad.min, this.tad.max)
        ])
      );
    }

    if (this.tas) {
      this.forma.addControl(
        'tas',
        new FormControl('', [
          Validators.required,
          Validators.pattern(this.getPatronFormato(this.tas.formato)),
          CustomValidators.porRango(this.tas.min, this.tas.max)
        ])
      );
    }

    if (this.pulso) {
      this.forma.addControl(
        'pulso',
        new FormControl('', [
          Validators.required,
          Validators.pattern(this.getPatronFormato(this.pulso.formato)),
          CustomValidators.porRango(this.pulso.min, this.pulso.max)
        ])
      );
    }

    const validators = this.forma.validator;

    this.forma.setValidators([validators, this.tasMenorTad]);
  }

  public getData(): VariableClinica[] {
    const result: VariableClinica[] = [];

    if (this.tad) {
      const tad = this.forma.value.tad.replace(',', '.');

      if (Number(tad) === NaN) {
        return [];
      }
      this.tad.valor = tad;
      result.push(this.tad);
    }

    if (this.tas) {
      const tas = this.forma.value.tas.replace(',', '.');

      if (Number(tas) === NaN) {
        return [];
      }
      this.tas.valor = this.forma.value.tas;
      result.push(this.tas);
    }

    if (this.pulso) {
      const pulso = this.forma.value.pulso.replace(',', '.');

      if (Number(pulso) === NaN) {
        return [];
      }
      this.pulso.valor = pulso;
      result.push(this.pulso);
    }

    return result;
  }

  private tasMenorTad(forma: FormGroup): { [s: string]: boolean } {
    // Método que comprueba que la TAS sea mayor a la TAD.
    if (forma.controls['tas'] && forma.controls['tad'] && Number(forma.controls['tas'].value) < Number(forma.controls['tad'].value)) {
      return {
        tasMenorTad: true
      };
    }
    return null;
  }
}
