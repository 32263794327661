<div class="container general-container no-scroll">
  <div class="row selector">
    <div class="col-12">
      <div class="btn-group btn-group-toggle d-none d-sm-inline-flex">
        <label class="btn opcion element-click" [ngClass]="{ active: vista == 'datos' }">
          <input type="radio" name="options" checked [(ngModel)]="vista" value="datos" (click)="loadDatos()" />
          {{ 'PERFIL.DATOS' | translate }}
        </label>
        <label class="btn opcion element-click" [ngClass]="{ active: vista == 'cuidadores' }" *ngIf="tieneNasi">
          <input type="radio" name="options" [(ngModel)]="vista" value="cuidadores" (click)="loadCuidadores()" />
          {{ 'PERFIL.CUIDADORES' | translate }}
        </label>
        <label class="btn opcion element-click" [ngClass]="{ active: vista == 'contrasena' }">
          <input type="radio" name="options" [(ngModel)]="vista" value="contrasena" (click)="loadContrasena()" />
          {{ 'PERFIL.CONTRASENA' | translate }}
        </label>
      </div>
      <div class="btn-group btn-group-toggle d-inline-flex d-sm-none">
        <label class="btn opcion" [ngClass]="{ active: vista == 'datos' }">
          <input type="radio" name="options" checked [(ngModel)]="vista" value="datos" (click)="loadDatos()" />
          {{ 'PERFIL.DATOS_REDUCIDO' | translate }}
        </label>
        <label class="btn opcion" [ngClass]="{ active: vista == 'cuidadores' }" *ngIf="tieneNasi">
          <input type="radio" name="options" [(ngModel)]="vista" value="cuidadores" (click)="loadCuidadores()" />
          {{ 'PERFIL.CUIDADORES_REDUCIDO' | translate }}
        </label>
        <label class="btn opcion" [ngClass]="{ active: vista == 'contrasena' }">
          <input type="radio" name="options" [(ngModel)]="vista" value="contrasena" (click)="loadContrasena()" />
          {{ 'PERFIL.CONTRASENA_REDUCIDO' | translate }}
        </label>
      </div>
    </div>
  </div>
  <div class="row seleccion">
    <div class="col-12 vista">
      <div class="vista-div" [ngSwitch]="vista">
        <app-datos *ngSwitchCase="'datos'" #datos></app-datos>
        <app-cuidadores *ngSwitchCase="'cuidadores'" #cuidadores></app-cuidadores>
        <app-contrasena *ngSwitchCase="'contrasena'" #contrasena></app-contrasena>
      </div>
    </div>
  </div>
</div>
