var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DispositivoGenerico } from '../dispositivo-generico.model';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';
import * as i0 from "@angular/core";
/**
 *  Componente que representa al dispositivo Tensiómetro.
 *
 * @author lreverendo
 *
 * @version 01.02.0070
 * @since 01.02.0000
 */
var TensiometroComponent = /** @class */ (function (_super) {
    __extends(TensiometroComponent, _super);
    function TensiometroComponent() {
        return _super.call(this) || this;
    }
    TensiometroComponent.prototype.ngOnInit = function () {
        // Se buscan las variables tad, tas y pulso.
        this.tad = this.variablesClinicas.find(function (v) { return v.alias === 'TAD'; });
        this.tas = this.variablesClinicas.find(function (v) { return v.alias === 'TAS'; });
        this.pulso = this.variablesClinicas.find(function (v) { return v.alias === 'PPM'; });
        if (this.tad) {
            this.forma.addControl('tad', new FormControl('', [
                Validators.required,
                Validators.pattern(this.getPatronFormato(this.tad.formato)),
                CustomValidators.porRango(this.tad.min, this.tad.max)
            ]));
        }
        if (this.tas) {
            this.forma.addControl('tas', new FormControl('', [
                Validators.required,
                Validators.pattern(this.getPatronFormato(this.tas.formato)),
                CustomValidators.porRango(this.tas.min, this.tas.max)
            ]));
        }
        if (this.pulso) {
            this.forma.addControl('pulso', new FormControl('', [
                Validators.required,
                Validators.pattern(this.getPatronFormato(this.pulso.formato)),
                CustomValidators.porRango(this.pulso.min, this.pulso.max)
            ]));
        }
        var validators = this.forma.validator;
        this.forma.setValidators([validators, this.tasMenorTad]);
    };
    TensiometroComponent.prototype.getData = function () {
        var result = [];
        if (this.tad) {
            var tad = this.forma.value.tad.replace(',', '.');
            if (Number(tad) === NaN) {
                return [];
            }
            this.tad.valor = tad;
            result.push(this.tad);
        }
        if (this.tas) {
            var tas = this.forma.value.tas.replace(',', '.');
            if (Number(tas) === NaN) {
                return [];
            }
            this.tas.valor = this.forma.value.tas;
            result.push(this.tas);
        }
        if (this.pulso) {
            var pulso = this.forma.value.pulso.replace(',', '.');
            if (Number(pulso) === NaN) {
                return [];
            }
            this.pulso.valor = pulso;
            result.push(this.pulso);
        }
        return result;
    };
    TensiometroComponent.prototype.tasMenorTad = function (forma) {
        // Método que comprueba que la TAS sea mayor a la TAD.
        if (forma.controls['tas'] && forma.controls['tad'] && Number(forma.controls['tas'].value) < Number(forma.controls['tad'].value)) {
            return {
                tasMenorTad: true
            };
        }
        return null;
    };
    TensiometroComponent.ngInjectableDef = i0.defineInjectable({ factory: function TensiometroComponent_Factory() { return new TensiometroComponent(); }, token: TensiometroComponent, providedIn: "root" });
    return TensiometroComponent;
}(DispositivoGenerico));
export { TensiometroComponent };
