export class SSO {
  bind_result: string;
  error: string;
  nombreSistema: string;

  constructor(bind_result: string, error: string, nombreSistema: string) {
    this.error = error;
    this.bind_result = bind_result;
    this.nombreSistema = nombreSistema;
  }
}
