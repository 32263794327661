<div class="container">
  <loader></loader>

  <div class="row h-100" data-simplebar>
    <div class="lista mx-auto" *ngIf="!loader.active">
      <h3 *ngIf="cuidadores.length == 0" style="text-align: center">
        {{ 'CUIDADORES.SINCUIDADORES' | translate }}
      </h3>
      <div>
        <ul class="list-group" style="width: 100%">
          <li class="list-group-item" *ngFor="let cuidador of cuidadores" style="width: 100%">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-auto avatar">
                    <i class="icon-avatar_v2 img-avatar"></i>
                  </div>
                  <div class="datosAvatar col">
                    <div class="row">
                      <div class="form-group col-12">
                        <label for="nombre" class="col-form-label">{{ 'CUIDADORES.NOMBRE' | translate }}</label>
                        <div class="datoCol col-12">
                          <input
                            type="text"
                            readonly
                            class="form-control-plaintext"
                            value="{{ cuidador.nombre | capitalizado }} {{ cuidador.apellido1 | capitalizado }} {{
                              cuidador.apellido2 | capitalizado
                            }}"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="separador"></div>
                    <div class="row">
                      <div class="form-group col-12 col-sm-6">
                        <div class="icon-relacionFamiliar icono"></div>
                        <label for="relacion" class="col-form-label">
                          {{ 'CUIDADORES.RELACION' | translate }}
                        </label>
                        <div class="datoCol">
                          <input type="text" readonly class="form-control-plaintext" value="{{ cuidador.relacion.relacion }}" />
                        </div>
                      </div>
                      <div class="form-group col-12 col-sm-6">
                        <div class="icon-telefono icono"></div>
                        <label for="telefono" class="col-form-label">
                          {{ 'CUIDADORES.TELEFONO' | translate }}
                        </label>
                        <div class="datoCol">
                          <input type="text" readonly class="form-control-plaintext" value="{{ cuidador.telfMovil }}" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
