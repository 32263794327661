import { HttpClient } from '@angular/common/http';
import { AutenticacionService } from './autenticacion.service';
import { AppConfigService } from './app-config.service';
import { sprintf } from 'sprintf-js';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./autenticacion.service";
/**
 * Servicio para la gestión del actividades.
 *
 * @author lreverendo
 * @author dvila
 *
 * @version 01.02.0030
 * @since 01.02.0000
 */
var ActividadesService = /** @class */ (function () {
    function ActividadesService(http, autenticacionService) {
        this.http = http;
        this.autenticacionService = autenticacionService;
    }
    ActividadesService.prototype.getBiomedidas = function (fechaIni, fechaFin) {
        var dniPaciente = this.autenticacionService.getLoginPaciente();
        var url = sprintf(AppConfigService.urls.recuperarActividadesBiomedidas, dniPaciente, fechaIni, fechaFin);
        return this.http.get(url);
    };
    ActividadesService.prototype.getCuestionarios = function (fechaIni, fechaFin) {
        var dniPaciente = this.autenticacionService.getLoginPaciente();
        var url = sprintf(AppConfigService.urls.recuperarActividadesCuestionarios, dniPaciente, fechaIni, fechaFin);
        return this.http.get(url);
    };
    ActividadesService.prototype.getPersonales = function (fechaIni, fechaFin) {
        var dniPaciente = this.autenticacionService.getLoginPaciente();
        var url = sprintf(AppConfigService.urls.recuperarActivdadesPersonales, dniPaciente, fechaIni, fechaFin);
        return this.http.get(url);
    };
    ActividadesService.prototype.getTodas = function (fechaIni, fechaFin, tiposActividades) {
        var dniPaciente = this.autenticacionService.getLoginPaciente();
        var url = sprintf(AppConfigService.urls.recuperarActividades, dniPaciente, fechaIni, fechaFin, tiposActividades);
        return this.http.get(url);
    };
    ActividadesService.prototype.getTiposActividades = function () {
        var url = sprintf(AppConfigService.urls.recuperarTiposActividades);
        return this.http.get(url);
    };
    ActividadesService.prototype.crearActividadPersonal = function (actividad) {
        var dniPaciente = this.autenticacionService.getLoginPaciente();
        var url = sprintf(AppConfigService.urls.crearActividadPersonal, dniPaciente);
        return this.http.post(url, actividad);
    };
    ActividadesService.prototype.editarActividadPersonal = function (actividad) {
        var dniPaciente = this.autenticacionService.getLoginPaciente();
        var url = sprintf(AppConfigService.urls.editarActividadPersonal, dniPaciente);
        return this.http.post(url, actividad);
    };
    ActividadesService.ngInjectableDef = i0.defineInjectable({ factory: function ActividadesService_Factory() { return new ActividadesService(i0.inject(i1.HttpClient), i0.inject(i2.AutenticacionService)); }, token: ActividadesService, providedIn: "root" });
    return ActividadesService;
}());
export { ActividadesService };
