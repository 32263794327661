import { Directive, HostListener } from '@angular/core';

/**
 *  Directiva que inhabilita el copiar, pegar y cortar desde el input.
 *
 * @author lreverendo
 *
 * @version 01.02.0022
 * @since 01.02.0022
 */

@Directive({
  selector: '[appBlockCopyPaste]'
})
export class BlockCopyPasteDirective {
  constructor() {}

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    e.preventDefault();
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    e.preventDefault();
  }
}
