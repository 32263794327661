import * as i0 from "@angular/core";
/**
 * Servicio para la gestión de permisos.
 *
 * @author lreverendo
 *
 * @version 01.02.0002
 * @since 01.02.0000
 */
var PermisosService = /** @class */ (function () {
    function PermisosService() {
    }
    PermisosService.prototype.setPermisos = function (permisos) {
        this.permisos = permisos;
    };
    PermisosService.prototype.getPermisos = function () {
        return this.permisos;
    };
    PermisosService.ngInjectableDef = i0.defineInjectable({ factory: function PermisosService_Factory() { return new PermisosService(); }, token: PermisosService, providedIn: "root" });
    return PermisosService;
}());
export { PermisosService };
