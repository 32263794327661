import * as i0 from "@angular/core";
/**
 * Servicio para la gestión de los datos de una página para el exterior.
 *
 * @author lreverendo
 *
 * @version 01.02.0102
 * @since 01.02.0000
 */
var ExternoService = /** @class */ (function () {
    function ExternoService() {
    }
    ExternoService.prototype.getOperacion = function () {
        return this.operacion;
    };
    ExternoService.prototype.setOperacion = function (operacion) {
        this.operacion = operacion;
    };
    ExternoService.prototype.getData = function () {
        return this.data;
    };
    ExternoService.prototype.setData = function (data) {
        this.data = data;
    };
    ExternoService.prototype.limpiarDatos = function () {
        this.data = null;
    };
    ExternoService.ngInjectableDef = i0.defineInjectable({ factory: function ExternoService_Factory() { return new ExternoService(); }, token: ExternoService, providedIn: "root" });
    return ExternoService;
}());
export { ExternoService };
