import { Component } from '@angular/core';
import { AlertService } from '../../../core/services/alert.service';

declare var $: any;

/**
 * Componente para las alertas.
 *
 * @author lreverendo
 *
 * @version 01.02.0000
 * @since 01.02.0000
 */
@Component({
  selector: 'alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent {
  public mensaje: string;
  public exito: boolean;

  constructor(private alertService: AlertService) {
    // Subscripción al evento alert para lanzar una alerta.
    this.alertService.alert.subscribe((data) => {
      // Se recoge mensaje y indicador de si es o no una alerta de éxito.
      this.mensaje = data.mensaje;
      this.exito = data.exito;

      // Se muestra la alerta.
      $('#alert').show();

      // Se esconde pasados 3 segundos.
      setTimeout(() => {
        $('#alert').hide();
      }, 3000);
    });
  }

  public hide(): void {
    // Función para esconder la alerta.

    $('#alert').hide();
  }
}
