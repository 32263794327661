import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe para convertir HTML a texto.
 *
 * @author lreverendo
 *
 * @version 01.02.0075
 * @since 01.02.0075
 */
@Pipe({
  name: 'htmlTexto'
})
export class HTMLTextoPipe implements PipeTransform {
  public transform(value: string): string {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = value;
    return tempDiv.innerText;
  }

  public escapeHTML(value: string): string {
    // Se escapan los caracteres \"'
    const asciiBackSlash = '&#x5c;';
    const asciiDoubleQuotes = '&#x22;';
    const asciiSingleQuote = '&#x27;';

    return value.replace(/\\/g, asciiBackSlash).replace(/\"/g, asciiDoubleQuotes).replace(/\'/g, asciiSingleQuote);
  }
}
