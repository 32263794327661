import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ItemResumenCuestionario } from '../../../../core/models/itemResumenCuestionario.model';

/**
 * Componente para el resumen de cuestionario.
 *
 * @author lreverendo
 *
 * @version 01.02.0000
 * @since 01.02.0000
 */
@Component({
  selector: 'app-resumen-cuestionario',
  templateUrl: './resumen-cuestionario.component.html',
  styleUrls: ['./resumen-cuestionario.component.css']
})
export class ResumenCuestionarioComponent implements OnChanges {
  @Input() items: ItemResumenCuestionario[] = [];

  @Output() abrirPregunta: EventEmitter<number> = new EventEmitter();

  constructor() {}

  public ngOnChanges(): void {}

  public clickPregunta(idPregunta: number): void {
    this.abrirPregunta.emit(idPregunta);
  }
}
