import { LocaleTexto } from './localeTexto.model';

export class Actividad {
  id: string;
  descripcion: LocaleTexto[];
  estado: string;
  fechaActividad: string;
  idPaciente: string;
  pautada: boolean;
  titulo: LocaleTexto[];
  tipo: string;
  agrupador: string;
  idProtocolo: string;
  codigoActividad: string;
  fechaInicioConMargen: string;
  fechaFinalConMargen: string;
  fechaInicioSinMargen: string;
  fechaFinalSinMargen: string;
  todoElDia: boolean;
  url: string;
  idInstancia: string;
  instancias?: string[];
  actRelacionadas: Actividad[];
  antesEjercicio: boolean;
  duranteEjercicio: boolean;
  despuesEjercicio: boolean;
}
