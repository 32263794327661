import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, EMPTY } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { AutenticacionService, AlertService } from '../services';
import { AppConfig } from '../services/app-config.service';

/**
 * Interceptor para la gestión de la sesión.
 *
 * @author priveiro
 * @author lreverendo
 *
 * @version 01.02.0200
 * @since 01.02.0000
 */
@Injectable()
export class AutenticacionInterceptor implements HttpInterceptor {
  private ACCESS_TOKEN_HEADER = 'Access-Token';
  private ACCESS_EXPIRATION_HEADER = 'Access-Expiration';

  constructor(private inj: Injector) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const alertService = this.inj.get(AlertService);
    const autenticacionService = this.inj.get(AutenticacionService);
    let requestModificada = request;

    // Si el usuario se ha autenticado se envía el usuario y los tickets en la petición
    const isUsuarioAutenticado = autenticacionService.isUsuarioAutenticado();
    if (isUsuarioAutenticado) {
      if (autenticacionService.isAccessTokenExpirado() && !request.url.includes('logout')) {
        autenticacionService.lanzarErrorSesion();
        return EMPTY;
      } else {
        const accessToken: string = autenticacionService.getAccessToken();

        let requestHeaders = new HttpHeaders();

        if (accessToken != null) {
          requestHeaders = requestHeaders.append(this.ACCESS_TOKEN_HEADER, accessToken);
        }

        requestHeaders = requestHeaders.append('Cache-Control', 'no-cache');
        requestHeaders = requestHeaders.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
        requestHeaders = requestHeaders.append('Pragma', 'no-cache');

        requestModificada = request.clone({
          headers: requestHeaders
        });
      }
    }

    return (
      next
        .handle(requestModificada)
        // Si en la respuesta se reciben los tickets actualizados se almacenan en el sessionStorage
        .pipe(
          tap((respuesta) => {
            if (respuesta instanceof HttpResponse) {
              if (respuesta.status === 200) {
                const headersRespuesta = respuesta.headers;

                const accessToken = headersRespuesta.get(this.ACCESS_TOKEN_HEADER);
                if (accessToken != null) {
                  autenticacionService.setAccessToken(accessToken);
                }

                const accessExpiration = headersRespuesta.get(this.ACCESS_EXPIRATION_HEADER);
                if (accessExpiration != null) {
                  autenticacionService.setAccessExpiration(accessExpiration);
                }
              }
            }
          }),
          // Gestión del error
          catchError((httpErrorResponse) => {
            if (httpErrorResponse instanceof HttpErrorResponse) {
              if (httpErrorResponse.url.indexOf('logout') < 0) {
                if (httpErrorResponse.status === 401) {
                  const error = httpErrorResponse.error;

                  switch (error.codigo) {
                    case AppConfig.error.XML_INCORRECTO: {
                      break;
                    }
                    case AppConfig.error.TICKET_NO_VALIDO:
                    case AppConfig.error.TOKEN_ACCESO_NO_RECIBIDO: {
                      autenticacionService.lanzarErrorSesion();
                      break;
                    }
                    default: {
                      autenticacionService.lanzarErrorSesion();
                    }
                  }
                }

                if (httpErrorResponse.status === 403) {
                  const error = httpErrorResponse.error;
                  if (error.codigo === AppConfig.error.TICKET_ACCESO_EXPIRADO) {
                    autenticacionService.lanzarErrorSesion();
                  } else {
                    if (!requestModificada.url.includes('/medicacion/') && !requestModificada.url.includes('/loginEsaude')) {
                      alertService.lanzarErrorPermiso();
                    }
                  }
                }
              }
            }
            return throwError(httpErrorResponse);
          })
        )
    );
  }
}
