<div class="form-group" *ngIf="temp" [formGroup]="forma">
  <label for="temp" class="col-form-label"
    >{{ 'BIOMEDIDAS.TERMOMETRO.TEMPE' | translate }} ({{ 'BIOMEDIDAS.VARIABLES.' + temp.alias + '.unidad' | translate }})</label
  >
  <div class="datoCol">
    <input
      type="text"
      [appFormatNumber]="temp.formato"
      appBlockCopyPaste
      class="form-control variable focus"
      id="temp"
      formControlName="temp"
    />
  </div>
</div>
<div
  class="filaError d-none"
  *ngIf="temp"
  [ngClass]="{ 'd-table-row': forma.controls['temp'].dirty && forma.controls['temp'].errors?.maxError }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted"> {{ 'BIOMEDIDAS.TERMOMETRO.TERMOMAX' | translate }}{{ temp.max }} </small>
</div>
<div
  class="filaError d-none"
  *ngIf="temp"
  [ngClass]="{ 'd-table-row': forma.controls['temp'].dirty && forma.controls['temp'].errors?.minError }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted"> {{ 'BIOMEDIDAS.TERMOMETRO.TERMOMIN' | translate }}{{ temp.min }} </small>
</div>
<div
  class="filaError d-none"
  *ngIf="temp"
  [ngClass]="{ 'd-table-row': forma.controls['temp'].dirty && forma.controls['temp'].errors?.required }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted">
    {{ 'BIOMEDIDAS.TERMOMETRO.TERMOREQUERIDO' | translate }}
  </small>
</div>
