<div class="container">
  <div class="row selec-fecha">
    <div class="selPag col-1 offset-sm-1 offset-md-2" (click)="restar()">
      <i class="icon-flechaIzq"></i>
    </div>
    <div class="col-10 col-sm-8 col-md-6 fecha">
      <h3 *ngIf="getCurrentIdioma() !== 'en'">
        {{ dia | date: 'd' }} {{ dia.getTime() | mes: getCurrentIdioma() }} {{ dia | date: 'yyyy' }}
      </h3>
      <h3 *ngIf="getCurrentIdioma() === 'en'">
        {{ dia.getTime() | mes: getCurrentIdioma() }} {{ dia | date: 'd' }} {{ dia | date: 'yyyy' }}
      </h3>
    </div>
    <div class="selPag col-1" (click)="sumar()">
      <i class="icon-flechaDcha"></i>
    </div>
  </div>

  <div class="row contenido">
    <loader [for]="[operation.recuperarActividades]"></loader>

    <div
      class="col-12 eventos-contenido"
      data-simplebar
      *ngIf="actividadesTratadas != null && (!loader.active || loader.status.operation !== 6) && dispositivosCargados"
    >
      <h3 *ngIf="actividadesTratadas.length <= 0 && !loader.active" class="texto-centrado">
        {{ 'CALENDARIO.SINACT' | translate }}
      </h3>
      <div *ngIf="actividadesTratadas.length > 0">
        <div
          *ngFor="let actividad of actividadesTratadas; index as i"
          class="list-group-item btn col-12 actividad"
          [ngClass]="{ primera: i == 0 }"
          [appBotonActividadPermiso]="actividad"
          (clickBotonActividadPermiso)="abrirActividad(actividad)"
        >
          <div class="row element-click">
            <i class="icono" [appClaseTipoActividad]="actividad" [conDispositivo]="true"> </i>
            <div class="col">
              <div class="row">
                <div class="col-10 actividadInfo" [ngClass]="{ 'col-12': !(actividad.estado | contiene: 'ADHERIDO') }">
                  {{ actividad.titulo | localeTexto: getCurrentIdioma() }}
                  <div class="detalle">
                    <p *ngIf="!actividad.todoElDia && !(actividad.estado | contiene: 'ADHERIDO')">
                      {{ actividad.fechaInicioSinMargen | date: 'HH:mm' }} - {{ actividad.fechaFinalSinMargen | date: 'HH:mm' }} &nbsp;
                    </p>
                    <p class="identificadorEjercicio" *ngIf="actividad.antesEjercicio">
                      {{ 'CALENDARIO.IDENTIFICADOR_EJERCICIO.ANTES' | translate }}
                    </p>
                    <p class="identificadorEjercicio" *ngIf="actividad.duranteEjercicio">
                      {{ 'CALENDARIO.IDENTIFICADOR_EJERCICIO.DURANTE' | translate }}
                    </p>
                    <p class="identificadorEjercicio" *ngIf="actividad.despuesEjercicio">
                      {{ 'CALENDARIO.IDENTIFICADOR_EJERCICIO.DESPUES' | translate }}
                    </p>
                  </div>
                </div>
                <div class="col-2 realizado" *ngIf="actividad.estado | contiene: 'ADHERIDO'">
                  <div class="icon-ok" align-self-center id="tickRealizado"></div>
                  <p>{{ actividad.fechaActividad | date: 'HH:mm' }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row linkActPersonal" *appIfPermisoEscritura>
    <div class="col-12">
      <i class="icon-mas masActPersonal"></i>
      <div class="actPersonal" (click)="crearActividad()">
        {{ 'CALENDARIO.PERSONAL.CREAR.TITULOPAG' | translate }}
      </div>
    </div>
  </div>
</div>
