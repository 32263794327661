import { Component, OnInit } from '@angular/core';
import { PerfilService, LoaderService, Operation } from '../../../../core/services';
import { Cuidador } from '../../../../core/models';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

/**
 * Componente para la visualización de los cuidadores del paciente.
 *
 * @author lreverendo
 *
 * @version 01.02.0000
 * @since 01.02.0000
 */
@Component({
  selector: 'app-cuidadores',
  templateUrl: './cuidadores.component.html',
  styleUrls: ['../../perfil.component.css', './cuidadores.component.css']
})
export class CuidadoresComponent implements OnInit {
  public cuidadores: Cuidador[] = [];
  public operation = Operation;

  private operacionFinalizada = new Subject();

  constructor(public loader: LoaderService, private perfilService: PerfilService) {}

  public ngOnInit(): void {
    this.reload();
  }

  public reload(): void {
    this.perfilService.getCuidadores();
    this.perfilService.cuidadoresPaciente$.pipe(takeUntil(this.operacionFinalizada)).subscribe(this.cargarCuiadores.bind(this));
  }

  private cargarCuiadores(cuidadores: Cuidador[]): void {
    if (cuidadores != null) {
      this.operacionFinalizada.next();
      this.cuidadores = cuidadores;
    }
  }
}
