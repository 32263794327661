import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe que se encarga de devolver el día de semana del valor pasado.
 *
 * @author lreverendo
 *
 * @version 01.02.0000
 * @since 01.02.0000
 */
@Pipe({
  name: 'dia'
})
export class DiaPipe implements PipeTransform {
  public transform(value: string, idioma: string = 'gl'): string {
    const diaGL: string[] = ['Dom', 'Lun', 'Mar', 'Mér', 'Xov', 'Ven', 'Sáb'];
    const diaES: string[] = ['Dom', 'Lun', 'Mar', 'Miér', 'Jue', 'Vie', 'Sáb'];
    const diaEN: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    if (value === '' || value === null) {
      return '';
    }

    let dia = parseInt(value, 10);

    if (dia > 6) {
      const date = new Date(parseInt(value, 10));
      dia = date.getDay();
    }

    let semana = diaES[dia];
    if (idioma === 'gl') {
      semana = diaGL[dia];
    }

    if (idioma === 'en') {
      semana = diaEN[dia];
    }

    return semana;
  }
}
