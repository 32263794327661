import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { AutenticacionService } from '../services/autenticacion.service';
import { PerfilService } from '../services/perfil.service';
import { takeUntil } from 'rxjs/operators';
import { Perfil } from '../models/perfil.model';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { LoaderService, NavegacionService } from '../services';

/**
 * Servicio que controla el redireccionamiento cuando el usuario está autenticado.
 *
 * @author priveiro
 *
 * @version 01.02.0002
 * @since 01.02.0000
 */
@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate {
  constructor(
    private autenticacionService: AutenticacionService,
    private perfilService: PerfilService,
    private navegacionService: NavegacionService,
    private router: Router,
    private loader: LoaderService
  ) {}

  public canActivate(_next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      if (this.autenticacionService.isUsuarioAutenticado()) {
        const perfilCargado = new Subject();
        const errorGestionado = new Subject();

        this.perfilService.error$.pipe(takeUntil(errorGestionado)).subscribe((_error) => {
          errorGestionado.next();
          perfilCargado.next();
          observer.next(true);
        });

        this.perfilService.perfilUsuario$.pipe(takeUntil(perfilCargado)).subscribe((perfilUsuario) => {
          if (perfilUsuario != null) {
            perfilCargado.next();
            this.comprobarAcceso(state, perfilUsuario).subscribe((navegar) => {
              this.loader.redireccionar.emit();
              if (!navegar) {
                this.router.navigate(['/perfil']);
              } else if (['/elegir'].indexOf(state.url) > -1) {
                if (perfilUsuario.pacientes.length < 1) {
                  navegar = false;
                }
              } else if (!this.autenticacionService.isPacienteSeleccionado()) {
                this.navegacionService.comprobarEsCuidador(perfilUsuario);
                navegar = false;
              }
              errorGestionado.next();
              observer.next(navegar);
            });
          }
        });
      } else {
        this.loader.redireccionar.emit();
        this.router.navigate(['/login']);
        observer.next(false);
      }
    });
  }

  private comprobarAcceso(state: RouterStateSnapshot, perfil: Perfil): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      let acceso = true;
      const perfilCargado = new Subject();

      if (['', '/primerAcceso', '/perfil', '/soporte', '/elegir'].indexOf(state.url) < 0) {
        if (this.autenticacionService.isPacienteSeleccionado()) {
          this.perfilService.perfilPaciente$.pipe(takeUntil(perfilCargado)).subscribe((perfilPaciente) => {
            if (perfilPaciente != null) {
              perfilCargado.next();
              perfil = perfilPaciente;
              acceso = this.comprobarNasi(perfil);
              observer.next(acceso);
            }
          });
        } else {
          acceso = this.comprobarNasi(perfil);
          observer.next(acceso);
        }
      } else {
        observer.next(acceso);
      }
    });
  }

  private comprobarNasi(perfil: Perfil): boolean {
    if (perfil.nasi == null) {
      this.loader.redireccionar.emit();
      return false;
    } else {
      return true;
    }
  }
}
