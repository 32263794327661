import { Operation } from '../../core/services/app-config.service';

export class OperationInfo {
  public operation: Operation;
  public pattern: RegExp;
  public requireSubscription: boolean;
  public lockScreen: boolean;

  constructor(operation: Operation, pattern: RegExp, requireSubscription: boolean, lockScreen: boolean) {
    this.operation = operation;
    this.pattern = pattern;
    this.requireSubscription = requireSubscription;
    this.lockScreen = lockScreen;
  }
}
