<div class="container general-container">
  <div class="row selector">
    <div class="col-4 migas">{{ 'MENU.ACTIVIDADES' | translate }} > {{ 'MENU.CUESTIONARIOS' | translate }}</div>
    <div class="col-12">
      <div class="btn-group btn-group-toggle">
        <label class="btn opcion element-click" [ngClass]="{ active: vista == 'registro' }">
          <input type="radio" name="options" checked [(ngModel)]="vista" value="registro" (click)="loadRegistro()" />
          {{ 'CUESTIONARIOS.TABS.REGISTRO' | translate }}
        </label>

        <label class="btn opcion element-click" [ngClass]="{ active: vista == 'historial' }">
          <input type="radio" name="options" [(ngModel)]="vista" value="historial" (click)="loadHistorial()" />
          {{ 'CUESTIONARIOS.TABS.HISTORIAL' | translate }}
        </label>
      </div>
    </div>
  </div>
  <div class="row seleccion">
    <div class="col-12 vista">
      <div class="vista-div" [ngSwitch]="vista">
        <app-listado-cuestionario *ngSwitchCase="'registro'"></app-listado-cuestionario>
        <app-historial-cuestionario *ngSwitchCase="'historial'"></app-historial-cuestionario>
      </div>
    </div>
  </div>
</div>

<app-visualizacion-cuestionario></app-visualizacion-cuestionario>
