var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { NgbDatepickerI18n, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import * as i0 from "@angular/core";
import * as i1 from "@ngx-translate/core";
var I18N_VALUES = {
    es: {
        weekdays: ['Lun', 'Mar', 'Miér', 'Jue', 'Vie', 'Sáb', 'Dom'],
        months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
    },
    gl: {
        weekdays: ['Lun', 'Mar', 'Mér', 'Xov', 'Ven', 'Sáb', 'Dom'],
        months: ['Xan', 'Feb', 'Mar', 'Abr', 'Mai', 'Xuñ', 'Xul', 'Ago', 'Set', 'Out', 'Nov', 'Dec']
    },
    en: {
        weekdays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    }
};
/**
 * Celendario personalizado.
 *
 * @author lreverendo
 *
 * @version 01.02.0030
 * @since 01.02.0000
 */
var CustomDatepickerI18n = /** @class */ (function (_super) {
    __extends(CustomDatepickerI18n, _super);
    function CustomDatepickerI18n(translateService) {
        var _this = _super.call(this) || this;
        _this.translateService = translateService;
        return _this;
    }
    CustomDatepickerI18n.prototype.getDayAriaLabel = function (_date) {
        // TODO
        return '';
    };
    CustomDatepickerI18n.prototype.getWeekdayShortName = function (weekday) {
        return I18N_VALUES[this.translateService.currentLang].weekdays[weekday - 1];
    };
    CustomDatepickerI18n.prototype.getMonthShortName = function (month) {
        return I18N_VALUES[this.translateService.currentLang].months[month - 1];
    };
    CustomDatepickerI18n.prototype.getMonthFullName = function (month) {
        return this.getMonthShortName(month);
    };
    CustomDatepickerI18n.ngInjectableDef = i0.defineInjectable({ factory: function CustomDatepickerI18n_Factory() { return new CustomDatepickerI18n(i0.inject(i1.TranslateService)); }, token: CustomDatepickerI18n, providedIn: "root" });
    return CustomDatepickerI18n;
}(NgbDatepickerI18n));
export { CustomDatepickerI18n };
function padNumber(value) {
    if (isNumber(value)) {
        return ("0" + value).slice(-2);
    }
    else {
        return '';
    }
}
function isNumber(value) {
    return !isNaN(toInteger(value));
}
function toInteger(value) {
    return parseInt("" + value, 10);
}
var CustomNgbDateParserFormatter = /** @class */ (function (_super) {
    __extends(CustomNgbDateParserFormatter, _super);
    function CustomNgbDateParserFormatter(translateService) {
        var _this = _super.call(this) || this;
        _this.translateService = translateService;
        return _this;
    }
    CustomNgbDateParserFormatter.prototype.parse = function (value) {
        if (value) {
            var dateParts = value.trim().split('/');
            if (dateParts.length === 1 && isNumber(dateParts[0])) {
                return { year: toInteger(dateParts[0]), month: null, day: null };
            }
            else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
                return { year: toInteger(dateParts[1]), month: toInteger(dateParts[0]), day: null };
            }
            else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
                return { year: toInteger(dateParts[2]), month: toInteger(dateParts[1]), day: toInteger(dateParts[0]) };
            }
        }
        return null;
    };
    CustomNgbDateParserFormatter.prototype.format = function (date) {
        var stringDate = '';
        if (date) {
            if (this.translateService.currentLang === 'en') {
                stringDate += isNumber(date.month) ? padNumber(date.month) + '/' : '';
                stringDate += isNumber(date.day) ? padNumber(date.day) + '/' : '';
                stringDate += date.year;
            }
            else {
                stringDate += isNumber(date.day) ? padNumber(date.day) + '/' : '';
                stringDate += isNumber(date.month) ? padNumber(date.month) + '/' : '';
                stringDate += date.year;
            }
        }
        return stringDate;
    };
    CustomNgbDateParserFormatter.ngInjectableDef = i0.defineInjectable({ factory: function CustomNgbDateParserFormatter_Factory() { return new CustomNgbDateParserFormatter(i0.inject(i1.TranslateService)); }, token: CustomNgbDateParserFormatter, providedIn: "root" });
    return CustomNgbDateParserFormatter;
}(NgbDateParserFormatter));
export { CustomNgbDateParserFormatter };
