import { Injectable, EventEmitter } from '@angular/core';
import { Actividad, Biomedida, Dispositivo, ModalButton } from '../models';
import { AlertService } from './alert.service';
import { BiomedidasService } from './biomedidas.service';
import { DispositivoService } from './dispositivo.service';
import { PermisosService } from './permisos.service';

import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { LocaleTextoPipe } from '../../shared/pipes/locale-texto/locale-texto';

/**
 * Servicio para la gestión del actividades de tipo biomedida.
 *
 * @author lreverendo
 *
 * @version 01.02.0050
 * @since 01.02.0050
 */
@Injectable({ providedIn: 'root' })
export class GestionActBiomedidaService {
  /**
   * Evento para abrir el modal de visualización de una biomedida.
   */
  public visualizarBiomedida: EventEmitter<{
    actividad: Actividad;
    biomedidas: {
      tipo: String;
      biomedida: Biomedida;
    }[];
  }> = new EventEmitter();

  /**
   * Evento para el registro de una biomedida.
   */
  public registroBiomedida: EventEmitter<{
    dispositivo: Dispositivo;
    actividad: Actividad;
  }> = new EventEmitter();

  public operacionFinalizada = new Subject();

  constructor(
    private alertService: AlertService,
    private biomedidasService: BiomedidasService,
    private dispositivoService: DispositivoService,
    private permisoService: PermisosService,
    private translateService: TranslateService,
    private localeTexto: LocaleTextoPipe
  ) {}

  public abrirBiomedida(actividad: Actividad, fechaCaducidad: Date): void {
    switch (actividad.estado) {
      case 'VACIO':
        if (moment(actividad.fechaInicioConMargen).toDate() > new Date()) {
          this.biomedidaFutura(actividad);
        } else if (this.permisoService.getPermisos().escritura) {
          this.realizarBiomedida(actividad);
        }
        break;
      case 'ADHERIDO':
        this.abrirVisualizacionBiomedida(actividad);
        break;
      case 'AUSENCIA':
        this.biomedidaCaducada(actividad, fechaCaducidad);
        break;
    }
  }

  public realizarBiomedida(actividad: Actividad): void {
    let dispositivo: Dispositivo;

    this.dispositivoService.dispositivos$.pipe(takeUntil(this.operacionFinalizada)).subscribe((dispositivos: Dispositivo[]) => {
      if (dispositivos.length === 0) {
        this.dispositivoService.cargarDispositivos();
      } else {
        this.operacionFinalizada.next();
        dispositivo = this.dispositivoService.buscarDispositivo(actividad, dispositivos);
      }
    });

    // Se redirige al dispositivo correspondiente.
    this.registroBiomedida.emit({
      dispositivo: dispositivo,
      actividad: actividad
    });
  }

  private abrirVisualizacionBiomedida(actividad: Actividad): void {
    const instancias: string[] = actividad.instancias;

    // Se cargan las biomedidas del paciente.
    this.biomedidasService.getBiomedidas(instancias).subscribe((biomedidas: Biomedida[]) => {
      const biomedidasTratas: {
        tipo: String;
        biomedida: Biomedida;
      }[] = [];

      // Se clasifican las biomedidas por tipo
      for (const biomedida of biomedidas) {
        biomedidasTratas.push({
          tipo: biomedida.tipo,
          biomedida: biomedida
        });
      }

      if (biomedidasTratas.length > 0) {
        // Se muestra la actividad.
        this.visualizarBiomedida.emit({
          actividad: actividad,
          biomedidas: biomedidasTratas
        });
      } else {
        this.alertService.lanzarError(404);
      }
    }, this.gestionarError.bind(this));
  }

  private biomedidaCaducada(actividad: Actividad, fechaCaducidad: Date): void {
    // Función para la gestión de una actividad biomedida caducada.
    const params = actividad;

    let buttons: ModalButton[] = [];

    // Si la actividad ha caducado hace menos de 30 días.
    if (moment(actividad.fechaInicioSinMargen).toDate() >= fechaCaducidad && this.permisoService.getPermisos().escritura) {
      buttons = [
        {
          texto: this.translateService.instant('BIOMEDIDAS.NOTIFICACION.REALIZAR'),
          type: 'button',
          accion: 'realizarBiomedida',
          params: params,
          cerrar: true
        }
      ];
    }

    const data = {
      origen: this,
      titulo: this.localeTexto.transform(actividad.titulo, this.getCurrentIdioma()),
      mensaje: this.translateService.instant('BIOMEDIDAS.NOTIFICACION.CADUCADO'),
      buttons: buttons,
      cerrar: true
    };

    this.alertService.modal.emit(data);
  }

  private biomedidaFutura(actividad: Actividad): void {
    // Función para la gestión de una actividad biomedida futura.
    const ahora = new Date();
    ahora.setDate(ahora.getDate() + 1);
    ahora.setHours(0, 0, 0, 0);

    const params = actividad;

    let buttons: ModalButton[] = [];

    // Si la actividad está programada para el día de hoy y se tienen permisos de escritura
    if (!(moment(actividad.fechaInicioConMargen).toDate() >= ahora) && this.permisoService.getPermisos().escritura) {
      buttons = [
        {
          texto: this.translateService.instant('BIOMEDIDAS.NOTIFICACION.REALIZAR'),
          type: 'button',
          accion: 'realizarBiomedida',
          params: params,
          cerrar: true
        }
      ];
    }
    const data = {
      origen: this,
      titulo: this.localeTexto.transform(actividad.titulo, this.getCurrentIdioma()),
      mensaje: this.translateService.instant('BIOMEDIDAS.NOTIFICACION.FUTURO'),
      buttons: buttons,
      cerrar: true
    };

    this.alertService.modal.emit(data);
  }

  private getCurrentIdioma(): string {
    // Se recupera el idioma actual.
    return this.translateService.currentLang;
  }

  private gestionarError(error: HttpErrorResponse): void {
    this.alertService.lanzarError(error.status);
  }
}
