<loader></loader>
<form [formGroup]="forma" (ngSubmit)="cambiarContrasena()" class="h-100">
  <div class="container" data-simplebar>
    <div class="row">
      <div class="contenido mx-auto">
        <div class="form-group col-12 mx-auto">
          <label for="actual" class="col-form-label">{{ 'CONTRASENA.ACTUAL' | translate }}*</label>
          <div class="datoCol">
            <input type="password" class="form-control" id="actual" formControlName="contrasenaActual" />
            <small
              class="error form-text text-muted"
              *ngIf="forma.controls['contrasenaActual'].touched && forma.controls['contrasenaActual'].errors?.required"
            >
              {{ 'CONTRASENA.ERRORES.ACTUAL.REQUERIDO' | translate }}
            </small>
            <small class="error form-text text-muted" *ngIf="forma.controls['contrasenaActual'].errors?.incorrecta">
              {{ 'CONTRASENA.ERRORES.ACTUAL.INCORRECTA' | translate }}
            </small>
          </div>
        </div>
        <div class="form-group col-12 mx-auto">
          <label for="nueva" class="col-form-label">{{ 'CONTRASENA.NUEVA' | translate }}*</label>
          <div class="datoCol">
            <input type="password" class="form-control" id="nueva" formControlName="contrasenaNueva" />
            <small
              class="error form-text text-muted"
              *ngIf="forma.controls['contrasenaNueva'].touched && forma.controls['contrasenaNueva'].errors?.required"
            >
              {{ 'CONTRASENA.ERRORES.NUEVA.REQUERIDO' | translate }}
            </small>
            <small
              class="error form-text text-muted"
              *ngIf="forma.controls['contrasenaNueva'].touched && forma.controls['contrasenaNueva'].errors?.minlength"
            >
              {{ 'CONTRASENA.ERRORES.NUEVA.CARACTERES' | translate }}
            </small>
            <small
              class="error form-text text-muted"
              *ngIf="forma.controls['contrasenaNueva'].touched && forma.controls['contrasenaNueva'].errors?.noiguales"
            >
              {{ 'CONTRASENA.ERRORES.NUEVA.NOIGUALES' | translate }}
            </small>
          </div>
        </div>
        <div class="form-group col-12 mx-auto">
          <label for="repetir" class="col-form-label">{{ 'CONTRASENA.REPETIR' | translate }}*</label>
          <div class="datoCol">
            <input type="password" class="form-control" id="repetir" formControlName="contrasenaRepetir" />
            <small
              class="error form-text text-muted"
              *ngIf="forma.controls['contrasenaRepetir'].touched && forma.controls['contrasenaRepetir'].errors?.required"
            >
              {{ 'CONTRASENA.ERRORES.REPETIR.REQUERIDO' | translate }}
            </small>
            <small
              class="error form-text text-muted"
              *ngIf="
                forma.controls['contrasenaRepetir'].touched &&
                forma.errors &&
                forma.errors['noiguales'] &&
                !forma.controls['contrasenaRepetir'].errors?.required
              "
            >
              {{ 'CONTRASENA.ERRORES.REPETIR.NOIGUALES' | translate }}
            </small>
          </div>
        </div>
      </div>
    </div>

    <div class="row seccion footer">
      <div class="form-group col-6" style="text-align: right">
        <button class="btn btn-primary btn-lg" type="button" (click)="limpiar()">
          {{ 'GENERAL.LIMPIAR' | translate }}
        </button>
      </div>
      <div class="form-group col-6">
        <button class="btn btn-success btn-lg" type="submit" [disabled]="disabled || !forma.valid">
          {{ 'GENERAL.GUARDAR' | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
