<form [formGroup]="forma" (ngSubmit)="guardarCambios()">
  <loader></loader>
  <div class="row tel-encabezado-pagina">
    <div class="col-12">
      <h3 class="tel-titulo-pagina">
        <strong>{{ dispositivo.descripcion | translate }}</strong>
      </h3>
    </div>
  </div>
  <div class="row">
    <div class="separador"></div>
  </div>
  <div *ngIf="actividad" class="detalleActividad">
    <p *ngIf="!actividad.todoElDia">
      {{ 'CALENDARIO.REALIZACION.REALIZAR_ENTRE' | translate }}: {{ actividad.fechaInicioSinMargen | date: 'HH:mm' }} -
      {{ actividad.fechaFinalSinMargen | date: 'HH:mm' }} &nbsp;
    </p>
    <p *ngIf="actividad.todoElDia">{{ 'CALENDARIO.REALIZACION.TODO_DIA' | translate }}&nbsp;</p>
    <p *ngIf="actividad.antesEjercicio || actividad.duranteEjercicio || actividad.despuesEjercicio">- &nbsp;</p>
    <p class="identificadorEjercicio" *ngIf="actividad.antesEjercicio">
      {{ 'CALENDARIO.IDENTIFICADOR_EJERCICIO.ANTES' | translate }}
    </p>
    <p class="identificadorEjercicio" *ngIf="actividad.duranteEjercicio">
      {{ 'CALENDARIO.IDENTIFICADOR_EJERCICIO.DURANTE' | translate }}
    </p>
    <p class="identificadorEjercicio" *ngIf="actividad.despuesEjercicio">
      {{ 'CALENDARIO.IDENTIFICADOR_EJERCICIO.DESPUES' | translate }}
    </p>
  </div>

  <!-- VARIABLE PRANDIAL -->
  <div *ngIf="basal || prePrandial || posPrandial" class="prandiales">
    <div
      class="prandial element-click"
      *ngIf="basal"
      (click)="cambiarVariablePrandial('BASAL')"
      [ngClass]="{
        prohibido:
          forma.controls['periodo'] &&
          (forma.controls['periodo'].value.alias == 'ANTES_ACOSTARSE' || forma.controls['periodo'].value.alias == 'DURANTE_NOCHE')
      }"
    >
      <div class="prandial-check" [ngClass]="{ checked: variablePrandial && (variablePrandial.alias | contiene: 'BASAL') }"></div>
      <div class="icon-basal icono" title="{{ 'BIOMEDIDAS.GLUCOMETRO.BASAL' | translate }}"></div>
      <div class="prandial-texto">
        {{ 'BIOMEDIDAS.GLUCOMETRO.BASAL' | translate }}
      </div>
    </div>
    <div
      class="prandial element-click"
      *ngIf="prePrandial"
      (click)="cambiarVariablePrandial('PRE')"
      [ngClass]="{ prohibido: forma.controls['periodo'] && forma.controls['periodo'].value.alias == 'ANTES_ACOSTARSE' }"
    >
      <div class="prandial-check" [ngClass]="{ checked: variablePrandial && (variablePrandial.alias | contiene: 'PRE') }"></div>
      <div class="icon-manzana icono" title="{{ 'BIOMEDIDAS.PREPRANDIAL' | translate }}"></div>
      <div class="prandial-texto">
        {{ 'BIOMEDIDAS.PREPRANDIAL' | translate }}
      </div>
    </div>
    <div
      class="prandial element-click"
      *ngIf="posPrandial"
      (click)="cambiarVariablePrandial('POS')"
      [ngClass]="{ prohibido: forma.controls['periodo'] && forma.controls['periodo'].value.alias == 'DURANTE_NOCHE' }"
    >
      <div class="prandial-check" [ngClass]="{ checked: variablePrandial && (variablePrandial.alias | contiene: 'POS') }"></div>
      <div class="icon-manzana_mordida icono" title="{{ 'BIOMEDIDAS.POSPRANDIAL' | translate }}"></div>
      <div class="prandial-texto">
        {{ 'BIOMEDIDAS.POSPRANDIAL' | translate }}
      </div>
    </div>
  </div>

  <table class="m-auto">
    <thead>
      <!-- PERIODOS -->
      <div *ngIf="periodos" class="form-group">
        <label for="periodo" class="col-form-label">{{ 'BIOMEDIDAS.PERIODODIA' | translate }}</label>
        <div class="datoCol periodos">
          <select class="form-control" [compareWith]="periodosIguales" id="periodo" formControlName="periodo">
            <option *ngFor="let periodo of periodos" [ngValue]="periodo">
              {{ 'BIOMEDIDAS.PERIODOS.' + periodo.alias | translate }}
            </option>
          </select>
          <div
            class="icon-info"
            [ngbTooltip]="tipContent"
            #t="ngbTooltip"
            placement="bottom"
            triggers="manual"
            (mouseleave)="cerrarTooltip()"
            (mouseenter)="abrirTooltip()"
            (click)="eventoClickTooltip()"
          ></div>
        </div>
      </div>
      <div
        class="filaError d-none"
        [ngClass]="{
          'd-table-row':
            periodos &&
            (parseHora(forma.controls['periodo'].value.horaIni) > parseHora(forma.controls['hora'].value.split(':')[0]) ||
              parseHora(forma.controls['hora'].value.split(':')[0]) > parseHora(forma.controls['periodo'].value.horaFin)) &&
            (!forma.errors?.fechaSuperior || !forma.errors?.fechaInferior) &&
            (basal || prePrandial || posPrandial) &&
            variablePrandial
        }"
      >
        <div class="d-none d-sm-table-cell"></div>
        <small class="error form-text text-muted aviso">
          {{ 'BIOMEDIDAS.PERIODODIAFUERA' | translate }}
        </small>
      </div>
      <div
        class="filaError d-none"
        [ngClass]="{
          'd-table-row': periodos && (basal || prePrandial || posPrandial) && !variablePrandial
        }"
      >
        <div class="d-none d-sm-table-cell"></div>
        <small class="error form-text text-muted">
          {{ 'BIOMEDIDAS.PERIODOPRANDIAL' | translate }}
        </small>
      </div>
      <!-- FECHA -->
      <div class="form-group">
        <label for="fecha" class="col-form-label">{{ 'BIOMEDIDAS.FECHAMEDIDA' | translate }}</label>
        <div class="input-group datoCol fechaMedida">
          <input class="form-control" placeholder="yyyy-mm-dd" formControlName="fecha" ngbDatepicker #d="ngbDatepicker" id="fecha" />
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
              <i class="icon-calendario" style="font-size: 1.5rem; cursor: pointer"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="filaError d-none" [ngClass]="{ 'd-table-row': forma.controls['fecha'].errors?.required }">
        <div class="d-none d-sm-table-cell"></div>
        <small class="error form-text text-muted">
          {{ 'BIOMEDIDAS.FECHAMEDIDAREQUERIDO' | translate }}
        </small>
      </div>
      <div
        class="filaError d-none"
        [ngClass]="{ 'd-table-row': forma.errors && forma.errors['fechaInferior'] && !forma.controls['fecha'].errors?.required }"
      >
        <div class="d-none d-sm-table-cell"></div>
        <small class="error form-text text-muted">
          {{ 'BIOMEDIDAS.FECHAINFERIOR' | translate }}
        </small>
      </div>
      <div
        class="filaError d-none"
        [ngClass]="{ 'd-table-row': forma.errors && forma.errors['fechaSuperior'] && !forma.controls['fecha'].errors?.required }"
      >
        <div class="d-none d-sm-table-cell"></div>
        <small class="error form-text text-muted">
          {{ 'BIOMEDIDAS.FECHASUPERIOR' | translate }}
        </small>
      </div>
      <!-- HORA -->
      <div class="form-group">
        <label for="hora" class="col-form-label">{{ 'BIOMEDIDAS.HORAMEDIDA' | translate }}</label>
        <div class="datoCol horaMedida">
          <input type="text" class="form-control" placeholder="hh:mm" id="hora" [textMask]="{ mask: mask }" formControlName="hora" />
        </div>
      </div>
      <div
        class="filaError d-none"
        [ngClass]="{
          'd-table-row': forma.controls['hora'].dirty && forma.controls['hora'].errors && !forma.controls['hora'].errors?.pattern
        }"
      >
        <div class="d-none d-sm-table-cell"></div>
        <small class="error form-text text-muted d-none" [ngClass]="{ 'd-block': forma.controls['hora'].errors?.required }">
          {{ 'BIOMEDIDAS.HORAMEDIDAREQUERIDO' | translate }}
        </small>
      </div>
    </thead>

    <!-- TBODY: FORMULARIO HIJO -->
    <tbody bascula *ngIf="dispositivo.subtipo.cod == 'BASC'" #disp [forma]="forma" [variablesClinicas]="variablesClinicas"></tbody>
    <tbody basculaIMC *ngIf="dispositivo.subtipo.cod == 'IMC'" #disp [forma]="forma" [variablesClinicas]="variablesClinicas"></tbody>
    <tbody tensiometro *ngIf="dispositivo.subtipo.cod == 'TENS'" #disp [forma]="forma" [variablesClinicas]="variablesClinicas"></tbody>
    <tbody coagulometro *ngIf="dispositivo.subtipo.cod == 'COAG'" #disp [forma]="forma" [variablesClinicas]="variablesClinicas"></tbody>
    <tbody hidratos-carbono *ngIf="dispositivo.subtipo.cod == 'RHC'" #disp [forma]="forma" [variablesClinicas]="variablesClinicas"></tbody>
    <tbody termometro *ngIf="dispositivo.subtipo.cod == 'TERM'" #disp [forma]="forma" [variablesClinicas]="variablesClinicas"></tbody>
    <tbody pulsioximetro *ngIf="dispositivo.subtipo.cod == 'PULS'" #disp [forma]="forma" [variablesClinicas]="variablesClinicas"></tbody>
    <tbody
      glucometro
      *ngIf="dispositivo.subtipo.cod == 'GLUC'"
      #disp
      [forma]="forma"
      [variablesClinicas]="variablesClinicas"
      [prandial]="variablePrandial"
    ></tbody>
    <tbody
      ejercicio
      *ngIf="dispositivo.subtipo.cod == 'EF'"
      #disp
      [forma]="forma"
      [variablesClinicas]="variablesClinicas"
      [prandial]="variablePrandial"
    ></tbody>
    <tbody
      insulina
      *ngIf="dispositivo.subtipo.cod == 'INS'"
      #disp
      [forma]="forma"
      [variablesClinicas]="variablesClinicas"
      [prandial]="variablePrandial"
    ></tbody>

    <!-- OBSERVACIONES -->
    <tfoot *ngIf="periodos">
      <div class="form-group">
        <label for="observaciones" class="col-form-label labelObservaciones">{{ 'BIOMEDIDAS.OBSERVACIONES' | translate }}</label>
        <div class="datoCol" id="observaciones">
          <textarea class="form-control focus2" formControlName="observaciones" maxlength="200" rows="5"></textarea>
        </div>
        <div class="observaciones d-table-cell">{{ forma.value.observaciones.length }}/200</div>
      </div>
      <div
        class="filaError d-none"
        [ngClass]="{ 'd-table-row': forma.controls['observaciones'].dirty && forma.controls['observaciones'].errors }"
      >
        <div class="d-none d-sm-table-cell"></div>
        <small class="error form-text text-muted">
          {{ 'BIOMEDIDAS.OBSERVACIONES_FORMATO' | translate }}
        </small>
      </div>
    </tfoot>
  </table>

  <!-- BOTONERA -->
  <div class="row footer">
    <div class="col-6" style="text-align: right">
      <button class="btn btn-primary btn-lg" type="button" (click)="limpiar()">
        {{ 'GENERAL.LIMPIAR' | translate }}
      </button>
    </div>
    <div class="col-6">
      <button
        class="btn btn-success btn-lg"
        type="submit"
        [disabled]="disabled || !forma.valid || ((basal || prePrandial || posPrandial) && !variablePrandial)"
      >
        {{ 'GENERAL.GUARDAR' | translate }}
      </button>
    </div>
  </div>
</form>

<!-- INFO -->
<ng-template #tipContent>
  <h5>{{ 'BIOMEDIDAS.PERIODOS.TITULO' | translate }}</h5>
  <div *ngFor="let periodo of periodos" style="text-align: left">
    {{ ('0' + periodo.horaIni).slice(-2) }}h-{{ ('0' + periodo.horaFin).slice(-2) }}h:
    {{ 'BIOMEDIDAS.PERIODOS.' + periodo.alias | translate }}
  </div>
</ng-template>
