import { Component, OnInit } from '@angular/core';
import { CalendarioService } from '../../core/services';

declare var $: any;

/**
 * Componente para la gestión de los cuestionarios.
 *
 * @author lreverendo
 *
 * @version 01.02.0000
 * @since 01.02.0000
 */
@Component({
  selector: 'app-cuestionarios',
  templateUrl: './cuestionarios.component.html',
  styleUrls: ['./cuestionarios.component.css']
})
export class CuestionariosComponent implements OnInit {
  public vista = 'registro';
  public activo = 'registro';

  constructor(private calendarioService: CalendarioService) {}

  public ngOnInit(): void {
    $('input:radio[name="options"]').on('click', function () {
      $(this).blur();
    });
  }

  public loadRegistro(): void {
    // Se carga o recarga la vista registro.
    if (this.activo === 'registro') {
      // Se lanza el evento actualizarCalendario para recargar la vista registro.
      this.calendarioService.actualizarCalendario.emit();
    }
    this.activo = 'registro';
  }

  public loadHistorial(): void {
    // Se carga o recarga la vista historial.
    if (this.activo === 'historial') {
      // Se lanza el evento actualizarCalendario para recargar la vista historial.
      this.calendarioService.actualizarCalendario.emit();
    }
    this.activo = 'historial';
  }
}
