import { FormGroup, AbstractControl } from '@angular/forms';
import { AppConfigService } from '../../core/services';

/**
 * @author lreverendo
 * @author dvila
 *
 * @version 01.02.0080
 * @since 01.02.0030
 */
export class CustomValidators {
  static fechaInferior(forma: FormGroup): { [s: string]: boolean } {
    // Función que comprueba que la fecha y hora de medida no sea anterior a la acutal en más de un mes.
    if (forma.controls['fecha'].value != null && forma.controls['hora'].value != null) {
      const limiteInferior = new Date();
      limiteInferior.setHours(limiteInferior.getHours(), limiteInferior.getMinutes(), 0, 0);
      limiteInferior.setDate(limiteInferior.getDate() - AppConfigService.diasCaducidadActividad);

      const hora = forma.controls['hora'].value.split(':')[0];
      const min = forma.controls['hora'].value.split(':')[1];

      let fecha = new Date(forma.value.fecha.year, forma.value.fecha.month - 1, forma.value.fecha.day, hora, min);

      if (hora === '' || min === '' || hora === undefined || min === undefined) {
        fecha = new Date(forma.value.fecha.year, forma.value.fecha.month - 1, forma.value.fecha.day);
      }

      if (fecha.getTime() < limiteInferior.getTime()) {
        return {
          fechaInferior: true
        };
      }
    }
    return null;
  }

  static fechaSuperior(forma: FormGroup): { [s: string]: boolean } {
    // Función que comprueba que la fecha y hora de medida no sea superior a la acutal.
    if (forma.controls['fecha'].value != null && forma.controls['hora'].value != null) {
      const ahora = new Date();
      ahora.setHours(ahora.getHours(), ahora.getMinutes(), 0, 0);

      const hora = forma.controls['hora'].value.split(':')[0];
      const min = forma.controls['hora'].value.split(':')[1];
      let fecha = new Date(forma.value.fecha.year, forma.value.fecha.month - 1, forma.value.fecha.day, hora, min);

      if (hora === '' || min === '' || hora === undefined || min === undefined) {
        fecha = new Date(forma.value.fecha.year, forma.value.fecha.month - 1, forma.value.fecha.day);
      }

      if (fecha.getTime() > ahora.getTime()) {
        return {
          fechaSuperior: true
        };
      }
    }
    return null;
  }

  static porRango(min: number, max: number) {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valor = parseFloat(control.value.replace(/,/, '.'));

      if (valor > max) {
        return { maxError: true };
      }
      if (valor < min) {
        return { minError: true };
      }
      return null;
    };
  }
}
