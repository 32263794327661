import { EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ActividadesService } from './actividades.service';
import { GestionActAnaliticaService } from './gestion-act-analitica.service';
import { GestionActBiomedidaService } from './gestion-act-biomedida.service';
import { GestionActCuestionarioService } from './gestion-act-cuestionario.service';
import { GestionActVideoconferenciaService } from './gestion-act-videoconferencia.service';
import { AppConfigService } from './app-config.service';
import * as moment from 'moment';
import { PermisosService } from './permisos.service';
import { isUndefined } from 'util';
import * as i0 from "@angular/core";
import * as i1 from "./actividades.service";
import * as i2 from "./gestion-act-analitica.service";
import * as i3 from "./gestion-act-biomedida.service";
import * as i4 from "./gestion-act-cuestionario.service";
import * as i5 from "./gestion-act-videoconferencia.service";
import * as i6 from "@ngx-translate/core";
import * as i7 from "@angular/common";
import * as i8 from "./permisos.service";
/**
 * Servicio para la gestión del calendario.
 *
 * @author lreverendo
 * @author fjsalgado
 * @author dvila
 *
 * @version 01.02.0060
 * @since 01.02.0000
 */
var CalendarioService = /** @class */ (function () {
    function CalendarioService(actividadesService, gestionActAnliticaService, gestionActBiomedidasService, gestionActCuestionarioService, gestionActVideoconferenciaService, translateService, datePipe, permisoService) {
        this.actividadesService = actividadesService;
        this.gestionActAnliticaService = gestionActAnliticaService;
        this.gestionActBiomedidasService = gestionActBiomedidasService;
        this.gestionActCuestionarioService = gestionActCuestionarioService;
        this.gestionActVideoconferenciaService = gestionActVideoconferenciaService;
        this.translateService = translateService;
        this.datePipe = datePipe;
        this.permisoService = permisoService;
        //////////////////////////////////////////////////////////////////
        // Flujos a los que los clientes del servicio se pueden suscribir
        //////////////////////////////////////////////////////////////////
        /**
         * Evento para la recarga del calendario. Sea la vista que sea. Regresa a la fecha actual.
         */
        this.reload = new EventEmitter();
        /**
         * Evento para la recarga del calendario. Sea la vista que sea.
         */
        this.actualizarCalendario = new EventEmitter();
        /**
         * Evento que carga la vista diaria del calendario.
         */
        this.vistaDia = new EventEmitter();
        /**
         * Evento para abrir el modal de visualización de una actividad personal.
         */
        this.visualizarActividadPersonal = new EventEmitter();
        /**
         * Evento para editar una actividad personal.
         */
        this.editarActividadPersonal = new EventEmitter();
        /**
         * Evento para la creación de una actividad personal.
         */
        this.crearActividadPersonal = new EventEmitter();
        this.operacionFinalizada = new Subject();
        this.diasCaducidadActividad = AppConfigService.diasCaducidadActividad;
        this.tiposActividadSubject = new BehaviorSubject(null);
        this.tiposActividad$ = this.tiposActividadSubject.asObservable();
        this.errorSubject = new Subject();
        this.error$ = this.errorSubject.asObservable();
        this.cargarTiposActividades();
    }
    CalendarioService.prototype.gestionarErrorSubject = function (errorResponse) {
        this.errorSubject.next(errorResponse);
    };
    /**
     * Recupera los tipos de actividad.
     */
    CalendarioService.prototype.cargarTiposActividades = function () {
        this.actividadesService.getTiposActividades().subscribe({
            next: this.actualizarTiposActividades.bind(this),
            error: this.gestionarErrorSubject.bind(this)
        });
    };
    CalendarioService.prototype.actualizarTiposActividades = function (tipos) {
        this.tiposActividad = tipos;
        this.tiposActividadSubject.next(this.tiposActividad);
    };
    /**
     * Recupera las actividades entre dos fechas y de los tipos pasados.
     * @param fechaInicioBusqueda Fecha de inicio de la búsqueda.
     * @param fechaFinBusqueda Fecha final de la búsqueda.
     * @param tiposActividad Tipos de actividades a recuperar.
     */
    CalendarioService.prototype.cargarActividades = function (fechaInicioBusqueda, fechaFinBusqueda, tiposActividad, identificadorEjercicio) {
        var _this = this;
        var actividadesTratadasSubject = new Subject();
        // Carga de las actividades.
        this.actividadesService
            .getTodas(moment(fechaInicioBusqueda).toISOString(), moment(fechaFinBusqueda).toISOString(), tiposActividad)
            .subscribe(function (actividades) {
            var actividadesTratadas = _this.filtrarActividades(actividades, identificadorEjercicio);
            actividadesTratadasSubject.next(actividadesTratadas);
        }, this.gestionarErrorSubject.bind(this));
        return actividadesTratadasSubject.asObservable();
    };
    CalendarioService.prototype.filtrarActividades = function (actividades, identificadorEjercicio) {
        var _this = this;
        // Función que se encarga de hacer un filtro de las actividades para así mostrarse correctamente.
        var actividadesTratadas = [];
        var indexes = {};
        var rangosIdentificadorEjercicio = AppConfigService.identificadorEjercicio;
        var codigosActividad = Object.keys(rangosIdentificadorEjercicio);
        var index = 0;
        var _loop_1 = function (actividad) {
            if (actividad.tipo === this_1.tiposActividad.personal && moment(actividad.fechaFinalSinMargen).toDate() < new Date()) {
                actividad.estado = 'AUSENCIA';
            }
            if (actividad.tipo === this_1.tiposActividad.videoconferencia) {
                var titulo_1 = [];
                this_1.translateService.getTranslation('gl').subscribe(function (traducciongl) {
                    titulo_1.push({
                        locale: 'gl',
                        texto: traducciongl.VIDEOCONFERENCIA.TITULO
                    });
                    _this.translateService.getTranslation('es').subscribe(function (traducciones) {
                        titulo_1.push({
                            locale: 'es',
                            texto: traducciones.VIDEOCONFERENCIA.TITULO
                        });
                        actividad.titulo = titulo_1;
                    });
                });
            }
            // Se comprueba si la actividad es de todo el día o pautada.
            actividad.todoElDia =
                this_1.datePipe.transform(actividad.fechaInicioSinMargen, 'HH:mm') === '00:00' &&
                    this_1.datePipe.transform(actividad.fechaFinalSinMargen, 'HH:mm') === '00:00' &&
                    actividad.fechaInicioSinMargen < actividad.fechaFinalSinMargen;
            var agrupador = null;
            if (actividad.agrupador != null) {
                var split = actividad.agrupador.split('_', 2);
                agrupador = split[0] + '_' + split[1];
            }
            var actRelacionadas = [];
            var actividadesFiltradas = [];
            actividad.instancias = [];
            // Se filtran las actividades de tensión IMC o pulso, con el mismo agrupador, fecha inicio y fin y fecha actividad.
            if (agrupador === 'ACT_TENS' || agrupador === 'ACT_IMC' || agrupador === 'ACT_PULS') {
                actividadesFiltradas = actividades.filter(function (act, actIndex) {
                    if (actIndex > index && act.agrupador != null) {
                        if (act.id !== actividad.id &&
                            act.codigoActividad !== actividad.codigoActividad &&
                            act.agrupador.indexOf(agrupador) > -1 &&
                            moment(actividad.fechaInicioSinMargen).isSame(act.fechaInicioSinMargen) &&
                            moment(actividad.fechaFinalSinMargen).isSame(act.fechaFinalSinMargen) &&
                            actividad.fechaActividad === act.fechaActividad) {
                            return true;
                        }
                    }
                    return false;
                });
            }
            this_1.guardarActividadesRelacionada(actividades, actividadesFiltradas, actRelacionadas, actividad);
            if (actividad.idInstancia) {
                actividad.instancias.push(actividad.idInstancia);
            }
            if (identificadorEjercicio) {
                this_1.setIdentificadorEjercicioActividades(actividadesTratadas, actividad, indexes, rangosIdentificadorEjercicio, codigosActividad);
            }
            else {
                actividadesTratadas.push(actividad);
            }
            index++;
        };
        var this_1 = this;
        for (var _i = 0, actividades_1 = actividades; _i < actividades_1.length; _i++) {
            var actividad = actividades_1[_i];
            _loop_1(actividad);
        }
        return actividadesTratadas;
    };
    CalendarioService.prototype.guardarActividadesRelacionada = function (actividades, actividadesFiltradas, actRelacionadas, actividad) {
        // Recoger las actividad relacionadas
        if (actividadesFiltradas.length > 0) {
            actRelacionadas.push(actividadesFiltradas.find(function (act) { return act.codigoActividad === 'TAS'; }));
            actRelacionadas.push(actividadesFiltradas.find(function (act) { return act.codigoActividad === 'TAD'; }));
            actRelacionadas.push(actividadesFiltradas.find(function (act) { return act.codigoActividad === 'SAT_O2'; }));
            actRelacionadas.push(actividadesFiltradas.find(function (act) { return act.codigoActividad === 'PPM'; }));
            actRelacionadas.push(actividadesFiltradas.find(function (act) { return act.codigoActividad === 'IMC'; }));
            actRelacionadas.push(actividadesFiltradas.find(function (act) { return act.codigoActividad === 'PESO'; }));
            actRelacionadas.push(actividadesFiltradas.find(function (act) { return act.codigoActividad === 'TALLA'; }));
            actRelacionadas = actRelacionadas.filter(function (act) { return act !== undefined; });
        }
        // Se asocian las actividad relacionadas a la actividad que se está tratando
        actividad.actRelacionadas = actRelacionadas;
        var _loop_2 = function (relacionada) {
            if (relacionada.idInstancia) {
                actividad.instancias.push(relacionada.idInstancia);
            }
            actividades.splice(actividades.findIndex(function (act) { return act.id === relacionada.id; }), 1);
        };
        // Se recogen las instancias de la actividad y las actividades relacionadas.
        for (var _i = 0, actRelacionadas_1 = actRelacionadas; _i < actRelacionadas_1.length; _i++) {
            var relacionada = actRelacionadas_1[_i];
            _loop_2(relacionada);
        }
        if (actividad.tipo !== this.tiposActividad.personal) {
            actividad.actRelacionadas.push(actividad);
        }
    };
    CalendarioService.prototype.setIdentificadorEjercicioActividades = function (actividades, actividad, indexes, rangosIdentificadorEjercicio, codigosActividad) {
        var rangoAntes = { ini: 0, fin: 0 };
        var rangoDurante = { ini: 0, fin: 0 };
        var rangoDespues = { ini: 0, fin: 0 };
        var length = 0;
        var ultimaFecha = isUndefined(actividades[actividades.length - 1])
            ? moment(actividad.fechaInicioSinMargen, 'DD-MM-YYYY')
            : moment(actividades[actividades.length - 1].fechaInicioSinMargen, 'DD-MM-YYYY');
        var fechaActividad = moment(actividad.fechaInicioSinMargen, 'DD-MM-YYYY');
        if (!ultimaFecha.isSame(fechaActividad)) {
            codigosActividad.forEach(function (cod) { return delete indexes[cod]; });
        }
        var key = codigosActividad.find(function (cod) { return cod.includes(actividad.codigoActividad); });
        if (key) {
            indexes[key] = isUndefined(indexes[key]) ? [] : indexes[key];
            length = indexes[key].push(actividad.id);
            var identificadorEjercicio = rangosIdentificadorEjercicio[key];
            if (identificadorEjercicio.antes > 0) {
                rangoAntes.ini = 0;
                rangoAntes.fin = identificadorEjercicio.antes + 1;
                actividad.antesEjercicio = length > rangoAntes.ini && length < rangoAntes.fin;
            }
            if (identificadorEjercicio.durante > 0) {
                rangoDurante.ini = identificadorEjercicio.antes;
                rangoDurante.fin = rangoDurante.ini + identificadorEjercicio.durante + 1;
                actividad.duranteEjercicio = length > rangoDurante.ini && length < rangoDurante.fin;
            }
            if (identificadorEjercicio.despues > 0) {
                rangoDespues.ini = identificadorEjercicio.antes + identificadorEjercicio.durante;
                rangoDespues.fin = rangoDespues.ini + identificadorEjercicio.despues + 1;
                actividad.despuesEjercicio = length > rangoDespues.ini && length < rangoDespues.fin;
            }
        }
        var idx = actividades.length;
        if (actividad.todoElDia) {
            var lastIndex = actividades
                .map(function (actTratada) {
                var fechaActividadTratada = moment(actTratada.fechaInicioSinMargen, 'DD-MM-YYYY');
                var mismaFecha = fechaActividad.isSame(fechaActividadTratada);
                return ((mismaFecha && actividad.antesEjercicio && actTratada.antesEjercicio) ||
                    (mismaFecha && actividad.duranteEjercicio && actTratada.antesEjercicio) ||
                    (mismaFecha && actividad.duranteEjercicio && actTratada.duranteEjercicio) ||
                    (mismaFecha && actividad.despuesEjercicio && actTratada.antesEjercicio) ||
                    (mismaFecha && actividad.despuesEjercicio && actTratada.duranteEjercicio) ||
                    (mismaFecha && actividad.despuesEjercicio && actTratada.despuesEjercicio));
            })
                .lastIndexOf(true);
            idx = lastIndex < 0 ? idx : lastIndex + 1;
        }
        actividades.splice(idx, 0, actividad);
        length = 0;
        rangoAntes = rangoDurante = rangoDespues = { ini: 0, fin: 0 };
        return actividades;
    };
    CalendarioService.prototype.abrirActividad = function (actividad) {
        var fechaCaducidad = this.obtenerFechaCaducidad();
        // Función que permite abrir una actividad según su tipo.
        if (actividad.tipo === this.tiposActividad.personal) {
            this.abrirActividadPersonal(actividad);
        }
        if (actividad.tipo === this.tiposActividad.biomedida) {
            this.gestionActBiomedidasService.abrirBiomedida(actividad, fechaCaducidad);
        }
        if (actividad.tipo === this.tiposActividad.cuestionario) {
            this.gestionActCuestionarioService.abrirCuestionario(actividad);
        }
        if (actividad.tipo === this.tiposActividad.videoconferencia) {
            this.gestionActVideoconferenciaService.abrirVideoconferencia(actividad);
        }
        if (actividad.tipo === this.tiposActividad.analitica) {
            this.gestionActAnliticaService.abrirAnalitica(actividad, fechaCaducidad);
        }
    };
    CalendarioService.prototype.obtenerFechaCaducidad = function () {
        var caducidad = new Date();
        caducidad.setHours(0, 0, 0, 0);
        caducidad.setDate(caducidad.getDate() - this.diasCaducidadActividad);
        return caducidad;
    };
    //////////////////////////////////////////////////////////////////
    // Gestión de actividad personal
    //////////////////////////////////////////////////////////////////
    CalendarioService.prototype.abrirActividadPersonal = function (actividad) {
        if (this.permisoService.getPermisos().escritura) {
            // Se abre la actividad personal.
            this.editarActividadPersonal.emit({ actividad: actividad });
        }
        else {
            // Se abre la visualización de la actividad personal.
            this.visualizarActividadPersonal.emit({ actividad: actividad });
        }
    };
    CalendarioService.ngInjectableDef = i0.defineInjectable({ factory: function CalendarioService_Factory() { return new CalendarioService(i0.inject(i1.ActividadesService), i0.inject(i2.GestionActAnaliticaService), i0.inject(i3.GestionActBiomedidaService), i0.inject(i4.GestionActCuestionarioService), i0.inject(i5.GestionActVideoconferenciaService), i0.inject(i6.TranslateService), i0.inject(i7.DatePipe), i0.inject(i8.PermisosService)); }, token: CalendarioService, providedIn: "root" });
    return CalendarioService;
}());
export { CalendarioService };
