import { Injectable } from '@angular/core';

/**
 * Servicio para la gestión de los datos de una página para el exterior.
 *
 * @author lreverendo
 *
 * @version 01.02.0102
 * @since 01.02.0000
 */
@Injectable({ providedIn: 'root' })
export class ExternoService {
  private operacion: String;
  private data: String;

  constructor() {}

  public getOperacion(): String {
    return this.operacion;
  }

  public setOperacion(operacion: String) {
    this.operacion = operacion;
  }

  public getData(): String {
    return this.data;
  }

  public setData(data: String) {
    this.data = data;
  }

  public limpiarDatos(): void {
    this.data = null;
  }
}
