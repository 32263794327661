var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DispositivoGenerico } from '../dispositivo-generico.model';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';
import * as i0 from "@angular/core";
/**
 * Componente que representa las raciones de hidratos de carbono.
 *
 * @author lreverendo
 *
 * @version 01.02.0070
 * @since 01.02.0000
 */
var HidratosCarbonoComponent = /** @class */ (function (_super) {
    __extends(HidratosCarbonoComponent, _super);
    function HidratosCarbonoComponent() {
        return _super.call(this) || this;
    }
    HidratosCarbonoComponent.prototype.ngOnInit = function () {
        // Se busca la variable RHC.
        this.rhc = this.variablesClinicas.find(function (v) { return v.alias === 'RHC'; });
        if (this.rhc) {
            this.forma.addControl('rhc', new FormControl('', [
                Validators.required,
                Validators.pattern(this.getPatronFormato(this.rhc.formato)),
                CustomValidators.porRango(this.rhc.min, this.rhc.max)
            ]));
        }
    };
    HidratosCarbonoComponent.prototype.getData = function () {
        var result = [];
        if (this.rhc) {
            var rhc = this.forma.value.rhc.replace(',', '.');
            if (Number(rhc) === NaN) {
                return [];
            }
            this.rhc.valor = rhc;
            result.push(this.rhc);
        }
        return result;
    };
    HidratosCarbonoComponent.ngInjectableDef = i0.defineInjectable({ factory: function HidratosCarbonoComponent_Factory() { return new HidratosCarbonoComponent(); }, token: HidratosCarbonoComponent, providedIn: "root" });
    return HidratosCarbonoComponent;
}(DispositivoGenerico));
export { HidratosCarbonoComponent };
