<div class="container general-container">
  <div class="row h-100">
    <div class="collapse width show" id="filtros">
      <div class="filtros">
        <div class="row selector">
          <div class="col-4 migas">{{ 'MENU.ACTIVIDADES' | translate }} > {{ 'MENU.BIOMEDIDAS' | translate }}</div>
        </div>
        <div class="filtros-lista">

          

          <div class="filtros-scroll" data-simplebar>

            <div *ngIf="biomedidasPrescritasAVisualizar.length === 0" >
              <span>
                <strong class="d-none d-md-inline-block filtros-titulo-selecciona ">{{
                  'BIOMEDIDAS.DISPOSITIVOS.SELECCIONA' | translate
                }} {{ 'BIOMEDIDAS.DISPOSITIVOS.SEL_BIOMEDIDA' | translate }}</strong>
                
              </span>
            </div>

            <div *ngIf="biomedidasPrescritasAVisualizar.length != 0" >
              <span>
                <strong class="d-none d-md-inline-block filtros-titulo-selecciona">{{
                  'BIOMEDIDAS.DISPOSITIVOS.PRESCRITAS' | translate
                }}</strong>        
              </span>
            </div>
            
            <div
              class="row filtro-item "
              *ngFor="let dispositivo of biomedidasPrescritasAVisualizar"
              [ngClass]="{ active: dispositivoSeleccionado.descripcion == dispositivo.descripcion }"
              (click)="seleccionarDispositivo(dispositivo)"
            >
              <div class="filtro-icono">
              <i appTipoDispositivoBiomedida class='icono' [dispositivoActual] = "dispositivo" ></i>
              </div>

              <div class="col d-none d-md-inline-block filtro-descripcion">
                <div>
                  {{ dispositivo.descripcion | translate }}
                </div>
              </div>
            </div>

            

            <div *ngIf="biomedidasPrescritasAVisualizar.length > 0 && dispositivosNoPrescritos.length > 0" >
              <span>
                <strong class="d-none d-md-inline-block filtros-titulo-selecciona">{{
                  'BIOMEDIDAS.DISPOSITIVOS.OTRAS' | translate
                }}</strong>        
              </span>
            </div>


            <div
              class="row filtro-item"
              *ngFor="let dispositivo of dispositivosNoPrescritos"
              [ngClass]="{ active: dispositivoSeleccionado.descripcion == dispositivo.descripcion }"
              (click)="seleccionarDispositivo(dispositivo)"
            >
            <div class="filtro-icono">
              <i appTipoDispositivoBiomedida class='icono' [dispositivoActual] = "dispositivo" ></i>
              </div>
              <div class="col d-none d-md-inline-block filtro-descripcion">
                <div>
                  {{ dispositivo.descripcion | translate }}
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
    <div class="col filtrado">
      <div class="row selector">
        <div class="col-12">
          <div class="btn-group btn-group-toggle">
            <label class="btn opcion element-click" [ngClass]="{ active: vista == 'registro' }">
              <input type="radio" name="options" checked [(ngModel)]="vista" value="registro" (click)="loadRegistro()" />
              {{ 'BIOMEDIDAS.TABS.REGISTRO' | translate }}
            </label>

            <label class="btn opcion element-click" [ngClass]="{ active: vista == 'historial' }">
              <input type="radio" name="options" [(ngModel)]="vista" value="historial" (click)="loadHistorial()" />
              {{ 'BIOMEDIDAS.TABS.HISTORIAL' | translate }}
            </label>
          </div>
        </div>
      </div>
      <div class="row seleccion">
        <div class="col vista" style="width: 200px" *ngIf="dispositivoSeleccionado" data-simplebar>
          <div class="vista-div" [ngSwitch]="vista">
            <div class="migas-filtro">
              <i class="icon-flechaDcha icono" (click)="abrirFiltros()"></i>
            </div>
            <app-registro-biomedida *ngSwitchCase="'registro'" [dispositivo]="dispositivoSeleccionado"></app-registro-biomedida>
            <app-historial-biomedida *ngSwitchCase="'historial'" [dispositivo]="dispositivoSeleccionado"></app-historial-biomedida>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
