import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, finalize, map } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { LoaderService } from '../services/loader.service';
import { Operation, AppConfig } from '../../core/services/app-config.service';
import { OperationInfo } from '../models/operationInfo.model';

/**
 * Interceptor de las peticiones.
 *
 * @author priveiro
 *
 * @version 01.02.0000
 * @since 01.02.0000
 */
@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  private peticionesPendientes = 0;
  private operations: OperationInfo[];
  private ignoredOperations: Operation[];
  private lockScreenMethods: string[];

  constructor(private loaderService: LoaderService) {
    this.operations = AppConfig.loader.operations;
    this.ignoredOperations = AppConfig.loader.ignoredOperations;
    this.lockScreenMethods = AppConfig.loader.lockScreenMethods;

    this.loaderService.redireccionar.subscribe((_data) => {
      this.peticionesPendientes = 0;
      this.loaderService.hideLoader();
    });
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const self = this;

    const operationInfo = this.getOperation(request);

    const seDebeIgnorarPeticion = this.isIgnoredOperation(operationInfo);
    if (!seDebeIgnorarPeticion) {
      this.peticionesPendientes++;
      if (1 === this.peticionesPendientes) {
        this.mustLockScreen(operationInfo, request);
        this.loaderService.showLoader(operationInfo);
      }
    }

    return next.handle(request).pipe(
      map(function (respuesta) {
        return respuesta;
      }),
      catchError(function (error) {
        return throwError(error);
      }),
      finalize(function () {
        if (!seDebeIgnorarPeticion && self.peticionesPendientes > 0) {
          self.peticionesPendientes--;
          if (0 === self.peticionesPendientes) {
            self.loaderService.hideLoader();
          }
        }
      })
    );
  }

  private getOperation(request: HttpRequest<any>): OperationInfo {
    let operation = null;
    for (const operationInfo of this.operations) {
      if (operationInfo.pattern.test(request.url)) {
        operation = operationInfo;
        break;
      }
    }

    return operation;
  }

  private mustLockScreen(operationInfo: OperationInfo, request: HttpRequest<any>): boolean {
    const metodoBloqueo = this.lockScreenMethods.indexOf(request.method) > -1;
    return operationInfo.lockScreen || metodoBloqueo;
  }

  private isIgnoredOperation(operationInfo: OperationInfo): boolean {
    return this.ignoredOperations.some(function (ignoredOperation) {
      return ignoredOperation === operationInfo.operation;
    });
  }
}
