import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe que devuelve el string pasado en mayúsculas.
 *
 * @author lreverendo
 * @author fjsalgado
 *
 * @version 01.02.0000
 * @since 01.02.0000
 */
@Pipe({
  name: 'capitalizado'
})
export class CapitalizadoPipe implements PipeTransform {
  public transform(value: string, todas: boolean = true): string {
    if (value != null) {
      value = value.toLowerCase();
      const nombres = value.split(' ').filter((v) => v.length > 0);
      if (todas) {
        for (let i = 0; i < nombres.length; i++) {
          nombres[i] = nombres[i][0].toUpperCase() + nombres[i].substr(1);
        }
      } else {
        nombres[0] = nombres[0][0].toUpperCase() + nombres[0].substr(1);
      }

      return nombres.join(' ');
    } else {
      return value;
    }
  }
}
