import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoaderService } from '../services';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../services/loader.service";
/**
 * Servicio que controla el redireccionamiento cuando ocurre un error
 *
 * @author lreverendo
 *
 * @version 01.02.0002
 * @since 01.02.0000
 */
var ErrorGuardService = /** @class */ (function () {
    function ErrorGuardService(router, loader) {
        this.router = router;
        this.loader = loader;
    }
    ErrorGuardService.prototype.canActivate = function (_next, _state) {
        var _this = this;
        return new Observable(function (observer) {
            try {
                if (sessionStorage) {
                    _this.loader.redireccionar.emit();
                    _this.router.navigate(['']);
                    observer.next(false);
                }
            }
            catch (e) {
                observer.next(true);
            }
        });
    };
    ErrorGuardService.ngInjectableDef = i0.defineInjectable({ factory: function ErrorGuardService_Factory() { return new ErrorGuardService(i0.inject(i1.Router), i0.inject(i2.LoaderService)); }, token: ErrorGuardService, providedIn: "root" });
    return ErrorGuardService;
}());
export { ErrorGuardService };
