import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { VariableClinica } from '../../../../../core/models';
import { DispositivoGenerico } from '../dispositivo-generico.model';
import { CustomValidators } from '../../../../../shared/validators/custom-validators';

/**
 *  Componente que representa al dispositivo Báscula.
 *
 * @author lreverendo
 *
 * @version 01.02.0070
 * @since 01.02.0000
 */
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tbody [bascula]',
  templateUrl: 'bascula.component.html',
  styleUrls: [
    '../../../biomedidas.component.css',
    '../../../views/registro-biomedida/registro-biomedida.component.css',
    './bascula.component.css'
  ]
})
export class BasculaComponent extends DispositivoGenerico implements OnInit {
  public peso: VariableClinica;

  constructor() {
    super();
  }

  public ngOnInit(): void {
    // Se busca la variable peso.
    this.peso = this.variablesClinicas.find((v) => v.alias === 'PESO');

    if (this.peso) {
      this.forma.addControl(
        'peso',
        new FormControl('', [
          Validators.required,
          Validators.pattern(this.getPatronFormato(this.peso.formato)),
          CustomValidators.porRango(this.peso.min, this.peso.max)
        ])
      );
    }
  }

  public getData(): VariableClinica[] {
    const result: VariableClinica[] = [];
    if (this.peso) {
      const peso = this.forma.value.peso.replace(',', '.');

      if (Number(peso) === NaN) {
        return [];
      }

      this.peso.valor = peso;
      result.push(this.peso);
    }

    return result;
  }
}
