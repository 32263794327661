<div
  class="alert alert-dismissible"
  id="alert"
  role="alert"
  style="display: none"
  [ngClass]="{ 'alert-success': exito, 'alert-danger': !exito }"
>
  <i *ngIf="!exito" class="icono icon-alerta2"> </i>

  <i *ngIf="exito" class="icono icon-ok2"> </i>

  <div class="d-inline-block">
    {{ mensaje }}
  </div>
  <button type="button" class="close" (click)="hide()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
