<div class="form-group" *ngIf="rhc" [formGroup]="forma">
  <label for="rhc" class="col-form-label" title="Ración = 10gr">
    {{ 'BIOMEDIDAS.RHC.RHC' | translate }} ({{ 'BIOMEDIDAS.RHC.raciones' | translate }})
  </label>
  <div class="datoCol">
    <input
      type="text"
      [appFormatNumber]="rhc.formato"
      appBlockCopyPaste
      class="form-control variable focus"
      id="rhc"
      formControlName="rhc"
    />
  </div>
</div>
<div
  class="filaError d-none"
  *ngIf="rhc"
  [ngClass]="{ 'd-table-row': forma.controls['rhc'].dirty && forma.controls['rhc'].errors?.maxError }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted"> {{ 'BIOMEDIDAS.RHC.RHCMAX' | translate }}{{ rhc.max }} </small>
</div>
<div
  class="filaError d-none"
  *ngIf="rhc"
  [ngClass]="{ 'd-table-row': forma.controls['rhc'].dirty && forma.controls['rhc'].errors?.minError }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted"> {{ 'BIOMEDIDAS.RHC.RHCMIN' | translate }}{{ rhc.min }} </small>
</div>
<div
  class="filaError d-none"
  *ngIf="rhc"
  [ngClass]="{ 'd-table-row': forma.controls['rhc'].dirty && forma.controls['rhc'].errors?.required }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted">
    {{ 'BIOMEDIDAS.RHC.RHCREQUERIDO' | translate }}
  </small>
</div>
