import { Injectable, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ActividadesService } from './actividades.service';
import { GestionActAnaliticaService } from './gestion-act-analitica.service';
import { GestionActBiomedidaService } from './gestion-act-biomedida.service';
import { GestionActCuestionarioService } from './gestion-act-cuestionario.service';
import { GestionActVideoconferenciaService } from './gestion-act-videoconferencia.service';
import { Actividad, LocaleTexto, TiposActividad } from '../models';
import { AppConfigService } from './app-config.service';
import * as moment from 'moment';
import { PermisosService } from './permisos.service';
import { isUndefined } from 'util';

/**
 * Servicio para la gestión del calendario.
 *
 * @author lreverendo
 * @author fjsalgado
 * @author dvila
 *
 * @version 01.02.0060
 * @since 01.02.0000
 */
@Injectable({ providedIn: 'root' })
export class CalendarioService {
  public tiposActividad: TiposActividad;
  private diasCaducidadActividad: number;

  //////////////////////////////////////////////////////////////////
  // Flujos a los que los clientes del servicio se pueden suscribir
  //////////////////////////////////////////////////////////////////

  /**
   * Evento para la recarga del calendario. Sea la vista que sea. Regresa a la fecha actual.
   */
  public reload: EventEmitter<any> = new EventEmitter();

  /**
   * Evento para la recarga del calendario. Sea la vista que sea.
   */
  public actualizarCalendario: EventEmitter<any> = new EventEmitter();

  /**
   * Evento que carga la vista diaria del calendario.
   */
  public vistaDia: EventEmitter<{ dia: Date }> = new EventEmitter();

  /**
   * Evento para abrir el modal de visualización de una actividad personal.
   */
  public visualizarActividadPersonal: EventEmitter<{
    actividad: Actividad;
  }> = new EventEmitter();

  /**
   * Evento para editar una actividad personal.
   */
  public editarActividadPersonal: EventEmitter<{
    actividad: Actividad;
  }> = new EventEmitter();

  /**
   * Evento para la creación de una actividad personal.
   */
  public crearActividadPersonal: EventEmitter<any> = new EventEmitter();

  /**
   * Devuelve una lista con los tipos de actividad disponibles.
   *
   * Este flujo no se cierra automáticamente después de la primera respuesta,
   * sino que continuará enviando datos cuando haya una actualización de los mismos.
   * Este flujo nunca devolverá un error. Hay que suscribirse al flujo error$ para ello.
   */
  public tiposActividad$: Observable<TiposActividad>;
  private tiposActividadSubject: BehaviorSubject<TiposActividad>;

  /**
   * Devuelve cualquier error que se produzca durante una operación.
   *
   * Este flujo no se cierra automáticamente después de la primera respuesta,
   * sino que continuará enviando todos los errores que se produzcan.
   */
  public error$: Observable<HttpErrorResponse>;
  private errorSubject: Subject<HttpErrorResponse>;

  public operacionFinalizada = new Subject();

  constructor(
    private actividadesService: ActividadesService,
    private gestionActAnliticaService: GestionActAnaliticaService,
    private gestionActBiomedidasService: GestionActBiomedidaService,
    private gestionActCuestionarioService: GestionActCuestionarioService,
    private gestionActVideoconferenciaService: GestionActVideoconferenciaService,
    private translateService: TranslateService,
    private datePipe: DatePipe,
    private permisoService: PermisosService
  ) {
    this.diasCaducidadActividad = AppConfigService.diasCaducidadActividad;

    this.tiposActividadSubject = new BehaviorSubject(null);
    this.tiposActividad$ = this.tiposActividadSubject.asObservable();

    this.errorSubject = new Subject();
    this.error$ = this.errorSubject.asObservable();

    this.cargarTiposActividades();
  }

  private gestionarErrorSubject(errorResponse: HttpErrorResponse): void {
    this.errorSubject.next(errorResponse);
  }

  /**
   * Recupera los tipos de actividad.
   */
  public cargarTiposActividades(): void {
    this.actividadesService.getTiposActividades().subscribe({
      next: this.actualizarTiposActividades.bind(this),
      error: this.gestionarErrorSubject.bind(this)
    });
  }

  private actualizarTiposActividades(tipos: TiposActividad): void {
    this.tiposActividad = tipos;
    this.tiposActividadSubject.next(this.tiposActividad);
  }

  /**
   * Recupera las actividades entre dos fechas y de los tipos pasados.
   * @param fechaInicioBusqueda Fecha de inicio de la búsqueda.
   * @param fechaFinBusqueda Fecha final de la búsqueda.
   * @param tiposActividad Tipos de actividades a recuperar.
   */
  public cargarActividades(
    fechaInicioBusqueda: Date,
    fechaFinBusqueda: Date,
    tiposActividad: String[],
    identificadorEjercicio: boolean
  ): Observable<Actividad[]> {
    const actividadesTratadasSubject = new Subject<Actividad[]>();

    // Carga de las actividades.
    this.actividadesService
      .getTodas(moment(fechaInicioBusqueda).toISOString(), moment(fechaFinBusqueda).toISOString(), tiposActividad)
      .subscribe((actividades) => {
        const actividadesTratadas = this.filtrarActividades(actividades, identificadorEjercicio);
        actividadesTratadasSubject.next(actividadesTratadas);
      }, this.gestionarErrorSubject.bind(this));

    return actividadesTratadasSubject.asObservable();
  }

  private filtrarActividades(actividades: Actividad[], identificadorEjercicio: boolean): Actividad[] {
    // Función que se encarga de hacer un filtro de las actividades para así mostrarse correctamente.
    const actividadesTratadas: Actividad[] = [];
    const indexes = {};
    const rangosIdentificadorEjercicio = AppConfigService.identificadorEjercicio;
    const codigosActividad = Object.keys(rangosIdentificadorEjercicio);
    let index = 0;

    for (const actividad of actividades) {
      if (actividad.tipo === this.tiposActividad.personal && moment(actividad.fechaFinalSinMargen).toDate() < new Date()) {
        actividad.estado = 'AUSENCIA';
      }

      if (actividad.tipo === this.tiposActividad.videoconferencia) {
        const titulo: LocaleTexto[] = [];
        this.translateService.getTranslation('gl').subscribe((traducciongl) => {
          titulo.push({
            locale: 'gl',
            texto: traducciongl.VIDEOCONFERENCIA.TITULO
          });
          this.translateService.getTranslation('es').subscribe((traducciones) => {
            titulo.push({
              locale: 'es',
              texto: traducciones.VIDEOCONFERENCIA.TITULO
            });
            actividad.titulo = titulo;
          });
        });
      }

      // Se comprueba si la actividad es de todo el día o pautada.
      actividad.todoElDia =
        this.datePipe.transform(actividad.fechaInicioSinMargen, 'HH:mm') === '00:00' &&
        this.datePipe.transform(actividad.fechaFinalSinMargen, 'HH:mm') === '00:00' &&
        actividad.fechaInicioSinMargen < actividad.fechaFinalSinMargen;

      let agrupador = null;

      if (actividad.agrupador != null) {
        const split = actividad.agrupador.split('_', 2);
        agrupador = split[0] + '_' + split[1];
      }

      const actRelacionadas = [];
      let actividadesFiltradas = [];
      actividad.instancias = [];

      // Se filtran las actividades de tensión IMC o pulso, con el mismo agrupador, fecha inicio y fin y fecha actividad.
      if (agrupador === 'ACT_TENS' || agrupador === 'ACT_IMC' || agrupador === 'ACT_PULS') {
        actividadesFiltradas = actividades.filter(function (act, actIndex) {
          if (actIndex > index && act.agrupador != null) {
            if (
              act.id !== actividad.id &&
              act.codigoActividad !== actividad.codigoActividad &&
              act.agrupador.indexOf(agrupador) > -1 &&
              moment(actividad.fechaInicioSinMargen).isSame(act.fechaInicioSinMargen) &&
              moment(actividad.fechaFinalSinMargen).isSame(act.fechaFinalSinMargen) &&
              actividad.fechaActividad === act.fechaActividad
            ) {
              return true;
            }
          }
          return false;
        });
      }

      this.guardarActividadesRelacionada(actividades, actividadesFiltradas, actRelacionadas, actividad);

      if (actividad.idInstancia) {
        actividad.instancias.push(actividad.idInstancia);
      }

      if (identificadorEjercicio) {
        this.setIdentificadorEjercicioActividades(actividadesTratadas, actividad, indexes, rangosIdentificadorEjercicio, codigosActividad);
      } else {
        actividadesTratadas.push(actividad);
      }

      index++;
    }

    return actividadesTratadas;
  }

  private guardarActividadesRelacionada(
    actividades: Actividad[],
    actividadesFiltradas: Actividad[],
    actRelacionadas: Actividad[],
    actividad: Actividad
  ): void {
    // Recoger las actividad relacionadas
    if (actividadesFiltradas.length > 0) {
      actRelacionadas.push(actividadesFiltradas.find((act) => act.codigoActividad === 'TAS'));
      actRelacionadas.push(actividadesFiltradas.find((act) => act.codigoActividad === 'TAD'));
      actRelacionadas.push(actividadesFiltradas.find((act) => act.codigoActividad === 'SAT_O2'));
      actRelacionadas.push(actividadesFiltradas.find((act) => act.codigoActividad === 'PPM'));
      actRelacionadas.push(actividadesFiltradas.find((act) => act.codigoActividad === 'IMC'));
      actRelacionadas.push(actividadesFiltradas.find((act) => act.codigoActividad === 'PESO'));
      actRelacionadas.push(actividadesFiltradas.find((act) => act.codigoActividad === 'TALLA'));
      actRelacionadas = actRelacionadas.filter((act) => act !== undefined);
    }

    // Se asocian las actividad relacionadas a la actividad que se está tratando
    actividad.actRelacionadas = actRelacionadas;

    // Se recogen las instancias de la actividad y las actividades relacionadas.
    for (const relacionada of actRelacionadas) {
      if (relacionada.idInstancia) {
        actividad.instancias.push(relacionada.idInstancia);
      }
      actividades.splice(
        actividades.findIndex((act) => act.id === relacionada.id),
        1
      );
    }

    if (actividad.tipo !== this.tiposActividad.personal) {
      actividad.actRelacionadas.push(actividad);
    }
  }

  private setIdentificadorEjercicioActividades(
    actividades: Actividad[],
    actividad: Actividad,
    indexes: {},
    rangosIdentificadorEjercicio: any,
    codigosActividad: string[]
  ): Actividad[] {
    let rangoAntes: {
      ini: number;
      fin: number;
    } = { ini: 0, fin: 0 };

    let rangoDurante: {
      ini: number;
      fin: number;
    } = { ini: 0, fin: 0 };

    let rangoDespues: {
      ini: number;
      fin: number;
    } = { ini: 0, fin: 0 };

    let length = 0;

    const ultimaFecha = isUndefined(actividades[actividades.length - 1])
      ? moment(actividad.fechaInicioSinMargen, 'DD-MM-YYYY')
      : moment(actividades[actividades.length - 1].fechaInicioSinMargen, 'DD-MM-YYYY');
    const fechaActividad = moment(actividad.fechaInicioSinMargen, 'DD-MM-YYYY');

    if (!ultimaFecha.isSame(fechaActividad)) {
      codigosActividad.forEach((cod) => delete indexes[cod]);
    }

    const key = codigosActividad.find((cod) => cod.includes(actividad.codigoActividad));
    if (key) {
      indexes[key] = isUndefined(indexes[key]) ? [] : indexes[key];
      length = indexes[key].push(actividad.id);
      const identificadorEjercicio = rangosIdentificadorEjercicio[key];

      if (identificadorEjercicio.antes > 0) {
        rangoAntes.ini = 0;
        rangoAntes.fin = identificadorEjercicio.antes + 1;
        actividad.antesEjercicio = length > rangoAntes.ini && length < rangoAntes.fin;
      }

      if (identificadorEjercicio.durante > 0) {
        rangoDurante.ini = identificadorEjercicio.antes;
        rangoDurante.fin = rangoDurante.ini + identificadorEjercicio.durante + 1;
        actividad.duranteEjercicio = length > rangoDurante.ini && length < rangoDurante.fin;
      }

      if (identificadorEjercicio.despues > 0) {
        rangoDespues.ini = identificadorEjercicio.antes + identificadorEjercicio.durante;
        rangoDespues.fin = rangoDespues.ini + identificadorEjercicio.despues + 1;
        actividad.despuesEjercicio = length > rangoDespues.ini && length < rangoDespues.fin;
      }
    }

    let idx = actividades.length;

    if (actividad.todoElDia) {
      const lastIndex = actividades
        .map((actTratada) => {
          const fechaActividadTratada = moment(actTratada.fechaInicioSinMargen, 'DD-MM-YYYY');
          const mismaFecha = fechaActividad.isSame(fechaActividadTratada);
          return (
            (mismaFecha && actividad.antesEjercicio && actTratada.antesEjercicio) ||
            (mismaFecha && actividad.duranteEjercicio && actTratada.antesEjercicio) ||
            (mismaFecha && actividad.duranteEjercicio && actTratada.duranteEjercicio) ||
            (mismaFecha && actividad.despuesEjercicio && actTratada.antesEjercicio) ||
            (mismaFecha && actividad.despuesEjercicio && actTratada.duranteEjercicio) ||
            (mismaFecha && actividad.despuesEjercicio && actTratada.despuesEjercicio)
          );
        })
        .lastIndexOf(true);

      idx = lastIndex < 0 ? idx : lastIndex + 1;
    }

    actividades.splice(idx, 0, actividad);

    length = 0;
    rangoAntes = rangoDurante = rangoDespues = { ini: 0, fin: 0 };

    return actividades;
  }

  public abrirActividad(actividad: Actividad): void {
    const fechaCaducidad = this.obtenerFechaCaducidad();
    // Función que permite abrir una actividad según su tipo.
    if (actividad.tipo === this.tiposActividad.personal) {
      this.abrirActividadPersonal(actividad);
    }
    if (actividad.tipo === this.tiposActividad.biomedida) {
      this.gestionActBiomedidasService.abrirBiomedida(actividad, fechaCaducidad);
    }
    if (actividad.tipo === this.tiposActividad.cuestionario) {
      this.gestionActCuestionarioService.abrirCuestionario(actividad);
    }
    if (actividad.tipo === this.tiposActividad.videoconferencia) {
      this.gestionActVideoconferenciaService.abrirVideoconferencia(actividad);
    }
    if (actividad.tipo === this.tiposActividad.analitica) {
      this.gestionActAnliticaService.abrirAnalitica(actividad, fechaCaducidad);
    }
  }

  private obtenerFechaCaducidad(): Date {
    const caducidad = new Date();
    caducidad.setHours(0, 0, 0, 0);
    caducidad.setDate(caducidad.getDate() - this.diasCaducidadActividad);
    return caducidad;
  }

  //////////////////////////////////////////////////////////////////
  // Gestión de actividad personal
  //////////////////////////////////////////////////////////////////

  private abrirActividadPersonal(actividad: Actividad): void {
    if (this.permisoService.getPermisos().escritura) {
      // Se abre la actividad personal.
      this.editarActividadPersonal.emit({ actividad: actividad });
    } else {
      // Se abre la visualización de la actividad personal.
      this.visualizarActividadPersonal.emit({ actividad: actividad });
    }
  }
}
