<div class="container general-container">
  <div class="row" style="height: 100%">
    <div class="contenedor-filtros">
      <div class="filtros">
        <div class="row selector">
          <div class="col-4 migas" *ngIf="vista == 'dia'">{{ 'MENU.CALENDARIO' | translate }} > {{ 'CALENDARIO.DIA' | translate }}</div>
          <div class="col-4 migas" *ngIf="vista == 'semana'">
            {{ 'MENU.CALENDARIO' | translate }} > {{ 'CALENDARIO.SEMANA' | translate }}
          </div>
          <div class="col-4 migas" *ngIf="vista == 'mes'">{{ 'MENU.CALENDARIO' | translate }} > {{ 'CALENDARIO.MES' | translate }}</div>
        </div>
        <div class="filtros-lista">
          <div class="filtros-titulo">
            <span>
              <strong>{{ 'CALENDARIO.FILTRO.TITULO' | translate }}</strong>
            </span>
          </div>
          <div class="filtros-scroll" data-simplebar>
            <div class="row filtro-item" (click)="agregarTipoActividad()">
              <div class="filtro-check">
                <div class="filtro-checkoff">
                  <i class="icon-check_off icono"></i>
                </div>
                <div class="filtro-checkon" *ngIf="filtro.todos()">
                  <i class="icon-check_on icono"></i>
                </div>
              </div>
              <div class="filtro-icono d-none d-md-table-cell"></div>
              <div class="col d-table-cell">
                <div>
                  {{ 'CALENDARIO.FILTRO.TODOS' | translate }}
                </div>
              </div>
            </div>
            <div class="row filtro-item" (click)="agregarTipoActividad('bio')" title="{{ 'CALENDARIO.FILTRO.BIO' | translate }}">
              <div class="filtro-check">
                <div class="filtro-checkoff">
                  <i class="icon-check_off icono"></i>
                </div>
                <div class="filtro-checkon" *ngIf="filtro.biomedida">
                  <i class="icon-check_on icono"></i>
                </div>
              </div>
              <div class="filtro-icono">
                <i class="icon-biomedidas_2 icono"></i>
              </div>
              <div class="col d-none d-md-table-cell">
                <div>
                  {{ 'CALENDARIO.FILTRO.BIO' | translate }}
                </div>
              </div>
            </div>
            <div class="row filtro-item" (click)="agregarTipoActividad('cuest')" title="{{ 'CALENDARIO.FILTRO.CUEST' | translate }}">
              <div class="filtro-check">
                <div class="filtro-checkoff">
                  <i class="icon-check_off icono"></i>
                </div>
                <div class="filtro-checkon" *ngIf="filtro.cuestionario">
                  <i class="icon-check_on icono"></i>
                </div>
              </div>
              <div class="filtro-icono">
                <i class="icon-cuestionario_2 icono"></i>
              </div>
              <div class="col d-none d-md-table-cell">
                <div>
                  {{ 'CALENDARIO.FILTRO.CUEST' | translate }}
                </div>
              </div>
            </div>
            <div class="row filtro-item" (click)="agregarTipoActividad('personal')" title=" {{ 'CALENDARIO.FILTRO.PERSONAL' | translate }}">
              <div class="filtro-check">
                <div class="filtro-checkoff">
                  <i class="icon-check_off icono"></i>
                </div>
                <div class="filtro-checkon" *ngIf="filtro.personal">
                  <i class="icon-check_on icono"></i>
                </div>
              </div>
              <div class="filtro-icono">
                <i class="icon-actividadPersonal_2 icono"></i>
              </div>
              <div class="col d-none d-md-table-cell">
                <div>
                  {{ 'CALENDARIO.FILTRO.PERSONAL' | translate }}
                </div>
              </div>
            </div>
            <div class="row filtro-item" (click)="agregarTipoActividad('video')" title=" {{ 'CALENDARIO.FILTRO.VIDEO' | translate }}">
              <div class="filtro-check">
                <div class="filtro-checkoff">
                  <i class="icon-check_off icono"></i>
                </div>
                <div class="filtro-checkon" *ngIf="filtro.videoconferencia">
                  <i class="icon-check_on icono"></i>
                </div>
              </div>
              <div class="filtro-icono">
                <i class="icon-videoconferencia icono"></i>
              </div>
              <div class="col d-none d-md-table-cell">
                <div>
                  {{ 'CALENDARIO.FILTRO.VIDEO' | translate }}
                </div>
              </div>
            </div>
            <div
              class="row filtro-item"
              (click)="agregarTipoActividad('analitica')"
              title=" {{ 'CALENDARIO.FILTRO.ANALITICA' | translate }}"
            >
              <div class="filtro-check">
                <div class="filtro-checkoff">
                  <i class="icon-check_off icono"></i>
                </div>
                <div class="filtro-checkon" *ngIf="filtro.analitica">
                  <i class="icon-check_on icono"></i>
                </div>
              </div>
              <div class="filtro-icono">
                <i class="icon-analitica icono"></i>
              </div>
              <div class="col d-none d-md-table-cell">
                <div>
                  {{ 'CALENDARIO.FILTRO.ANALITICA' | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col filtrado">
      <div class="row selector">
        <div class="col-6 migas-filtro">
          <i class="icon-filtro icono" (click)="abrirFiltros()"></i>
        </div>
        <div class="offset-5 col-1 migas-info">
          <i
            class="icon-info_leyenda icono"
            #popoverInfo="ngbPopover"
            [ngbPopover]="tipContent"
            [placement]="['top', 'left', 'right', 'auto']"
            container="body"
            popoverClass="infoPopover"
            triggers="manual"
            (mouseleave)="cerrarInfoPopover()"
            (mouseenter)="abrirInfoPopover()"
            (click)="eventoClickInfoPopover()"
          ></i>
        </div>
        <div class="col-12">
          <div class="btn-group btn-group-toggle">
            <label class="btn opcion element-click" [ngClass]="{ active: vista == 'dia' }">
              <input type="radio" name="options" checked [(ngModel)]="vista" value="dia" (click)="reloadDia()" />
              {{ 'CALENDARIO.DIA' | translate }}
            </label>
            <label class="btn opcion element-click" [ngClass]="{ active: vista == 'semana' }">
              <input type="radio" name="options" [(ngModel)]="vista" value="semana" (click)="reloadSemana()" />
              {{ 'CALENDARIO.SEMANA' | translate }}
            </label>
            <label class="btn opcion element-click" [ngClass]="{ active: vista == 'mes' }">
              <input type="radio" name="options" [(ngModel)]="vista" value="mes" (click)="reloadMes()" />
              {{ 'CALENDARIO.MES' | translate }}
            </label>
          </div>
        </div>
      </div>
      <div class="row seleccion">
        <div class="col vista" style="width: 200px" data-simplebar>
          <div class="vista-div" [ngSwitch]="vista">
            <app-dia *ngSwitchCase="'dia'" [dia]="fecha" [tiposActividad]="tiposActividadFiltrados"></app-dia>
            <app-semana *ngSwitchCase="'semana'" [tiposActividad]="tiposActividadFiltrados"></app-semana>
            <app-mes *ngSwitchCase="'mes'" [tiposActividad]="tiposActividadFiltrados"></app-mes>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-visualizacion-biomedida></app-visualizacion-biomedida>
<app-visualizacion-cuestionario></app-visualizacion-cuestionario>
<app-visualizacion-actividad-personal></app-visualizacion-actividad-personal>
<app-actividad-personal></app-actividad-personal>
<app-registro-cuestionario></app-registro-cuestionario>
<app-extraccion-analitica></app-extraccion-analitica>
<app-visualizacion-extraccion-analitica></app-visualizacion-extraccion-analitica>

<div class="modal fade" id="registro">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" (click)="limpiarRegistro()">&times;</button>
      </div>

      <div class="modal-body" data-simplebar>
        <app-registro-biomedida
          *ngIf="dispositivo"
          [dispositivo]="dispositivo"
          [actividad]="actividadSeleccionada"
        ></app-registro-biomedida>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="filtros">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h3>
          <strong>{{ 'CALENDARIO.FILTRO.TITULO' | translate }}</strong>
        </h3>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <div class="modal-body" data-simplebar>
        <div class="filtros-lista">
          <div class="filtros-scroll">
            <div class="mx-auto d-table">
              <div class="row filtro-item" (click)="elegirTipoActividad()">
                <div class="filtro-check">
                  <div class="filtro-checkoff">
                    <i class="icon-check_off icono"></i>
                  </div>
                  <div class="filtro-checkon" *ngIf="filtro.todos()">
                    <i class="icon-check_on icono"></i>
                  </div>
                </div>
                <div class="filtro-icono d-table-cell"></div>
                <div class="col d-table-cell">
                  <div>
                    {{ 'CALENDARIO.FILTRO.TODOS' | translate }}
                  </div>
                </div>
              </div>
              <div class="row filtro-item" (click)="elegirTipoActividad('bio')" title="{{ 'CALENDARIO.FILTRO.BIO' | translate }}">
                <div class="filtro-check">
                  <div class="filtro-checkoff">
                    <i class="icon-check_off icono"></i>
                  </div>
                  <div class="filtro-checkon md-left" *ngIf="filtro.biomedida">
                    <i class="icon-check_on icono"></i>
                  </div>
                </div>
                <div class="filtro-icono">
                  <i class="icon-biomedidas_2 icono"></i>
                </div>
                <div class="col d-md-table-cell">
                  <div>
                    {{ 'CALENDARIO.FILTRO.BIO' | translate }}
                  </div>
                </div>
              </div>
              <div class="row filtro-item" (click)="elegirTipoActividad('cuest')" title="{{ 'CALENDARIO.FILTRO.CUEST' | translate }}">
                <div class="filtro-check">
                  <div class="filtro-checkoff">
                    <i class="icon-check_off icono"></i>
                  </div>
                  <div class="filtro-checkon md-left" *ngIf="filtro.cuestionario">
                    <i class="icon-check_on icono"></i>
                  </div>
                </div>
                <div class="filtro-icono">
                  <i class="icon-cuestionario_2 icono"></i>
                </div>
                <div class="col d-table-cell">
                  <div>
                    {{ 'CALENDARIO.FILTRO.CUEST' | translate }}
                  </div>
                </div>
              </div>
              <div
                class="row filtro-item"
                (click)="elegirTipoActividad('personal')"
                title=" {{ 'CALENDARIO.FILTRO.PERSONAL' | translate }}"
              >
                <div class="filtro-check">
                  <div class="filtro-checkoff">
                    <i class="icon-check_off icono"></i>
                  </div>
                  <div class="filtro-checkon md-left" *ngIf="filtro.personal">
                    <i class="icon-check_on icono"></i>
                  </div>
                </div>
                <div class="filtro-icono">
                  <i class="icon-actividadPersonal_2 icono"></i>
                </div>
                <div class="col d-md-table-cell">
                  <div>
                    {{ 'CALENDARIO.FILTRO.PERSONAL' | translate }}
                  </div>
                </div>
              </div>
              <div class="row filtro-item" (click)="elegirTipoActividad('video')" title=" {{ 'CALENDARIO.FILTRO.VIDEO' | translate }}">
                <div class="filtro-check">
                  <div class="filtro-checkoff">
                    <i class="icon-check_off icono"></i>
                  </div>
                  <div class="filtro-checkon md-left" *ngIf="filtro.videoconferencia">
                    <i class="icon-check_on icono"></i>
                  </div>
                </div>
                <div class="filtro-icono">
                  <i class="icon-videoconferencia icono"></i>
                </div>
                <div class="col d-table-cell">
                  <div>
                    {{ 'CALENDARIO.FILTRO.VIDEO' | translate }}
                  </div>
                </div>
              </div>
              <div
                class="row filtro-item"
                (click)="elegirTipoActividad('analitica')"
                title="{{ 'CALENDARIO.FILTRO.ANALITICA' | translate }}"
              >
                <div class="filtro-check">
                  <div class="filtro-checkoff">
                    <i class="icon-check_off icono"></i>
                  </div>
                  <div class="filtro-checkon md-left" *ngIf="filtro.analitica">
                    <i class="icon-check_on icono"></i>
                  </div>
                </div>
                <div class="filtro-icono">
                  <i class="icon-analitica icono"></i>
                </div>
                <div class="col d-md-table-cell">
                  <div>
                    {{ 'CALENDARIO.FILTRO.ANALITICA' | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary btn-lg" data-dismiss="modal" (click)="filtrarTiposActividad()">
          {{ 'CALENDARIO.FILTRO.APLICAR' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #tipContent>
  <app-leyenda></app-leyenda>
</ng-template>
