import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AutenticacionService } from './autenticacion.service';
import { AppConfigService, AppConfig } from './app-config.service';
import { Notificacion } from '../../core/models/notificacion.model';
import { sprintf } from 'sprintf-js';
import { ErrorOperacion } from '../models/errorOperacion.model';
import { PerfilService } from './perfil.service';
import { TipoNotificacion } from 'src/app/core/models';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { timer, Subscription } from 'rxjs';
import * as moment from 'moment';

/**
 * Servicio para la gestión del notificaciones.
 *
 * @author priveiro
 * @author fjsalgado
 *
 * @version 01.02.0002
 * @since 01.02.0000
 */
@Injectable({ providedIn: 'root' })
export class NotificacionesService implements OnDestroy {
  private fechaUltimaBusqueda: string;
  private tipoNotificacionesSuscritas: string[];

  // Lista con todas las notificaciones cargadas hasta el momento
  private notificaciones: Notificacion[];

  private tipoNotificacionesSubscription: Subscription;
  private nuevasNotificacionesSubcription: Subscription;

  //////////////////////////////////////////////////////////////////
  // Flujos a los que los clientes del servicio se pueden suscribir
  //////////////////////////////////////////////////////////////////

  /**
   * Devuelve todas las notificaciones cargadas hasta el momento.
   * Siempre devuelve un valor en la suscripción. Devolverá null si aún no se han cargado las notificaciones.
   *
   * Este flujo no se cierra automáticamente después de la primera respuesta,
   * sino que continuará enviando datos cuando haya una actualización de los mismos.
   * Este flujo nunca devolverá un error. Hay que suscribirse al flujo error$ para ello.
   */
  public notificaciones$: Observable<Notificacion[]>;
  private notificacionesSubject: BehaviorSubject<Notificacion[]>;

  /**
   * Devuelve las notificaciones que haya nuevas.
   * Se consultas las notificaciones cada minuto.
   *
   * Este flujo no se cierra automáticamente después de la primera respuesta,
   * sino que continuará enviando datos cuando haya una actualización de los mismos.
   * Este flujo nunca devolverá un error. Hay que suscribirse al flujo error$ para ello.
   */
  public nuevasNotificaciones$: Observable<Notificacion[]>;
  private nuevasNotificacionesSubject: Subject<Notificacion[]>;

  /**
   * Devuelve el número de notificaciones pendientes de revisar por el usuario
   * Siempre devuelve un valor en la suscripción. Devolverá 0 si aún no se han cargado las notificaciones.
   *
   * Este flujo no se cierra automáticamente después de la primera respuesta,
   * sino que continuará enviando datos cuando haya una actualización de los mismos.
   * Este flujo nunca devolverá un error. Hay que suscribirse al flujo error$ para ello.
   */
  public numeroNotificacionesNoRevisadas$: Observable<Number>;
  private numeroNotificacionesNoRevisadasSubject: BehaviorSubject<Number>;

  /**
   * Devuelve cualquier error que se produzca durante una operación.
   *
   * Este flujo no se cierra automáticamente después de la primera respuesta,
   * sino que continuará enviando todos los errores que se produzcan.
   */
  public error$: Observable<ErrorOperacion>;
  private errorSubject: Subject<ErrorOperacion>;

  constructor(private http: HttpClient, private autenticacionService: AutenticacionService, private perfilService: PerfilService) {
    this.notificaciones = null;
    this.tipoNotificacionesSuscritas = null;

    this.notificacionesSubject = new BehaviorSubject(null);
    this.notificaciones$ = this.notificacionesSubject.asObservable();

    this.nuevasNotificacionesSubject = new Subject();
    this.nuevasNotificaciones$ = this.nuevasNotificacionesSubject.asObservable();

    this.numeroNotificacionesNoRevisadasSubject = new BehaviorSubject(0);
    this.numeroNotificacionesNoRevisadas$ = this.numeroNotificacionesNoRevisadasSubject.asObservable();

    this.errorSubject = new Subject();
    this.error$ = this.errorSubject.asObservable();
  }

  public iniciar() {
    this.tipoNotificacionesSuscritas = null;
    this.fechaUltimaBusqueda = moment().utc().format(AppConfig.constant.utcFormat);

    // Se suscribe al flujo que devuelve el tipo de notificaciones al que el paciente está suscrito.
    // Cada vez que se cambien se recargarán las notificaciones.
    this.tipoNotificacionesSubscription = this.perfilService.tipoNotificacionesSuscritas$.subscribe(this.cargarNotificaciones.bind(this));
  }

  private cargarNotificaciones(arrayTipoNotificacionesSuscritas: TipoNotificacion[]) {
    if (arrayTipoNotificacionesSuscritas != null) {
      const tipoNotificacionesSuscritasAnteriores = this.tipoNotificacionesSuscritas;
      const tipoNotificacionesSuscritas: string[] = arrayTipoNotificacionesSuscritas.map((tipoNotificacion) => tipoNotificacion.codigo);
      this.tipoNotificacionesSuscritas = tipoNotificacionesSuscritas;

      // Si no se ha lanzado una búsqueda de notificaciones se lanza
      if (tipoNotificacionesSuscritasAnteriores == null) {
        // Recupera todas las notificaciones del paciente
        this.buscarNotificaciones().subscribe(
          this.actualizarNotificaciones.bind(this),
          this.enviarErrorRecuperandoNotificaciones.bind(this)
        );

        // Cada x segundos se lanza una petición para buscar nuevas notificaciones
        const tiempoRefresco = AppConfigService.segundosRefrescoNotificaciones * 1000;
        this.nuevasNotificacionesSubcription = timer(tiempoRefresco, tiempoRefresco)
          .pipe(switchMap(() => this.buscarNuevasNotificaciones()))
          .subscribe(this.actualizarNuevasNotificaciones.bind(this), this.enviarErrorRecuperandoNuevasNotificaciones.bind(this));
      } else if (!this.sonIguales(tipoNotificacionesSuscritasAnteriores, tipoNotificacionesSuscritas)) {
        // Si ya se había lanzado una búsqueda y han variado los tipos de notificaciones suscritos
        // comprueba si el usuario se ha suscrito a nuevos tipos
        // Si el usuario se ha suscrito a nuevos tipos de notificaciones hay que lanzar una nueva búsqueda
        if (this.suscritosNuevosTipoNotificaciones(tipoNotificacionesSuscritas, tipoNotificacionesSuscritasAnteriores)) {
          this.buscarNotificaciones().subscribe(
            this.actualizarNotificaciones.bind(this),
            this.enviarErrorRecuperandoNotificaciones.bind(this)
          );
        } else {
          // Si solo se han eliminado suscripciones se filtra la lista de notificaciones dejando solo las de los tipos suscritos
          this.notificaciones = this.notificaciones.filter(function (notificacion) {
            return tipoNotificacionesSuscritas.includes(notificacion.tipo);
          });
          this.notificacionesSubject.next(this.notificaciones);
          this.actualizarNumeroNotificacionesNoRevisadas();
        }
      }
    }
  }

  suscritosNuevosTipoNotificaciones(tipoNotificacionesSuscritas: string[], tipoNotificacionesSuscritasAnteriores: string[]): boolean {
    for (const notificacionSuscrita of tipoNotificacionesSuscritas) {
      if (!tipoNotificacionesSuscritasAnteriores.includes(notificacionSuscrita)) {
        return true;
      }
    }
    return false;
  }

  private sonIguales(arrayA: string[], arrayB: string[]) {
    if (arrayA.length !== arrayB.length) {
      return false;
    }
    for (const elementArrayA of arrayA) {
      if (!arrayB.includes(elementArrayA)) {
        return false;
      }
    }
    return true;
  }

  /* private arrayAContieneElementosNoExistentesEnB(arrayA: string[], arrayB: string[]) {
    if (arrayA.length !== arrayB.length) {
      return false;
    }
    for (const elementArrayA of arrayA) {
      if (!arrayB.includes(elementArrayA)) {
        return false;
      }
    }
    return true;
  } */

  private actualizarNotificaciones(notificaciones: Notificacion[]) {
    this.ordenarNotificacionesPorFecha(notificaciones);
    this.notificaciones = notificaciones;
    this.notificacionesSubject.next(notificaciones);
    this.actualizarNumeroNotificacionesNoRevisadas();
  }

  private enviarErrorRecuperandoNotificaciones(error: any) {
    this.notificacionesSubject.error(error);
  }

  private actualizarNuevasNotificaciones(notificaciones: Notificacion[]) {
    if (notificaciones != null && notificaciones.length > 0) {
      // Elimina las notificaciones que ya estubiesen cargadas
      const notificacionesNuevas = notificaciones.filter(
        (notificacionNueva) => !this.notificaciones.some((notificacion) => notificacion.id === notificacionNueva.id)
      );

      if (notificacionesNuevas.length > 0) {
        // Actualiza la lista de notificaciones
        this.notificaciones = this.notificaciones.concat(notificacionesNuevas);
        this.ordenarNotificacionesPorFecha(this.notificaciones);
        this.notificacionesSubject.next(this.notificaciones);

        // Envía los nuevos datos
        this.nuevasNotificacionesSubject.next(notificacionesNuevas);
        this.actualizarNumeroNotificacionesNoRevisadas();
      }
    }
  }

  private enviarErrorRecuperandoNuevasNotificaciones(error: any) {
    this.nuevasNotificacionesSubject.error(error);
  }

  private ordenarNotificacionesPorFecha(listaNotificaciones: Notificacion[]) {
    listaNotificaciones.sort((notificacionA: Notificacion, notificacionB: Notificacion) => {
      if (notificacionA.fecha < notificacionB.fecha) {
        return 1;
      } else if (notificacionA.fecha > notificacionB.fecha) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  private buscarNotificaciones(): Observable<Object> {
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.busquedaNotificaciones, dniPaciente, this.tipoNotificacionesSuscritas);
    return this.http.get(url);
  }

  private buscarNuevasNotificaciones(): Observable<Notificacion[]> {
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const url = sprintf(
      AppConfigService.urls.busquedaUltimasNotificaciones,
      dniPaciente,
      this.tipoNotificacionesSuscritas,
      this.fechaUltimaBusqueda
    );
    this.fechaUltimaBusqueda = moment().utc().format(AppConfig.constant.utcFormat);
    return this.http.get<Notificacion[]>(url);
  }

  private actualizarNumeroNotificacionesNoRevisadas() {
    // Actualiza el número de notificaciones sin leer
    const numeroNotificacionesNoRevisadas = this.notificaciones.filter((notificacion) => notificacion.revisada === false).length;
    this.numeroNotificacionesNoRevisadasSubject.next(numeroNotificacionesNoRevisadas);
  }

  public marcarNotificacionRevisada(notificacion: Notificacion): Observable<boolean> {
    const loginPaciente = this.autenticacionService.getLoginPaciente();
    const idNotificacion = notificacion.id;
    const url = sprintf(AppConfigService.urls.marcarNotificacionRevisada, loginPaciente, idNotificacion);
    const peticion = this.http.put<boolean>(url, null);
    const peticion$ = new BehaviorSubject(null);

    // Se suscribe para actualizar la lista de notificaciones
    peticion.subscribe(
      (marcadaComoRevisada: boolean) => {
        if (marcadaComoRevisada) {
          this.marcarComoRevisadaOEliminar(notificacion);
          this.actualizarNumeroNotificacionesNoRevisadas();
        }
        peticion$.next(marcadaComoRevisada);
      },
      (err) => peticion$.error(err)
    );

    return peticion$;
  }

  marcarComoRevisadaOEliminar(notificacion: Notificacion): any {
    const diasDiferenciaFechaActual = this.getDiasDiferenciaFechaActual(notificacion);
    if (diasDiferenciaFechaActual <= AppConfigService.diasNotificacionesRecientes) {
      // Si es una notificación reciente la marca como revisada
      notificacion.revisada = true;
    } else {
      // Si es una notificación anterior la elimina
      const posicionEnArray = this.notificaciones.indexOf(notificacion);
      if (posicionEnArray !== -1) {
        this.notificaciones.splice(posicionEnArray, 1);
      }
    }
  }

  getDiasDiferenciaFechaActual(notificacion: Notificacion): number {
    const fechaActual = moment();
    const fechaNotificacion = moment.utc(notificacion.fecha).local();
    return fechaActual.diff(fechaNotificacion, 'days');
  }

  public detener() {
    if (this.tipoNotificacionesSubscription) {
      this.tipoNotificacionesSubscription.unsubscribe();
    }

    if (this.nuevasNotificacionesSubcription) {
      this.nuevasNotificacionesSubcription.unsubscribe();
    }

    this.notificaciones = null;
    this.notificacionesSubject.next(null);
    this.numeroNotificacionesNoRevisadasSubject.next(0);
  }

  public ngOnDestroy(): void {
    this.detener();
  }
}
