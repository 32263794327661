import { Directive, ElementRef, HostListener } from '@angular/core';

/**
 *  Directiva que permite sólo la impresión de números enteros y ciertos caracteres especiales.
 *
 * @author lreverendo
 *
 * @version 01.02.0022
 * @since 01.02.0022
 */
@Directive({
  selector: '[appTelefono]'
})
export class TelefonoDirective {
  /* private carEspeciales: Array<string> = [ 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete', 'Decimal', 'Left', 'Right', 'Del']; */
  private carEspeciales: Array<number> = [8, 9, 35, 36, 37, 38, 39, 45, 46, 109];

  constructor(private elementRef: ElementRef) {}

  @HostListener('beforeinput', ['$event'])
  public onBeforeInput(event): void {
    const before: string = event.target.value;
    const data: string = event.data;

    if (data && before.length === 9) {
      event.preventDefault();
      return;
    }

    if (data && data.match('[^0-9]')) {
      event.preventDefault();
    }
  }

  @HostListener('input', ['$event'])
  public onInput(_event): void {
    const data = this.elementRef.nativeElement.value;
    this.elementRef.nativeElement.value = data.replace(/[^0-9]/g, '');
  }

  @HostListener('keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent): void {
    const actual: String = this.elementRef.nativeElement.value;
    const data: string = event.key;

    // Permite los caracteres especiales
    if (this.carEspeciales.indexOf(event.keyCode) !== -1) {
      return;
    }

    if (actual.length === 9) {
      event.preventDefault();
      return;
    }

    if (data && !(data.match('[0-9]') || data.match('[0-9]'))) {
      event.preventDefault();
      return;
    }
  }
}
