<loader></loader>

<div class="row tel-encabezado-pagina">
  <div class="col-12">
    <h3 class="tel-titulo-pagina">
      <strong>{{ 'CUESTIONARIOS.HISTORIAL.TITULO' | translate }}</strong>
    </h3>
  </div>
</div>
<div class="row">
  <div class="separador"></div>
</div>

<h3 *ngIf="cuestionarios.length == 0 && !loader.active" class="texto-centrado">
  {{ 'CUESTIONARIOS.SIN' | translate }}
</h3>

<div class="eventos-contenido" *ngIf="!loader.active" data-simplebar>
  <div class="listado" *ngFor="let elem of tabla">
    <div class="fecha">
      <h5>{{ elem.fecha }}</h5>
    </div>
    <div
      *ngFor="let cuest of elem.cuestionarios; let i = index"
      (click)="verCuestionario(cuest)"
      class="list-group-item btn col-12 actividad element-click"
      [ngClass]="{ primera: i == 0 }"
    >
      <div class="row">
        <i class="icon-cuestionario_listado icono actRealizada"> </i>
        <div class="col">
          <div class="row">
            <div class="col-10 actividadInfo">
              {{ cuest.titulo | localeTexto: getCurrentIdioma() }}
            </div>
            <div class="col-2 realizado">
              <div class="icon-ok" align-self-center id="tickRealizado"></div>
              <p>{{ cuest.fechaActividad | date: 'HH:mm' }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
