import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AutenticacionService } from './autenticacion.service';
import { AppConfigService } from './app-config.service';
import { Mensaje } from '../models';
import { sprintf } from 'sprintf-js';

/**
 * Servicio para la gestión de mensajería.
 *
 * @author lreverendo
 *
 * @version 01.02.0002
 * @since 01.02.0000
 */
@Injectable({ providedIn: 'root' })
export class MensajeriaService {
  /**
   * Evento para abrir el modal de visualización de un mensaje.
   */
  public visualizarMensaje: EventEmitter<{
    mensaje: Mensaje;
    recibido: boolean;
  }> = new EventEmitter();

  /**
   * Evento que recarga los mensajes recibidos.
   */
  public reloadRecibidos: EventEmitter<any> = new EventEmitter();

  /**
   * Evento que recarga los mensajes enviados.
   */
  public reloadEnviados: EventEmitter<any> = new EventEmitter();

  /**
   * Evento que abre el modal para enviar un nuevo mensaje.
   */
  public crearNuevoMensaje: EventEmitter<{
    mensaje?: Mensaje;
  }> = new EventEmitter();

  /**
   * Evento actualizar enviados.
   */
  public actualizarEnviados: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient, private autenticacionService: AutenticacionService) {}

  public getMensajesRecibidos(): Observable<Mensaje[]> {
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.recuperarMensajesRecibidos, dniPaciente);
    return this.http.get<Mensaje[]>(url);
  }

  public getMensajesEnviados(): Observable<Mensaje[]> {
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.recuperarMensajesEnviados, dniPaciente);
    return this.http.get<Mensaje[]>(url);
  }

  public enviarMensaje(mensaje: Mensaje): Observable<Mensaje> {
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.enviarMensaje, dniPaciente);
    return this.http.post<Mensaje>(url, mensaje);
  }

  public marcarMensajeLeido(idMensaje: string): Observable<boolean> {
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.marcarMensajeLeido, dniPaciente, idMensaje);
    return this.http.put<boolean>(url, null);
  }
}
