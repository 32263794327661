<nav class="navbar navbar-expand-lg navbar-light">
  <div class="row">
    <div class="opcion align-items-center col d-table" (click)="irAtras()" *ngIf="atras">
      <div>
        <i class="icon-volver icono" title="{{ 'MENU.VOLVER' | translate }}"></i>
      </div>
    </div>
    <div class="perfil align-items-center col mr-auto" [ngClass]="{ perfilSinAtras: !atras }">
      <i class="icon-avatar icono"></i>
      <h5 class="nomPerfil" *ngIf="perfil != null" title="{{ perfil.nombre | capitalizado }}">
        {{ perfil.nombre | capitalizado }}
      </h5>
    </div>

    <div class="titulo align-items-center col">
      <h3 style="width: 100%; text-align: left">
        <strong>{{ 'MENU.TITULO' | translate }}</strong>
      </h3>
    </div>

    <div class="opcion align-items-center col-auto salir d-flex" (click)="salir()">
      <div>
        <i class="icon-salir icono" title="{{ 'MENU.SALIR' | translate }}"></i>
        <span class="d-none d-lg-inline-block">
          {{ 'MENU.SALIR' | translate }}
        </span>
      </div>
    </div>
  </div>
</nav>

<div class="container-elegir">
  <div class="container general-container">
    <div class="row tel-encabezado-pagina">
      <div class="col-12">
        <h3 class="tel-titulo-pagina">
          <strong>{{ 'ELEGIRCUENTA.TITULO' | translate }}</strong>
        </h3>
      </div>
    </div>
    <div class="row">
      <div class="separador"></div>
    </div>
    <div class="row cuentasRow" data-simplebar>
      <loader [lockScreen]="true"></loader>

      <div class="cuentas" *ngIf="pacientes.length > 0">
        <div class="row">
          <button class="btn btn-success eleccion" (click)="iniciarComo()">
            <div class="row">
              <i class="icon-avatar icono"></i>
              <div class="col cuenta">
                <h5>{{ 'ELEGIRCUENTA.GESTIONAR' | translate }}</h5>
                <h5>
                  <strong>{{ 'ELEGIRCUENTA.MICUENTA' | translate }}</strong>
                </h5>
              </div>
              <i class="icon-flechaDcha icono"></i>
            </div>
          </button>
        </div>
        <div class="row" *ngFor="let paciente of pacientes">
          <button class="btn btn-primary eleccion" (click)="iniciarComo(paciente)">
            <div class="row">
              <i class="icon-avatar icono"></i>
              <div class="col cuenta">
                <h5>{{ 'ELEGIRCUENTA.CUIDAR' | translate }}</h5>
                <h5>
                  <strong>{{ paciente.nombre }} {{ paciente.apellido1 }} {{ paciente.apellido2 }}</strong>
                </h5>
              </div>
              <i class="icon-flechaDcha icono"></i>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
