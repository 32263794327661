<div class="container general-container">
  <div class="row selector">
    <div class="col-4 migas">
      {{ 'MENU.MENSAJERIA' | translate }}
    </div>
    <div class="col-12">
      <div class="btn-group btn-group-toggle">
        <label class="btn opcion element-click" [ngClass]="{ active: vista == 'recibidos' }">
          <input type="radio" name="options" [(ngModel)]="vista" value="recibidos" (click)="loadRecibidos()" />
          {{ 'MENSAJERIA.TABS.RECIBIDOS' | translate }}
        </label>

        <label class="btn opcion element-click" [ngClass]="{ active: vista == 'enviados' }">
          <input type="radio" name="options" checked [(ngModel)]="vista" value="enviados" (click)="loadEnviados()" />
          {{ 'MENSAJERIA.TABS.ENVIADOS' | translate }}
        </label>
      </div>
    </div>
  </div>
  <div class="row seleccion">
    <div class="col-12 vista">
      <div class="vista-div" [ngSwitch]="vista">
        <app-mensajes-recibidos *ngSwitchCase="'recibidos'" [permisoEnvioMensaje]="permisoEnvioMensaje"></app-mensajes-recibidos>
        <app-mensajes-enviados *ngSwitchCase="'enviados'" [permisoEnvioMensaje]="permisoEnvioMensaje"></app-mensajes-enviados>
      </div>
    </div>
  </div>
</div>

<app-visualizacion-mensaje [permisoEnvioMensaje]="permisoEnvioMensaje"></app-visualizacion-mensaje>
<app-nuevo-mensaje *ngIf="permisoEnvioMensaje"></app-nuevo-mensaje>
