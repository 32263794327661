<nav class="navbar navbar-expand-lg navbar-light">
  <div class="row">
    <div class="titulo align-items-center col">
      <h4 style="width: 100%; text-align: center">
        <strong>{{ nombreUsuario | uppercase }}</strong>
      </h4>
    </div>
  </div>
</nav>

<h3 *ngIf="errorValiacionXml" class="error">{{ 'EXTERNO.ERROR.VALIDACIONXML' | translate }}</h3>
<h3 *ngIf="errorPermisos" class="error">{{ 'EXTERNO.ERROR.PERMISOS' | translate }}</h3>

<router-outlet></router-outlet>
