import { Component, OnInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Cuestionario } from '../../../../core/models/cuestionario.model';
import {
  AlertService,
  CalendarioService,
  CuestionariosService,
  GestionActCuestionarioService,
  LoaderService
} from '../../../../core/services';

/**
 * Componente con la lista del historial de cuestionarios.
 *
 * @author lreverendo
 *
 * @version 01.02.0050
 * @since 01.02.0000
 */
@Component({
  selector: 'app-historial-cuestionario',
  templateUrl: './historial-cuestionario.component.html',
  styleUrls: ['../../cuestionarios.component.css', './historial-cuestionario.component.css']
})
export class HistorialCuestionarioComponent implements OnInit, OnDestroy {
  public cuestionarios: Cuestionario[] = [];
  public tabla: {
    fecha: String;
    cuestionarios: Cuestionario[];
  }[];
  private sub: Subscription;

  constructor(
    public loader: LoaderService,
    private alertService: AlertService,
    private calendarioService: CalendarioService,
    private cuestionariosService: CuestionariosService,
    private gestionActCuestionarioService: GestionActCuestionarioService,
    private translateService: TranslateService,
    private datePipe: DatePipe
  ) {
    // Subscripción en el evento actualizarCalendario.
    this.sub = this.calendarioService.actualizarCalendario.subscribe((_data) => {
      this.ngOnInit();
    });
  }

  public ngOnInit(): void {
    this.tabla = [];
    this.cuestionariosService.getHistorico().subscribe(
      (cuestionarios: Cuestionario[]) => {
        this.cuestionarios = cuestionarios;
        this.crearTabla();
      },
      (error) => {
        this.alertService.lanzarError(error.status);
      }
    );
  }

  public ngOnDestroy(): void {
    // Baja a la subscripción.
    this.sub.unsubscribe();
  }

  public verCuestionario(cuest: Cuestionario): void {
    this.gestionActCuestionarioService.visualizarCuestionario.emit({ cuestionario: cuest });
  }

  public getCurrentIdioma(): string {
    // Se recupera el idioma actual.
    return this.translateService.currentLang;
  }

  private crearTabla(): void {
    // Método para crear la tabla de cuestionarios que incluirá cada fecha y los cuestionarios realizados en esa fecha.
    const fechas: String[] = [];
    const tabla: {
      fecha: String;
      cuestionarios: Cuestionario[];
    }[] = [];

    for (const cuestionario of this.cuestionarios) {
      let fecha = this.datePipe.transform(new Date(cuestionario.fechaActividad), 'dd/MM/yyyy');
      if (this.translateService.currentLang === 'en') {
        fecha = this.datePipe.transform(new Date(cuestionario.fechaActividad), 'MM/dd/yyyy');
      }

      const index = fechas.indexOf(fecha);
      if (index === -1) {
        fechas.push(fecha);
        tabla.push({
          fecha: fecha,
          cuestionarios: [cuestionario]
        });
      } else {
        tabla[index].cuestionarios.push(cuestionario);
      }
    }
    this.tabla = tabla;
  }
}
