import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MensajeriaService, PerfilService, AlertService } from '../../core/services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

declare var $: any;

/**
 * Componente para la gestión de la mensajería.
 *
 * @author lreverendo
 *
 * @version 01.02.0060
 * @since 01.02.0000
 */
@Component({
  selector: 'app-mensajeria',
  templateUrl: 'mensajeria.component.html',
  styleUrls: ['./mensajeria.component.css']
})
export class MensajeriaComponent implements OnInit, OnDestroy {
  public vista = 'recibidos';
  public activo = 'recibidos';

  private subs: Subscription[] = [];

  public operacionPerfilFinalizada = new Subject();
  public permisoEnvioMensaje: Boolean = true;

  constructor(private alertService: AlertService, private mensajeriaService: MensajeriaService, private perfilService: PerfilService) {
    this.subs.push(this.mensajeriaService.actualizarEnviados.subscribe(this.cargarVistaEnviados.bind(this)));
    this.subs.push(
      this.perfilService.perfilPaciente$.pipe(takeUntil(this.operacionPerfilFinalizada)).subscribe((perfilPaciente) => {
        if (perfilPaciente != null) {
          this.permisoEnvioMensaje = perfilPaciente.envioMensajeria;
        }
      }, this.gestionarError.bind(this))
    );
  }

  public ngOnInit(): void {
    $('input:radio[name="options"]').on('click', function () {
      $(this).blur();
    });
  }

  public ngOnDestroy(): void {
    // Baja en todas las subscripciones.
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  public loadEnviados(): void {
    // Se carga o recarga la vista enviados.
    if (this.activo === 'enviados') {
      this.mensajeriaService.reloadEnviados.emit();
    }
    this.activo = 'enviados';
  }

  public loadRecibidos(): void {
    // Se carga o recarga la vista recibidos.
    if (this.activo === 'recibidos') {
      this.mensajeriaService.reloadRecibidos.emit();
    }
    this.activo = 'recibidos';
  }

  private cargarVistaEnviados(): void {
    this.loadEnviados();
    this.vista = 'enviados';
  }

  private gestionarError(error: HttpErrorResponse): void {
    this.alertService.lanzarError(error.status);
  }
}
