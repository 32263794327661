var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DispositivoGenerico } from '../dispositivo-generico.model';
import { CustomValidators } from '../../../../../shared/validators/custom-validators';
import * as i0 from "@angular/core";
/**
 * Componente que representa al dispositivo Coagulómetro.
 *
 * @author lreverendo
 *
 * @version 01.02.0070
 * @since 01.02.0000
 */
var CoagulometroComponent = /** @class */ (function (_super) {
    __extends(CoagulometroComponent, _super);
    function CoagulometroComponent() {
        return _super.call(this) || this;
    }
    CoagulometroComponent.prototype.ngOnInit = function () {
        // Se busca la variable INR.
        this.inr = this.variablesClinicas.find(function (v) { return v.alias === 'INR'; });
        if (this.inr) {
            this.forma.addControl('inr', new FormControl('', [
                Validators.required,
                Validators.pattern(this.getPatronFormato(this.inr.formato)),
                CustomValidators.porRango(this.inr.min, this.inr.max)
            ]));
        }
    };
    CoagulometroComponent.prototype.getData = function () {
        var result = [];
        if (this.inr) {
            var inr = this.forma.value.inr.replace(',', '.');
            if (Number(inr) === NaN) {
                return [];
            }
            this.inr.valor = inr;
            result.push(this.inr);
        }
        return result;
    };
    CoagulometroComponent.ngInjectableDef = i0.defineInjectable({ factory: function CoagulometroComponent_Factory() { return new CoagulometroComponent(); }, token: CoagulometroComponent, providedIn: "root" });
    return CoagulometroComponent;
}(DispositivoGenerico));
export { CoagulometroComponent };
