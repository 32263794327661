var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DispositivoGenerico } from '../dispositivo-generico.model';
import { CustomValidators } from '../../../../../shared/validators/custom-validators';
import * as i0 from "@angular/core";
/**
 *  Componente que representa al dispositivo Báscula.
 *
 * @author lreverendo
 *
 * @version 01.02.0070
 * @since 01.02.0000
 */
var BasculaComponent = /** @class */ (function (_super) {
    __extends(BasculaComponent, _super);
    function BasculaComponent() {
        return _super.call(this) || this;
    }
    BasculaComponent.prototype.ngOnInit = function () {
        // Se busca la variable peso.
        this.peso = this.variablesClinicas.find(function (v) { return v.alias === 'PESO'; });
        if (this.peso) {
            this.forma.addControl('peso', new FormControl('', [
                Validators.required,
                Validators.pattern(this.getPatronFormato(this.peso.formato)),
                CustomValidators.porRango(this.peso.min, this.peso.max)
            ]));
        }
    };
    BasculaComponent.prototype.getData = function () {
        var result = [];
        if (this.peso) {
            var peso = this.forma.value.peso.replace(',', '.');
            if (Number(peso) === NaN) {
                return [];
            }
            this.peso.valor = peso;
            result.push(this.peso);
        }
        return result;
    };
    BasculaComponent.ngInjectableDef = i0.defineInjectable({ factory: function BasculaComponent_Factory() { return new BasculaComponent(); }, token: BasculaComponent, providedIn: "root" });
    return BasculaComponent;
}(DispositivoGenerico));
export { BasculaComponent };
