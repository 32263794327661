import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AutenticacionService } from './autenticacion.service';
import { AppConfigService } from './app-config.service';
import { sprintf } from 'sprintf-js';
import { SSO } from '../models';

/**
 * Servicio para la gestión de la vinculacion con dispositivos.
 *
 * @author lreverendo
 * @author aarodriguezo
 *
 * @version 01.02.0250
 * @since 01.02.0100
 */
@Injectable({ providedIn: 'root' })
export class VinculacionDispositivosService {
  private ssoVinculacion = null;
  private httpheadersPost = new HttpHeaders().set('Content-Type', 'application/json;charset=UTF-8');

  constructor(private http: HttpClient, private autenticacionService: AutenticacionService) {}

  public setSSOVinculacion(bind_result: string, error: string, nombreSistema: string) {
    this.ssoVinculacion = new SSO(bind_result, error, nombreSistema);
  }

  public getSSOVinculacion(): SSO {
    return this.ssoVinculacion;
  }

  public recuperarUrlVinculacionDispositivo(nombreSistema: string): Observable<Object> {
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const codCentro = this.autenticacionService.getCentroPaciente();    
    const url = sprintf(AppConfigService.urls.vinculacionDispositivos, dniPaciente, codCentro, nombreSistema, AppConfigService.listaVinculacionDispositivos[nombreSistema].urlRedireccion);
    return this.http.get<Object>(url);
  }

  /* TODO cambiar el idSistema fijo de aquí y desde dispositivos.component.ts mandar el seleccionado con el botón*/
  public vincularDispositivoSuccess(body: any): Observable<Object> {
    const nombreSistema = this.ssoVinculacion.nombreSistema;
    this.ssoVinculacion = null;
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const codCentro = this.autenticacionService.getCentroPaciente();
    const url = sprintf(AppConfigService.urls.vinculacionDispositivosSuccess, dniPaciente, codCentro, AppConfigService.listaVinculacionDispositivos[nombreSistema].idSistema);
    return this.http.post<Boolean>(url, body, { headers: this.httpheadersPost });
  }
}

