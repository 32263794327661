var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DispositivoGenerico } from '../dispositivo-generico.model';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';
import * as i0 from "@angular/core";
/**
 *  Componente que representa al dispositivo Termómetro.
 *
 * @author lreverendo
 *
 * @version 01.02.0070
 * @since 01.02.0000
 */
var TermometroComponent = /** @class */ (function (_super) {
    __extends(TermometroComponent, _super);
    function TermometroComponent() {
        return _super.call(this) || this;
    }
    TermometroComponent.prototype.ngOnInit = function () {
        // Se busca la variable de temperatura.
        this.temp = this.variablesClinicas.find(function (v) { return v.alias === 'T'; });
        if (this.temp) {
            this.forma.addControl('temp', new FormControl('', [
                Validators.required,
                Validators.pattern(this.getPatronFormato(this.temp.formato)),
                CustomValidators.porRango(this.temp.min, this.temp.max)
            ]));
        }
    };
    TermometroComponent.prototype.getData = function () {
        var result = [];
        if (this.temp) {
            var temp = this.forma.value.temp.replace(',', '.');
            if (Number(temp) === NaN) {
                return [];
            }
            this.temp.valor = temp;
            result.push(this.temp);
        }
        return result;
    };
    TermometroComponent.ngInjectableDef = i0.defineInjectable({ factory: function TermometroComponent_Factory() { return new TermometroComponent(); }, token: TermometroComponent, providedIn: "root" });
    return TermometroComponent;
}(DispositivoGenerico));
export { TermometroComponent };
