import { Component } from '@angular/core';

/**
 * Componente para el error de almacenamiento.
 *
 * @author lreverendo
 *
 * @version 01.02.0000
 * @since 01.02.0000
 */
@Component({
  selector: 'app-error-almacenamiento',
  templateUrl: './errorAlmacenamiento.component.html',
  styleUrls: ['./errorAlmacenamiento.component.css']
})
export class ErrorAlmacenamientoComponent {
  constructor() {}
}
