import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { DispositivoService } from '../../core/services';
import { Dispositivo } from '../../core/models/dispositivo.model';
import { HistorialBiomedidaComponent } from './views/historial-biomedida/historial-biomedida.component';
import { RegistroBiomedidaComponent } from './views/registro-biomedida/registro-biomedida.component';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PerfilService } from '../../core/services/perfil.service';
import { ActividadesPrescritas } from '../../core/models/actividadesPrescritas.model';

declare var $: any;

/**
 *  Componente para la gestión de la biomedidas.
 *
 * @author lreverendo
 * @author aarodriguezo
 *
 * @version 01.02.0200
 * @since 01.02.0000
 */
@Component({
  selector: 'app-biomedidas',
  templateUrl: './biomedidas.component.html',
  styleUrls: ['./biomedidas.component.css']
})
export class BiomedidasComponent implements OnInit, OnDestroy {
  @ViewChild(HistorialBiomedidaComponent) historial: HistorialBiomedidaComponent;
  @ViewChild(RegistroBiomedidaComponent) registro: RegistroBiomedidaComponent;

  public vista = 'registro';
  public activo = 'registro';

  public dispositivoSeleccionado: Dispositivo;

  public dispositivosFiltrados: Dispositivo[] = [];

  public operacionFinalizada = new Subject();

  private subs: Subscription[] = [];

  public actividadesPrescritas: ActividadesPrescritas;

  public biomedidasPrescritasAVisualizar: Dispositivo[] = [];

  public dispositivosNoPrescritos: Dispositivo[] = [];

  

  constructor(private dispositivoService: DispositivoService, private perfilService: PerfilService) {
    this.subs.push(
      this.dispositivoService.dispositivos$.pipe(takeUntil(this.operacionFinalizada)).subscribe((dispositivos: Dispositivo[]) => {
        if (dispositivos == null || dispositivos.length === 0) {
          this.dispositivoService.cargarDispositivos();
        } else {
          this.operacionFinalizada.next();
          this.dispositivosFiltrados = dispositivos;
          this.dispositivoSeleccionado = this.dispositivosFiltrados[0];
        }
        this.actividadesPrescritas = this.perfilService.obtenerActividadesPrescritas();    
        this.destacarBiomedidasProtocolo();

      })
    );
  }

  public ngOnInit(): void {
    $('input:radio[name="options"]').on('click', function () {
      $(this).blur();
    });
  }

  public ngOnDestroy(): void {
    // Baja en todas las subscripciones.
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  public loadRegistro(): void {
    // Se carga o recarga la vista registro.
    if (this.activo === 'registro') {
      if (this.dispositivoSeleccionado === this.dispositivosFiltrados[0]) {
        this.registro.limpiar();
      } else {
        this.dispositivoSeleccionado = this.dispositivosFiltrados[0];
      }
    }
    this.activo = 'registro';
  }

  public loadHistorial(): void {
    // Se carga o recarga la vista historial.
    if (this.activo === 'historial') {
      if (this.dispositivoSeleccionado === this.dispositivosFiltrados[0]) {
        this.historial.ngOnChanges();
      } else {
        this.dispositivoSeleccionado = this.dispositivosFiltrados[0];
      }
    }
    this.activo = 'historial';
  }

  public seleccionarDispositivo(dispositivo: Dispositivo): void {
    this.dispositivoSeleccionado = dispositivo;
    if ($(window).width() < 418 || (window.orientation != null && window.orientation !== 0 && $(window).height() < 418)) {
      $('#filtros').collapse('hide');
    }
  }

  public abrirFiltros(): void {
    $('#filtros').collapse('show');
  }

  private destacarBiomedidasProtocolo(): void {
    // recorrer actividadesprescritas y comparar con dispositivos filtrados, 
    // cuando coincida el nombre se guarda en biomedidas prescritas,
    // si no esta el nombre aparece en dispositivos no prescritos

    if (this.actividadesPrescritas === null || this.actividadesPrescritas.biomedidas.length === 0) {
      this.dispositivosFiltrados.forEach(dispositivo => {
        this.dispositivosNoPrescritos.push(dispositivo);
      });
    }    
    else {
      this.dispositivosFiltrados.forEach(dispositivo => {
        let  esPrescrito = 0;
        this.actividadesPrescritas.biomedidas.forEach(actividad => {                  
          if (dispositivo.sensor.actividad.includes(actividad)){          
            if ( !this.biomedidasPrescritasAVisualizar.includes(dispositivo) ){
              this.biomedidasPrescritasAVisualizar.push(dispositivo);
            }
            esPrescrito = 1;
          }      
  
        });
        if (esPrescrito === 0){
          this.dispositivosNoPrescritos.push(dispositivo);
        }
  
      });
    }
    if (this.actividadesPrescritas  != null && this.biomedidasPrescritasAVisualizar.length > 0){  
      this.seleccionarDispositivo(this.biomedidasPrescritasAVisualizar[0]);      
    }

  }

}

