<div class="modal fade" id="recuperacion">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h3>
          <strong> {{ 'LOGIN.RECUPERACION' | translate }} </strong>
        </h3>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <div class="modal-separador"></div>

      <loader></loader>

      <!-- Modal body -->
      <div class="modal-body" *ngIf="exito" data-simplebar>
        <h5>{{ 'LOGIN.EXITO' | translate }}</h5>
      </div>

      <!-- Modal body -->
      <div class="modal-body" *ngIf="!exito" data-simplebar>
        <div class="mensaje" *ngIf="!pin" text-wrap>
          {{ 'LOGIN.MSJTEL' | translate }}
        </div>
        <div class="mensaje" *ngIf="pin" text-wrap>
          {{ 'LOGIN.MSJRECUPIN' | translate }}
          <br />
          {{ 'LOGIN.SUBMSJRECUPIN' | translate }}
        </div>
        <form [formGroup]="forma">
          <ul class="list-group">
            <li class="list-group-item form-group" style="padding-top: 0">
              <div>
                <input
                  class="input-placeholder"
                  id="usuario"
                  type="text"
                  maxlength="9"
                  formControlName="usuario"
                  tabindex="1"
                  [readonly]="pin"
                />
                <label class="placeholder" *ngIf="forma.controls['usuario'].value == ''" [ngClass]="{ readOnly: pin }">
                  {{ 'LOGIN.USUARIO' | translate }}
                </label>
              </div>
              <label
                text-wrap
                class="error form-control-feedback"
                *ngIf="forma.controls['usuario'].touched && forma.controls['usuario'].errors?.required && !errorServidor"
              >
                {{ 'LOGIN.ERRORES.USUARIO.REQUERIDO' | translate }}
              </label>
            </li>
            <li class="list-group-item form-group" *ngIf="!pin">
              <div>
                <input id="telefono" type="tel" appTelefono formControlName="telefono" tabindex="1" />
                <label class="placeholder" *ngIf="forma.controls['telefono'].value == ''">
                  {{ 'LOGIN.TELEFONO' | translate }}
                </label>
              </div>

              <label
                text-wrap
                class="error form-control-feedback"
                *ngIf="forma.controls['telefono'].touched && forma.controls['telefono'].errors?.required && !errorServidor"
              >
                {{ 'LOGIN.ERRORES.TELEFONO.REQUERIDO' | translate }}
              </label>
              <label
                text-wrap
                class="error form-control-feedback"
                *ngIf="forma.controls['telefono'].touched && forma.controls['telefono'].errors?.credenciales && !errorServidor"
              >
                {{ 'LOGIN.ERRORES.TELEFONO.INCORRECTO' | translate }}
              </label>
              <label text-wrap class="error form-control-feedback" *ngIf="errorServidor">
                {{ 'GENERAL.ERRORES.SERVIDOR' | translate }}
              </label>
            </li>
            <li class="list-group-item form-group" *ngIf="pin">
              <div>
                <input id="pin" type="password" formControlName="pin" tabindex="2" autofocus />
                <label class="placeholder" *ngIf="forma.controls['pin'].value == ''">
                  {{ 'LOGIN.PIN' | translate }}
                </label>
              </div>
              <label
                text-wrap
                class="error form-control-feedback"
                *ngIf="forma.controls['pin'].touched && forma.controls['pin'].errors?.required && !errorServidor"
              >
                {{ 'LOGIN.ERRORES.PIN.REQUERIDO' | translate }}
              </label>
              <label
                text-wrap
                class="error form-control-feedback"
                *ngIf="forma.controls['pin'].touched && forma.controls['pin'].errors?.pinincorrecto && !errorServidor"
              >
                {{ 'LOGIN.ERRORES.PIN.INCORRECTO' | translate }}
              </label>
            </li>
            <li class="list-group-item form-group" *ngIf="pin">
              <div>
                <input type="password" formControlName="contrasena" tabindex="2" />
                <label class="placeholder" *ngIf="forma.controls['contrasena'].value == ''">
                  {{ 'LOGIN.CONTRASENA' | translate }}
                </label>
              </div>
              <label
                text-wrap
                class="error form-control-feedback"
                *ngIf="forma.controls['contrasena'].touched && forma.controls['contrasena'].errors?.required && !errorServidor"
              >
                {{ 'LOGIN.ERRORES.CONTRASENA.REQUERIDO' | translate }}
              </label>
            </li>
            <li class="list-group-item form-group" *ngIf="pin">
              <div>
                <input type="password" formControlName="repeticion" tabindex="2" />
                <label class="placeholder" *ngIf="forma.controls['repeticion'].value == ''">
                  {{ 'LOGIN.REPETICION' | translate }}
                </label>
              </div>
              <label
                text-wrap
                class="error form-control-feedback"
                *ngIf="forma.controls['repeticion'].touched && forma.controls['repeticion'].errors?.required && !errorServidor"
              >
                {{ 'LOGIN.ERRORES.REPETICION.REQUERIDO' | translate }}
              </label>
              <label text-wrap class="error form-control-feedback" *ngIf="forma.errors && forma.errors['noiguales'] && !errorServidor">
                {{ 'LOGIN.ERRORES.REPETICION.NOIGUALES' | translate }}
              </label>
              <label text-wrap class="error form-control-feedback" *ngIf="errorServidor">
                {{ 'GENERAL.ERRORES.SERVIDOR' | translate }}
              </label>
            </li>
          </ul>
        </form>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button
          type="submit"
          tabindex="1"
          class="btn btn-primary btn-lg"
          *ngIf="!pin && !exito"
          (click)="enviar()"
          [disabled]="
            forma.controls['telefono'].errors?.required ||
            forma.controls['usuario'].errors?.required ||
            forma.controls['telefono'].errors?.pattern
          "
        >
          {{ 'LOGIN.ENVIAR' | translate }}
        </button>
        <button
          type="submit"
          tabindex="2"
          class="btn btn-primary btn-lg"
          *ngIf="pin && !exito"
          (click)="confirmarPin()"
          [disabled]="!forma.valid"
        >
          {{ 'LOGIN.CONTINUAR' | translate }}
        </button>
        <button type="submit" class="btn btn-primary btn-lg" data-dismiss="modal" *ngIf="exito">
          {{ 'GENERAL.CERRAR' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
