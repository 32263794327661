import { Component, OnInit } from '@angular/core';
import { GestionActAnaliticaService } from 'src/app/core/services';
import { ExtraccionAnalitica } from 'src/app/core/models/extraccionAnalitica.model';

declare var $: any;

/**
 *
 * @author dvila
 * @author lreverendo
 *
 * @version 01.02.0080
 * @since 01.02.0030
 */

@Component({
  selector: 'app-visualizacion-extraccion-analitica',
  templateUrl: './visualizacion-extraccion-analitica.component.html',
  styleUrls: ['./visualizacion-extraccion-analitica.component.css']
})
export class VisualizacionExtraccionAnaliticaComponent implements OnInit {
  public analitica: ExtraccionAnalitica = new ExtraccionAnalitica();

  constructor(private gestionActAnliticaService: GestionActAnaliticaService) {}

  ngOnInit() {
    this.gestionActAnliticaService.visualizarAnalitica.subscribe((analitica: ExtraccionAnalitica) => {
      this.analitica = analitica;
      $('#visualizacion-analitica').modal('show');
    });
  }
}
