import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormControl, Validators, FormArray, AbstractControl, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AlertService, PerfilService, LoaderService } from '../../../../core/services';
import { Perfil } from '../../../../core/models';

/**
 * Componente para la gestión de los datos del paciente.
 *
 * @author lreverendo
 *
 * @version 01.02.0030
 * @since 01.02.0000
 */
@Component({
  selector: 'app-datos',
  templateUrl: './datos.component.html',
  styleUrls: ['../../perfil.component.css', './datos.component.css']
})
export class DatosComponent implements OnInit, OnDestroy {
  public perfil: Perfil;
  public formulario: FormGroup;
  private errorSubscription: Subscription;
  private perfilPacienteSubscription: Subscription;

  constructor(
    public loader: LoaderService,
    private alertService: AlertService,
    private perfilService: PerfilService,
    private translateService: TranslateService,
    private fb: FormBuilder
  ) {}

  public ngOnInit(): void {
    // Se inicializa el formulario.
    this.formulario = this.fb.group({
      email: ['', Validators.email],
      movil: ['', [Validators.pattern('([0-9]{9})'), Validators.required]],
      localePaciente: ['', Validators.required],
      paginaInicio: ['', Validators.required],
      tipoNotificacionesSuscritas: [[]]
    });
    this.reload();
  }

  public ngOnDestroy(): void {
    if (this.errorSubscription) {
      this.errorSubscription.unsubscribe();
    }

    if (this.perfilPacienteSubscription) {
      this.perfilPacienteSubscription.unsubscribe();
    }
  }

  public reload(): void {
    this.errorSubscription = this.perfilService.error$.subscribe(this.gestionarError.bind(this));
    this.perfilPacienteSubscription = this.perfilService.perfilPaciente$.subscribe(this.cargarDatosPerfil.bind(this));
  }

  public getCurrentIdioma(): string {
    // Se recupera el idioma actual.
    return this.translateService.currentLang;
  }

  public guardarCambios(): void {
    const { email, movil, localePaciente, paginaInicio } = this.formulario.value;

    let perfilDatosNuevos = new Perfil();

    perfilDatosNuevos = this.perfil;

    perfilDatosNuevos.email = email;
    perfilDatosNuevos.movil = movil;
    perfilDatosNuevos.localePaciente = localePaciente;
    perfilDatosNuevos.paginaInicio = paginaInicio;

    this.actualizarTipoNotificacionesSuscritas(perfilDatosNuevos);


    // Se llama al método encargado de editar el perfil.
    this.perfilService.editarPerfil(perfilDatosNuevos).subscribe(
      () => this.gestionarExito(),
      (error) =>  {
        this.gestionarError(error);
        this.limpiar();
      }
    );

    this.formulario.markAsPristine();
  }

  public limpiar(): void {
    const tipoNotificacionesSuscritas = this.perfil.tipoNotificacionesSuscritas.map((tipoNotificacion) => tipoNotificacion.suscrita);

    // Reseteo del formulario.
    this.formulario.reset({
      email: this.perfil.email,
      movil: this.perfil.movil,
      localePaciente: this.perfil.localePaciente,
      paginaInicio: this.perfil.paginaInicio,
      tipoNotificacionesSuscritas: tipoNotificacionesSuscritas
    });
  }

  public getNotificacionesSuscritas(): AbstractControl[] {
    const notificaciones = <FormArray>this.formulario.get('tipoNotificacionesSuscritas');
    return notificaciones.controls;
  }

  public getTituloNotificacion(codigo: string): String {
    let titulo: string;

    switch (codigo) {
      case 'ActividadCalendario':
        titulo = 'DATOS.NOTIFICACIONES.CALENDARIO';
        break;
      case 'Mensajeria_TELEA':
        titulo = 'DATOS.NOTIFICACIONES.MENSAJERIA';
        break;
      case 'VideoConferencia_TELEA':
        titulo = 'DATOS.NOTIFICACIONES.VIDEOCONFERENCIA';
        break;
      default:
        titulo = 'DATOS.NOTIFICACIONES.CALENDARIO';
        break;
    }

    return this.translateService.instant(titulo);
  }

  private cargarDatosPerfil(perfilPaciente: Perfil): void {
    if (perfilPaciente != null) {
      this.perfil = perfilPaciente;
      this.perfil.direccion = perfilPaciente.direccion.filter((dir) => dir.direccion1 != null);

      if (perfilPaciente.localePaciente === null) {
        perfilPaciente.localePaciente = this.getCurrentIdioma();
      }
      if (perfilPaciente.paginaInicio === null) {
        perfilPaciente.paginaInicio = '/calendario';
      }

      const { email, movil, localePaciente, paginaInicio } = perfilPaciente;
      // Se actualizan los datos del paciente.
      this.formulario.patchValue({ email, movil, localePaciente, paginaInicio });

      this.resetTipoNotificacionesSuscritas();
    }
  }

  private actualizarTipoNotificacionesSuscritas(perfil: Perfil): void {
    const { tipoNotificacionesSuscritas } = this.formulario.value;

    tipoNotificacionesSuscritas.forEach((element, idx) => {
      perfil.tipoNotificacionesSuscritas[idx].suscrita = element;
    });
  }

  private resetTipoNotificacionesSuscritas(): void {
    const formArray = new FormArray([]);

    this.perfil.tipoNotificacionesSuscritas.forEach((tipoNotificacion) => {
      const checkbox = new FormControl(false);
      checkbox.setValue(tipoNotificacion.suscrita);
      formArray.push(checkbox);
    });
    this.formulario.setControl('tipoNotificacionesSuscritas', formArray);
  }

  private gestionarExito(): void {
    this.alertService.lanzarExito(this.translateService.instant('DATOS.NOTIFICACION.SUCCESS'));
  }

  private gestionarError(error: HttpErrorResponse): void {
    this.alertService.lanzarError(error.status);
  }
}
