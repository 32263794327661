import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from './app-config.service';
import { sprintf } from 'sprintf-js';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
/**
 * Servicio para la gestión de la autenticación en el API.
 *
 * @author lreverendo
 *
 * @version 01.02.0002
 * @since 01.02.0000
 */
var LoginService = /** @class */ (function () {
    function LoginService(http) {
        this.http = http;
        this.httpheadersPost = new HttpHeaders().set('Content-Type', 'application/json;charset=UTF-8');
    }
    LoginService.prototype.prepararLogin = function (body) {
        var url = sprintf(AppConfigService.urls.prepararLogin);
        return this.http.post(url, body, { headers: this.httpheadersPost });
    };
    LoginService.prototype.enviarPin = function (body) {
        var url = sprintf(AppConfigService.urls.enviarPin);
        var acceso = this.http.post(url, body, { headers: this.httpheadersPost });
        return acceso;
    };
    LoginService.prototype.logout = function () {
        var url = sprintf(AppConfigService.urls.logout);
        return this.http.post(url, { headers: this.httpheadersPost });
    };
    LoginService.prototype.prepararRecuperacion = function (body) {
        var url = sprintf(AppConfigService.urls.prepararRecuperacion);
        return this.http.post(url, body, { headers: this.httpheadersPost });
    };
    LoginService.prototype.recuperar = function (body) {
        var url = sprintf(AppConfigService.urls.recuperar);
        return this.http.post(url, body, { headers: this.httpheadersPost });
    };
    LoginService.prototype.validarCertificado = function (body) {
        var url = sprintf(AppConfigService.urls.validarCertificado);
        return this.http.post(url, body, { headers: this.httpheadersPost });
    };
    LoginService.prototype.recuperarUrlEsaude = function () {
        var url = sprintf(AppConfigService.urls.urlEsaude, AppConfigService.urlEsaudeRedireccion);
        return this.http.get(url);
    };
    LoginService.prototype.loginEsaude = function (body) {
        var url = sprintf(AppConfigService.urls.loginEsaude, AppConfigService.urlEsaudeRedireccion);
        return this.http.post(url, body, { headers: this.httpheadersPost });
    };
    LoginService.prototype.recuperarUrlLogoutEsaude = function (params) {
        var body = {
            urlRedireccion: AppConfigService.urlLogoutEsaudeRedireccion + params
        };
        var url = sprintf(AppConfigService.urls.urlLogoutEsaude);
        return this.http.post(url, body, { headers: this.httpheadersPost });
    };
    LoginService.ngInjectableDef = i0.defineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.inject(i1.HttpClient)); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
export { LoginService };
