import { EventEmitter } from '@angular/core';
import { AlertService } from './alert.service';
import { AnaliticaService } from './analitica.service';
import { PermisosService } from './permisos.service';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { LocaleTextoPipe } from '../../shared/pipes/locale-texto/locale-texto';
import * as i0 from "@angular/core";
import * as i1 from "./alert.service";
import * as i2 from "./analitica.service";
import * as i3 from "./permisos.service";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../shared/pipes/locale-texto/locale-texto";
/**
 * Servicio para la gestión del actividades de tipo analítica.
 *
 * @author lreverendo
 *
 * @version 01.02.0050
 * @since 01.02.0050
 */
var GestionActAnaliticaService = /** @class */ (function () {
    function GestionActAnaliticaService(alertService, analiticaService, permisoService, translateService, localeTexto) {
        this.alertService = alertService;
        this.analiticaService = analiticaService;
        this.permisoService = permisoService;
        this.translateService = translateService;
        this.localeTexto = localeTexto;
        /**
         * Evento para la creación de una analítica
         */
        this.registroAnalitica = new EventEmitter();
        this.visualizarAnalitica = new EventEmitter();
    }
    GestionActAnaliticaService.prototype.abrirAnalitica = function (actividad, fechaCaducidad) {
        switch (actividad.estado) {
            case 'VACIO':
                if (moment(actividad.fechaInicioConMargen).toDate() > new Date()) {
                    this.analiticaFutura(actividad);
                }
                else if (this.permisoService.getPermisos().escritura) {
                    this.crearAnalitica(actividad);
                }
                break;
            case 'ADHERIDO':
                this.abrirVisualizacionAnalitica(actividad);
                break;
            case 'AUSENCIA':
                this.analiticaCaducada(actividad, fechaCaducidad);
                break;
        }
    };
    GestionActAnaliticaService.prototype.crearAnalitica = function (actividad) {
        this.registroAnalitica.emit(actividad);
    };
    GestionActAnaliticaService.prototype.abrirVisualizacionAnalitica = function (actividad) {
        var _this = this;
        this.analiticaService.getExtraccionAnalitica(actividad.idInstancia).subscribe(function (analitica) {
            _this.visualizarAnalitica.emit(analitica);
        });
    };
    GestionActAnaliticaService.prototype.analiticaCaducada = function (actividad, fechaCaducidad) {
        var buttons = [];
        // Si la actividad ha caducado hace menos de 30 días.
        if (moment(actividad.fechaInicioSinMargen).toDate() >= fechaCaducidad && this.permisoService.getPermisos().escritura) {
            buttons = [
                {
                    texto: this.translateService.instant('CALENDARIO.ANALITICA.NOTIFICACION.REALIZAR'),
                    type: 'button',
                    accion: 'crearAnalitica',
                    params: actividad,
                    cerrar: true
                }
            ];
        }
        var data = {
            origen: this,
            titulo: this.localeTexto.transform(actividad.titulo, this.getCurrentIdioma()),
            mensaje: this.translateService.instant('CALENDARIO.ANALITICA.NOTIFICACION.CADUCADO'),
            buttons: buttons,
            cerrar: true
        };
        this.alertService.modal.emit(data);
    };
    GestionActAnaliticaService.prototype.analiticaFutura = function (actividad) {
        var ahora = new Date();
        var params = actividad;
        var buttons = [];
        ahora.setDate(ahora.getDate() + 1);
        ahora.setHours(0, 0, 0, 0);
        // Si la actividad está programada para el día de hoy y se tienen permisos de escritura
        if (!(moment(actividad.fechaInicioConMargen).toDate() >= ahora) && this.permisoService.getPermisos().escritura) {
            buttons = [
                {
                    texto: this.translateService.instant('CALENDARIO.ANALITICA.NOTIFICACION.REALIZAR'),
                    type: 'button',
                    accion: 'crearAnalitica',
                    params: params,
                    cerrar: true
                }
            ];
        }
        var data = {
            origen: this,
            titulo: this.localeTexto.transform(actividad.titulo, this.getCurrentIdioma()),
            mensaje: this.translateService.instant('CALENDARIO.ANALITICA.NOTIFICACION.FUTURO'),
            buttons: buttons,
            cerrar: true
        };
        this.alertService.modal.emit(data);
    };
    GestionActAnaliticaService.prototype.getCurrentIdioma = function () {
        // Se recupera el idioma actual.
        return this.translateService.currentLang;
    };
    GestionActAnaliticaService.ngInjectableDef = i0.defineInjectable({ factory: function GestionActAnaliticaService_Factory() { return new GestionActAnaliticaService(i0.inject(i1.AlertService), i0.inject(i2.AnaliticaService), i0.inject(i3.PermisosService), i0.inject(i4.TranslateService), i0.inject(i5.LocaleTextoPipe)); }, token: GestionActAnaliticaService, providedIn: "root" });
    return GestionActAnaliticaService;
}());
export { GestionActAnaliticaService };
