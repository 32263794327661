import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

/**
 * Pipe que devuelve el string pasado en mayúsculas.
 *
 * @author lreverendo
 *
 * @version 01.02.0000
 * @since 01.02.0000
 */
@Pipe({
  name: 'semana'
})
export class SemanaPipe implements PipeTransform {
  public transform(value: string) {
    const fecha = moment(value);
    return fecha.week();
  }
}
