export class FiltroActividad {
  biomedida: boolean;
  cuestionario: boolean;
  personal: boolean;
  videoconferencia: boolean;
  analitica: boolean;

  constructor(biomedida: boolean, cuestionario: boolean, personal: boolean, videoconferencia: boolean, analitica: boolean) {
    this.biomedida = biomedida;
    this.cuestionario = cuestionario;
    this.personal = personal;
    this.videoconferencia = videoconferencia;
    this.analitica = analitica;
  }

  todos(): boolean {
    return this.biomedida && this.cuestionario && this.personal && this.videoconferencia;
  }

  ninguno(): boolean {
    return !this.biomedida && !this.cuestionario && !this.personal && !this.videoconferencia;
  }
}
