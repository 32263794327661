import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { LoaderService } from '../services';

/**
 * Servicio que controla el redireccionamiento cuando ocurre un error
 *
 * @author lreverendo
 *
 * @version 01.02.0002
 * @since 01.02.0000
 */
@Injectable({ providedIn: 'root' })
export class ErrorGuardService implements CanActivate {
  constructor(private router: Router, private loader: LoaderService) {}

  public canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      try {
        if (sessionStorage) {
          this.loader.redireccionar.emit();
          this.router.navigate(['']);
          observer.next(false);
        }
      } catch (e) {
        observer.next(true);
      }
    });
  }
}
