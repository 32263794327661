import { Component, HostListener, Input, ViewChild, OnChanges } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { NgbDatepickerI18n, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AlertService, BiomedidasService, CalendarioService, Operation } from '../../../../core/services';
import { CustomDatepickerI18n, CustomNgbDateParserFormatter } from '../../../../shared/directives/custom-datepicker/custom-datepicker';
import { Dispositivo, Periodo, VariableClinica, Actividad } from '../../../../core/models';
import { DispositivoGenerico } from '../../components/dispositivos/dispositivo-generico.model';
import { Fecha } from '../../../../core/models/fecha.model';
import { CustomValidators } from '../../../../shared/validators/custom-validators';

declare var $: any;

/**
 *  Componente para el registro de biomedidas de cualquier dispositivo.
 *
 * @author lreverendo
 * @author fjsalgado
 * @author dvila
 *
 * @version 01.02.0080
 * @since 01.02.0000
 */
@Component({
  selector: 'app-registro-biomedida [dispositivo]',
  templateUrl: 'registro-biomedida.component.html',
  styleUrls: ['../../biomedidas.component.css', './registro-biomedida.component.css'],
  providers: [
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    { provide: NgbDateParserFormatter, useClass: CustomNgbDateParserFormatter }
  ]
})
export class RegistroBiomedidaComponent implements OnChanges {
  @Input() dispositivo: Dispositivo;
  @Input() actividad: Actividad;

  @ViewChild('t') public tooltip: NgbTooltip;
  @ViewChild('disp') public disp: DispositivoGenerico;

  public forma: FormGroup;
  public hijoValid: boolean;
  public variablePrandial: VariableClinica = null;
  public basal = false;
  public prePrandial = false;
  public posPrandial = false;
  public mask = [/[0-2]/, /\d/, ':', /[0-5]/, /\d/];
  public formularioHijo: boolean;
  public periodos: Periodo[] = [];
  public disabled = false;
  public operation = Operation;
  private variablesClinicas: VariableClinica[] = [];

  private hoy: Fecha;
  private hora: string;
  private periodoActual: Periodo;
  private clickTooltip = false;

  constructor(
    private alertService: AlertService,
    private biomedidasService: BiomedidasService,
    private calendarioService: CalendarioService,
    private translateService: TranslateService,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder
  ) {}

  public ngOnChanges(): void {
    this.iniciarVariables();

    this.forma.get('hora').valueChanges.subscribe(this.changeRegex.bind(this));
    this.forma.controls['hora'].markAsTouched();
    if (this.forma.controls['periodo']) {
      this.forma.controls['periodo'].valueChanges.subscribe((data) => {
        this.variablePrandial = this.calcularVariablePrandial(data.alias);
      });
    }
  }

  private iniciarVariables(): void {
    this.calcularFecha();
    this.calcularVariablesClinicas();

    // Se buscan los periodos.
    this.periodos = this.dispositivo.sensor.periodos;
    // Se comprueba si existen las variables basal, prePrandial y posPrandial
    this.basal = this.variablesClinicas.find((v) => v.alias.indexOf('BASAL') > -1) != null;
    this.prePrandial = this.variablesClinicas.find((v) => v.alias.indexOf('PRE') > -1) != null;
    this.posPrandial = this.variablesClinicas.find((v) => v.alias.indexOf('POS') > -1) != null;

    // Inicialización del formulario.
    this.forma = this.formBuilder.group(
      {
        fecha: [this.hoy, Validators.required],
        hora: [this.hora, [Validators.required, Validators.pattern('^([01][0-9]|2[0-3]):[0-5][0-9]$')]]
      },
      {
        validator: [CustomValidators.fechaInferior, CustomValidators.fechaSuperior]
      }
    );

    // Calcula los periodos
    this.calcularPeriodos();
  }

  private calcularPeriodos(): void {
    if (this.periodos) {
      this.periodoActual = this.calcularPeriodoActual();
      // Se calcula la variable prandial por defecto teniendo en cuenta el periodo actual
      this.variablePrandial = this.calcularVariablePrandial(this.periodoActual.alias);

      this.forma.addControl('periodo', new FormControl(this.periodoActual, [Validators.required]));
      this.forma.addControl('observaciones', new FormControl('', [Validators.pattern('^([ña-záéíóúA-ZÁÉÍÓÚ0-9 %/()¿?¡!ºª.,;:+\n]*)$')]));
    } else {
      // Se calcula la variable prandial por defecto
      this.variablePrandial = this.calcularVariablePrandial('');
    }
  }

  @HostListener('document:click', ['$event'])
  public clickOutside(event): void {
    if (
      this.clickTooltip &&
      !event.target.classList.contains('icon-info') &&
      !event.target.parentNode.classList.contains('tooltip-inner') &&
      !event.target.parentNode.classList.contains('tooltip')
    ) {
      this.tooltip.close();
      this.clickTooltip = !this.clickTooltip;
    }
  }

  public guardarCambios(): void {
    // Método para enviar las biomedidas.
    const dataHijo = this.disp.getData();

    if (dataHijo.length > 0) {
      this.disabled = true;

      // Se recoge la fecha y hora del formulario
      const hora = this.forma.controls['hora'].value.split(':')[0];
      const min = this.forma.controls['hora'].value.split(':')[1];
      const fecha = new Date(
        this.forma.value.fecha.year,
        this.forma.value.fecha.month - 1,
        this.forma.value.fecha.day,
        hora,
        min,
        new Date().getSeconds()
      );

      // Calculamos el agrupado
      let agrupador = '';
      if (this.actividad) {
        agrupador = this.actividad.agrupador;
      } else {
        agrupador = this.dispositivo.sensor.actividad[0];
        if (this.variablePrandial) {
          if (this.variablePrandial.alias.indexOf('BASAL') > -1) {
            agrupador = this.dispositivo.sensor.actividad.find((act) => act.indexOf('BAS') > -1);
          } else {
            agrupador = this.dispositivo.sensor.actividad.find((act) => act.indexOf(this.variablePrandial.alias) > -1);
          }
        }
      }

      // Creamos el cuerpo a enviar en la petición
      interface Cuerpo {
        idDispositivo: string;
        agrupador: string;
        valores: VariableClinica[];
        fecha: number;
        periodo?: string;
        observaciones?: string;
      }

      const data: Cuerpo = {
        idDispositivo: this.dispositivo.id,
        agrupador: agrupador,
        valores: dataHijo,
        fecha: fecha.getTime()
      };

      // Se añaden los datos referidos al periodo y observaciones
      if (this.periodos) {
        data.periodo = this.forma.controls['periodo'].value.alias;
        data.observaciones = this.forma.controls['observaciones'].value;
      }
      // Envío de la petición para la creación de las biomedidas
      this.biomedidasService.enviarBiomedida(data).subscribe(
        () => {
          // Se emite el evento actualizarCalendario.
          this.disabled = false;
          this.limpiar();
          $('#registro').modal('hide');
          this.calendarioService.actualizarCalendario.emit();
          this.alertService.lanzarExito(this.translateService.instant('BIOMEDIDAS.NOTIFICACION.SUCCESS'));
        },
        (error) => {
          this.disabled = false;
          this.limpiar();
          $('#registro').modal('hide');
          this.alertService.lanzarError(error.status);
        }
      );
    } else {
      this.disabled = false;
      this.limpiar();
      this.alertService.lanzarError(500);
    }
  }

  public cambiarVariablePrandial(tipo: string): void {
    // Método que cambia el tipo de la variable prandial.
    if (
      this.forma.controls['periodo'].value.alias !== 'ANTES_ACOSTARSE' &&
      this.forma.controls['periodo'].value.alias !== 'DURANTE_NOCHE'
    ) {
      // Si el periodo no es ANTES_ACOSTARSE o DURANTE_NOCHE se permite el cambio.
      this.variablePrandial = this.variablesClinicas.find((v) => v.alias.indexOf(tipo) > -1);
    }
  }

  public limpiar(): void {
    // Método para resetear los formularios (el padre y el del dispositivo hijo)
    // y, si aplica, el tipo de variable prandial.
    this.calcularFecha();
    this.disp.limpiar();
    this.forma.controls['fecha'].reset(this.hoy);
    this.forma.controls['hora'].reset(this.hora);

    if (this.periodos) {
      this.forma.controls['periodo'].reset(this.periodoActual);
      this.forma.controls['observaciones'].reset('');
    }
  }

  public eventoClickTooltip(): void {
    if (this.clickTooltip) {
      this.tooltip.close();
      this.clickTooltip = !this.clickTooltip;
    } else {
      if (!this.tooltip.isOpen()) {
        this.clickTooltip = !this.clickTooltip;
      }
      this.tooltip.open();
    }
  }

  public abrirTooltip(): void {
    if (!this.clickTooltip) {
      this.tooltip.open();
    }
  }

  public cerrarTooltip(): void {
    if (!this.clickTooltip) {
      this.tooltip.close();
    }
  }

  public periodosIguales(o1: Periodo, o2: Periodo): boolean {
    // Méotodo para comprobar que los periodos sean iguales
    return o1.alias === o2.alias;
  }

  public parseHora(hora): number {
    if (hora === '0' || hora === '00') {
      hora = '24';
    }

    return parseInt(hora, 10);
  }

  private calcularVariablePrandial(periodo: string): VariableClinica {
    // Método para calcular la variable prandial predeterminada según el periodo actual
    let variablePrandial: VariableClinica = null;

    switch (periodo) {
      case 'DURANTE_NOCHE': {
        // Si el periodo es DURANTE_NOCHE se busca la variable que contenga PRE.
        if (this.prePrandial) {
          variablePrandial = this.variablesClinicas.find((v) => v.alias.indexOf('PRE') > -1);
        }
        break;
      }
      case 'ANTES_ACOSTARSE': {
        // Si el periodo es ANTES_ACOSTARSE se busca la variable que contenga POS.
        if (this.posPrandial) {
          variablePrandial = this.variablesClinicas.find((v) => v.alias.indexOf('POS') > -1);
        }
        break;
      }
      default: {
        if (this.prePrandial) {
          variablePrandial = this.variablesClinicas.find((v) => v.alias.indexOf('PRE') > -1);
        } else if (this.posPrandial) {
          variablePrandial = this.variablesClinicas.find((v) => v.alias.indexOf('POS') > -1);
        } else if (this.basal) {
          variablePrandial = this.variablesClinicas.find((v) => v.alias.indexOf('BASAL') > -1);
        }
      }
    }

    return variablePrandial;
  }

  private changeRegex(value: string): void {
    // Se comprueba el formato de la hora
    if (value.charAt(0) === '2') {
      this.mask[1] = new RegExp('[0-3]');
    } else {
      this.mask[1] = new RegExp('\\d');
    }
  }

  private calcularPeriodoActual(): Periodo {
    if (this.hora !== '' && this.hora != null) {
      // Método que calcula el periodo correspondiente a la hora actual.
      return this.periodos.filter(
        (p) =>
          this.parseHora(p.horaIni) <= this.parseHora(this.hora.split(':')[0]) &&
          this.parseHora(this.hora.split(':')[0]) <= this.parseHora(p.horaFin)
      )[0];
    } else {
      return this.periodos.filter((p) => p.alias === 'MEDIA_MANANA')[0];
    }
  }

  private calcularFecha(): void {
    // Se calcula la fecha actual.
    let fecha = new Date();
    this.hora = this.datePipe.transform(fecha, 'HH:mm');

    if (this.actividad && this.actividad.estado === 'AUSENCIA') {
      fecha = new Date(this.actividad.fechaInicioSinMargen);
      this.hora = '';
    }

    this.hoy = { year: fecha.getFullYear(), month: fecha.getMonth() + 1, day: fecha.getDate() };
  }

  private calcularVariablesClinicas(): void {
    // Se recogen las variables clinicas en función de si se trata de una actividad calendarizada o no.
    let variablesClinicas = [];

    if (this.actividad) {
      const actividad = this.actividad;
      this.dispositivo.sensor.variablesclinicasobj.filter(function (variable) {
        // Se comprueba si hay actividades relacionadas para esa variable
        for (const actRelacionada of actividad.actRelacionadas) {
          if (actRelacionada.codigoActividad.includes(variable.alias)) {
            variablesClinicas.push(variable);
          }
        }
      });
    } else {
      variablesClinicas = this.dispositivo.sensor.variablesclinicasobj;
    }

    this.variablesClinicas = variablesClinicas;
  }
}
