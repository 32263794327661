export class Mensaje {
  id: string;
  pacienteNombre: string;
  pacienteApellido1: string;
  pacienteApellido2: string;
  cuerpo: string;
  asunto: string;
  leido: boolean;
  fechaEnvio: string;
  fechaLeido: string;
}
