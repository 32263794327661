import { Component, HostListener } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UpperCasePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../../core/services/login.service';

declare var $: any;

/**
 * Componente para la recuperación de la contraseña.
 *
 * @author lreverendo
 * @author fjsalgado
 *
 * @version 01.02.0000
 * @since 01.02.0000
 */
@Component({
  selector: 'app-recuperacion',
  templateUrl: 'recuperacion.component.html',
  styleUrls: ['../../login.component.css', './recuperacion.component.css']
})
export class RecuperacionComponent {
  public forma: FormGroup;

  public pin = false;
  public exito = false;
  public usuario: string;
  public errorServidor = false;

  constructor(private loginService: LoginService, private translateService: TranslateService, private uppercase: UpperCasePipe) {
    this.errorServidor = false;

    this.translateService.setDefaultLang('gl');

    // Inicialización del formulario.
    this.forma = new FormGroup(
      {
        usuario: new FormControl('', Validators.required),
        telefono: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{0,9}$')]),
        pin: new FormControl('', Validators.required),
        contrasena: new FormControl('', Validators.required),
        repeticion: new FormControl('', Validators.required)
      },
      this.noIgual
    );
  }

  @HostListener('document:keypress', ['$event'])
  public handleKeyboardEvent(event: KeyboardEvent): void {
    const submit = $('#recuperacion').find('button:submit')[0];
    if (!submit.disabled && event.charCode === 13) {
      submit.click();
    }
  }

  public abrirModal(usuario: String): void {
    this.pin = false;
    this.exito = false;
    this.errorServidor = false;
    this.forma.reset({
      usuario: usuario,
      telefono: '',
      pin: '',
      contrasena: '',
      repeticion: ''
    });
    $('#recuperacion').modal('show');
    $('#recuperacion').on('shown.bs.modal', function () {
      setTimeout(function () {
        if (usuario === '') {
          $('#usuario').trigger('focus');
        } else {
          $('#telefono').trigger('focus');
        }
      }, 150);
    });
    $('#recuperacion').on('hidden.bs.modal', function () {
      $('input:text').focus();
    });
  }

  public enviar(): void {
    this.errorServidor = false;
    this.usuario = this.uppercase.transform(this.forma.value.usuario);

    const peticion = {
      usuario: this.usuario,
      tel: this.forma.value.telefono
    };

    // Se llama al método encargado de comprobar usuario y teléfono.
    this.loginService.prepararRecuperacion(peticion).subscribe(
      () => {
        // Se pide el pin.
        this.pin = true;
        setTimeout(function () {
          $('#pin').trigger('focus');
        }, 150);
      },
      (error) => {
        if (error.status === 401) {
          $(':submit').focus();
          this.forma.controls['telefono'].setErrors({ credenciales: true });
        } else {
          this.errorServidor = true;
        }
      }
    );
  }

  // Función para mostrar error en el formulario.
  private noIgual(forma: FormGroup): { [s: string]: boolean } {
    // Se comprueba si la contraseña nueva y la repetición son iguales.
    if (forma.controls['repeticion'].value !== forma.controls['contrasena'].value) {
      return {
        noiguales: true
      };
    }
    return null;
  }

  protected confirmarPin(): void {
    // Se llama al método encargado de comprobar usuario y pin.
    this.errorServidor = false;

    this.usuario = this.uppercase.transform(this.forma.value.usuario);

    const peticion = {
      usuario: this.usuario,
      pin: this.forma.value.pin,
      contrasena: this.forma.value.contrasena,
      repeticion: this.forma.value.repeticion
    };

    // Se llama al método encargado de comprobar usuario y teléfono.
    this.loginService.recuperar(peticion).subscribe(
      () => {
        // Se muestra el mensaje de éxito.
        this.exito = true;
      },
      (error) => {
        if (error.status === 401) {
          $(':submit').focus();
          this.forma.controls['pin'].setErrors({ pinincorrecto: true });
        } else {
          this.errorServidor = true;
        }
      }
    );
  }
}
