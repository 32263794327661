import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService, AutenticacionService, PerfilService, LoaderService } from '../../core/services';
import { Perfil, Direccion } from '../../core/models';
import { takeUntil } from 'rxjs/operators';
import { AccesoPrimeraVez } from '../../core/models/accesoPrimeraVez.model';
import { RespuestaPeticion } from '../../core/models';

/**
 * Component para la página de primer acceso.
 *
 * @author lreverendo
 *
 * @version 01.02.0002
 * @since 01.02.0000
 */
@Component({
  selector: 'app-primer-acceso',
  templateUrl: 'primer-acceso.component.html',
  styleUrls: ['../perfil/perfil.component.css', '../principal/principal.component.css', './primer-acceso.component.css']
})
export class PrimerAccesoComponent implements OnInit, OnDestroy {
  public perfil: Perfil;
  public direcciones: Direccion[] = [];

  private errorSubscription: Subscription;
  private operacionFinalizada = new Subject();
  private accesoPrimeraVez: AccesoPrimeraVez;

  constructor(
    public loader: LoaderService,
    private alertService: AlertService,
    private autenticacionService: AutenticacionService,
    private perfilService: PerfilService,
    private translateService: TranslateService,
    public router: Router
  ) {}

  public ngOnInit(): void {
    this.errorSubscription = this.perfilService.error$.subscribe(this.gestionarError.bind(this));
    this.perfilService.perfilPaciente$.pipe(takeUntil(this.operacionFinalizada)).subscribe(this.recuperarDatosPaciente.bind(this));

    this.accesoPrimeraVez = {
      accesoPrimeraVez: false
    };
  }

  public ngOnDestroy(): void {
    if (this.errorSubscription) {
      this.errorSubscription.unsubscribe();
    }
  }

  public getCurrentIdioma(): string {
    // Se recupera el idioma actual.
    return this.translateService.currentLang;
  }

  public modificar(): void {
    this.acceder('perfil');
  }

  public continuar(): void {
    this.acceder(this.perfil.paginaInicio != null ? this.perfil.paginaInicio : '/calendario');
  }

  public salir(): void {
    // Se prepara el modal de salir.
    const data = {
      origen: this.autenticacionService,
      titulo: this.translateService.instant('MODALES.SALIR.TITULO'),
      mensaje: this.translateService.instant('MODALES.SALIR.MENSAJE'),
      buttons: [
        {
          texto: this.translateService.instant('MODALES.SALIR.NO'),
          type: 'button',
          cerrar: true
        },
        {
          texto: this.translateService.instant('MODALES.SALIR.SI'),
          type: 'button',
          accion: 'logout',
          success: true,
          cerrar: true
        }
      ],
      cerrar: false
    };

    // Se emite el evento modal.
    this.alertService.modal.emit(data);
  }

  private acceder(pagina: string): void {
    this.perfilService.actualizarAccesoPrimeraVez(this.accesoPrimeraVez).subscribe((resp: RespuestaPeticion) => {
      if (resp.executionResult) {
        this.router.navigate([pagina]);
      } else {
        // Se comprueba el código de error devuelto.
        switch (resp.errorCode) {
          case 'ER_CHPWD_01':
            this.alertService.lanzarError(404);
            break;
        }
      }
    });
  }

  private gestionarError(error: HttpErrorResponse): void {
    if (error.status !== 401 && error.status !== 403) {
      this.alertService.lanzarError(error.status);
    }
  }

  private recuperarDatosPaciente(perfilPaciente: Perfil): void {
    if (perfilPaciente != null) {
      this.operacionFinalizada.next();
      this.perfil = perfilPaciente;
      this.direcciones = perfilPaciente.direccion.filter((dir) => dir.direccion1 != null);
      if (this.direcciones.length === 1 && this.direcciones[0].localidad == null) {
        this.direcciones = [];
      }
    }
  }
}
