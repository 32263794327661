import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { AutenticacionService } from '../services/autenticacion.service';
import { Observable } from 'rxjs';
import { ExternoService, LoaderService } from '../services';

/**
 * Servicio que controla el redireccionamiento cuando el usuario está autenticado en una página externa.
 *
 * @author lreverendo
 *
 * @version 01.02.0002
 * @since 01.02.0000
 */
@Injectable({ providedIn: 'root' })
export class ExternoAuthGuardService implements CanActivate {
  constructor(
    private autenticacionService: AutenticacionService,
    private externoService: ExternoService,
    private router: Router,
    private loader: LoaderService
  ) {}

  public canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      if (this.autenticacionService.isUsuarioAutenticado()) {
        if (this.externoService.getOperacion() == null) {
          this.autenticacionService.logout();
          observer.next(false);
        }
        observer.next(true);
      } else {
        this.loader.redireccionar.emit();
        this.router.navigate(['/externo']);
        observer.next(false);
      }
    });
  }
}
