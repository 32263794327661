<div class="form-group" [formGroup]="forma" *ngIf="peso">
  <label for="peso" class="col-form-label"
    >{{ 'BIOMEDIDAS.VARIABLES.' + peso.alias + '.descripcion' | translate | capitalizado }} ({{
      'BIOMEDIDAS.VARIABLES.' + peso.alias + '.unidad' | translate
    }})</label
  >
  <div class="datoCol">
    <input
      type="text"
      [appFormatNumber]="peso.formato"
      appBlockCopyPaste
      class="form-control variable focus"
      id="peso"
      formControlName="peso"
    />
  </div>
</div>
<div
  class="filaError d-none"
  *ngIf="peso"
  [ngClass]="{ 'd-table-row': forma.controls['peso'].dirty && forma.controls['peso'].errors?.maxError }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted"> {{ 'BIOMEDIDAS.BASCULA.PESOMAX' | translate }}{{ peso.max }} </small>
</div>
<div
  class="filaError d-none"
  *ngIf="peso"
  [ngClass]="{ 'd-table-row': forma.controls['peso'].dirty && forma.controls['peso'].errors?.minError }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted"> {{ 'BIOMEDIDAS.BASCULA.PESOMIN' | translate }}{{ peso.min }} </small>
</div>
<div
  class="filaError d-none"
  *ngIf="peso"
  [ngClass]="{ 'd-table-row': forma.controls['peso'].dirty && forma.controls['peso'].errors?.required }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted">
    {{ 'BIOMEDIDAS.BASCULA.PESOREQUERIDO' | translate }}
  </small>
</div>
