import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '../../core/services/alert.service';
import { AutenticacionService } from '../../core/services/autenticacion.service';
import { DispositivoService } from '../../core/services/dispositivo.service';
import { PerfilService } from '../../core/services/perfil.service';
import { Persona } from '../../core/models/persona.model';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { Perfil } from '../../core/models/perfil.model';
import { HttpErrorResponse } from '@angular/common/http';
import { LoaderService } from '../../core/services/loader.service';
import { NavegacionService } from '../../core/services/navegacion.service';

/**
 * Componente para la elección de cuenta para un paciente ecuidador.
 *
 * @author lreverendo
 * @author dvila
 *
 * @version 01.02.0026
 * @since 01.02.0000
 */
@Component({
  selector: 'app-elegir-cuenta',
  templateUrl: 'elegir-cuenta.component.html',
  styleUrls: ['../principal/principal.component.css', './elegir-cuenta.component.css']
})
export class ElegirCuentaComponent implements OnInit, OnDestroy {
  public perfil: Perfil;
  public pacientes: Persona[] = [];
  public atras = true;

  private operacionFinalizada = new Subject();
  private errorSubscription: Subscription;

  constructor(
    public loader: LoaderService,
    private alertService: AlertService,
    private autenticacionService: AutenticacionService,
    private dispositivoService: DispositivoService,
    private navegacionService: NavegacionService,
    private perfilService: PerfilService,
    private translateService: TranslateService,
    private location: Location
  ) {}

  public ngOnInit(): void {
    this.dispositivoService.setDispositivos([]);
    this.errorSubscription = this.perfilService.error$.subscribe(this.lanzarError.bind(this));
    this.perfilService.perfilUsuario$.pipe(takeUntil(this.operacionFinalizada)).subscribe(this.recuperarPerfilUsuario.bind(this));
    this.atras = this.autenticacionService.isPacienteSeleccionado();
  }

  public ngOnDestroy(): void {
    if (this.errorSubscription) {
      this.errorSubscription.unsubscribe();
    }
  }

  public getCurrentIdioma(): string {
    return this.translateService.currentLang;
  }

  public iniciarComo(paciente: Persona = null): void {
    const loginPaciente = paciente == null ? this.perfil.dni : paciente.nif;
    this.navegacionService.cargarPerfilPaciente(loginPaciente);
  }

  public salir(): void {
    // Se prepara el modal de salir.
    const data = {
      origen: this.autenticacionService,
      titulo: this.translateService.instant('MODALES.SALIR.TITULO'),
      mensaje: this.translateService.instant('MODALES.SALIR.MENSAJE'),
      buttons: [
        {
          texto: this.translateService.instant('MODALES.SALIR.NO'),
          type: 'button',
          cerrar: true
        },
        {
          texto: this.translateService.instant('MODALES.SALIR.SI'),
          type: 'button',
          accion: 'logout',
          success: true,
          cerrar: true
        }
      ],
      cerrar: false
    };

    // Se emite el evento modal.
    this.alertService.modal.emit(data);
  }

  public irAtras(): void {
    this.location.back();
  }

  private recuperarPerfilUsuario(perfilUsuario: Perfil): void {
    if (perfilUsuario != null) {
      this.operacionFinalizada.next();
      this.perfil = perfilUsuario;
      this.pacientes = perfilUsuario.pacientes;
      // Orderna los pacientes
      this.pacientes.sort((a, b) => {
        return a.nombre.localeCompare(b.nombre) || a.apellido1.localeCompare(b.apellido1) || a.apellido2.localeCompare(b.apellido2);
      });
    }
  }

  private lanzarError(error: HttpErrorResponse): void {
    this.alertService.lanzarError(error.status);
  }
}
