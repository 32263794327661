import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AlertService, LoaderService, MensajeriaService } from '../../../../core/services';
import { AppConfigService } from '../../../../core/services';
import { Mensaje } from '../../../../core/models';
import * as moment from 'moment';

/**
 * Componente para la gestión de los mensajes recibidos.
 *
 * @author lreverendo
 * @author aarodriguezo
 *
 * @version 01.02.0200
 * @since 01.02.0000
 */
@Component({
  selector: 'app-mensajes-recibidos',
  templateUrl: 'mensajes-recibidos.component.html',
  styleUrls: ['./mensajes-recibidos.component.css', '../../mensajeria.component.css']
})
export class MensajesRecibidosComponent implements OnInit, OnDestroy {
  @Input() permisoEnvioMensaje: Boolean;

  public mensajesRecibidosRecientes: Mensaje[] = [];
  public mensajesRecibidosAnteriores: Mensaje[] = [];

  private subs: Subscription[] = [];

  constructor(
    private alertService: AlertService,
    public loader: LoaderService,
    private mensajeriaService: MensajeriaService,
    private translateService: TranslateService,
    private route: ActivatedRoute
  ) {
    this.subs.push(this.mensajeriaService.reloadRecibidos.subscribe(this.cargarMensajesRecibidos.bind(this)));
  }

  public ngOnInit(): void {
    this.cargarMensajesRecibidos();
  }

  public ngOnDestroy(): void {
    // Baja en todas las subscripciones.
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  public leerMensaje(mensaje: Mensaje): void {
    this.comprobarLeido(mensaje);
    this.mensajeriaService.visualizarMensaje.emit({
      mensaje: mensaje,
      recibido: true
    });
  }

  public nuevoMensaje(): void {
    this.mensajeriaService.crearNuevoMensaje.emit();
  }

  private cargarMensajesRecibidos(): void {
    this.mensajeriaService.getMensajesRecibidos().subscribe(this.filtrarMensajesRecibidos.bind(this), this.gestionarError.bind(this));
  }

  private gestionarError(error: HttpErrorResponse): void {
    this.alertService.lanzarError(error.status);
  }

  private comprobarLeido(mensaje: Mensaje): void {
    if (!mensaje.leido) {
      this.mensajeriaService.marcarMensajeLeido(mensaje.id).subscribe(() => (mensaje.leido = true));
    }
  }

  private filtrarMensajesRecibidos(mensajesRecibidos: Mensaje[]): void {
    this.mensajesRecibidosRecientes = mensajesRecibidos.filter((mensaje) => this.comprobarReciente(mensaje));
    this.mensajesRecibidosAnteriores = mensajesRecibidos.filter((mensaje) => this.comprobarAnterior(mensaje));

    this.route.queryParams.subscribe((params) => {
      if (Object.keys(params).length !== 0) {
        this.abrirMensajeNotificacion(params['fechaNot']);
      }
    });
  }

  private comprobarReciente(mensaje: Mensaje): boolean {
    const diasDiferenciaFechaActual = this.getDiasDiferenciaFechaActual(mensaje);

    const esMensajeReciente = diasDiferenciaFechaActual <= AppConfigService.diasMensajesRecientes;

    return esMensajeReciente;
  }

  private comprobarAnterior(mensaje: Mensaje): boolean {
    const diasDiferenciaFechaActual = this.getDiasDiferenciaFechaActual(mensaje);

    const esMensajeAnterior = diasDiferenciaFechaActual > AppConfigService.diasMensajesRecientes;

    return esMensajeAnterior;
  }

  private getDiasDiferenciaFechaActual(mensaje: Mensaje): number {
    const fechaActual = moment();
    const fechaNotificacion = moment.utc(mensaje.fechaEnvio).local();
    return fechaActual.diff(fechaNotificacion, 'days');
  }

  private abrirMensajeNotificacion(fechaMensajeNotificacion: string): void {
    this.mensajesRecibidosRecientes.forEach((mensajeBucle) => {
      if (mensajeBucle.fechaEnvio === fechaMensajeNotificacion) {
        this.leerMensaje(mensajeBucle);
      }
    });

    this.mensajesRecibidosAnteriores.forEach((mensajeBucle) => {
      if (mensajeBucle.fechaEnvio === fechaMensajeNotificacion) {
        this.leerMensaje(mensajeBucle);
      }
    });
  }

  public getFechaMensajeFormateada(fecha: string): string {
    moment.locale(this.translateService.currentLang);
    const fechaNotificacion = moment.utc(fecha).local();
    return fechaNotificacion.calendar();
  }
}
