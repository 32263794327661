<div class="form-group" [formGroup]="forma">
  <label for="pasos" class="col-form-label">{{ 'BIOMEDIDAS.EF.PASOS' | translate }}</label>
  <div class="datoCol">
    <input type="text" class="form-control" id="pasos" formControlName="pasos" [appFormatNumber]="ef.formato" appBlockCopyPaste />
  </div>
</div>

<!-- Error máximo pasos -->
<div class="filaError d-table-row" *ngIf="forma.controls['pasos'].errors?.maxError && forma.controls['pasos'].dirty">
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted">{{ 'BIOMEDIDAS.EF.PASOSMAX' | translate }}{{ ef.max }}</small>
</div>
<!-- Error mínimo pasos -->
<div class="filaError d-table-row" *ngIf="forma.controls['pasos'].errors?.minError && forma.controls['pasos'].dirty">
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted"> {{ 'BIOMEDIDAS.EF.PASOSMIN' | translate }}{{ ef.min }} </small>
</div>
<!-- Error requerido pasos -->
<div class="filaError d-table-row" *ngIf="forma.controls['pasos'].errors?.required && forma.controls['pasos'].dirty">
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted">{{ 'BIOMEDIDAS.EF.PASOSREQUERIDO' | translate }}</small>
</div>
