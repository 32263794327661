<div class="modal fade" id="registro-cuestionario">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <div class="selectorPagina row justify-content-center" *ngIf="plantilla.preguntas.length > pagina && !avisoCerrar">
          <div
            class="burbuja col-auto"
            *ngFor="let pregunta of plantilla.preguntas; let i = index"
            [ngClass]="{
              'd-none': i > 0 && i < plantilla.preguntas.length - 1 && (i < medioTramo.primero || i > medioTramo.ultimo)
            }"
            [ngStyle]="{ width: 'calc(100% / ' + (desplazamientoMedioTramo * 2 + 4) + ')' }"
          >
            <button
              type="button"
              class="button"
              (click)="irPagina(i)"
              [ngClass]="{
                completadaSeleccionada: pagina == i && respuestas[pregunta.id] != null,
                preguntaCompletada: respuestas[pregunta.id] != null,
                preguntaSeleccionada: pagina == i && respuestas[pregunta.id] == null,
                disabled: i != 0 && respuestas[plantilla.preguntas[i - 1].id] == null
              }"
              [disabled]="i != 0 && respuestas[plantilla.preguntas[i - 1].id] == null"
            >
              {{ i + 1 }}
            </button>
            <div
              *ngIf="i + 1 < plantilla.preguntas.length"
              class="lineaSelector"
              [ngClass]="{
                lineaSuspensivos:
                  (i == 0 && i + 1 != medioTramo.primero) || (i == medioTramo.ultimo && i + 1 != plantilla.preguntas.length - 1)
              }"
            ></div>
          </div>
        </div>
        <div
          class="resumen d-none"
          [ngClass]="{ 'd-block': plantilla.preguntas.length > 0 && plantilla.preguntas.length <= pagina && !avisoCerrar }"
        >
          <h3>
            <strong>{{ 'CUESTIONARIOS.REALIZACION.RESUMEN' | translate }}: {{ titulo | localeTexto: getCurrentIdioma() }}</strong>
          </h3>
        </div>
        <div *ngIf="avisoCerrar">
          <h3>{{ 'CUESTIONARIOS.NOTIFICACION.TITULOREGRESAR' | translate }}</h3>
        </div>
        <button type="button" class="close" (click)="cerrar()">&times;</button>
      </div>

      <div class="modal-separador" *ngIf="plantilla.preguntas.length < pagina"></div>

      <!-- Modal body -->
      <div class="modal-body" *ngIf="plantilla.preguntas.length > pagina && !avisoCerrar">
        <div class="row">
          <button
            class="col-2 col-md-1 btn flecha"
            (click)="irPagina(pagina - 1)"
            *ngIf="pagina != plantilla.preguntas.length"
            [disabled]="pagina == 0"
          >
            <i class="icon-flechaIzq" [ngClass]="{ flechaDisabled: pagina == 0 }"></i>
          </button>

          <ngx-simplebar class="col-8 col-md-10 preguntas" #preguntas [ngClass]="{ todasCompletas: todasCompletas() }">
            <div class="pregunta">
              <h3
                *ngIf="plantilla.preguntas.length > pagina && getCurrentIdioma() == 'gl' && !avisoCerrar"
                [innerHTML]="plantilla.preguntas[pagina].nombre_gl.split('&lt;br>').join('&lt;br>&lt;br>')"
              ></h3>
              <h3
                *ngIf="plantilla.preguntas.length > pagina && getCurrentIdioma() == 'es' && !avisoCerrar"
                [innerHTML]="plantilla.preguntas[pagina].nombre.split('&lt;br>').join('&lt;br>&lt;br>')"
              ></h3>
              <h3
                *ngIf="plantilla.preguntas.length > pagina && getCurrentIdioma() == 'en' && !avisoCerrar"
                [innerHTML]="
                  plantilla.preguntas[pagina].nombre_en
                    ? plantilla.preguntas[pagina].nombre_en.split('&lt;br>').join('&lt;br>&lt;br>')
                    : plantilla.preguntas[pagina].nombre_gl.split('&lt;br>').join('&lt;br>&lt;br>')
                "
              ></h3>
            </div>

            <div class="separador"></div>

            <loader></loader>

            <div class="respuestas">
              <!-- Respuesta de tipo enumerado -->
              <div *ngIf="plantilla.preguntas[pagina].formatoRespuesta.valueOf() == 'enumerado'">
                <div *ngFor="let resp of plantilla.preguntas[pagina].respuestasAsociadas; index as o">
                  <button
                    type="button"
                    class="btn btn-lg respuesta"
                    (click)="agregarRespuesta(plantilla.preguntas[pagina].id, resp.id)"
                    [ngClass]="{
                      seleccionado: respuestas[plantilla.preguntas[pagina].id] == resp.id,
                      respuestaPrimera: o == 0
                    }"
                    *ngIf="plantilla.preguntas[pagina]"
                  >
                    <div *ngIf="getCurrentIdioma() == 'gl'">{{ resp.descripcion_gl }}</div>
                    <div *ngIf="getCurrentIdioma() == 'es'">{{ resp.descripcion }}</div>
                    <div *ngIf="getCurrentIdioma() == 'en'">
                      {{ resp.descripcion_en ? resp.descripcion_en : resp.descripcion_gl }}
                    </div>
                  </button>
                </div>
              </div>
              <!-- Respuesta de tipo numerico -->
              <div *ngIf="plantilla.preguntas[pagina].formatoRespuesta.valueOf() == 'numerico'">
                <div class="input-group col-6 col-md-4 mx-auto respuesta">
                  <input
                    id="respuestaNum"
                    type="text"
                    class="form-control"
                    #respuestaNumerica
                    [appFormatNumber]="entero"
                    appBlockCopyPaste
                    [value]="respuestas[plantilla.preguntas[pagina].id] != undefined ? respuestas[plantilla.preguntas[pagina].id] : ''"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary seleccionado"
                      type="button"
                      (click)="agregarRespuesta(plantilla.preguntas[pagina].id, respuestaNumerica.value)"
                      *ngIf="plantilla.preguntas[pagina]"
                    >
                      {{ 'CUESTIONARIOS.REALIZACION.ACEPTAR' | translate }}
                    </button>
                  </div>
                </div>
              </div>
              <!-- Respuesta de tipo texto -->
              <div *ngIf="plantilla.preguntas[pagina].formatoRespuesta.valueOf() == 'texto'">
                <div class="input-group mx-auto w-80 respuesta">
                  <input
                    id="respuestaTexto"
                    type="text"
                    class="form-control"
                    #respuestaTexto
                    maxlength="50"
                    [value]="respuestas[plantilla.preguntas[pagina].id] != undefined ? respuestas[plantilla.preguntas[pagina].id] : ''"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary seleccionado"
                      type="button"
                      (click)="agregarRespuesta(plantilla.preguntas[pagina].id, respuestaTexto.value)"
                      *ngIf="plantilla.preguntas[pagina]"
                    >
                      {{ 'CUESTIONARIOS.REALIZACION.ACEPTAR' | translate }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ngx-simplebar>
          <button
            class="col-2 col-md-1 btn flecha"
            (click)="asociarPregunta(plantilla.preguntas[pagina].id)"
            *ngIf="pagina != plantilla.preguntas.length"
            [disabled]="pagina == plantilla.preguntas.length - 1 || respuestas[pagina + 1] == null"
          >
            <i
              class="icon-flechaDcha"
              [ngClass]="{ flechaDisabled: pagina == plantilla.preguntas.length - 1 || respuestas[pagina + 1] == null }"
            ></i>
          </button>
        </div>
      </div>

      <div
        class="modal-body scroll d-none"
        [ngClass]="{ 'd-block': plantilla.preguntas.length > 0 && plantilla.preguntas.length <= pagina && !avisoCerrar }"
        data-simplebar
      >
        <app-resumen-cuestionario [items]="items" (abrirPregunta)="irPregunta($event)"></app-resumen-cuestionario>
      </div>

      <div class="modal-body" *ngIf="avisoCerrar">
        <h5>{{ 'CUESTIONARIOS.NOTIFICACION.MENSAJEREGRESAR' | translate }}</h5>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer" *ngIf="!avisoCerrar && todasCompletas()">
        <div class="col-6" style="text-align: left" *ngIf="pagina == plantilla.preguntas.length">
          <button type="button" class="btn btn-primary btn-lg" (click)="irPagina(0)">
            {{ 'CUESTIONARIOS.REALIZACION.ATRAS' | translate }}
          </button>
        </div>
        <div class="col-6" style="text-align: right" *ngIf="pagina == plantilla.preguntas.length">
          <button class="btn btn-success btn-lg" data-dismiss="modal" (click)="enviarCuestionario()" [disabled]="disabled">
            {{ 'CUESTIONARIOS.REALIZACION.ENVIAR' | translate }}
          </button>
        </div>
        <div class="col-12" style="text-align: right" *ngIf="pagina < plantilla.preguntas.length">
          <button type="button" class="btn btn-success btn-lg" (click)="pagina = plantilla.preguntas.length">
            {{ 'CUESTIONARIOS.REALIZACION.VERRESUMEN' | translate }}
          </button>
        </div>
      </div>

      <div class="modal-footer" *ngIf="avisoCerrar">
        <div class="row justify-content-center">
          <div class="col-auto">
            <button type="button" class="btn btn-primary btn-lg" (click)="avisoCerrar = false">
              {{ 'MODALES.SALIR.NO' | translate }}
            </button>
          </div>
          <div class="col-auto">
            <button type="button" class="btn btn-primary btn-lg success" data-dismiss="modal">
              {{ 'MODALES.SALIR.SI' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
