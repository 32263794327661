var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { TranslateService } from '@ngx-translate/core';
import { AlertService, CalendarioService, PerfilService, LoaderService, DispositivoService } from '../../../../core/services';
import { VistaCalendario } from '../vista-calendario.model';
import * as moment from 'moment';
/**
 * Componente para la vista mensual del calendario.
 *
 * @author lreverendo
 *
 * @version 01.02.0050
 * @since 01.02.0000
 */
var MesComponent = /** @class */ (function (_super) {
    __extends(MesComponent, _super);
    function MesComponent(loader, alertService, calendarioService, perfilService, dispositivoService, translateService) {
        var _this = _super.call(this, alertService, calendarioService, perfilService, dispositivoService, translateService) || this;
        _this.loader = loader;
        _this.alertService = alertService;
        _this.calendarioService = calendarioService;
        _this.perfilService = perfilService;
        _this.dispositivoService = dispositivoService;
        _this.mes = [];
        // Inicialización de los datos.
        var hoy = new Date();
        hoy.setHours(0, 0, 0, 0);
        _this.hoy = new Date(+hoy).getTime();
        _this.fecha = new Date();
        // Subscripción al evento reload.
        // Se recarga la vista semanal.
        _this.subs.push(_this.calendarioService.reload.subscribe(function () {
            _this.fecha = new Date();
            _this.cargarActividades();
        }));
        return _this;
    }
    MesComponent.prototype.calcularFechaInicial = function () {
        // Cálculo de la fecha inicial.
        var primerDiaAMostrar = new Date(+this.fecha);
        this.fechaIni = new Date(primerDiaAMostrar.getFullYear(), primerDiaAMostrar.getMonth(), 1, 0, 0, 0, 0);
        var primerDiaSemana = (new Date(+this.fechaIni).getDay() || 7) - 1;
        this.fechaIni.setDate(this.fechaIni.getDate() - primerDiaSemana);
    };
    MesComponent.prototype.calcularFechaFinal = function () {
        var fechaFinMes = new Date(this.fechaIni);
        fechaFinMes.setDate(fechaFinMes.getDate() + 42);
        this.fechaFin = new Date(fechaFinMes);
    };
    MesComponent.prototype.actualizarActividades = function (actividades) {
        this.actividadesTratadas = actividades;
        this.prepararMes();
    };
    MesComponent.prototype.restar = function () {
        // Función que cambia la fecha a mostrar a la del mes anterior y carga los datos de ese mes.
        var ahora = new Date();
        var fecha = new Date(this.fecha);
        // Se establece como día el 1 del mes ya que, si el día actual es mayor que 28, el cambio de enero a febrero es incorrecto.
        fecha.setDate(1);
        fecha.setMonth(fecha.getMonth() - 1);
        if (fecha.getMonth() === ahora.getMonth()) {
            fecha.setDate(ahora.getDate());
        }
        this.fecha = fecha;
        this.cargarActividades();
    };
    MesComponent.prototype.sumar = function () {
        // Función que cambia la fecha a mostrar a la del mes posterior y carga los datos de ese mes.
        var ahora = new Date();
        var fecha = new Date(this.fecha);
        // Se establece como día el 1 del mes ya que, si el día actual es mayor que 28, el cambio de enero a febrero es incorrecto.
        fecha.setDate(1);
        fecha.setMonth(fecha.getMonth() + 1);
        if (fecha.getMonth() === ahora.getMonth()) {
            fecha.setDate(ahora.getDate());
        }
        this.fecha = fecha;
        this.cargarActividades();
    };
    MesComponent.prototype.prepararMes = function () {
        this.mes[0] = [];
        var semana = 0;
        var dia = 0;
        var _loop_1 = function (fecha) {
            var actividades = void 0;
            var fechaInicioDia = new Date(fecha);
            var fechaFinDia = new Date(fecha);
            fechaFinDia.setDate(fecha.getDate() + 1);
            actividades = this_1.actividadesTratadas.filter(function (a) {
                return moment(a.fechaInicioSinMargen).toDate() >= fechaInicioDia &&
                    ((moment(a.fechaFinalSinMargen).toDate() < fechaFinDia &&
                        moment(a.fechaInicioSinMargen).toDate() === moment(a.fechaFinalSinMargen).toDate()) ||
                        (moment(a.fechaFinalSinMargen).toDate() <= fechaFinDia &&
                            moment(a.fechaInicioSinMargen).toDate() !== moment(a.fechaFinalSinMargen).toDate()));
            });
            var otro = fecha.getMonth() !== this_1.fecha.getMonth();
            this_1.mes[semana][dia] = {
                valor: fecha.getTime(),
                dia: fecha.getDate(),
                otro: otro,
                pautadas: actividades.filter(function (a) { return a.estado === 'VACIO' && !a.todoElDia; }).length > 0,
                todoeldia: actividades.filter(function (a) { return a.todoElDia && a.estado === 'VACIO'; }).length > 0,
                caducadas: actividades.filter(function (a) {
                    // Las actividades personales nunca pasan a estado 'AUSENCIA'.
                    return moment(a.fechaFinalSinMargen).isBefore(moment(new Date())) &&
                        (a.tipo === 'personal' || (a.tipo !== 'personal' && a.estado === 'AUSENCIA'));
                }).length > 0,
                realizadas: actividades.filter(function (a) { return a.estado === 'ADHERIDO'; }).length > 0
            };
            // Si es el último día de la semana, se crea una semana más, exceptuando el caso de la semana número 6.
            if (dia / 6 === 1 && semana !== 5) {
                semana++;
                dia = 0;
                this_1.mes[semana] = [];
            }
            else {
                dia++;
            }
        };
        var this_1 = this;
        // Para cada fecha (de fechaIni a fechaFin) se recuperan las actividades y
        // se guarda en la semana y día correspondientes del mes.
        for (var fecha = new Date(+this.fechaIni); fecha.getTime() < this.fechaFin.getTime(); fecha.setDate(fecha.getDate() + 1)) {
            _loop_1(fecha);
        }
    };
    return MesComponent;
}(VistaCalendario));
export { MesComponent };
