<div class="modal fade" id="personal">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <h3 *ngIf="!editar">
          <strong> {{ 'CALENDARIO.PERSONAL.CREAR.TITULOPAG' | translate }} </strong>
        </h3>
        <h3 *ngIf="editar">
          <strong> {{ 'CALENDARIO.PERSONAL.EDITAR.TITULOPAG' | translate }} </strong>
        </h3>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal body -->
      <div class="modal-body" data-simplebar>
        <loader></loader>

        <form [formGroup]="forma">
          <div class="container formulario">
            <div class="row">
              <div class="form-group col-12">
                <label for="titulo" class="col-form-label">{{ 'CALENDARIO.PERSONAL.TITULO' | translate }}</label>
                <div class="datoCol">
                  <input type="text" class="form-control" id="titulo" formControlName="titulo" />
                  <small
                    class="error form-text text-muted"
                    *ngIf="forma.controls['titulo'].touched && forma.controls['titulo'].errors?.required"
                  >
                    {{ 'CALENDARIO.PERSONAL.ERRORES.TITULO.REQUERIDO' | translate }}
                  </small>
                </div>
              </div>
              <div class="form-group col-12">
                <label for="descripcion" class="col-form-label">{{ 'CALENDARIO.PERSONAL.DESCRIPCION' | translate }}</label>
                <div class="datoCol">
                  <textarea class="form-control" maxlength="200" id="descripcion" formControlName="descripcion" rows="6"></textarea>
                  <small
                    class="error form-text text-muted"
                    *ngIf="forma.controls['descripcion'].touched && forma.controls['descripcion'].errors?.required"
                  >
                    {{ 'CALENDARIO.PERSONAL.ERRORES.DESCRIPCION.REQUERIDO' | translate }}
                  </small>
                </div>
                <small class="d-table-cell">{{ forma.value.descripcion.length }}/200</small>
              </div>
              <div class="form-group col-12">
                <div class="datoCol form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="todoElDia"
                    formControlName="todoElDia"
                    (click)="todoeldia()"
                    (dblclick)="dobleClick()"
                  />
                  <label class="form-check-label" for="todoElDia">{{ 'CALENDARIO.PERSONAL.TODOELDIA' | translate }}</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-12 col-md-4">
                <label for="fecha" class="col-form-label">{{ 'CALENDARIO.PERSONAL.FECHA' | translate }}</label>
                <div class="input-group">
                  <input class="form-control" placement="top-left" formControlName="fecha" ngbDatepicker #d="ngbDatepicker" id="fecha" />
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary btn-calendar" (click)="d.toggle()" type="button">
                      <i class="icon-calendario" style="font-size: 1.5rem; cursor: pointer"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group col-12 col-md-4">
                <label for="horaIni" class="col-form-label">{{ 'CALENDARIO.PERSONAL.HORAINI' | translate }}</label>
                <div class="datoCol">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="hh:mm"
                    id="horaIni"
                    [textMask]="{ mask: mask }"
                    formControlName="horaIni"
                  />
                </div>
                <small
                  class="error error-hora form-text text-muted"
                  *ngIf="forma.errors && forma.errors['noCorrectas'] && !forma.value.todoElDia"
                >
                  {{ 'CALENDARIO.PERSONAL.ERRORES.HORAINI.MENOR' | translate }}
                </small>
                <small
                  class="error error-hora form-text text-muted"
                  *ngIf="
                    ((forma.controls['horaIni'].touched && forma.controls['horaIni'].errors?.required) ||
                      (forma.controls['horaFin'].touched && forma.controls['horaFin'].errors?.required)) &&
                    !forma.value.todoElDia
                  "
                >
                  {{ 'CALENDARIO.PERSONAL.ERRORES.HORAS.REQUERIDO' | translate }}
                </small>
              </div>
              <div class="form-group col-12 col-md-4">
                <label for="horaFin" class="col-form-label">{{ 'CALENDARIO.PERSONAL.HORAFIN' | translate }}</label>
                <div class="datoCol">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="hh:mm"
                    id="horaFin"
                    [textMask]="{ mask: mask }"
                    formControlName="horaFin"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button class="btn btn-primary btn-lg" type="button" (click)="limpiar()">
          {{ 'GENERAL.LIMPIAR' | translate }}
        </button>
        <button class="btn btn-success btn-lg" type="submit" (click)="guardarCambios()" [disabled]="disabled || !forma.valid">
          {{ 'GENERAL.GUARDAR' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
