import { Component, OnDestroy } from '@angular/core';
import { AlertService } from '../../../core/services/alert.service';
import { Subscription } from 'rxjs';
import { ModalButton } from 'src/app/core/models';

declare var $: any;

/**
 * Componente para los modales.
 *
 * @author lreverendo
 *
 * @version 01.02.0000
 * @since 01.02.0000
 */
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnDestroy {
  public titulo: string;
  public subtitulo: string;
  public mensaje: string;
  public buttons: ModalButton[] = [];
  public self: any;
  public cerrar: boolean;

  public sub: Subscription;

  constructor(private _alertService: AlertService) {
    // Subscripción al evento modal para abrir un modal.
    this.sub = this._alertService.modal.subscribe((data) => {
      // Se recogen el título, mensaje y botones,
      // el componente padre (self) y un indicador para permitir cerrar o no el modal.
      this.self = data.origen;
      this.titulo = data.titulo;
      this.subtitulo = data.subtitulo;
      this.mensaje = data.mensaje;
      this.buttons = data.buttons;
      this.cerrar = data.cerrar;

      $('.modal-backdrop').remove();

      $('#modal').modal({ backdrop: 'static', keyboard: false });

      $('#modal').modal('show');
    });
  }

  public ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  public onClickButton(button: ModalButton): void {
    if (button.accion != null) {
      if (button.params != null) {
        this.self[button.accion](button.params);
      }
      this.self[button.accion](button.params);
    }
    if (button.cerrar) {
      $('#modal').modal('hide');
    }
  }
}
