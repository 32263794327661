var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DispositivoGenerico } from '../dispositivo-generico.model';
import { CustomValidators } from '../../../../../shared/validators/custom-validators';
import * as i0 from "@angular/core";
/**
 * Componente que representa la Insulina.
 *
 * @author lreverendo
 *
 * @version 01.02.0070
 * @since 01.02.0000
 */
var InsulinaComponent = /** @class */ (function (_super) {
    __extends(InsulinaComponent, _super);
    function InsulinaComponent() {
        return _super.call(this) || this;
    }
    InsulinaComponent.prototype.ngOnInit = function () {
        if (this.prandial) {
            this.forma.addControl('insulina', new FormControl('', [
                Validators.required,
                Validators.pattern(this.getPatronFormato(this.prandial.formato)),
                CustomValidators.porRango(this.prandial.min, this.prandial.max)
            ]));
        }
    };
    InsulinaComponent.prototype.getData = function () {
        var result = [];
        if (this.prandial) {
            var insulina = this.forma.value.insulina.replace(',', '.');
            if (Number(insulina) === NaN) {
                return [];
            }
            this.prandial.valor = insulina;
            result.push(this.prandial);
        }
        return result;
    };
    InsulinaComponent.prototype.changeMaxMin = function () {
        if (this.forma && this.forma.controls['insulina']) {
            this.forma.controls['insulina'].setValidators([
                Validators.required,
                Validators.pattern(this.getPatronFormato(this.prandial.formato)),
                CustomValidators.porRango(this.prandial.min, this.prandial.max)
            ]);
            this.forma.controls['insulina'].updateValueAndValidity();
        }
    };
    InsulinaComponent.ngInjectableDef = i0.defineInjectable({ factory: function InsulinaComponent_Factory() { return new InsulinaComponent(); }, token: InsulinaComponent, providedIn: "root" });
    return InsulinaComponent;
}(DispositivoGenerico));
export { InsulinaComponent };
