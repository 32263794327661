import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LocaleTextoPipe } from '../../../../shared/pipes/locale-texto/locale-texto';
import { GestionActCuestionarioService } from '../../../../core/services';
import { Actividad, Cuestionario, ItemResumenCuestionario } from '../../../../core/models';

declare var $: any;

/**
 * Componente para la visualización de cuestionario.
 *
 * @author lreverendo
 *
 * @version 01.02.0050
 * @since 01.02.0000
 */
@Component({
  selector: 'app-visualizacion-cuestionario',
  templateUrl: './visualizacion-cuestionario.component.html',
  styleUrls: ['./visualizacion-cuestionario.component.css'] // '../../modal/modal.component.css',
})
export class VisualizacionCuestionarioComponent implements OnInit, OnDestroy {
  public cuestionario: Cuestionario = {
    idPaciente: '',
    tipo: '',
    itemsCuestionario: [],
    fechaActividad: null,
    totalValoracion: null,
    titulo: [],
    descripcionValoracion: []
  };

  public titulo = '';
  public items: ItemResumenCuestionario[] = [];

  public actividad: Actividad;

  private locale = '';
  private sub: Subscription;

  constructor(
    private gestionActCuestionarioService: GestionActCuestionarioService,
    private translateService: TranslateService,
    private localeTexto: LocaleTextoPipe
  ) {}

  public ngOnInit(): void {
    const ahora = new Date();
    ahora.setHours(0, 0, 0, 0);

    // Subscripción al evento visualizarCuestionario.
    this.sub = this.gestionActCuestionarioService.visualizarCuestionario.subscribe((data) => {
      this.locale = this.translateService.currentLang;
      this.actividad = data.actividad;
      this.cuestionario = data.cuestionario;
      this.titulo = this.localeTexto.transform(this.cuestionario.titulo, this.locale);

      this.items = [];

      // Se cargan los items (pregunta - respuesta).
      for (const itemCuestionario of this.cuestionario.itemsCuestionario) {
        const item = itemCuestionario;
        const pregunta = this.localeTexto.transform(item.literalPregunta, this.locale);
        const respuesta = this.localeTexto.transform(item.valorRespuesta, this.locale);
        this.items.push({
          pregunta: {
            id: -1,
            nombre: pregunta
          },
          respuesta: {
            id: -1,
            descripcion: respuesta
          }
        });
      }

      // Se abre el modal.
      $('#cuestionario').modal('show');
    });
  }

  public ngOnDestroy(): void {
    // Baja a la subscripción.
    this.sub.unsubscribe();
  }
}
