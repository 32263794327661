import { Direccion } from './direccion.model';
import { Persona } from './persona.model';
import { TipoNotificacion } from './tipoNotificacion.model';
import { Protocolo } from './protocolo.model';

export class Perfil {
  nombre: string;
  cip: string;
  dni: string;
  nasi: number;
  fechaNacimiento: string;
  localePaciente: string;
  paginaInicio: string;
  movil: string;
  email: string;
  servicioSoporte: String;
  accesoPrimeraVez: boolean;
  botonPanico: boolean;
  envioMensajeria: boolean;
  identificadorEjercicio: boolean;
  codCentro: String;
  tieneVinculoAbbott: boolean;
  direccion: Direccion[];
  pacientes: Persona[];
  tipoNotificacionesSuscritas: TipoNotificacion[];
  protocolosActivos: Protocolo[];
}
