import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { AlertService, MedicacionService } from '../../core/services';
import { Medicacion, Pdf } from '../../core/models';
import { LoaderService } from '../../core/services/loader.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;

/**
 * Componente para la carga de la medicacion del paciente.
 *
 * @author lreverendo
 *
 * @version 01.02.0075
 * @since 01.02.0000
 */
@Component({
  selector: 'app-medicacion',
  templateUrl: 'medicacion.component.html',
  styleUrls: ['./medicacion.component.css']
})
export class MedicacionComponent implements OnInit {
  public listaMedicacion: Medicacion[] = [];
  public seleccionada: Medicacion = null;

  public abierto = true;
  public errorServicio = false;

  private pdf: Pdf;
  private BASE64_MARKER = ';base64,';

  @ViewChild('iframe') iframe: ElementRef;

  constructor(
    public loader: LoaderService,
    private alertService: AlertService,
    private medicacionService: MedicacionService,
    private translateService: TranslateService
  ) {}

  public ngOnInit(): void {
    this.medicacionService.getMedicacion().subscribe(this.actualizarMedicacion.bind(this), this.gestionarError.bind(this));
  }

  public abrirPdf(medicacion: Medicacion): void {
    $('#otros').modal('hide');

    this.seleccionada = medicacion;

    this.medicacionService.getHojaMedicacion(medicacion.idControl).subscribe((data) => {
      this.pdf = data;

      const pdfAsDataUri = 'data:application/pdf;base64,' + this.pdf.valor;
      const pdfAsArray = this.convertDataURIToBinary(pdfAsDataUri);

      const url = 'assets/viewer/web/viewer.html?file=';

      const binaryData = [];
      binaryData.push(pdfAsArray);
      const dataPdf = window.URL.createObjectURL(new Blob(binaryData, { type: 'application/pdf' }));

      this.iframe.nativeElement.setAttribute('src', url + encodeURIComponent(dataPdf) + '#locale=' + this.translateService.currentLang);
    }, this.gestionarError.bind(this));
  }

  public verOtrosResultados() {
    $('#otros').modal('show');
  }

  private actualizarMedicacion(medicacion: Medicacion[]): void {
    if (medicacion != null) {
      this.errorServicio = true;
      this.listaMedicacion = medicacion;
      this.seleccionada = this.listaMedicacion[0];
      this.abrirPdf(this.seleccionada);
    }
  }

  private convertDataURIToBinary(dataURI: any): Uint8Array {
    const base64Index = dataURI.indexOf(this.BASE64_MARKER) + this.BASE64_MARKER.length;
    const base64 = dataURI.substring(base64Index);
    const raw = window.atob(base64);
    const rawLength = raw.length;
    const array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }

  private gestionarError(error: HttpErrorResponse): void {
    this.alertService.lanzarError(error.status);
    if (error.status !== 401 && error.status !== 403 && error.status !== 404) {
      this.errorServicio = true;
    }
  }
}
