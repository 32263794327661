import { Component, OnInit, OnChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { VariableClinica } from '../../../../../core/models';
import { DispositivoGenerico } from '../dispositivo-generico.model';
import { CustomValidators } from '../../../../../shared/validators/custom-validators';

/**
 * Componente que representa al dispositivo Glucómetro.
 *
 * @author lreverendo
 *
 * @version 01.02.0070
 * @since 01.02.0000
 */
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tbody [glucometro]',
  templateUrl: 'glucometro.component.html',
  styleUrls: [
    '../../../biomedidas.component.css',
    '../../../views/registro-biomedida/registro-biomedida.component.css',
    './glucometro.component.css'
  ]
})
export class GlucometroComponent extends DispositivoGenerico implements OnInit, OnChanges {
  constructor() {
    super();
  }

  public ngOnInit(): void {
    if (this.prandial) {
      this.forma.addControl(
        'glucosa',
        new FormControl('', [
          Validators.required,
          Validators.pattern(this.getPatronFormato(this.prandial.formato)),
          CustomValidators.porRango(this.prandial.min, this.prandial.max)
        ])
      );
    }
  }

  public getData(): VariableClinica[] {
    const result: VariableClinica[] = [];

    if (this.prandial) {
      const glucosa = this.forma.value.glucosa.replace(',', '.');

      if (Number(glucosa) === NaN) {
        return [];
      }
      this.prandial.valor = glucosa;
      result.push(this.prandial);
    }

    return result;
  }

  public changeMaxMin(): void {
    if (this.forma && this.forma.controls['glucosa']) {
      this.forma.controls['glucosa'].setValidators([
        Validators.required,
        Validators.pattern(this.getPatronFormato(this.prandial.formato)),
        CustomValidators.porRango(this.prandial.min, this.prandial.max)
      ]);
      this.forma.controls['glucosa'].updateValueAndValidity();
    }
  }
}
