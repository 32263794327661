<div class="contenido">
  <h1 class="tituloError">Erro de acceso á aplicación:</h1>

  <h4>Non se pode acceder ao almacenamento local.</h4>
  <br />
  <div>
    Pode enviar as súas consultas técnicas acerca da plataforma TELEA á conta de correo
    <a href="mailto:soporte.cidadania@sergas.es?Subject=TELEA" target="_top"><u>soporte.cidadania@sergas.es</u></a> (Asunto: TELEA) e
    recibirá resposta á maior brevidade posible.
  </div>
</div>
