var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DispositivoGenerico } from '../dispositivo-generico.model';
import { AlertService } from '../../../../../core/services/alert.service';
import { BiomedidasService, LoaderService } from '../../../../../core/services';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';
import * as i0 from "@angular/core";
import * as i1 from "../../../../../core/services/alert.service";
import * as i2 from "../../../../../core/services/loader.service";
import * as i3 from "../../../../../core/services/biomedidas.service";
/**
 * Componente que representa al dispositivo BásculaIMC.
 *
 * @author lreverendo
 *
 * @version 01.02.0070
 * @since 01.02.0000
 */
var BasculaImcComponent = /** @class */ (function (_super) {
    __extends(BasculaImcComponent, _super);
    function BasculaImcComponent(alertService, loader, biomedidasService) {
        var _this_1 = _super.call(this) || this;
        _this_1.alertService = alertService;
        _this_1.loader = loader;
        _this_1.biomedidasService = biomedidasService;
        return _this_1;
    }
    BasculaImcComponent.prototype.ngOnInit = function () {
        var _this_1 = this;
        // Se buscan las variables peso, talla e imc.
        this.peso = this.variablesClinicas.find(function (v) { return v.alias === 'PESO'; });
        this.talla = this.variablesClinicas.find(function (v) { return v.alias === 'TALLA'; });
        this.imc = this.variablesClinicas.find(function (v) { return v.alias === 'IMC'; });
        if (this.peso) {
            this.forma.addControl('peso', new FormControl('', [
                Validators.required,
                Validators.pattern(this.getPatronFormato(this.peso.formato)),
                CustomValidators.porRango(this.peso.min, this.peso.max)
            ]));
        }
        if (this.talla) {
            this.forma.addControl('talla', new FormControl('', [
                Validators.required,
                Validators.pattern(this.getPatronFormato(this.talla.formato)),
                CustomValidators.porRango(this.talla.min, this.talla.max)
            ]));
        }
        if (this.talla) {
            this.forma.disable();
            this.loader.showLoader();
            // Ser recoge la última talla enviada en los últimos 6 meses.
            this.biomedidasService.getTalla().subscribe(function (data) {
                if (data.length > 0) {
                    _this_1.talla.valor = Number(data[0].valor);
                    _this_1.forma.controls['talla'].setValue(_this_1.talla.valor);
                }
                _this_1.forma.enable();
                $('.focus').focus();
            }, function (error) {
                _this_1.alertService.lanzarError(error.status);
            });
        }
        // Si hay cambios en el formulario (peso o talla) se recalcula el IMC.
        this.forma.valueChanges.subscribe(function () {
            // Sólo se recalcula el IMC si la talla y el peso no son nulos.
            if (_this_1.forma.status === 'VALID' && _this_1.imc && _this_1.peso && _this_1.talla) {
                var peso = _this_1.forma.value.peso.replace(',', '.');
                var value = (peso / ((_this_1.forma.value.talla / 100) * (_this_1.forma.value.talla / 100))).toFixed(2);
                _this_1.imc.valor = Number(value);
            }
        });
        this.forma.controls['talla'].valueChanges.subscribe(function (val) {
            _this_1.forma.controls['talla'].reset(String(val).replace(/[^0-9]/g, ''), { emitEvent: false });
            _this_1.forma.controls['talla'].markAsDirty();
        });
    };
    BasculaImcComponent.prototype.ngOnDestroy = function () {
        if (this.loader.active) {
            this.loader.redireccionar.emit();
        }
    };
    BasculaImcComponent.prototype.getData = function () {
        var result = [];
        if (this.peso) {
            var peso = this.forma.value.peso.replace(',', '.');
            if (Number(peso) === NaN) {
                return [];
            }
            this.peso.valor = peso;
            result.push(this.peso);
        }
        if (this.talla) {
            var talla = this.forma.value.talla;
            if (Number(talla) === NaN) {
                return [];
            }
            this.talla.valor = talla;
            result.push(this.talla);
        }
        if (this.imc) {
            if (Number(this.imc) === NaN) {
                return [];
            }
            result.push(this.imc);
        }
        return result;
    };
    BasculaImcComponent.prototype.limpiar = function () {
        var _this_1 = this;
        var _this = this;
        Object.keys(this.forma.controls).map(function (k) {
            if (k === 'talla') {
                _this_1.forma.controls[k].reset(_this.talla.valor.toString());
            }
            else {
                _this_1.forma.controls[k].reset('');
            }
        });
        if (this.imc) {
            this.imc.valor = null;
        }
    };
    BasculaImcComponent.ngInjectableDef = i0.defineInjectable({ factory: function BasculaImcComponent_Factory() { return new BasculaImcComponent(i0.inject(i1.AlertService), i0.inject(i2.LoaderService), i0.inject(i3.BiomedidasService)); }, token: BasculaImcComponent, providedIn: "root" });
    return BasculaImcComponent;
}(DispositivoGenerico));
export { BasculaImcComponent };
