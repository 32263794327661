var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { DispositivoGenerico } from '../dispositivo-generico.model';
import { Validators, FormControl } from '@angular/forms';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';
import * as i0 from "@angular/core";
/**
 * Componente que representa la EjercicioFisico.
 *
 * @author lreverendo
 * @author dvila
 *
 * @version 01.02.0070
 * @since 01.02.0000
 */
var EjercicioComponent = /** @class */ (function (_super) {
    __extends(EjercicioComponent, _super);
    function EjercicioComponent() {
        return _super.call(this) || this;
    }
    EjercicioComponent.prototype.ngOnInit = function () {
        // Se busca la variable INR.
        this.ef = this.variablesClinicas.find(function (v) { return v.alias === 'EF'; });
        if (this.ef) {
            this.forma.addControl('pasos', new FormControl('', [
                Validators.required,
                Validators.pattern(this.getPatronFormato(this.ef.formato)),
                CustomValidators.porRango(this.ef.min, this.ef.max)
            ]));
        }
    };
    EjercicioComponent.prototype.getData = function () {
        var result = [];
        if (this.ef) {
            var pasos = this.forma.value.pasos;
            this.ef.valor = pasos;
            result.push(this.ef);
        }
        return result;
    };
    EjercicioComponent.ngInjectableDef = i0.defineInjectable({ factory: function EjercicioComponent_Factory() { return new EjercicioComponent(); }, token: EjercicioComponent, providedIn: "root" });
    return EjercicioComponent;
}(DispositivoGenerico));
export { EjercicioComponent };
