import * as i0 from "@angular/core";
/**
 * Servicio para la pantalla del splash screen.
 *
 * @author lreverendo
 *
 * @version 01.02.0002
 * @since 01.02.0000
 */
var SplashScreenService = /** @class */ (function () {
    function SplashScreenService() {
    }
    SplashScreenService.prototype.cerrarSplashScreen = function () {
        // Oculta el splashScreen
        document.body.style.opacity = '0';
        // Elimina el splashScreen y muestra la pantalla inicial
        setTimeout(function () {
            document.body.style.opacity = '1';
            var splashScreen = document.getElementById('splashScreen');
            splashScreen.parentElement.removeChild(splashScreen);
        }, 800);
        // Elimina los estilos del splashScreen
        setTimeout(function () {
            var splashScreenStyles = document.getElementById('splashScreenStyles');
            splashScreenStyles.parentElement.removeChild(splashScreenStyles);
        }, 5000);
    };
    SplashScreenService.ngInjectableDef = i0.defineInjectable({ factory: function SplashScreenService_Factory() { return new SplashScreenService(); }, token: SplashScreenService, providedIn: "root" });
    return SplashScreenService;
}());
export { SplashScreenService };
