<div class="form-group" [formGroup]="forma" *ngIf="peso">
  <label for="peso" class="col-form-label"
    >{{ 'BIOMEDIDAS.VARIABLES.' + peso.alias + '.descripcion' | translate | capitalizado }} ({{
      'BIOMEDIDAS.VARIABLES.' + peso.alias + '.unidad' | translate
    }})</label
  >
  <div class="datoCol">
    <input
      type="text"
      [appFormatNumber]="peso.formato"
      appBlockCopyPaste
      class="form-control variable focus"
      id="peso"
      formControlName="peso"
    />
  </div>
</div>
<div
  class="filaError d-none"
  *ngIf="peso"
  [ngClass]="{ 'd-table-row': forma.controls['peso'].dirty && forma.controls['peso'].errors?.maxError }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted"> {{ 'BIOMEDIDAS.BASCULAIMC.PESOMAX' | translate }}{{ peso.max }} </small>
</div>
<div
  class="filaError d-none"
  *ngIf="peso"
  [ngClass]="{ 'd-table-row': forma.controls['peso'].dirty && forma.controls['peso'].errors?.minError }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted"> {{ 'BIOMEDIDAS.BASCULAIMC.PESOMIN' | translate }}{{ peso.min }} </small>
</div>
<div
  class="filaError d-none"
  *ngIf="peso"
  [ngClass]="{ 'd-table-row': forma.controls['peso'].dirty && forma.controls['peso'].errors?.required }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted">
    {{ 'BIOMEDIDAS.BASCULAIMC.PESOREQUERIDO' | translate }}
  </small>
</div>
<div class="form-group" *ngIf="talla" [formGroup]="forma">
  <label for="talla" class="col-form-label"
    >{{ 'BIOMEDIDAS.VARIABLES.' + talla.alias + '.descripcion' | translate | capitalizado }} ({{
      'BIOMEDIDAS.VARIABLES.' + talla.alias + '.unidad' | translate
    }})</label
  >
  <div class="datoCol">
    <input
      type="text"
      [appFormatNumber]="talla.formato"
      appBlockCopyPaste
      class="form-control variable"
      id="talla"
      formControlName="talla"
    />
  </div>
</div>
<div
  class="filaError d-none"
  *ngIf="talla"
  [ngClass]="{ 'd-table-row': forma.controls['talla'].dirty && forma.controls['talla'].errors?.maxError }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted"> {{ 'BIOMEDIDAS.BASCULAIMC.TALLAMAX' | translate }}{{ talla.max }} </small>
</div>
<div
  class="filaError d-none"
  *ngIf="talla"
  [ngClass]="{
    'd-table-row': forma.controls['talla'].dirty && forma.controls['talla'].errors?.minError
  }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted"> {{ 'BIOMEDIDAS.BASCULAIMC.TALLAMIN' | translate }}{{ talla.min }} </small>
</div>
<div
  class="filaError d-none"
  *ngIf="talla"
  [ngClass]="{ 'd-table-row': forma.controls['talla'].dirty && forma.controls['talla'].errors?.required }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted">
    {{ 'BIOMEDIDAS.BASCULAIMC.TALLAREQUERIDO' | translate }}
  </small>
</div>
<div class="form-group" *ngIf="imc && peso && talla">
  <label for="imc" class="col-form-label" title="Índice de masa corporal"
    >{{ 'BIOMEDIDAS.VARIABLES.' + imc.alias + '.descripcion' | translate | capitalizado }} ({{
      'BIOMEDIDAS.VARIABLES.' + imc.alias + '.unidad' | translate
    }})</label
  >
  <div class="datoCol">
    <input type="text" class="form-control variable" id="imc" [(ngModel)]="imc.valor" readonly />
  </div>
</div>
