<div class="collapse width" id="menu">
  <div class="menu">
    <div class="menu-contenido" data-simplebar>
      <div class="row perfilMenu d-none menu-opciones">
        <h4 class="nomPerfilMenu" *ngIf="perfil != null" title="{{ perfil.nombre | capitalizado }}">
          {{ perfil.nombre | capitalizado }}
        </h4>
      </div>

      <div class="row d-md-none">
        <div class="menu-opciones-juntas">
          <h4 class="menu-subtitulo">{{ 'MENU.PERFIL' | translate }}</h4>
        </div>
        <div class="menu-opciones-juntas" *ngIf="tieneNasi">
          <h4 class="menu-subtitulo">{{ 'MENU.CALENDARIO' | translate }}</h4>
        </div>
      </div>

      <div class="row d-md-none menu-opciones">
        <div class="menu-opcion align-items-center" (click)="abrir('perfil')" style="padding: 19px">
          <div>
            <i class="icon-perfil menu-icono"></i>
            {{ 'MENU.PERFIL' | translate }}
          </div>
        </div>
        <div class="menu-opcion align-items-center" *ngIf="tieneNasi" (click)="abrir('calendario')">
          <div>
            <i class="icon-calendario menu-icono"></i>
            {{ 'MENU.CALENDARIO' | translate }}
          </div>
        </div>
      </div>

      <h4 class="menu-subtitulo" *ngIf="tieneNasi">{{ 'MENU.DISPOSITIVOS' | translate }}</h4>
      <div class="row menu-opciones" *ngIf="tieneNasi">
        <div class="menu-opcion align-items-center" (click)="abrir('dispositivos')">
          <div>
            <i class="icon-mediciones menu-icono"></i>
            {{ 'MENU.DISPOSITIVOS' | translate }}
          </div>
        </div>
      </div>

      <h4 class="menu-subtitulo" *ngIf="tieneNasi">{{ 'MENU.ACTIVIDADES' | translate }}</h4>
      <div class="row menu-opciones" *ngIf="tieneNasi">
        <div class="menu-opcion align-items-center" (click)="abrir('biomedidas')">
          <div>
            <i class="icon-biomedidas_2 menu-icono"></i>
            {{ 'MENU.BIOMEDIDAS' | translate }}
          </div>
        </div>
        <div class="menu-opcion align-items-center" (click)="abrir('cuestionarios')">
          <div>
            <i class="icon-cuestionario_2 menu-icono"></i>
            {{ 'MENU.CUESTIONARIOS' | translate }}
          </div>
        </div>
        <div class="menu-opcion align-items-center" (click)="abrir('medicacion')">
          <div>
            <i class="icon-medicacion-activa menu-icono"></i>
            {{ 'MEDICACION.TITULO' | translate }}
          </div>
        </div>
      </div>

      <div class="row" *ngIf="tieneNasi">
        <div class="menu-opciones-juntas">
          <h4 class="menu-subtitulo">{{ 'MENU.MENSAJERIA' | translate }}</h4>
        </div>
        <div class="menu-opciones-juntas d-md-none">
          <h4 class="menu-subtitulo">{{ 'MENU.NOTIFICACIONES' | translate }}</h4>
        </div>
      </div>

      <div class="row menu-opciones" *ngIf="tieneNasi">
        <div class="menu-opcion align-items-center" (click)="abrir('mensajeria')">
          <div>
            <i class="icon-mensaje menu-icono"></i>
            {{ 'MENU.MENSAJERIA' | translate }}
          </div>
        </div>
        <div class="menu-opcion align-items-center d-md-none" (click)="abrir('notificaciones')" style="padding: 19px 0; text-align: center">
          <div>
            <i class="icon-actividades menu-icono"></i>
            {{ 'MENU.NOTIFICACIONES' | translate }}
          </div>
        </div>
      </div>

      <h4 class="menu-subtitulo" *ngIf="tieneNasi">{{ 'MENU.SERVICIOS' | translate }}</h4>
      <div class="row menu-opciones" *ngIf="tieneNasi">
        <div class="menu-opcion align-items-center" (click)="abrirInstrucciones()">
          <div>
            <i class="icon-serviciosAdicionales menu-icono"></i>
            {{ 'MENU.INSTRUCCIONES' | translate }}
          </div>
        </div>
      </div>
      <h4 class="menu-subtitulo">{{ 'MENU.OTROS' | translate }}</h4>
      <div class="row menu-opciones">
        <div class="menu-opcion align-items-center" (click)="abrir('soporte')">
          <div>
            <i class="icon-otros menu-icono"></i>
            {{ 'MENU.SOPORTE' | translate }}
          </div>
        </div>
        <div class="menu-opcion align-items-center" *ngIf="cuidador" (click)="abrir('elegir')">
          <div>
            <i class="icon-avatar_menu menu-icono"></i>
            {{ 'MENU.CAMBIAR' | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<nav class="navbar navbar-expand-lg navbar-light">
  <div class="row">
    <div class="opcion align-items-center col d-table" (click)="abrirMenu()">
      <div>
        <i class="icon-menu icono" title="{{ 'MENU.TOOLTIP' | translate }}"></i>
      </div>
    </div>

    <div class="perfil align-items-center col mr-auto">
      <i *ngIf="!cuidador" class="icon-avatar icono d-none d-sm-inline-block"></i>
      <i
        *ngIf="cuidador"
        class="icon-avatar_rol icono d-none d-sm-inline-block"
        title="{{ 'MENU.CAMBIAR' | translate }}"
        (click)="abrir('elegir')"
      ></i>
      <h5 class="nomPerfil" *ngIf="perfil != null" title="{{ perfil.nombre | capitalizado }}">
        {{ perfil.nombre | capitalizado }}
      </h5>
    </div>

    <div class="titulo align-items-center col">
      <h3 style="width: 100%; text-align: center">
        <strong>{{ 'MENU.TITULO' | translate }}</strong>
      </h3>
    </div>

    <div
      class="opcion align-items-center col d-none d-md-table"
      [ngClass]="{ activo: this.router.url == '/calendario' }"
      (click)="abrir('calendario')"
      *ngIf="tieneNasi"
    >
      <div>
        <i class="icon-calendario icono" title="{{ 'MENU.CALENDARIO' | translate }}"></i>
        <span class="d-none d-lg-inline-block">
          {{ 'MENU.CALENDARIO' | translate }}
        </span>
      </div>
    </div>
    <div
      class="opcion align-items-center col d-none d-md-table"
      [ngClass]="{ activo: this.router.url == '/perfil' }"
      style="border-style: none !important"
      (click)="abrir('perfil')"
    >
      <div>
        <i class="icon-perfil icono" title="{{ 'MENU.PERFIL' | translate }}"></i>
        <span class="d-none d-lg-inline-block" style="vertical-align: middle">
          {{ 'MENU.PERFIL' | translate }}
        </span>
      </div>
    </div>
    <div
      class="opcion align-items-center col actividades d-table"
      [ngClass]="{ activo: this.router.url == '/notificaciones' }"
      (click)="abrir('notificaciones')"
      *ngIf="tieneNasi"
    >
      <div>
        <i class="icon-actividades icono" title="{{ 'MENU.NOTIFICACIONES' | translate }}"></i>
        <span class="badge badge-pill badge-primary" *ngIf="numeroNotificacionesSinRevisar > 0">{{ numeroNotificacionesSinRevisar }}</span>
        <span class="d-none d-lg-inline-block">
          {{ 'MENU.NOTIFICACIONES' | translate }}
        </span>
      </div>
    </div>

    <div class="opcion align-items-center col boton-panico d-table" (click)="panico()" *ngIf="botonPanico">
      <div>
        <i class="icon-sos icono" title="{{ 'MENU.PANICO' | translate }}"></i>
      </div>
    </div>
    <div class="opcion align-items-center col salir d-table" (click)="salir()">
      <div>
        <i class="icon-salir icono" title="{{ 'MENU.SALIR' | translate }}"></i>
        <span class="d-none d-lg-inline-block">
          {{ 'MENU.SALIR' | translate }}
        </span>
      </div>
    </div>
  </div>
</nav>

<div (click)="click()" class="pagina">
  <loader [for]="[operation.logout]"></loader>
  <router-outlet></router-outlet>
</div>

<app-instrucciones #instrucciones></app-instrucciones>
