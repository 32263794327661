import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Perfil } from '../../core/models';
import { AlertService, PerfilService, LoaderService } from '../../core/services';

/**
 * Componente que muestra la información de soporte.
 *
 * @author lreverendo
 *
 * @version 01.02.0000
 * @since 01.02.0000
 */
@Component({
  selector: 'app-soporte-tecnico',
  templateUrl: 'soporte-tecnico.component.html',
  styleUrls: ['./soporte-tecnico.component.css']
})
export class SoporteTecnicoComponent implements OnInit {
  public mensajeServicioSoporte: String;

  constructor(public loader: LoaderService, private alertService: AlertService, private perfilService: PerfilService) {}

  public ngOnInit(): void {
    this.perfilService.perfilPaciente$.subscribe(this.cargarMensajeSoporte.bind(this), this.gestionarError.bind(this));
  }

  private cargarMensajeSoporte(perfilPaciente: Perfil): void {
    if (perfilPaciente != null) {
      this.mensajeServicioSoporte = perfilPaciente.servicioSoporte;
    }
  }

  private gestionarError(error: HttpErrorResponse): void {
    this.alertService.lanzarError(error.status);
  }
}
