import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { VariableClinica } from '../../../../../core/models';
import { DispositivoGenerico } from '../dispositivo-generico.model';
import { CustomValidators } from '../../../../../shared/validators/custom-validators';

/**
 * Componente que representa al dispositivo Coagulómetro.
 *
 * @author lreverendo
 *
 * @version 01.02.0070
 * @since 01.02.0000
 */
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tbody [coagulometro]',
  templateUrl: 'coagulometro.component.html',
  styleUrls: [
    '../../../biomedidas.component.css',
    '../../../views/registro-biomedida/registro-biomedida.component.css',
    './coagulometro.component.css'
  ]
})
export class CoagulometroComponent extends DispositivoGenerico implements OnInit {
  public inr: VariableClinica;

  constructor() {
    super();
  }

  public ngOnInit(): void {
    // Se busca la variable INR.
    this.inr = this.variablesClinicas.find((v) => v.alias === 'INR');

    if (this.inr) {
      this.forma.addControl(
        'inr',
        new FormControl('', [
          Validators.required,
          Validators.pattern(this.getPatronFormato(this.inr.formato)),
          CustomValidators.porRango(this.inr.min, this.inr.max)
        ])
      );
    }
  }

  public getData(): VariableClinica[] {
    const result: VariableClinica[] = [];

    if (this.inr) {
      const inr = this.forma.value.inr.replace(',', '.');

      if (Number(inr) === NaN) {
        return [];
      }
      this.inr.valor = inr;
      result.push(this.inr);
    }

    return result;
  }
}
