import { EventEmitter } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { LoginService } from './login.service';
import { Router } from '@angular/router';
import { AppConfigService } from './app-config.service';
import * as moment from 'moment';
import { merge, fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "angular-user-idle";
import * as i2 from "./login.service";
import * as i3 from "@angular/router";
/**
 * Servicio para la gestión de la autenticación.
 *
 * @author lreverendo
 * @author aarodriguezo
 *
 * @version 01.02.0250
 * @since 01.02.0000
 */
var AutenticacionService = /** @class */ (function () {
    function AutenticacionService(userIdle, loginService, router) {
        this.userIdle = userIdle;
        this.loginService = loginService;
        this.router = router;
        this.cierreSesionEvent = new EventEmitter();
        this.USUARIO = 'usuario';
        this.CENTRO = 'centro';
        this.LOGUEADO = 'logueado';
        this.ACCESS_TOKEN = 'accessToken';
        this.ACCESS_EXPIRATION = 'accessExpiration';
        this.operacionFinalizada = new Subject();
    }
    AutenticacionService.prototype.configurarInactividad = function () {
        var config = { idle: AppConfigService.tiempoSesion, timeout: 0, ping: 0 };
        this.userIdle.setConfigValues(config);
        this.userIdle.setCustomActivityEvents(merge(fromEvent(window, 'mousemove'), fromEvent(window, 'resize'), fromEvent(document, 'keydown'), fromEvent(document, 'touchstart'), fromEvent(document, 'touchend'), fromEvent(window, 'wheel')));
    };
    AutenticacionService.prototype.isUsuarioAutenticado = function () {
        return this.getLoginUsuario() != null;
    };
    AutenticacionService.prototype.isPacienteSeleccionado = function () {
        return this.getLoginPaciente() != null;
    };
    AutenticacionService.prototype.isAccessTokenExpirado = function () {
        try {
            var expirado = true;
            var accessExpiration = sessionStorage.getItem(this.ACCESS_EXPIRATION);
            if (accessExpiration != null) {
                var fechaActual = moment().utcOffset(0);
                var fechaExpiracionSesion = moment(parseInt(accessExpiration, 10));
                expirado = !(fechaExpiracionSesion > fechaActual);
            }
            return expirado;
        }
        catch (e) {
            this.router.navigate(['/error']);
        }
    };
    AutenticacionService.prototype.getLoginPaciente = function () {
        try {
            return sessionStorage.getItem(this.USUARIO);
        }
        catch (e) {
            this.router.navigate(['/error']);
        }
    };
    AutenticacionService.prototype.setLoginPaciente = function (loginUsuario) {
        try {
            sessionStorage.setItem(this.USUARIO, loginUsuario);
        }
        catch (e) {
            this.router.navigate(['/error']);
        }
    };
    AutenticacionService.prototype.getCentroPaciente = function () {
        try {
            return sessionStorage.getItem(this.CENTRO);
        }
        catch (e) {
            this.router.navigate(['/error']);
        }
    };
    AutenticacionService.prototype.setCentroPaciente = function (codCentro) {
        try {
            sessionStorage.setItem(this.CENTRO, codCentro);
        }
        catch (e) {
            this.router.navigate(['/error']);
        }
    };
    AutenticacionService.prototype.getLoginUsuario = function () {
        try {
            return sessionStorage.getItem(this.LOGUEADO);
        }
        catch (e) {
            this.router.navigate(['/error']);
        }
    };
    AutenticacionService.prototype.setLoginUsuario = function (loginUsuario) {
        try {
            sessionStorage.setItem(this.LOGUEADO, loginUsuario);
            this.userIdle.startWatching();
            this.userIdle.onTimerStart().pipe(takeUntil(this.operacionFinalizada)).subscribe(this.gestionarInactividad.bind(this));
        }
        catch (e) {
            this.router.navigate(['/error']);
        }
    };
    AutenticacionService.prototype.gestionarInactividad = function () {
        this.operacionFinalizada.next();
        this.userIdle.stopTimer();
        this.userIdle.stopWatching();
        this.lanzarErrorSesion();
    };
    AutenticacionService.prototype.getAccessToken = function () {
        try {
            return sessionStorage.getItem(this.ACCESS_TOKEN);
        }
        catch (e) {
            this.router.navigate(['/error']);
        }
    };
    AutenticacionService.prototype.setAccessToken = function (accessToken) {
        try {
            sessionStorage.setItem(this.ACCESS_TOKEN, accessToken);
        }
        catch (e) {
            this.router.navigate(['/error']);
        }
    };
    AutenticacionService.prototype.setAccessExpiration = function (accessExpiration) {
        try {
            sessionStorage.setItem(this.ACCESS_EXPIRATION, accessExpiration);
        }
        catch (e) {
            this.router.navigate(['/error']);
        }
    };
    AutenticacionService.prototype.borrarDatosAutenticacion = function () {
        try {
            sessionStorage.removeItem(this.USUARIO);
            sessionStorage.removeItem(this.LOGUEADO);
            sessionStorage.removeItem(this.ACCESS_TOKEN);
            sessionStorage.removeItem(this.ACCESS_EXPIRATION);
            sessionStorage.removeItem('pdfjs.history');
        }
        catch (e) {
            this.router.navigate(['/error']);
        }
    };
    AutenticacionService.prototype.eliminarModals = function () {
        $('#modal').modal('hide');
    };
    AutenticacionService.prototype.borrarPdfHistory = function () {
        try {
            sessionStorage.removeItem('pdfjs.history');
        }
        catch (e) {
            this.router.navigate(['/error']);
        }
    };
    AutenticacionService.prototype.lanzarErrorSesion = function () {
        var _this = this;
        if (this.isUsuarioAutenticado()) {
            this.loginService.logout().subscribe(function () {
                _this.salir(true);
            }, function () {
                _this.salir(true);
            });
        }
    };
    AutenticacionService.prototype.logout = function () {
        var _this = this;
        this.loginService.logout().subscribe(function () {
            _this.salir(false);
        }, function () {
            _this.salir(false);
        });
    };
    AutenticacionService.prototype.salir = function (sesion) {
        this.borrarDatosAutenticacion();
        this.cierreSesionEvent.next({ errorSesion: sesion });
    };
    AutenticacionService.ngInjectableDef = i0.defineInjectable({ factory: function AutenticacionService_Factory() { return new AutenticacionService(i0.inject(i1.UserIdleService), i0.inject(i2.LoginService), i0.inject(i3.Router)); }, token: AutenticacionService, providedIn: "root" });
    return AutenticacionService;
}());
export { AutenticacionService };
