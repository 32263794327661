import { Injectable, EventEmitter } from '@angular/core';
import { Actividad, ModalButton, CuestionarioPlantilla, LocaleTexto, Plantilla, ValoracionCuestionario } from '../models';
import { AlertService } from './alert.service';
import { PermisosService } from './permisos.service';
import { CuestionariosService } from './cuestionarios.service';

import * as moment from 'moment';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { LocaleTextoPipe } from '../../shared/pipes/locale-texto/locale-texto';

/**
 * Servicio para la gestión del actividades de tipo cuestionario.
 *
 * @author lreverendo
 *
 * @version 01.02.0050
 * @since 01.02.0050
 */
@Injectable({ providedIn: 'root' })
export class GestionActCuestionarioService {
  /**
   * Evento para el registro de un cuestionario.
   */
  public registroCuestionario: EventEmitter<{
    titulo: LocaleTexto[];
    plantilla: Plantilla;
    tipoOntologia: string;
    valoraciones: ValoracionCuestionario[];
  }> = new EventEmitter();

  /**
   * Evento para abrir el modal de visualización de un cuestionario.
   */
  public visualizarCuestionario: EventEmitter<{
    actividad?: Actividad; // Parámetro opcional.
    cuestionario: any;
  }> = new EventEmitter();

  constructor(
    private alertService: AlertService,
    private cuestionariosService: CuestionariosService,
    private permisoService: PermisosService,
    private translateService: TranslateService,
    private localeTexto: LocaleTextoPipe
  ) {}

  public abrirCuestionario(actividad: Actividad): void {
    switch (actividad.estado) {
      case 'VACIO':
        if (moment(actividad.fechaInicioConMargen).toDate() > new Date()) {
          this.cuestionarioFuturo(actividad);
        } else if (this.permisoService.getPermisos().escritura) {
          this.realizarCuestionario(actividad);
        }
        break;
      case 'ADHERIDO':
        this.abrirVisualizacionCuestionario(actividad);
        break;
      case 'AUSENCIA':
        this.cuestionarioCaducado(actividad);
        break;
    }
  }

  public realizarCuestionario(actividad: Actividad): void {
    // Función para la realización de un cuestinario.
    this.cuestionariosService
      .getCuestionario(actividad.codigoActividad, this.getCurrentIdioma())
      .subscribe((cuestionario: CuestionarioPlantilla) => {
        const params = {
          actividad: actividad,
          cuestionario: cuestionario
        };

        if (cuestionario.descripcion || cuestionario.leyenda) {
          let descripcion = null;
          if (cuestionario.descripcion && cuestionario.descripcion.length > 0) {
            descripcion = [
              {
                locale: 'es',
                texto: cuestionario.descripcion
              },
              {
                locale: 'gl',
                texto: cuestionario.descripcion_gl
              },
              {
                locale: 'en',
                texto: cuestionario.descripcion_en
              }
            ];
          }

          let leyenda = null;
          if (cuestionario.leyenda && cuestionario.leyenda.length > 0) {
            leyenda = [
              {
                locale: 'es',
                texto: cuestionario.leyenda
              },
              {
                locale: 'gl',
                texto: cuestionario.leyenda_gl
              },
              {
                locale: 'en',
                texto: cuestionario.leyenda_en
              }
            ];
          }

          const buttons: ModalButton[] = [
            {
              texto: this.translateService.instant('CUESTIONARIOS.NOTIFICACION.EMPEZAR'),
              type: 'button',
              accion: 'empezarCuestionario',
              params: params,
              cerrar: true
            }
          ];

          const data = {
            origen: this,
            titulo: this.localeTexto.transform(actividad.titulo, this.getCurrentIdioma()),
            subtitulo: this.localeTexto.transform(descripcion, this.getCurrentIdioma()),
            mensaje: this.localeTexto.transform(leyenda, this.getCurrentIdioma()),
            buttons: buttons,
            cerrar: true
          };

          this.alertService.modal.emit(data);
        } else {
          this.empezarCuestionario(params);
        }
      }, this.gestionarError.bind(this));
  }

  public empezarCuestionario(params: { actividad: Actividad; cuestionario: CuestionarioPlantilla }): void {
    // Se emite el evento de registroCuestionario.
    this.registroCuestionario.emit({
      titulo: params.actividad.titulo,
      plantilla: params.cuestionario.plantilla,
      tipoOntologia: params.actividad.codigoActividad,
      valoraciones: params.cuestionario.valoraciones
    });
  }

  private abrirVisualizacionCuestionario(actividad: Actividad): void {
    // Carga del cuestionario.
    this.cuestionariosService.getCuestionarioRealizado(actividad.idInstancia, actividad.codigoActividad).subscribe((data) => {
      // Visualización del cuestionario.
      this.visualizarCuestionario.emit({
        actividad: actividad,
        cuestionario: data[0]
      });
    }, this.gestionarError.bind(this));
  }

  private cuestionarioCaducado(actividad: Actividad): void {
    const ahora = new Date();
    ahora.setHours(0, 0, 0, 0);
    let buttons: ModalButton[] = [];

    const params = actividad;

    // Si la actividad tiene una fecha de inicio del día actual se permite volver a realizar como actividad no pautada.
    if (!(moment(actividad.fechaInicioConMargen).toDate() < ahora) && this.permisoService.getPermisos().escritura) {
      buttons = [
        {
          texto: this.translateService.instant('CUESTIONARIOS.NOTIFICACION.REALIZAR'),
          type: 'button',
          accion: 'realizarCuestionario',
          params: params,
          cerrar: false
        }
      ];
    }

    const data = {
      origen: this,
      titulo: this.localeTexto.transform(actividad.titulo, this.getCurrentIdioma()),
      mensaje: this.translateService.instant('CUESTIONARIOS.NOTIFICACION.CADUCADO'),
      buttons: buttons,
      cerrar: true
    };

    this.alertService.modal.emit(data);
  }

  private cuestionarioFuturo(actividad: Actividad): void {
    // Función para la gestión de una actividad cuestionario futura.
    const ahora = new Date();
    ahora.setDate(ahora.getDate() + 1);
    ahora.setHours(0, 0, 0, 0);

    const params = actividad;

    let buttons: ModalButton[] = [];

    // Si la actividad está programada para el día de hoy y se tienen permisos de escritura
    if (!(moment(actividad.fechaInicioConMargen).toDate() >= ahora) && this.permisoService.getPermisos().escritura) {
      buttons = [
        {
          texto: this.translateService.instant('CUESTIONARIOS.NOTIFICACION.REALIZAR'),
          type: 'button',
          accion: 'realizarCuestionario',
          params: params,
          cerrar: false
        }
      ];
    }

    const data = {
      origen: this,
      titulo: this.localeTexto.transform(actividad.titulo, this.getCurrentIdioma()),
      mensaje: this.translateService.instant('CUESTIONARIOS.NOTIFICACION.FUTURO'),
      buttons: buttons,
      cerrar: true
    };

    this.alertService.modal.emit(data);
  }

  private getCurrentIdioma(): string {
    // Se recupera el idioma actual.
    return this.translateService.currentLang;
  }

  private gestionarError(error: HttpErrorResponse): void {
    this.alertService.lanzarError(error.status);
  }
}
