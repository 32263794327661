import { Injectable, EventEmitter } from '@angular/core';
import { Actividad, ModalButton } from '../models';
import { AlertService } from './alert.service';
import { AnaliticaService } from './analitica.service';
import { PermisosService } from './permisos.service';

import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { LocaleTextoPipe } from '../../shared/pipes/locale-texto/locale-texto';

/**
 * Servicio para la gestión del actividades de tipo analítica.
 *
 * @author lreverendo
 *
 * @version 01.02.0050
 * @since 01.02.0050
 */
@Injectable({ providedIn: 'root' })
export class GestionActAnaliticaService {
  /**
   * Evento para la creación de una analítica
   */
  public registroAnalitica: EventEmitter<any> = new EventEmitter<Actividad>();
  public visualizarAnalitica: EventEmitter<any> = new EventEmitter<Actividad>();

  constructor(
    private alertService: AlertService,
    private analiticaService: AnaliticaService,
    private permisoService: PermisosService,
    private translateService: TranslateService,
    private localeTexto: LocaleTextoPipe
  ) {}

  public abrirAnalitica(actividad: Actividad, fechaCaducidad: Date): void {
    switch (actividad.estado) {
      case 'VACIO':
        if (moment(actividad.fechaInicioConMargen).toDate() > new Date()) {
          this.analiticaFutura(actividad);
        } else if (this.permisoService.getPermisos().escritura) {
          this.crearAnalitica(actividad);
        }
        break;
      case 'ADHERIDO':
        this.abrirVisualizacionAnalitica(actividad);
        break;
      case 'AUSENCIA':
        this.analiticaCaducada(actividad, fechaCaducidad);
        break;
    }
  }

  private crearAnalitica(actividad: Actividad) {
    this.registroAnalitica.emit(actividad);
  }

  private abrirVisualizacionAnalitica(actividad: Actividad) {
    this.analiticaService.getExtraccionAnalitica(actividad.idInstancia).subscribe((analitica) => {
      this.visualizarAnalitica.emit(analitica);
    });
  }

  private analiticaCaducada(actividad: Actividad, fechaCaducidad: Date) {
    let buttons: ModalButton[] = [];

    // Si la actividad ha caducado hace menos de 30 días.
    if (moment(actividad.fechaInicioSinMargen).toDate() >= fechaCaducidad && this.permisoService.getPermisos().escritura) {
      buttons = [
        {
          texto: this.translateService.instant('CALENDARIO.ANALITICA.NOTIFICACION.REALIZAR'),
          type: 'button',
          accion: 'crearAnalitica',
          params: actividad,
          cerrar: true
        }
      ];
    }
    const data = {
      origen: this,
      titulo: this.localeTexto.transform(actividad.titulo, this.getCurrentIdioma()),
      mensaje: this.translateService.instant('CALENDARIO.ANALITICA.NOTIFICACION.CADUCADO'),
      buttons: buttons,
      cerrar: true
    };

    this.alertService.modal.emit(data);
  }

  private analiticaFutura(actividad: Actividad) {
    const ahora = new Date();
    const params = actividad;
    let buttons: ModalButton[] = [];

    ahora.setDate(ahora.getDate() + 1);
    ahora.setHours(0, 0, 0, 0);

    // Si la actividad está programada para el día de hoy y se tienen permisos de escritura
    if (!(moment(actividad.fechaInicioConMargen).toDate() >= ahora) && this.permisoService.getPermisos().escritura) {
      buttons = [
        {
          texto: this.translateService.instant('CALENDARIO.ANALITICA.NOTIFICACION.REALIZAR'),
          type: 'button',
          accion: 'crearAnalitica',
          params: params,
          cerrar: true
        }
      ];
    }
    const data = {
      origen: this,
      titulo: this.localeTexto.transform(actividad.titulo, this.getCurrentIdioma()),
      mensaje: this.translateService.instant('CALENDARIO.ANALITICA.NOTIFICACION.FUTURO'),
      buttons: buttons,
      cerrar: true
    };

    this.alertService.modal.emit(data);
  }

  private getCurrentIdioma(): string {
    // Se recupera el idioma actual.
    return this.translateService.currentLang;
  }
}
