import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  LoginComponent,
  RecuperacionComponent,
  PrincipalComponent,
  PerfilComponent,
  DatosComponent,
  CuidadoresComponent,
  ContrasenaComponent,
  LeyendaComponent,
  CalendarioComponent,
  PrincipalExternoComponent,
  DiaComponent,
  SemanaComponent,
  MesComponent,
  VisualizacionBiomedidaComponent,
  VisualizacionCuestionarioComponent,
  VisualizacionActividadPersonalComponent,
  ActividadPersonalComponent,
  BiomedidasComponent,
  RegistroBiomedidaComponent,
  HistorialBiomedidaComponent,
  BasculaComponent,
  BasculaImcComponent,
  CoagulometroComponent,
  GlucometroComponent,
  PulsioximetroComponent,
  TensiometroComponent,
  TermometroComponent,
  InsulinaComponent,
  EjercicioComponent,
  HidratosCarbonoComponent,
  DispositivosComponent,
  CuestionariosComponent,
  ResumenCuestionarioComponent,
  RegistroCuestionarioComponent,
  HistorialCuestionarioComponent,
  ListadoCuestionarioComponent,
  InstruccionesComponent,
  SoporteTecnicoComponent,
  PrimerAccesoComponent,
  ElegirCuentaComponent,
  MedicacionComponent,
  NotificacionesComponent,
  MensajeriaComponent,
  MensajesEnviadosComponent,
  MensajesRecibidosComponent,
  VisualizacionMensajeComponent,
  NuevoMensajeComponent,
  ExtraccionAnaliticaComponent,
  VisualizacionExtraccionAnaliticaComponent
} from './index';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { APP_ROUTING } from '../app.routes';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SimplebarAngularModule } from 'simplebar-angular';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxEchartsModule } from 'ngx-echarts';

@NgModule({
  declarations: [
    LoginComponent,
    RecuperacionComponent,
    PrincipalComponent,
    PerfilComponent,
    DatosComponent,
    CuidadoresComponent,
    ContrasenaComponent,
    LeyendaComponent,
    CalendarioComponent,
    PrincipalExternoComponent,
    DiaComponent,
    SemanaComponent,
    MesComponent,
    VisualizacionBiomedidaComponent,
    VisualizacionCuestionarioComponent,
    VisualizacionActividadPersonalComponent,
    ActividadPersonalComponent,
    BiomedidasComponent,
    RegistroBiomedidaComponent,
    HistorialBiomedidaComponent,
    BasculaComponent,
    BasculaImcComponent,
    CoagulometroComponent,
    GlucometroComponent,
    PulsioximetroComponent,
    TensiometroComponent,
    TermometroComponent,
    InsulinaComponent,
    EjercicioComponent,
    HidratosCarbonoComponent,
    DispositivosComponent,
    CuestionariosComponent,
    ResumenCuestionarioComponent,
    RegistroCuestionarioComponent,
    HistorialCuestionarioComponent,
    ListadoCuestionarioComponent,
    InstruccionesComponent,
    SoporteTecnicoComponent,
    PrimerAccesoComponent,
    ElegirCuentaComponent,
    MedicacionComponent,
    NotificacionesComponent,
    MensajeriaComponent,
    MensajesEnviadosComponent,
    MensajesRecibidosComponent,
    VisualizacionMensajeComponent,
    NuevoMensajeComponent,
    ExtraccionAnaliticaComponent,
    VisualizacionExtraccionAnaliticaComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    APP_ROUTING,
    NgbModule,
    SimplebarAngularModule,
    TextMaskModule,
    NgxEchartsModule
  ]
})
export class PagesModule {}
