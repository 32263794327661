import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

const I18N_VALUES = {
  es: {
    weekdays: ['Lun', 'Mar', 'Miér', 'Jue', 'Vie', 'Sáb', 'Dom'],
    months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
  },
  gl: {
    weekdays: ['Lun', 'Mar', 'Mér', 'Xov', 'Ven', 'Sáb', 'Dom'],
    months: ['Xan', 'Feb', 'Mar', 'Abr', 'Mai', 'Xuñ', 'Xul', 'Ago', 'Set', 'Out', 'Nov', 'Dec']
  },
  en: {
    weekdays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  }
};

/**
 * Celendario personalizado.
 *
 * @author lreverendo
 *
 * @version 01.02.0030
 * @since 01.02.0000
 */
@Injectable({ providedIn: 'root' })
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  public getDayAriaLabel(_date: NgbDateStruct): string {
    // TODO
    return '';
  }
  constructor(private translateService: TranslateService) {
    super();
  }

  public getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this.translateService.currentLang].weekdays[weekday - 1];
  }
  public getMonthShortName(month: number): string {
    return I18N_VALUES[this.translateService.currentLang].months[month - 1];
  }
  public getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
}

function padNumber(value: number) {
  if (isNumber(value)) {
    return `0${value}`.slice(-2);
  } else {
    return '';
  }
}

function isNumber(value: any): boolean {
  return !isNaN(toInteger(value));
}

function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

@Injectable({ providedIn: 'root' })
export class CustomNgbDateParserFormatter extends NgbDateParserFormatter {
  constructor(private translateService: TranslateService) {
    super();
  }

  public parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split('/');
      if (dateParts.length === 1 && isNumber(dateParts[0])) {
        return { year: toInteger(dateParts[0]), month: null, day: null };
      } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
        return { year: toInteger(dateParts[1]), month: toInteger(dateParts[0]), day: null };
      } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
        return { year: toInteger(dateParts[2]), month: toInteger(dateParts[1]), day: toInteger(dateParts[0]) };
      }
    }
    return null;
  }

  public format(date: NgbDateStruct): string {
    let stringDate = '';
    if (date) {
      if (this.translateService.currentLang === 'en') {
        stringDate += isNumber(date.month) ? padNumber(date.month) + '/' : '';
        stringDate += isNumber(date.day) ? padNumber(date.day) + '/' : '';
        stringDate += date.year;
      } else {
        stringDate += isNumber(date.day) ? padNumber(date.day) + '/' : '';
        stringDate += isNumber(date.month) ? padNumber(date.month) + '/' : '';
        stringDate += date.year;
      }
    }
    return stringDate;
  }
}
