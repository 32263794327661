<div class="form-group" *ngIf="tas" [formGroup]="forma">
  <label for="tas" class="col-form-label"
    >{{ 'BIOMEDIDAS.TENSIOMETRO.TAS' | translate }} ({{ 'BIOMEDIDAS.VARIABLES.' + tas.alias + '.unidad' | translate }})</label
  >
  <div class="datoCol">
    <input
      type="text"
      [appFormatNumber]="tas.formato"
      appBlockCopyPaste
      class="form-control variable focus"
      id="tas"
      formControlName="tas"
    />
  </div>
</div>
<div
  class="filaError d-none"
  *ngIf="tas"
  [ngClass]="{ 'd-table-row': forma.controls['tas'].dirty && forma.controls['tas'].errors?.maxError }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted"> {{ 'BIOMEDIDAS.TENSIOMETRO.TASMAX' | translate }}{{ tas.max }} </small>
</div>
<div
  class="filaError d-none"
  *ngIf="tas"
  [ngClass]="{ 'd-table-row': forma.controls['tas'].dirty && forma.controls['tas'].errors?.minError }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted"> {{ 'BIOMEDIDAS.TENSIOMETRO.TASMIN' | translate }}{{ tas.min }} </small>
</div>
<div
  class="filaError d-none"
  *ngIf="tas"
  [ngClass]="{ 'd-table-row': forma.controls['tas'].dirty && forma.controls['tas'].errors?.required }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted">
    {{ 'BIOMEDIDAS.TENSIOMETRO.TASREQUERIDO' | translate }}
  </small>
</div>
<div class="form-group" *ngIf="tad" [formGroup]="forma">
  <label for="tad" class="col-form-label"
    >{{ 'BIOMEDIDAS.TENSIOMETRO.TAD' | translate }} ({{ 'BIOMEDIDAS.VARIABLES.' + tad.alias + '.unidad' | translate }})</label
  >
  <div class="datoCol">
    <input type="text" [appFormatNumber]="tad.formato" appBlockCopyPaste class="form-control variable" id="tad" formControlName="tad" />
  </div>
</div>
<div
  class="filaError d-none"
  *ngIf="tad"
  [ngClass]="{ 'd-table-row': forma.controls['tad'].dirty && forma.controls['tad'].errors?.maxError }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted"> {{ 'BIOMEDIDAS.TENSIOMETRO.TADMAX' | translate }}{{ tad.max }} </small>
</div>
<div
  class="filaError d-none"
  *ngIf="tad"
  [ngClass]="{ 'd-table-row': forma.controls['tad'].dirty && forma.controls['tad'].errors?.minError }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted"> {{ 'BIOMEDIDAS.TENSIOMETRO.TADMIN' | translate }}{{ tad.max }} </small>
</div>
<div
  class="filaError d-none"
  *ngIf="tad && tas"
  [ngClass]="{ 'd-table-row': forma.errors && forma.errors['tasMenorTad'] && forma.controls['tad'].valid }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted">
    {{ 'BIOMEDIDAS.TENSIOMETRO.TASTAD' | translate }}
  </small>
</div>
<div
  class="filaError d-none"
  *ngIf="tad"
  [ngClass]="{ 'd-table-row': forma.controls['tad'].dirty && forma.controls['tad'].errors?.required }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted">
    {{ 'BIOMEDIDAS.TENSIOMETRO.TADREQUERIDO' | translate }}
  </small>
</div>
<div class="form-group" *ngIf="pulso" [formGroup]="forma">
  <label for="pulso" class="col-form-label"
    >{{ 'BIOMEDIDAS.TENSIOMETRO.PULSO' | translate }} ({{ 'BIOMEDIDAS.VARIABLES.' + pulso.alias + '.unidad' | translate }})</label
  >
  <div class="datoCol">
    <input
      type="text"
      [appFormatNumber]="pulso.formato"
      appBlockCopyPaste
      class="form-control variable"
      id="pulso"
      formControlName="pulso"
    />
  </div>
</div>
<div
  class="filaError d-none"
  *ngIf="pulso"
  [ngClass]="{ 'd-table-row': forma.controls['pulso'].dirty && forma.controls['pulso'].errors?.maxError }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted"> {{ 'BIOMEDIDAS.TENSIOMETRO.PULSOMAX' | translate }}{{ pulso.max }} </small>
</div>
<div
  class="filaError d-none"
  *ngIf="pulso"
  [ngClass]="{ 'd-table-row': forma.controls['pulso'].dirty && forma.controls['pulso'].errors?.minError }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted"> {{ 'BIOMEDIDAS.TENSIOMETRO.PULSOMIN' | translate }}{{ pulso.min }} </small>
</div>
<div
  class="filaError d-none"
  *ngIf="pulso"
  [ngClass]="{ 'd-table-row': forma.controls['pulso'].dirty && forma.controls['pulso'].errors?.required }"
>
  <div class="d-none d-sm-table-cell"></div>
  <small class="error form-text text-muted">
    {{ 'BIOMEDIDAS.TENSIOMETRO.PULSOREQUERIDO' | translate }}
  </small>
</div>
