/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./resumen-cuestionario.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./resumen-cuestionario.component";
var styles_ResumenCuestionarioComponent = [i0.styles];
var RenderType_ResumenCuestionarioComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ResumenCuestionarioComponent, data: {} });
export { RenderType_ResumenCuestionarioComponent as RenderType_ResumenCuestionarioComponent };
function View_ResumenCuestionarioComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.respuesta.descripcion; _ck(_v, 1, 0, currVal_0); }); }
function View_ResumenCuestionarioComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["-"]))], null, null); }
function View_ResumenCuestionarioComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "tr", [["style", "margin-bottom: 1em"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(2, { lineaPar: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 2, "td", [["class", "pregunta"]], [[8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickPregunta(_v.context.$implicit.pregunta.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { preguntaResumen: 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 4, "td", [["class", "respuesta"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResumenCuestionarioComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResumenCuestionarioComponent_3)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, ((_v.context.index % 2) == 0)); _ck(_v, 1, 0, currVal_0); var currVal_2 = "pregunta"; var currVal_3 = _ck(_v, 5, 0, (_v.context.$implicit.pregunta.id > (0 - 1))); _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_4 = (_v.context.$implicit.respuesta.id != null); _ck(_v, 8, 0, currVal_4); var currVal_5 = (_v.context.$implicit.respuesta.id == null); _ck(_v, 10, 0, currVal_5); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.pregunta.nombre; _ck(_v, 3, 0, currVal_1); }); }
export function View_ResumenCuestionarioComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "table", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResumenCuestionarioComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ResumenCuestionarioComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-resumen-cuestionario", [], null, null, null, View_ResumenCuestionarioComponent_0, RenderType_ResumenCuestionarioComponent)), i1.ɵdid(1, 573440, null, 0, i3.ResumenCuestionarioComponent, [], null, null)], null, null); }
var ResumenCuestionarioComponentNgFactory = i1.ɵccf("app-resumen-cuestionario", i3.ResumenCuestionarioComponent, View_ResumenCuestionarioComponent_Host_0, { items: "items" }, { abrirPregunta: "abrirPregunta" }, []);
export { ResumenCuestionarioComponentNgFactory as ResumenCuestionarioComponentNgFactory };
