import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { VariableClinica } from '../../../../../core/models';
import { DispositivoGenerico } from '../dispositivo-generico.model';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';

/**
 *  Componente que representa al dispositivo Pulxioxímetro.
 *
 * @author lreverendo
 *
 * @version 01.02.0070
 * @since 01.02.0000
 */
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tbody [pulsioximetro]',
  templateUrl: 'pulsioximetro.component.html',
  styleUrls: [
    '../../../biomedidas.component.css',
    '../../../views/registro-biomedida/registro-biomedida.component.css',
    './pulsioximetro.component.css'
  ]
})
export class PulsioximetroComponent extends DispositivoGenerico implements OnInit {
  public sato2: VariableClinica;
  public pulso: VariableClinica;

  constructor() {
    super();
  }

  public ngOnInit(): void {
    // Se buscan las variables de saturación y pulso.
    this.sato2 = this.variablesClinicas.find((v) => v.alias === 'SAT_O2');
    this.pulso = this.variablesClinicas.find((v) => v.alias === 'PPM');

    if (this.sato2) {
      this.forma.addControl(
        'sat',
        new FormControl('', [
          Validators.required,
          Validators.pattern(this.getPatronFormato(this.sato2.formato)),
          CustomValidators.porRango(this.sato2.min, this.sato2.max)
        ])
      );
    }

    if (this.pulso) {
      this.forma.addControl(
        'pulso',
        new FormControl('', [
          Validators.required,
          Validators.pattern(this.getPatronFormato(this.pulso.formato)),
          CustomValidators.porRango(this.pulso.min, this.pulso.max)
        ])
      );
    }
  }

  public getData(): VariableClinica[] {
    const result: VariableClinica[] = [];

    if (this.sato2) {
      const sat = this.forma.value.sat.replace(',', '.');
      if (Number(sat) === NaN) {
        return [];
      }
      this.sato2.valor = sat;
      result.push(this.sato2);
    }
    if (this.pulso) {
      const pulso = this.forma.value.pulso.replace(',', '.');

      if (Number(pulso) === NaN) {
        return [];
      }
      this.pulso.valor = pulso;
      result.push(this.pulso);
    }

    return result;
  }
}
