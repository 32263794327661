import { Directive, ElementRef, HostListener, Input, Renderer2, OnInit } from '@angular/core';

/**
 *  Directiva que controla el formato de los números, entero o decimal.
 *
 * @author lreverendo
 *
 * @version 01.02.0070
 * @since 01.02.0070
 */
@Directive({
  selector: '[appFormatNumber]'
})
export class FormatNumberDirective implements OnInit {
  @Input() appFormatNumber: string;
  private decimal: boolean;
  /* private carEspeciales: Array<string> = [ 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete', 'Decimal', 'Left', 'Right', 'Del']; */
  private carEspeciales: Array<number> = [8, 9, 35, 36, 37, 38, 39, 45, 46, 109];

  constructor(public renderer: Renderer2, private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.decimal = this.appFormatNumber.trim().toUpperCase() === 'DECIMAL';
    const inputMode = this.decimal ? 'decimal' : 'numeric';
    this.renderer.setAttribute(this.elementRef.nativeElement, 'inputmode', inputMode);
  }

  // Validación para dispositivos móviles
  @HostListener('beforeinput', ['$event'])
  public onBeforeInput(event): void {
    const before: string = event.target.value;
    const data: string = event.data;

    // Si se escribe algo y el número ya escrito tiene 8 dígitos no permite seguir escribiendo
    if (data && before.length === 8) {
      event.preventDefault();
      return;
    }

    if (this.decimal) {
      const posicion = event.target.selectionStart;

      // Si ya existía un punto (o coma) se recuperan las dos partes del número
      const partsBefore = before.split(new RegExp('\\.|\\,', 'g'));

      // Si se escribe algo y después del punto (o coma) hay 2 dígitos, no permite escribir después del punto (o coma)
      if (data && partsBefore.length > 1 && partsBefore[1].length > 1) {
        if ((before.indexOf('.') > -1 && posicion > before.indexOf('.')) || (before.indexOf(',') > -1 && posicion > before.indexOf(','))) {
          event.preventDefault();
          return;
        }
      }

      // Si se escribe un punto o una coma y ya existía un punto o una coma, o no hay nada escrito, no permite escribir
      // Además si se escribe un punto o una coma dejando más de dos dígitos en la parte decimal, no permite escribir
      if ((data === '.' || data === ',') && (partsBefore.length > 1 || before === '' || posicion < before.length - 2 || posicion === 7)) {
        event.preventDefault();
        return;
      }

      // Si se escribe algo que no es un dígito (0-9) o un punto o una coma, no permite escribir
      if (data && !(data.match('[0-9.]') || data.match('[0-9,]'))) {
        event.preventDefault();
        return;
      }
    } else {
      // Si se escribe algo que no es un dígito (0-9), no permite escribir
      if (data && data.match('[^0-9]')) {
        event.preventDefault();
        return;
      }
    }
  }

  // Validación para dispositivos móviles
  @HostListener('input', ['$event'])
  public onInput(_event): void {
    const data = this.elementRef.nativeElement.value;

    if (this.decimal) {
      let primeroInsertado = true;
      // Se remplaza todo lo que no es dígito (0-9) ni punto ni coma por una cadena vacía dando la sensación de que no permite escribir
      this.elementRef.nativeElement.value = data
        .replace(/[^0-9(.|,)]/g, '')
        .replace(/[\.\%]/g, function (match, offset, all) {
          // Si ya existe un punto remplaza el punto insertado por una cadena vacía dando la sensación de que no permite escribir.
          const index = all.indexOf('.');
          let insertar = true;
          if (index === offset) {
            if (index < all.length - 3) {
              insertar = false;
              primeroInsertado = false;
            }
          } else {
            if (primeroInsertado && offset - index < 3) {
              insertar = false;
            }
          }
          return match === '.' ? (insertar ? '.' : '') : '';
        })
        .replace(/[\,\%]/g, function (match, offset, all) {
          // Si ya existe un punto remplaza el punto insertado por una cadena vacía dando la sensación de que no permite escribir.
          const index = all.indexOf(',');
          let insertar = true;
          if (index === offset) {
            if (index < all.length - 3) {
              insertar = false;
              primeroInsertado = false;
            }
          } else {
            if (primeroInsertado && offset - index < 3) {
              insertar = false;
            }
          }
          return match === ',' ? (insertar ? ',' : '') : '';
        });
    } else {
      // Se remplaza todo lo que no es dígito (0-9) por una cadena vacía dando la sensación de que no permite escribir
      this.elementRef.nativeElement.value = data.replace(/[^0-9]/g, '');
    }
  }

  // Se incluye este método para IE
  @HostListener('keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent): void {
    const actual: String = this.elementRef.nativeElement.value;
    const data: string = event.key;

    // Permite los caracteres especiales
    if (this.carEspeciales.indexOf(event.keyCode) !== -1) {
      return;
    }

    // Si se escribe algo y el número ya escrito tiene 8 dígitos no permite seguir escribiendo
    if (actual.length === 8) {
      event.preventDefault();
      return;
    }

    if (this.decimal) {
      const posicion = this.elementRef.nativeElement.selectionStart;

      // Si ya existía un punto (o coma) se recuperan las dos partes del número
      const partsactual = actual.split(new RegExp('\\.|\\,', 'g'));

      // Si se escribe algo y después del punto (o coma) hay 2 dígitos, no permite escribir después del punto (o coma)
      if (data && partsactual.length > 1 && partsactual[1].length > 1) {
        if ((actual.indexOf('.') > -1 && posicion > actual.indexOf('.')) || (actual.indexOf(',') > -1 && posicion > actual.indexOf(','))) {
          event.preventDefault();
          return;
        }
      }

      // Si se escribe un punto o una coma y ya existía un punto o una coma, o no hay nada escrito, no permite escribir
      // 110 es el código del punto decimal en los dispositivos móviles
      // Además si se escribe un punto o una coma dejando más de dos dígitos en la parte decimal, no permite escribir
      if (
        (data === '.' || data === ',' || event.keyCode === 110) &&
        (partsactual.length > 1 || actual === '' || posicion < actual.length - 2 || posicion === 7)
      ) {
        event.preventDefault();
        return;
      }

      // Si se escribe algo que no es un dígito (0-9) o un punto (o coma), no permite escribir
      // 110 es el código del punto decimal en los dispositivos móviles
      if (data && !(data.match('[0-9.]') || data.match('[0-9,]') || event.keyCode === 110)) {
        event.preventDefault();
        return;
      }
    } else {
      // Si se escribe algo que no es un dígito (0-9), no permite escribir
      if (data && !data.match('[0-9]')) {
        event.preventDefault();
        return;
      }
    }
  }
}
