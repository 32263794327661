<div class="container">
  <div class="row selec-fecha">
    <div class="selPag col-1 offset-sm-1 offset-lg-2" (click)="restar()">
      <i class="icon-flechaIzq"></i>
    </div>
    <div class="col-10 col-sm-8 col-lg-6 fecha">
      <h3>{{ fecha.getTime() | mes: getCurrentIdioma() }} {{ fecha | date: 'yyyy' }}</h3>
    </div>
    <div class="selPag col-1" (click)="sumar()">
      <i class="icon-flechaDcha"></i>
    </div>
  </div>

  <div class="row contenido">
    <div class="col-12 eventos-cabecera">
      <table class="table table-bordered" style="border-bottom: 0px">
        <tr>
          <th scope="col" *ngFor="let item of [1, 2, 3, 4, 5, 6, 0]" class="diasSemana">
            {{ item | dia: getCurrentIdioma() }}
            <br />
          </th>
        </tr>
      </table>
    </div>
    <div class="col-12 eventos-contenido" data-simplebar>
      <table class="table table-bordered">
        <tr *ngIf="loader.active">
          <td colspan="7">
            <loader [for]="[operation.recuperarActividades]"></loader>
          </td>
        </tr>
        <tr *ngFor="let semana of mes" [ngClass]="{ 'd-none': loader.active && loader.status.operation === 6 }">
          <td *ngFor="let dia of semana" (click)="irDia(dia.valor)" class="dia">
            <div class="diaMes" [ngClass]="{ otro: dia.otro }">
              <p style="margin: 0" [ngClass]="{ hoy: (dia.valor | date: 'ddMMyyyy') == (hoy | date: 'ddMMyyyy') }">
                {{ dia.dia }}
              </p>
              <div *ngIf="dia.todoeldia" class="todoeldia"></div>
              <div *ngIf="dia.pautadas" class="pautada"></div>
              <div *ngIf="dia.realizadas" class="realizada"></div>
              <div *ngIf="dia.caducadas" class="caducada"></div>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>

  <div class="row linkActPersonal" *appIfPermisoEscritura>
    <div class="col-12">
      <i class="icon-mas masActPersonal"></i>
      <div class="actPersonal" (click)="crearActividad()">
        {{ 'CALENDARIO.PERSONAL.CREAR.TITULOPAG' | translate }}
      </div>
    </div>
  </div>
</div>
