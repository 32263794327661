import { HttpClient } from '@angular/common/http';
import { AutenticacionService } from './autenticacion.service';
import { AppConfigService } from './app-config.service';
import { sprintf } from 'sprintf-js';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./autenticacion.service";
/**
 * Servicio para la gestión de los cuestionarios.
 *
 * @author lreverendo
 * @author dvila
 *
 * @version 01.02.0040
 * @since 01.02.0000
 */
var CuestionariosService = /** @class */ (function () {
    function CuestionariosService(http, autenticacionService) {
        this.http = http;
        this.autenticacionService = autenticacionService;
    }
    CuestionariosService.prototype.getHistorico = function () {
        var dniPaciente = this.autenticacionService.getLoginPaciente();
        var url = sprintf(AppConfigService.urls.recuperarHistorico, dniPaciente);
        return this.http.get(url);
    };
    CuestionariosService.prototype.getCuestionarioRealizado = function (id, cod) {
        var dniPaciente = this.autenticacionService.getLoginPaciente();
        var url = sprintf(AppConfigService.urls.recuperarCuestionarioRealizado, dniPaciente, id, cod);
        return this.http.get(url);
    };
    CuestionariosService.prototype.getCuestionario = function (cod, locale) {
        if (locale === void 0) { locale = 'gl'; }
        var dniPaciente = this.autenticacionService.getLoginPaciente();
        var url = sprintf(AppConfigService.urls.recuperarCuestionario, cod, dniPaciente, locale);
        return this.http.get(url);
    };
    CuestionariosService.prototype.enviarCuestionario = function (body) {
        var dniPaciente = this.autenticacionService.getLoginPaciente();
        var url = sprintf(AppConfigService.urls.enviarCuestionario, dniPaciente);
        return this.http.post(url, body);
    };
    CuestionariosService.ngInjectableDef = i0.defineInjectable({ factory: function CuestionariosService_Factory() { return new CuestionariosService(i0.inject(i1.HttpClient), i0.inject(i2.AutenticacionService)); }, token: CuestionariosService, providedIn: "root" });
    return CuestionariosService;
}());
export { CuestionariosService };
