var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { TranslateService } from '@ngx-translate/core';
import { AlertService, CalendarioService, DispositivoService, PerfilService, LoaderService } from '../../../../core/services';
import { VistaCalendario } from '../vista-calendario.model';
/**
 * Componente para la vista diaria del calendario.
 *
 * @author lreverendo
 *
 * @version 01.02.0050
 * @since 01.02.0000
 */
var DiaComponent = /** @class */ (function (_super) {
    __extends(DiaComponent, _super);
    function DiaComponent(loader, alertService, calendarioService, perfilService, dispositivoService, translateService) {
        var _this = _super.call(this, alertService, calendarioService, perfilService, dispositivoService, translateService) || this;
        _this.loader = loader;
        _this.alertService = alertService;
        _this.calendarioService = calendarioService;
        _this.perfilService = perfilService;
        _this.dispositivoService = dispositivoService;
        // Subscripción al evento reload.
        // Se recarga la vista diaria.
        _this.subs.push(_this.calendarioService.reload.subscribe(function () {
            var fecha = new Date();
            _this.dia = fecha;
        }));
        return _this;
    }
    DiaComponent.prototype.actualizarActividades = function (actividades) {
        this.actividadesTratadas = actividades;
    };
    DiaComponent.prototype.sumar = function () {
        // Función que cambia la fecha a mostrar a la del día siguiente y carga los datos de ese día.
        var fecha = new Date(this.dia);
        fecha.setDate(fecha.getDate() + 1);
        this.dia = fecha;
        this.cargarActividades();
    };
    DiaComponent.prototype.restar = function () {
        // Función que cambia la fecha a mostrar a la del día anterior y carga los datos de ese día.
        var fecha = new Date(this.dia);
        fecha.setDate(fecha.getDate() - 1);
        this.dia = fecha;
        this.cargarActividades();
    };
    DiaComponent.prototype.calcularFechaInicial = function () {
        this.dia.setHours(0, 0, 0, 0);
        this.fechaIni = new Date(this.dia);
    };
    DiaComponent.prototype.calcularFechaFinal = function () {
        var fechaFin = new Date(this.dia);
        fechaFin.setDate(fechaFin.getDate() + 1);
        this.fechaFin = fechaFin;
    };
    return DiaComponent;
}(VistaCalendario));
export { DiaComponent };
