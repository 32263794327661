import { Injectable, EventEmitter } from '@angular/core';
import { UserIdleService, UserIdleConfig } from 'angular-user-idle';
import { LoginService } from './login.service';
import { Router } from '@angular/router';
import { AppConfigService } from './app-config.service';
import * as moment from 'moment';
import { merge, fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

declare var $: any;

/**
 * Servicio para la gestión de la autenticación.
 *
 * @author lreverendo
 * @author aarodriguezo
 *
 * @version 01.02.0250
 * @since 01.02.0000
 */
@Injectable({ providedIn: 'root' })
export class AutenticacionService {
  public cierreSesionEvent: EventEmitter<{ errorSesion: boolean }> = new EventEmitter<{ errorSesion: boolean }>();

  private USUARIO = 'usuario';
  private CENTRO = 'centro';
  private LOGUEADO = 'logueado';
  private ACCESS_TOKEN = 'accessToken';
  private ACCESS_EXPIRATION = 'accessExpiration';
  public operacionFinalizada = new Subject();

  constructor(private userIdle: UserIdleService, private loginService: LoginService, private router: Router) {}

  public configurarInactividad(): void {
    const config: UserIdleConfig = { idle: AppConfigService.tiempoSesion, timeout: 0, ping: 0 };
    this.userIdle.setConfigValues(config);
    this.userIdle.setCustomActivityEvents(
      merge(
        fromEvent(window, 'mousemove'),
        fromEvent(window, 'resize'),
        fromEvent(document, 'keydown'),
        fromEvent(document, 'touchstart'),
        fromEvent(document, 'touchend'),
        fromEvent(window, 'wheel')
      )
    );
  }

  public isUsuarioAutenticado(): boolean {
    return this.getLoginUsuario() != null;
  }

  public isPacienteSeleccionado(): boolean {
    return this.getLoginPaciente() != null;
  }

  public isAccessTokenExpirado(): boolean {
    try {
      let expirado = true;
      const accessExpiration = sessionStorage.getItem(this.ACCESS_EXPIRATION);
      if (accessExpiration != null) {
        const fechaActual = moment().utcOffset(0);
        const fechaExpiracionSesion = moment(parseInt(accessExpiration, 10));
        expirado = !(fechaExpiracionSesion > fechaActual);
      }
      return expirado;
    } catch (e) {
      this.router.navigate(['/error']);
    }
  }

  public getLoginPaciente(): string {
    try {
      return sessionStorage.getItem(this.USUARIO);
    } catch (e) {
      this.router.navigate(['/error']);
    }
  }

  public setLoginPaciente(loginUsuario: string): void {
    try {
      sessionStorage.setItem(this.USUARIO, loginUsuario);
    } catch (e) {
      this.router.navigate(['/error']);
    }
  }

  
  public getCentroPaciente(): string {
    try {
      return sessionStorage.getItem(this.CENTRO);
    } catch (e) {
      this.router.navigate(['/error']);
    }
  }
  public setCentroPaciente(codCentro: string): void {
    try {
      sessionStorage.setItem(this.CENTRO, codCentro);
    } catch (e) {
      this.router.navigate(['/error']);
    }
  }

  public getLoginUsuario(): string {
    try {
      return sessionStorage.getItem(this.LOGUEADO);
    } catch (e) {
      this.router.navigate(['/error']);
    }
  }

  public setLoginUsuario(loginUsuario: string): void {
    try {
      sessionStorage.setItem(this.LOGUEADO, loginUsuario);
      this.userIdle.startWatching();
      this.userIdle.onTimerStart().pipe(takeUntil(this.operacionFinalizada)).subscribe(this.gestionarInactividad.bind(this));
    } catch (e) {
      this.router.navigate(['/error']);
    }
  }

  private gestionarInactividad(): void {
    this.operacionFinalizada.next();
    this.userIdle.stopTimer();
    this.userIdle.stopWatching();
    this.lanzarErrorSesion();
  }

  public getAccessToken(): string {
    try {
      return sessionStorage.getItem(this.ACCESS_TOKEN);
    } catch (e) {
      this.router.navigate(['/error']);
    }
  }

  public setAccessToken(accessToken: string): void {
    try {
      sessionStorage.setItem(this.ACCESS_TOKEN, accessToken);
    } catch (e) {
      this.router.navigate(['/error']);
    }
  }

  public setAccessExpiration(accessExpiration: string): void {
    try {
      sessionStorage.setItem(this.ACCESS_EXPIRATION, accessExpiration);
    } catch (e) {
      this.router.navigate(['/error']);
    }
  }

  public borrarDatosAutenticacion(): void {
    try {
      sessionStorage.removeItem(this.USUARIO);
      sessionStorage.removeItem(this.LOGUEADO);
      sessionStorage.removeItem(this.ACCESS_TOKEN);
      sessionStorage.removeItem(this.ACCESS_EXPIRATION);
      sessionStorage.removeItem('pdfjs.history');
    } catch (e) {
      this.router.navigate(['/error']);
    }
  }

  public eliminarModals(): void {
    $('#modal').modal('hide');
  }

  public borrarPdfHistory(): void {
    try {
      sessionStorage.removeItem('pdfjs.history');
    } catch (e) {
      this.router.navigate(['/error']);
    }
  }

  public lanzarErrorSesion(): void {
    if (this.isUsuarioAutenticado()) {
      this.loginService.logout().subscribe(
        () => {
          this.salir(true);
        },
        () => {
          this.salir(true);
        }
      );
    }
  }

  public logout(): void {
    this.loginService.logout().subscribe(
      () => {
        this.salir(false);
      },
      () => {
        this.salir(false);
      }
    );
  }

  public salir(sesion: boolean): void {
    this.borrarDatosAutenticacion();
    this.cierreSesionEvent.next({ errorSesion: sesion });
  }
}
