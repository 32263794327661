import { Component } from '@angular/core';

/**
 * Componente con la leyenda de colores.
 *
 * @author lreverendo
 *
 * @version 01.02.0000
 * @since 01.02.0000
 */
@Component({
  selector: 'app-leyenda',
  templateUrl: './leyenda.component.html',
  styleUrls: ['../../calendario.component.css', './leyenda.component.css']
})
export class LeyendaComponent {
  constructor() {}
}
