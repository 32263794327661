import { HttpClient } from '@angular/common/http';
import { AutenticacionService } from './autenticacion.service';
import { AppConfigService } from './app-config.service';
import { sprintf } from 'sprintf-js';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./autenticacion.service";
/**
 * Servicio para la gestión de la medicacion del paciente.
 *
 * @author lreverendo
 *
 * @version 01.02.0002
 * @since 01.02.0000
 */
var MedicacionService = /** @class */ (function () {
    function MedicacionService(http, autenticacionService) {
        this.http = http;
        this.autenticacionService = autenticacionService;
    }
    MedicacionService.prototype.getMedicacion = function () {
        var usuario = this.autenticacionService.getLoginPaciente();
        var url = sprintf(AppConfigService.urls.obtenerMedicacion, usuario);
        return this.http.get(url);
    };
    MedicacionService.prototype.getHojaMedicacion = function (codigo) {
        var usuario = this.autenticacionService.getLoginPaciente();
        var url = sprintf(AppConfigService.urls.obtenerlHojaMedicacion, usuario, codigo);
        return this.http.get(url);
    };
    MedicacionService.ngInjectableDef = i0.defineInjectable({ factory: function MedicacionService_Factory() { return new MedicacionService(i0.inject(i1.HttpClient), i0.inject(i2.AutenticacionService)); }, token: MedicacionService, providedIn: "root" });
    return MedicacionService;
}());
export { MedicacionService };
