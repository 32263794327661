import { EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoaderStatus } from '../models/loaderStatus.model';
import { Operation } from '../../core/services/app-config.service';
import * as i0 from "@angular/core";
/**
 * Servicio para la gestión del loader.
 *
 * @author priveiro
 *
 * @version 01.02.0002
 * @since 01.02.0000
 */
var LoaderService = /** @class */ (function () {
    function LoaderService() {
        /**
         * Evento que indica si se trata de una redireccion.
         */
        this.redireccionar = new EventEmitter();
        this.active = false;
        this.status = new LoaderStatus();
        this.resetStatus();
        this.loaderStatusSubject = new BehaviorSubject(this.status);
        this.loaderStatus$ = this.loaderStatusSubject.asObservable();
    }
    LoaderService.prototype.resetStatus = function () {
        this.status.enabled = false;
        this.status.lockScreen = false;
        this.status.operation = Operation.unknown;
        this.status.requireSubscription = false;
    };
    LoaderService.prototype.showLoader = function (operationInfo) {
        if (operationInfo === void 0) { operationInfo = null; }
        this.status.enabled = true;
        if (operationInfo !== null) {
            this.status.lockScreen = operationInfo.lockScreen;
            this.status.operation = operationInfo.operation;
            this.status.requireSubscription = operationInfo.requireSubscription;
        }
        this.loaderStatusSubject.next(this.status);
        this.active = true;
    };
    LoaderService.prototype.hideLoader = function () {
        this.resetStatus();
        this.loaderStatusSubject.next(this.status);
        this.active = false;
    };
    LoaderService.ngInjectableDef = i0.defineInjectable({ factory: function LoaderService_Factory() { return new LoaderService(); }, token: LoaderService, providedIn: "root" });
    return LoaderService;
}());
export { LoaderService };
