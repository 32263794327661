import { Component, HostListener, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AlertService, CalendarioService, PerfilService, LoaderService, DispositivoService } from '../../../../core/services';
import { Actividad } from '../../../../core/models';

import { VistaCalendario } from '../vista-calendario.model';

import * as moment from 'moment';
import { SimplebarAngularComponent } from 'simplebar-angular';

/**
 * Componente para la vista semanal del calendario.
 *
 * @author lreverendo
 *
 * @version 01.02.0090
 * @since 01.02.0000
 */
@Component({
  selector: 'app-semana',
  templateUrl: 'semana.component.html',
  styleUrls: ['../../calendario.component.css', './semana.component.css']
})
export class SemanaComponent extends VistaCalendario {
  @ViewChild('scroll') eventosScroll: SimplebarAngularComponent;
  public actividadSeleccionada: Actividad = null;
  public fechas: number[];
  public dataset: any[] = [];

  public hoy: number;
  private fecha: Date;
  private lastPopoverRef: any;

  constructor(
    public loader: LoaderService,
    public alertService: AlertService,
    public calendarioService: CalendarioService,
    public perfilService: PerfilService,
    public dispositivoService: DispositivoService,
    translateService: TranslateService
  ) {
    super(alertService, calendarioService, perfilService, dispositivoService, translateService);

    // Inicialización de los datos.
    const hoy = new Date();
    hoy.setHours(0, 0, 0, 0);
    this.hoy = new Date(+hoy).getTime();
    this.fecha = new Date();

    // Subscripción al evento reload.
    // Se recarga la vista semanal.
    this.subs.push(
      this.calendarioService.reload.subscribe(() => {
        this.hoy = new Date(+hoy).getTime();
        this.fecha = new Date();
        this.cargarActividades();
      })
    );
  }

  public actualizarActividades(actividades: Actividad[]): void {
    this.actividadSeleccionada = null;

    this.actividadesTratadas = actividades;
    this.guardarActividadesDataset();
  }

  public calcularFechaInicial(): void {
    // Cálculo de la fecha inicial.
    const numeroDia = this.fecha.getDay() || 7;
    let diasHastaLunes;
    if (numeroDia === 1) {
      diasHastaLunes = 0;
    } else {
      diasHastaLunes = numeroDia - 1;
    }
    const primerDiaAMostrar = new Date(+this.fecha);
    primerDiaAMostrar.setDate(primerDiaAMostrar.getDate() - diasHastaLunes);
    this.fechaIni = new Date(primerDiaAMostrar.getFullYear(), primerDiaAMostrar.getMonth(), primerDiaAMostrar.getDate(), 0, 0, 0, 0);
    // Se prepara la semana.
    this.prepararSemana();
  }

  public calcularFechaFinal(): void {
    // Cálculo de la fecha final.
    const numeroDia = this.fecha.getDay();
    let diasHastaDomingo;
    if (numeroDia === 0) {
      diasHastaDomingo = 1;
    } else {
      // Se calculan 8 días ya que la fecha final tendrá una hora de 00:00.
      diasHastaDomingo = 8 - numeroDia;
    }
    const ultimoDiaAMostrar = new Date(+this.fecha);
    ultimoDiaAMostrar.setDate(ultimoDiaAMostrar.getDate() + diasHastaDomingo);
    this.fechaFin = new Date(ultimoDiaAMostrar.getFullYear(), ultimoDiaAMostrar.getMonth(), ultimoDiaAMostrar.getDate(), 0, 0, 0, 0);
  }

  public restar(): void {
    // Función que cambia la fecha a mostrar a la de la semana anterior y carga los datos de esa semana.
    this.fecha.setDate(this.fecha.getDate() - 7);
    this.cargarActividades();
  }

  public sumar(): void {
    // Función que cambia la fecha a mostrar a la de la semana posterior y carga los datos de esa semana.
    this.fecha.setDate(this.fecha.getDate() + 7);
    this.cargarActividades();
  }

  private guardarActividadesDataset(): void {
    this.dataset = [];
    // Se guardan las actividades en su fecha correspondiente.
    for (const fecha of this.fechas) {
      const fechaFin = new Date(fecha);
      fechaFin.setDate(fechaFin.getDate() + 1);
      this.dataset.push({
        fecha: fecha,
        actividades: this.actividadesTratadas.filter(
          (a) =>
            moment(a.fechaInicioSinMargen).toDate().getTime() >= fecha &&
            ((moment(a.fechaFinalSinMargen).toDate().getTime() < fechaFin.getTime() &&
              moment(a.fechaInicioSinMargen).toDate().getTime() === moment(a.fechaFinalSinMargen).toDate().getTime()) ||
              (moment(a.fechaFinalSinMargen).toDate().getTime() <= fechaFin.getTime() &&
                moment(a.fechaInicioSinMargen).toDate().getTime() !== moment(a.fechaFinalSinMargen).toDate().getTime()))
        )
      });
    }

    const instancia = this;

    this.eventosScroll.SimpleBar.getScrollElement().addEventListener('scroll', function (_event) {
      if (instancia.lastPopoverRef) {
        instancia.lastPopoverRef.close();
        instancia.lastPopoverRef = null;
      }
    });
  }

  private prepararSemana(): void {
    // Método para preparar la semana.
    this.fechas = [];
    this.fechas.push(this.fechaIni.getTime());
    const fechaAux = new Date(this.fechaIni.getTime());
    fechaAux.setHours(0, 0, 0, 0);
    for (let i = 1; i < 7; i++) {
      fechaAux.setDate(fechaAux.getDate() + 1);
      this.fechas.push(fechaAux.getTime());
    }
  }

  //////////////////////////////////////////////////////////////////
  // Gestión de los popovers.
  //////////////////////////////////////////////////////////////////

  @HostListener('document:click', ['$event'])
  public clickOutside(event): void {
    // Método que escucha el evento click en el documento.

    // Si existe lastPopoverRef y el evento click se realizó fuera de este.
    if (this.lastPopoverRef && !this.lastPopoverRef._elementRef.nativeElement.contains(event.target)) {
      this.lastPopoverRef.close();
      this.lastPopoverRef = null;
    }
  }

  public seleccionarActividad(popReference, actividad: Actividad): void {
    popReference.container = 'body';
    // Si ya existe una última referencia y la nueva referencia es distinta.
    if (this.lastPopoverRef && this.lastPopoverRef !== popReference) {
      this.lastPopoverRef.close();
    }
    // Registro de la nueva referencia.
    this.lastPopoverRef = popReference;
    this.actividadSeleccionada = actividad;
  }
}
