import { Injectable } from '@angular/core';
import { Dispositivo } from '../models/dispositivo.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { BiomedidasService } from './biomedidas.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertService } from './alert.service';
import { Actividad } from '../models';

/**
 * Servicio para la gestión de los dispositivos.
 *
 * @author lreverendo
 * @author aarodriguezo
 *
 * @version 01.02.0200
 * @since 01.02.0000
 */
@Injectable({ providedIn: 'root' })
export class DispositivoService {
  private dispositivos: Dispositivo[] = [];

  /**
   * Devuelve una lista con los dispositivos disponibles.
   *
   * Este flujo no se cierra automáticamente después de la primera respuesta,
   * sino que continuará enviando datos cuando haya una actualización de los mismos.
   * Este flujo nunca devolverá un error. Hay que suscribirse al flujo error$ para ello.
   */
  public dispositivos$: Observable<Dispositivo[]>;
  private dispositivosSubject: BehaviorSubject<Dispositivo[]>;

  constructor(private biomedidasService: BiomedidasService, private alertService: AlertService) {
    this.dispositivosSubject = new BehaviorSubject(null);
    this.dispositivos$ = this.dispositivosSubject.asObservable();
  }

  public cargarDispositivos(): void {
    if (this.dispositivos.length === 0) {
      this.biomedidasService.getDispositivos().subscribe(this.filtrarDispositivos.bind(this), this.gestionarError.bind(this));
    }
  }

  public getDispositivos(): Dispositivo[] {
    return this.dispositivos;
  }

  public setDispositivos(dispositivos: Dispositivo[]): Dispositivo[] {
    this.filtrarDispositivos(dispositivos);
    return this.dispositivos;
  }

  private filtrarDispositivos(dispositivos: Dispositivo[]): void {
    // Filtro de los dispositivos para mostrar la descripción correcta.
    for (const disp of dispositivos) {
      switch (disp.subtipo.cod) {
        case 'TENS': {
          disp.descripcion = 'BIOMEDIDAS.TENSIOMETRO.DESCRIPCION';
          break;
        }
        case 'BASC': {
          disp.descripcion = 'BIOMEDIDAS.BASCULA.DESCRIPCION';
          break;
        }
        case 'IMC': {
          disp.descripcion = 'BIOMEDIDAS.BASCULAIMC.DESCRIPCION';
          break;
        }
        case 'COAG': {
          disp.descripcion = 'BIOMEDIDAS.COAGULOMETRO.DESCRIPCION';
          break;
        }
        case 'GLUC': {
          disp.descripcion = 'BIOMEDIDAS.GLUCOMETRO.DESCRIPCION';
          break;
        }
        case 'PULS': {
          disp.descripcion = 'BIOMEDIDAS.PULSIOXIMETRO.DESCRIPCION';
          break;
        }
        case 'TERM': {
          disp.descripcion = 'BIOMEDIDAS.TERMOMETRO.DESCRIPCION';
          break;
        }
        case 'INS': {
          disp.descripcion = 'BIOMEDIDAS.INSULINA.DESCRIPCION';
          break;
        }
        case 'RHC': {
          disp.descripcion = 'BIOMEDIDAS.RHC.DESCRIPCION';
          break;
        }
        case 'EF': {
          disp.descripcion = 'BIOMEDIDAS.EF.DESCRIPCION';
          break;
        }
      }
    }

    this.dispositivos = dispositivos;
    this.dispositivosSubject.next(this.dispositivos);
  }

  public buscarDispositivo(actividad: Actividad, dispositivos: Dispositivo[]): Dispositivo {
    // Función que se encarga de abrir el dispositivo correspondiente a la actividad de toma de biomedida.
    const split = actividad.agrupador.split('_2', 1);
    const agrupador = split[0];
    const agrupador2 = agrupador.split('_')[1];

    // Se encuentra el dispositivo correspondiente a la actividad.
    const dispositivo = dispositivos.filter(
      (d) => d.sensor.actividad.indexOf(agrupador) > -1 || d.sensor.variablesclinicas.indexOf(agrupador2) > -1
    )[0];

    return dispositivo;
  }


  private gestionarError(error: HttpErrorResponse): void {
    this.alertService.lanzarError(error.status);
  }
}
