import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Actividad, Biomedida } from '../../../../core/models';
import { GestionActBiomedidaService } from '../../../../core/services/gestion-act-biomedida.service';

declare var $: any;

/**
 *  Componente para la visualización de biomedidas.
 *
 * @author lreverendo
 *
 * @version 01.02.0070
 * @since 01.02.0000
 */
@Component({
  selector: 'app-visualizacion-biomedida',
  templateUrl: './visualizacion-biomedida.component.html',
  styleUrls: ['../../biomedidas.component.css', './visualizacion-biomedida.component.css'] // falta '../../modal/modal.component.css'
})
export class VisualizacionBiomedidaComponent implements OnInit, OnDestroy {
  public actividad: Actividad = {
    id: '',
    descripcion: [],
    estado: '',
    fechaActividad: '',
    idPaciente: '',
    pautada: true,
    titulo: [],
    tipo: '',
    agrupador: '',
    idProtocolo: '',
    codigoActividad: '',
    fechaInicioConMargen: '',
    fechaFinalConMargen: '',
    fechaInicioSinMargen: '',
    fechaFinalSinMargen: '',
    todoElDia: false,
    url: '',
    idInstancia: '',
    actRelacionadas: [],
    antesEjercicio: false,
    duranteEjercicio: false,
    despuesEjercicio: false
  };

  public datasets: {
    tipo: String;
    biomedida: Biomedida;
  }[] = [];

  public periodo: String;
  public observaciones: String;

  private sub: Subscription;

  constructor(private gestionActBiomedidaService: GestionActBiomedidaService, private translateService: TranslateService) {}

  public ngOnInit(): void {
    this.periodo = null;
    this.observaciones = null;

    // Subscripción al evento visualizarBiomedida.
    this.sub = this.gestionActBiomedidaService.visualizarBiomedida.subscribe((data) => {
      // Se recuperan la actividad y biomedidas pasadas.
      this.actividad = data.actividad;
      this.datasets = data.biomedidas;

      this.periodo = this.datasets[0].biomedida.periodoDia;
      this.observaciones = this.datasets[0].biomedida.observaciones;

      // Se abre el modal.
      $('#biomedida').modal('show');
    });
  }

  public ngOnDestroy(): void {
    // Baja a la subscripción.
    this.sub.unsubscribe();
  }

  public registrar(): void {
    this.gestionActBiomedidaService.realizarBiomedida(this.actividad);
  }

  public getCurrentIdioma(): string {
    // Se recupera el idioma actual.
    return this.translateService.currentLang;
  }

  public isNaN(valor: number) {
    // Método que recupera si el valor pasado es o no nulo.
    return Number.isNaN(valor);
  }
}
