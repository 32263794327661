import { Router } from '@angular/router';
import { AutenticacionService } from '../services/autenticacion.service';
import { Observable } from 'rxjs';
import { ExternoService, LoaderService } from '../services';
import * as i0 from "@angular/core";
import * as i1 from "../services/autenticacion.service";
import * as i2 from "../services/externo.service";
import * as i3 from "@angular/router";
import * as i4 from "../services/loader.service";
/**
 * Servicio que controla el redireccionamiento cuando el usuario está autenticado en una página externa.
 *
 * @author lreverendo
 *
 * @version 01.02.0002
 * @since 01.02.0000
 */
var ExternoAuthGuardService = /** @class */ (function () {
    function ExternoAuthGuardService(autenticacionService, externoService, router, loader) {
        this.autenticacionService = autenticacionService;
        this.externoService = externoService;
        this.router = router;
        this.loader = loader;
    }
    ExternoAuthGuardService.prototype.canActivate = function (_next, _state) {
        var _this = this;
        return new Observable(function (observer) {
            if (_this.autenticacionService.isUsuarioAutenticado()) {
                if (_this.externoService.getOperacion() == null) {
                    _this.autenticacionService.logout();
                    observer.next(false);
                }
                observer.next(true);
            }
            else {
                _this.loader.redireccionar.emit();
                _this.router.navigate(['/externo']);
                observer.next(false);
            }
        });
    };
    ExternoAuthGuardService.ngInjectableDef = i0.defineInjectable({ factory: function ExternoAuthGuardService_Factory() { return new ExternoAuthGuardService(i0.inject(i1.AutenticacionService), i0.inject(i2.ExternoService), i0.inject(i3.Router), i0.inject(i4.LoaderService)); }, token: ExternoAuthGuardService, providedIn: "root" });
    return ExternoAuthGuardService;
}());
export { ExternoAuthGuardService };
