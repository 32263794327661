import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { VariableClinica } from '../../../../../core/models';
import { DispositivoGenerico } from '../dispositivo-generico.model';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';

/**
 * Componente que representa las raciones de hidratos de carbono.
 *
 * @author lreverendo
 *
 * @version 01.02.0070
 * @since 01.02.0000
 */
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tbody [hidratos-carbono]',
  templateUrl: 'hidratos-carbono.component.html',
  styleUrls: [
    '../../../biomedidas.component.css',
    '../../../views/registro-biomedida/registro-biomedida.component.css',
    './hidratos-carbono.component.css'
  ]
})
export class HidratosCarbonoComponent extends DispositivoGenerico implements OnInit {
  public rhc: VariableClinica;

  constructor() {
    super();
  }

  public ngOnInit(): void {
    // Se busca la variable RHC.
    this.rhc = this.variablesClinicas.find((v) => v.alias === 'RHC');

    if (this.rhc) {
      this.forma.addControl(
        'rhc',
        new FormControl('', [
          Validators.required,
          Validators.pattern(this.getPatronFormato(this.rhc.formato)),
          CustomValidators.porRango(this.rhc.min, this.rhc.max)
        ])
      );
    }
  }

  public getData(): VariableClinica[] {
    const result: VariableClinica[] = [];

    if (this.rhc) {
      const rhc = this.forma.value.rhc.replace(',', '.');

      if (Number(rhc) === NaN) {
        return [];
      }

      this.rhc.valor = rhc;
      result.push(this.rhc);
    }

    return result;
  }
}
