import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AutenticacionService } from './autenticacion.service';
import { AppConfigService } from './app-config.service';
import { Actividad, TiposActividad } from '../models';
import { sprintf } from 'sprintf-js';

/**
 * Servicio para la gestión del actividades.
 *
 * @author lreverendo
 * @author dvila
 *
 * @version 01.02.0030
 * @since 01.02.0000
 */
@Injectable({ providedIn: 'root' })
export class ActividadesService {
  constructor(private http: HttpClient, private autenticacionService: AutenticacionService) {}

  public getBiomedidas(fechaIni: String, fechaFin: String): Observable<Actividad[]> {
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.recuperarActividadesBiomedidas, dniPaciente, fechaIni, fechaFin);
    return this.http.get<Actividad[]>(url);
  }

  public getCuestionarios(fechaIni: String, fechaFin: String): Observable<Actividad[]> {
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.recuperarActividadesCuestionarios, dniPaciente, fechaIni, fechaFin);
    return this.http.get<Actividad[]>(url);
  }

  public getPersonales(fechaIni: String, fechaFin: String): Observable<Actividad[]> {
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.recuperarActivdadesPersonales, dniPaciente, fechaIni, fechaFin);
    return this.http.get<Actividad[]>(url);
  }

  public getTodas(fechaIni: String, fechaFin: String, tiposActividades: String[]): Observable<Actividad[]> {
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.recuperarActividades, dniPaciente, fechaIni, fechaFin, tiposActividades);
    return this.http.get<Actividad[]>(url);
  }

  public getTiposActividades(): Observable<TiposActividad> {
    const url = sprintf(AppConfigService.urls.recuperarTiposActividades);
    return this.http.get<TiposActividad>(url);
  }

  public crearActividadPersonal(actividad: Actividad): Observable<Object> {
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.crearActividadPersonal, dniPaciente);
    return this.http.post(url, actividad);
  }

  public editarActividadPersonal(actividad: Actividad): Observable<Object> {
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.editarActividadPersonal, dniPaciente);
    return this.http.post(url, actividad);
  }
}
