<loader></loader>

<div class="row tel-encabezado-pagina">
  <div class="col-12">
    <h3 class="tel-titulo-pagina">
      <strong>{{ 'CUESTIONARIOS.LISTADO.TITULO' | translate }}</strong>
    </h3>
  </div>
</div>
<div class="row">
  <div class="separador"></div>
</div>
<h3 *ngIf="actividadesTratadas.length == 0 && !loader.active" class="texto-centrado">
  {{ 'CUESTIONARIOS.SIN' | translate }}
</h3>
<div class="eventos-contenido" *ngIf="!loader.active" data-simplebar>
  <div
    *ngFor="let actividad of actividadesTratadas; index as i"
    class="list-group-item btn col-12 actividad"
    (click)="calendarioService.abrirCuestionario(actividad)"
    [ngClass]="{ primera: i == 0 }"
  >
    <div class="row">
      <i class="icon-cuestionario_listado icono" [appClaseTipoActividad]="actividad"> </i>
      <div class="col">
        <div class="row">
          <div class="col-10 actividadInfo" [ngClass]="{ 'col-12': !(actividad.estado | contiene: 'ADHERIDO') }">
            {{ actividad.titulo | localeTexto: getCurrentIdioma() }}
            <p *ngIf="!actividad.todoElDia && !(actividad.estado | contiene: 'ADHERIDO')">
              {{ actividad.fechaInicioSinMargen | date: 'HH:mm' }} - {{ actividad.fechaFinalSinMargen | date: 'HH:mm' }}
            </p>
          </div>
          <div class="col-2 realizado" *ngIf="actividad.estado | contiene: 'ADHERIDO'">
            <div class="icon-ok" align-self-center id="tickRealizado"></div>
            <p>{{ actividad.fechaActividad | date: 'HH:mm' }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-registro-cuestionario></app-registro-cuestionario>
