import { AutenticacionService } from '../services/autenticacion.service';
import { Observable } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../services/autenticacion.service";
/**
 * Servicio que controla el redireccionamiento cuando el usuario está autenticado.
 *
 * @author priveiro
 *
 * @version 01.02.0002
 * @since 01.02.0000
 */
var LoginGuardService = /** @class */ (function () {
    function LoginGuardService(autenticacionService) {
        this.autenticacionService = autenticacionService;
    }
    LoginGuardService.prototype.canActivate = function (_next, _state) {
        var _this = this;
        return new Observable(function (observer) {
            if (_this.autenticacionService.isUsuarioAutenticado()) {
                observer.next(false);
            }
            else {
                _this.autenticacionService.borrarDatosAutenticacion();
                observer.next(true);
            }
        });
    };
    LoginGuardService.ngInjectableDef = i0.defineInjectable({ factory: function LoginGuardService_Factory() { return new LoginGuardService(i0.inject(i1.AutenticacionService)); }, token: LoginGuardService, providedIn: "root" });
    return LoginGuardService;
}());
export { LoginGuardService };
