/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dispositivos.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../../shared/components/loader/loader.component.ngfactory";
import * as i5 from "../../shared/components/loader/loader.component";
import * as i6 from "../../core/services/loader.service";
import * as i7 from "./dispositivos.component";
import * as i8 from "../../core/services/alert.service";
import * as i9 from "../../core/services/perfil.service";
import * as i10 from "../../core/services/vinculacionDispositivos.service";
var styles_DispositivosComponent = [i0.styles];
var RenderType_DispositivosComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DispositivosComponent, data: {} });
export { RenderType_DispositivosComponent as RenderType_DispositivosComponent };
function View_DispositivosComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "dispositivoVinculado"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("DISPOSITIVOS.VINCULACION.VINCULADO")); _ck(_v, 1, 0, currVal_0); }); }
function View_DispositivosComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.irURLVinculacionDispositivo(_v.parent.context.$implicit["nombre"]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("DISPOSITIVOS.VINCULACION.VINCULAR")); _ck(_v, 1, 0, currVal_0); }); }
function View_DispositivosComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "list-group-item btn col-12 dispositivo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["class", "icono"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "col dispositivoInfo"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DispositivosComponent_2)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DispositivosComponent_3)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.tieneVinculoAbbott; _ck(_v, 9, 0, currVal_2); var currVal_3 = !_co.tieneVinculoAbbott; _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = ("assets/images/" + _v.context.$implicit["icono"]); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(("DISPOSITIVOS." + _v.context.$implicit["nombre"]))); _ck(_v, 6, 0, currVal_1); }); }
export function View_DispositivosComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "loader", [], null, null, null, i4.View_LoaderComponent_0, i4.RenderType_LoaderComponent)), i1.ɵdid(2, 442368, null, 0, i5.LoaderComponent, [i1.ElementRef, i6.LoaderService], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "row tel-encabezado-pagina"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "h3", [["class", "tel-titulo-pagina"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["class", "separador"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "eventos-contenido"], ["data-simplebar", ""]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DispositivosComponent_1)), i1.ɵdid(13, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_1 = _co.listaDispositivosTodos; _ck(_v, 13, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("DISPOSITIVOS.TITULO")); _ck(_v, 7, 0, currVal_0); }); }
export function View_DispositivosComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dispositivos", [], null, null, null, View_DispositivosComponent_0, RenderType_DispositivosComponent)), i1.ɵdid(1, 245760, null, 0, i7.DispositivosComponent, [i8.AlertService, i6.LoaderService, i9.PerfilService, i2.TranslateService, i10.VinculacionDispositivosService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DispositivosComponentNgFactory = i1.ɵccf("app-dispositivos", i7.DispositivosComponent, View_DispositivosComponent_Host_0, {}, {}, []);
export { DispositivosComponentNgFactory as DispositivosComponentNgFactory };
